<template>
  <div class="vlw__cti-questions">
    <Collapse :label="label" :initiallyCollapsed="false">
      <template slot="buttons">
        <VlButton
          :class="{ 'vl-button--disabled': hasClosedStatus }"
          :mod-narrow="true"
          @click.prevent.stop="addNewQuestion"
          icon="plus"
          :mod-icon-before="true"
          >Vraag toevoegen</VlButton
        >
      </template>

      <Question
        v-for="q in questions"
        :ref="'question-' + q.internalId"
        :question="q"
        :key="q.internalId"
        @delete="onDeleteQuestion(q)"
      />
    </Collapse>
  </div>
</template>

<script>
// Import modules
import { mapState, mapActions, mapGetters } from 'vuex';
import Question from './Question.vue';
import bus from '@/utils/bus';

export default {
  name: 'Questions',
  components: {
    Question,
  },
  computed: {
    ...mapState('contact', ['contact']),
    ...mapGetters('contact', ['hasClosedStatus', 'channelIsTicket']),
    ...mapGetters('metadata', ['getLabelByName']),

    // Return all questions that aren't deleted yet
    questions() {
      return this.contact.questions;
    },

    // Amount of questions
    questionsAmount() {
      return this.questions ? this.questions.length : 0;
    },

    label() {
      return `Vragen (${this.questionsAmount})`;
    },
  },
  methods: {
    ...mapGetters('contact', ['isScreenSharingAllowed']),
    ...mapActions('contact', ['addQuestion', 'deleteQuestion']),

    // Add a new question
    addNewQuestion() {
      const label = this.getLabelByName('add_question_missing_theme');

      // Exit early if contact has no theme
      if (!this.contact.themeId) {
        bus.$emit('show-modal', {
          message: label.title,
          body: label.body,
        });
        return;
      }

      // Setup question defaults
      const questionDefaults = {
        expanded: true,
        channel: this.contact.channel, // Copy over the channel from the contact
      };

      if (this.contact.citizen) {
        questionDefaults.citizen = this.contact.citizen;
        questionDefaults.firstName = this.contact.citizen.firstName;
        questionDefaults.lastName = this.contact.citizen.lastName;
      }

      if (this.channelIsTicket) {
        questionDefaults.title = this.contact.sourceQuestion;
      }

      if (this.isScreenSharingAllowed) {
        questionDefaults.addScreenshotsForEscalationEmail = this.isScreenSharingAllowed();
      }

      // Add a new question using the store
      this.addQuestion(questionDefaults).then(question => {
        // Make sure the questions section is expanded
        this.isExpanded = true;

        // Focus on the question input
        this.$nextTick(() => {
          if (
            question &&
            question.internalId &&
            this.$refs &&
            this.$refs[`question-${question.internalId}`] &&
            this.$refs[`question-${question.internalId}`][0] &&
            this.$refs[`question-${question.internalId}`][0].focus
          ) {
            this.$refs[`question-${question.internalId}`][0].focus();
          }
        });
      });
    },

    // On delete question
    onDeleteQuestion(q) {
      // Check if the question is in progress
      if (!q.isInProgress) {
        // Show a modal
        const label = this.getLabelByName('delete_question_not_processed');

        bus.$emit('show-modal', {
          message: label.title,
          body: label.body,
        });

        // Exit early
        return;
      }

      // Reference self
      const self = this;

      // Show a confirmation modal
      const label = this.getLabelByName('confirm_delete_question');

      bus.$emit('show-modal', {
        message: label.title,
        body: label.body,
        cancel: true,
        cancelText: label.cancel,
        confirmText: label.confirm,
        confirm() {
          // Delete the question
          self.deleteQuestion(q);
        },
      });
    },
  },
};
</script>
