// Import modules
import { crmAxios } from '@/utils/Axios';
import { UPDATE_LOADING_STATE } from '@/constants/mutation-types';

// Export action to retrieve open contacts
export default ({ commit }) => {
  // Update the loading state
  commit(UPDATE_LOADING_STATE, true);

  // Call the API
  return crmAxios({
    method: 'get',
    url: `/Contact/myopencontacts`,
    headers: {
      Accept: 'application/json',
    },
  })
    .then(({ data }) => {
      // Update the loading state
      commit(UPDATE_LOADING_STATE, false);

      // Immediately return the data, as it will be consumed by the component immediately when being created
      return data;
    })
    .catch(err => {
      // Update the loading state
      commit(UPDATE_LOADING_STATE, false);

      // Re-throw the error
      throw err;
    });
};
