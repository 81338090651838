<template>
  <div role="alert" class="vl-alert" v-if="shouldBeVisible">
    <div class="vl-alert__icon">
      <span aria-hidden="true" class="vl-icon vl-vi vl-vi-warning"></span>
    </div>
    <div class="vl-alert__content">
      <div class="vl-alert__message">
        <ul class="list-disc mr-4">
          <li v-for="(text, index) in informationBanners" :key="index">
            {{ text }}
          </li>
        </ul>
      </div>
    </div>
    <button @click="hasBeenClosed = true">
      <VlIcon icon="close" />
    </button>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'InformationBanner',

  data() {
    return {
      hasBeenClosed: false,
    };
  },

  computed: {
    ...mapGetters('contact', ['informationBanners']),
    ...mapGetters('metadata', ['isInformationBannerEnabled']),

    shouldBeVisible() {
      return (
        !this.hasBeenClosed &&
        this.isInformationBannerEnabled &&
        this.informationBanners.length > 0
      );
    },
  },
};
</script>
