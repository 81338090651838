<template>
  <div class="vlw__cti-answer-list">
    <!-- Answers -->
    <VlDataTable v-if="answers.length">
      <tbody>
        <tr
          v-for="a in answers"
          :key="a.id"
          class="vlw__cti-answer-list__answer"
        >
          <td>
            <h2 class="vlw__cti-answer-list__answer-title">
              <VlIcon
                v-if="a.linked"
                icon="check"
                class="vlw__cti-answer-list__linked"
              />
              <VlLink
                href="#"
                @click.prevent="$emit('open-link', a)"
                v-html="a.title"
              ></VlLink>
              <VlIcon
                icon="external"
                class="vlw__cti-answer-list__answer-title__external"
              ></VlIcon>
              <span
                class="vlw__cti-answer-list__answer-date"
                :class="lastModifiedClass(a)"
                v-text="lastModified(a)"
              ></span>
            </h2>
          </td>

          <td>
            {{ a.category }}
          </td>

          <td class="vlw__cti-answer-list__answer-actions">
            <VlButton
              @click.prevent="$emit('use', a)"
              :mod-narrow="true"
              :mod-disabled="a.linked"
            >
              voorlichten</VlButton
            >
            <VlLink
              target="_blank"
              @click.prevent="$emit('open', a)"
              class="vl-button vl-button--narrow vl-button--secondary"
              >Open</VlLink
            >
          </td>
        </tr>
      </tbody>
    </VlDataTable>
  </div>
</template>

<script>
// Import modules
import formatDistance from 'date-fns/formatDistance';
import nlLocale from 'date-fns/locale/nl';

// Export component
export default {
  name: 'AnswersList',
  props: {
    answers: {
      type: Array,
      required: true,
    },
  },
  methods: {
    lastModifiedClass(answer) {
      // Check if created in the last 4 days
      let lastCreatedInDays = answer.lastCreatedInDays;
      if (typeof lastCreatedInDays === 'number' && lastCreatedInDays < 5) {
        return {
          'vlw__cti-answer-list__answer-date--red': true,
        };
      }

      // Check if updated in the last 4 days
      let lastUpdatedInDays = answer.lastUpdatedInDays;
      if (typeof lastUpdatedInDays === 'number' && lastUpdatedInDays < 5) {
        return {
          'vlw__cti-answer-list__answer-date--green': true,
        };
      }

      // Return false by default
      return false;
    },

    lastModified(answer) {
      // Exit early if possible
      if (!this.lastModifiedClass) {
        return false;
      }

      // Get the date to compare with
      let compareDate = answer.updatedOn;
      let compareTextSuffix = ' geleden bijgewerkt';

      let lastCreatedInDays = answer.lastCreatedInDays;
      if (typeof lastCreatedInDays === 'number' && lastCreatedInDays < 5) {
        compareDate = answer.createdOn;
        compareTextSuffix = ' geleden aangemaakt';
      }

      // Return the formatted distance to today
      return (
        formatDistance(new Date(), compareDate, {
          locale: nlLocale,
        }) + compareTextSuffix
      );
    },
  },
};
</script>

<style lang="scss">
// Wrapper
.vlw__cti-answer-search {
  position: relative;
}

// Results
.vlw__cti-answer-list {
  position: relative;
  margin-bottom: 20px;

  .vl-loader {
    position: absolute;
    top: 20px;
    left: 50%;
    transform: translateX(-50%);
  }

  &.loading {
    .vlw__cti-answer-list__answer {
      pointer-events: none;
      opacity: 0.5;
    }
  }
}

// Answer
.vlw__cti-answer-list__answer {
}

// Answer title
.vlw__cti-answer-list__answer-title {
  font-size: 16px;
  color: #0055cc;
  display: inline-block;

  .vl-icon {
    margin-right: 10px;
  }

  a {
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }

  // Linked icon
  .vlw__cti-answer-list__linked {
    font-size: 14px;
    color: #8bae00;
  }
}

// External link icon
.vl-icon.vlw__cti-answer-list__answer-title__external {
  display: inline-block;
  transform: translate(4px, 4px);
}

// Answer date created / modified
.vlw__cti-answer-list__answer-date {
  font-weight: normal;
  font-size: 13px;
  display: inline-block;
  padding: 0 5px;
  color: #333332;

  &--green {
    color: green;
  }

  &--red {
    background: red;
  }
}

// Answer updated
.vlw__cti-answer-list-details {
  display: inline-block;
  font-size: 14px;
  color: #666;
}

// Answer actions
.vl-data-table td.vlw__cti-answer-list__answer-actions {
  min-width: 250px;
  text-align: right;

  > button {
    margin-right: 5px;
    display: inline;

    &:last-child {
      margin: 0;
    }
  }
}
</style>
