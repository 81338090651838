// Import modules
import formatDate from 'date-fns/format';
import Answer from './Answer';
import Citizen from './Citizen';
import { priorities, responseTypeCodes } from '@/config';
import Annotation from './Annotation';
import { crmAxios } from '@/utils/Axios';

// Create class
class Question {
  constructor(obj) {
    // Default the obj
    obj = obj || {};

    // UI properties
    this.loading = false;
    this.isValid = true;
    this.errors = [];

    // Set properties
    this.id = obj.id || null;
    this.questionNumber = obj.questionNumber || '';
    this.internalId = obj.internalId || null;
    this.themeId = obj.themeId || ''; // guid
    this.title = obj.title || obj.questionDescription || '';
    this.extraInformation = obj.extraInformation || '';
    this.expanded =
      typeof obj.expanded === 'undefined' ? true : obj.expanded || true;

    // Optional props
    this.licensePlate = obj.licensePlate || null;
    this.birthdate = obj.birthdate || null;

    // Channel
    const defaultresponseTypeCode = responseTypeCodes.find(
      rtc => !!rtc.isDefault,
    );
    this.responseTypeCode =
      obj.responseTypeCode ||
      (defaultresponseTypeCode ? defaultresponseTypeCode.key : null);

    // Priority
    const defaultPriority = priorities.find(p => !!p.isDefault);
    this.priorityCode =
      obj.priorityCode || (defaultPriority ? defaultPriority.key : null);

    // Citizen
    this.citizen =
      typeof obj.citizen !== 'undefined' ? new Citizen(obj.citizen) : {}; // citizen instance
    this.firstName = obj.firstName || '';
    this.lastName = obj.lastName || '';

    this.kindEnGezin_PersonId = obj.kindEnGezin_PersonId || null;

    // Variable fields
    this.nationalRegistryNumber = obj.nationalRegistryNumber || '';
    this.kboNumber = obj.kboNumber || '';
    this.bisNumber = obj.bisNumber || '';
    this.relationshipTypeCode = obj.relationshipTypeCode || null;
    this.articleNumber = obj.articleNumber || null;
    this.subscriptionNumber = obj.subscriptionNumber || ''; // Abonnementsnummer De Lijn
    this.dossierNumber = obj.dossierNumber || ''; // Dossiernummer De Lijn
    this.recognitionNumber = obj.recognitionNumber || ''; // Erkenningsnummer VAPH
    this.organization = obj.organization || '';
    this.attestNumber = obj.attestNumber || '';
    this.daycare = obj.daycare;
    this.addScreenshotsForEscalationEmail =
      typeof obj.addScreenshotsForEscalationEmail === 'undefined'
        ? false
        : obj.addScreenshotsForEscalationEmail || false;

    this.status = obj.status || null;
    this.subStatus = obj.subStatus || obj.questionSubStatusId || null;

    // Selected escalation point
    this.escalationPoint = obj.escalationPoint;

    // @todo check with Mathias why the CRM api uses the property `name` where it's `title` in other endpoints
    if (this.escalationPoint) {
      this.escalationPoint.title = this.escalationPoint.name;
    }

    // Solutions
    this.answers = (obj.solutions || []).map(s => {
      // Remember the CRM guid to be able to remove the solutions from the question
      s.crmId = s.id;

      // Update the id to match the original article number once this is available in the CRM api
      s.id = s.articleId;

      // Return an answer instance
      return new Answer(s);
    });
    this.answerResults = null; // results of searched / popular answers

    // Collapsed state of popular answers
    this.popularAnswersCollapsed = false;

    // Results of popular answers for this question
    this.popularAnswers = null;

    // Mark answers as escalated
    this.markEscalated();

    // Modified & created dates
    this.modifiedOn = obj.modifiedOn ? new Date(obj.modifiedOn) : new Date(); // 2018-11-07T10:06:45.7516543+00:00
    this.createdOn = obj.createdOn ? new Date(obj.createdOn) : new Date();

    this.annotations = (obj.annotations || []).map(s => {
      s.crmId = s.id;
      s.fileName = s.fileName;
      s.fileSize = s.fileSize;
      s.mimeType = s.mimeType;
      return new Annotation(s);
    });
  }

  // Getter to retrieve an array of all escalation points available for this question, based on linked solutions
  get escalationPoints() {
    return this.answers.filter(({ category }) =>
      ['escalation', 'contact'].includes(category),
    );
  }

  // Getter for modified
  get modified() {
    return formatDate(this.modifiedOn, 'dd/MM/yyyy HH:mm:ss');
  }

  // Getter for created
  get created() {
    return formatDate(this.createdOn, 'dd/MM/yyyy HH:mm:ss');
  }

  // Getter to check if the question is in progress ("in behandeling")
  get isInProgress() {
    return !(this.title && this.answers && this.answers.length);
  }

  // Helper to mark answers as escalated
  markEscalated() {
    // Solutions
    this.answers.forEach(a => {
      // Check if answer is escalated
      if (
        this.escalationPoint &&
        (this.escalationPoint.articleId === a.id || // @todo cleanup this property mess: CRM API uses different properties than the VLS, so we're hardcoding property names here. Preferably use properties unique to the VLP and match different sources to internal props.
          this.escalationPoint.externalReferenceId === a.id)
      ) {
        a.isEscalated = true;
      }
    });
  }

  // Helper function to clone an instance of this class
  clone() {
    return new Question(this);
  }

  // Helper method to add an answer
  addAnswer(a) {
    // Add the answer
    this.answers.push(a);

    // Update the isEscalated states of the answers
    if (this.escalationPoint) {
      this.answers.forEach(a => {
        a.isEscalated =
          a.id.toString() === this.escalationPoint.articleId.toString();
      });
    }
  }

  // Helper method to delete an answer
  deleteAnswer(answerObjOrId) {
    // Find the answer
    let idx = -1;
    this.answers.find((answer, i) => {
      if (answer.id === (answerObjOrId.id || answerObjOrId)) {
        idx = i;
        return true;
      }

      return false;
    });

    // Remove if found
    if (idx > -1) {
      this.answers.splice(idx, 1);
    }
  }

  addAnnotation(file, dataSource) {
    // Create a annotation instance
    const fileName = 'screenshot_' + Date.now().toString() + '.png';
    const annotationInstance = new Annotation({
      fileName: fileName,
      fileSize: file.size,
      mimeType: file.type,
      imageDataURL: dataSource,
    });
    annotationInstance.loading = true;
    const data = new FormData();

    data.append('annotation', file, fileName);

    // Build the url
    let url = `/Question/${this.id}/annotations`;
    let method = 'post';

    // Call the API
    return crmAxios({
      method,
      url,
      data,
    }).then(response => {
      // Validate response
      if (!response.data) {
        throw new Error(
          'CRM Error: No response received when saving question.',
        );
      }

      annotationInstance.crmId = response.data.id;
      this.annotations.push(annotationInstance);
      annotationInstance.loading = false;
    });
  }

  // Helper function to validate required props
  validate() {
    // Reset the isValid and errors state
    this.isValid = true;
    this.errors = [];

    // Check if question has a title
    if (!this.title) {
      this.errors.push('Vraag is verplicht.');
    }

    // Check if question has linked answers
    if (!this.answers.length) {
      this.errors.push('Koppel minstens één antwoord.');
    }

    // Non valid
    if (this.errors.length) {
      this.isValid = false;
      return {
        isValid: false,
        error: new Error(this.errors.join('\n')),
      };
    }

    // Valid
    return {
      isValid: true,
    };
  }

  // Helper function to retrieve an object to sync to the API
  toApiObject() {
    return {
      id: this.id,
      citizenId: this.citizen ? this.citizen.id : undefined,
      themeId: this.themeId,
      questionSubStatusId: this.subStatus,
      questionDescription: this.title,
      extraInformation: this.extraInformation,
      priorityCode: this.priorityCode,
      responseTypeCode: this.responseTypeCode,
      nationalRegistryNumber: this.nationalRegistryNumber,
      relationshipTypeCode: this.relationshipTypeCode,
      licensePlate: this.licensePlate,
      kindEnGezin_PersonId: this.kindEnGezin_PersonId,
      kboNumber: this.kboNumber,
      articleNumber: this.articleNumber,
      recognitionNumber: this.recognitionNumber,
      subscriptionNumber: this.subscriptionNumber,
      bisNumber: this.bisNumber,
      organization: this.organization,
      attestNumber: this.attestNumber,
      dossierNumber: this.dossierNumber,
      daycare: this.daycare,
      addScreenshotsForEscalationEmail: this.addScreenshotsForEscalationEmail,
    };
  }
}

// Export class
export default Question;
