import actions from './actions';
import mutations from './mutations';

const state = {
  answers: null,
  loading: false,
  loadingError: false,
  popularAnswersByThemeId: {},
};

const getters = {
  answers(state) {
    return state.answers || [];
  },

  getPopularAnswersByThemeId: state => themeId => {
    return state.popularAnswersByThemeId[themeId] || [];
  },
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
