<template>
  <div class="vlw__cti__home">
    <InformationBanner />

    <Contact />

    <Questions />
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import Push from 'push.js';

import Contact from './Contact';
import Questions from './Questions';
import InformationBanner from './InformationBanner';

export default {
  name: 'Home',

  components: {
    Contact,
    Questions,
    InformationBanner,
  },

  computed: {
    ...mapState('navigation', ['modals']),
  },

  methods: {
    ...mapActions('navigation', ['toggleModal']),
  },

  mounted() {
    // Request permission to show notifications
    Push.Permission.request().catch(() => {}); // eslint-disable-line no-empty
  },
};
</script>

<style lang="scss">
.vlw__cti__home {
  position: relative;
  max-width: 1920px;
  padding: 1rem;
  flex-grow: 1;
}

// Collapsible header
.vlw__cti__collapsible-header {
  display: flex;
  margin-bottom: 10px;

  .vlw__cti__collapsible-header__toggle {
    display: flex;
    text-decoration: none;
    flex: 1 0 auto;
    position: relative;
    padding-left: 25px;

    h1 {
      margin: 0;
    }

    .vl-icon {
      color: #000;
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      transition: transform ease-out 0.15s;
    }

    &.expanded {
      .vl-icon {
        transform: translateY(-50%) rotate(90deg);
      }
    }
  }
}

// Flex header
.vlw__cti__flex-header {
  display: flex;

  > * {
    align-self: center;
  }
}

// Pane
.vlw__cti__pane {
  padding-left: 15px;
  border-left: 4px solid #e8e8e8;

  .vlw__cti__pane {
    border-color: #d8d8d8;
  }
}

// Details flex fallback
.vlw__cti__details {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 10px;
}

// Details
.vlw__cti__details {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
  grid-gap: 0 20px;
}

.vlw__cti__details-item {
  flex: 1 0 150px; // flex fallback
  margin-bottom: 10px;
}

.vlw__cti__details-item__label {
  font-weight: 500;
}

.vlw__cti__details-item__value {
  select,
  input {
    width: 100%;
  }
}

// Details alternative
.vlw__cti__details--alternative {
  .vlw__cti__details-item__label {
    font-weight: normal;
  }

  .vlw__cti__details-item__value {
    font-weight: 500;
  }
}
</style>
