<template>
  <!-- Modal wrapper -->
  <Modal
    @closed="$emit('close')"
    id="modal-login"
    class="vlw__cti-modal-login"
    :open="true"
    :closable="false"
  >
    <!-- Log in -->
    <div>
      <!-- Title -->
      <h1>Log in</h1>

      <!-- Content -->
      <p v-if="!isRedirecting">
        You are not authorized. Please click on the button to login.
      </p>
      <p v-if="isRedirecting">Logging in...</p>
      <VlLoader v-if="isRedirecting"></VlLoader>

      <!-- Button -->
      <VlButton
        :mod-disabled="isRedirecting"
        class="vlw__cti-modal-login__login"
        @click.prevent="login"
        >Login</VlButton
      >
    </div>
  </Modal>
</template>

<script>
// Import modules
import { mapState } from 'vuex';

export default {
  name: 'Login',
  computed: {
    ...mapState('authentication', ['isRedirecting']),
  },
  methods: {
    // Login
    login() {
      this.$store.dispatch('authentication/login');
    },
  },
};
</script>

<style lang="scss">
.vlw__cti-modal-login__login {
  margin-top: 20px;
}
</style>
