<template>
  <div class="vl-input-group vl-datepicker inline-flex">
    <div>
      <VlInputField
        v-model="textValue"
        :mod-block="true"
        :mod-error="!isValid"
        @blur="handleBlur"
        :required="required"
      />

      <div class="mt-2" v-if="!isValid">
        <VlFormMessageError>Formaat is bijv. 20-09-2019</VlFormMessageError>
      </div>
    </div>

    <button
      ref="datepicker"
      class="vl-input-addon"
      tabindex="-1"
      type="button"
      @click.prevent
    >
      <span aria-hidden="true" class="vl-icon vl-vi vl-vi-calendar" />

      <span class="vl-u-visually-hidden">kies datum</span>
    </button>
  </div>
</template>

<script>
import flatpickr from 'flatpickr';
import Dutch from 'flatpickr/dist/l10n/nl.js';
import { format, isAfter, isValid } from 'date-fns';

const API_DATE_FORMAT = 'y-MM-dd';
const VISUAL_DATE_FORMAT = 'dd-MM-y';

const parse = string => {
  if (!string) {
    return string;
  }

  const [day, month, year] = string.split('-').map(Number);

  return new Date(year, month - 1, day);
};

export default {
  props: {
    defaultValue: String,
    required: Boolean,
  },

  data() {
    return this.defaultValue
      ? this.parseDefaultValue(this.defaultValue)
      : {
          textValue: '',
          value: '',
        };
  },

  computed: {
    isValid() {
      if (!this.value) {
        return true;
      }

      return isValid(this.value) && isAfter(this.value, new Date(1000, 1, 1));
    },
  },

  mounted() {
    this.datepicker = flatpickr(this.$refs.datepicker, {
      locale: Dutch.nl,
    });

    this.datepicker.config.onChange.push(selectedDates => {
      const [date] = selectedDates;

      if (date == null) {
        return;
      }

      this.value = date;

      this.textValue = this.getVisualFormat();
    });
  },

  methods: {
    getApiFormat() {
      return this.value ? format(this.value, API_DATE_FORMAT) : '';
    },

    getVisualFormat(value = this.value) {
      return value ? format(value, VISUAL_DATE_FORMAT) : '';
    },

    handleBlur() {
      this.value = parse(this.textValue);
    },

    parseDefaultValue(defaultValue) {
      return {
        textValue: this.getVisualFormat(new Date(defaultValue)),
        value: new Date(defaultValue),
      };
    },
  },

  watch: {
    defaultValue(newVal) {
      if (!newVal) {
        return;
      }

      const { textValue, value } = this.parseDefaultValue(newVal);

      this.textValue = textValue;
      this.value = value;
    },

    value() {
      if (this.isValid) {
        this.$emit('input', this.getApiFormat());

        this.datepicker.setDate(this.value);
      }
    },
  },
};
</script>
