export const COOKIE_NAME_CHAT_ROOM_ID = 'vl-chat-roomId';
export const COOKIE_NAME_CONTACT_ID = 'vlp-contact-id';
export const COOKIE_NAME_SIDEBAR_WIDTH = 'vlp-sidebar-width';

// pureconnect events
export const USER_EVENT = 'userEvent';
export const CTI_EVENT = 'ctiEvent';

// user status ids
export const ACW = 'ACW';
export const I2C_ACW = 'I2C_ACW';

// channel types
export const INCOMING_CHAT = 'IncomingChat';
export const INCOMING_CALL = 'IncomingCall';
export const INCOMING_EMAIL = 'IncomingEmail';
export const OUTGOING_CALL = 'OutgoingCall';
export const EXTERNAL_TICKET = 'ExternalTicket';

// extra interaction types
export const MANUAL_EMAIL_BACKOFFICE = 'ManualEmailBackoffice';
export const MANUAL_OUTGOING_CALL_BACKOFFICE = 'ManualOutgoingCallBackOffice';
export const OUTGOING_CALL_BACKOFFICE = 'OutgoingCallBackOffice';
export const OUTGOING_EMAIL = 'OutgoingEmail';
export const RECALL = 'Callback';
export const TELETOLK = 'TELETOLK';
export const SCREENSHARING = 'SCREENSHARING';

// api entities
export const CONTACT = 'CONTACT';

// cti
export const DISCONNECT_SUCCESS = 'DISCONNECT_SUCCESS';

export const EIC_GENERIC_OBJECT = 'EIC Generic Object';
