<template>
  <div v-resize:throttle.initial="onResize" class="vlw__cti-contact">
    <Collapse :label="label" :initiallyCollapsed="false">
      <template slot="buttons">
        <div class="flex items-center">
          <VlLink
            v-if="contact.isManuallyCreated"
            class="flex items-center mx-2"
            href="#"
            @click.prevent="onDeleteContact"
          >
            <VlIcon class="mr-4" icon="trash" />Contact verwijderen
          </VlLink>

          <VlLink
            class="mx-2"
            v-if="isLegacy"
            href="#"
            @click.prevent="onBackToOpenContacts"
            >Terug naar open contacten</VlLink
          >
        </div>
      </template>

      <div class="vlw__cti__details vlw__cti__details--alternative">
        <div class="vlw__cti__details-item">
          <div class="vlw__cti__details-item__label">Contact ID</div>
          <div class="vlw__cti__details-item__value">
            {{ contact.contactNumber }}
          </div>
        </div>
        <div class="vlw__cti__details-item">
          <div class="vlw__cti__details-item__label">Aangemaakt op</div>
          <div class="vlw__cti__details-item__value">{{ contact.created }}</div>
        </div>
        <div class="vlw__cti__details-item">
          <div class="vlw__cti__details-item__label">Aangepast</div>
          <div class="vlw__cti__details-item__value">
            {{ contact.modified }}
          </div>
        </div>
        <div class="vlw__cti__details-item">
          <div class="vlw__cti__details-item__label">Aangemaakt door</div>
          <div class="vlw__cti__details-item__value">{{ userName }}</div>
        </div>
        <div class="vlw__cti__details-item">
          <div class="vlw__cti__details-item__label">Herkomst</div>
          <div class="vlw__cti__details-item__value">
            {{ contact.isManuallyCreated ? 'Manueel' : 'Cti' }}
          </div>
        </div>
      </div>

      <!-- Form fields -->
      <div class="vlw__cti-contact__fields" :class="classes">
        <!-- status -->
        <label class="vl-form__label vlw__cti__field--status">
          <span>Status</span>

          <select
            tabindex="1"
            class="vl-select"
            :value="statusId"
            :disabled="true"
          >
            <option
              v-for="{ id, name } in contactStatuses"
              :key="id"
              :value="id"
              >{{ name }}</option
            >
          </select>
        </label>

        <!-- substatus -->
        <label class="vl-form__label vlw__cti__field--substatus">
          <span>Substatus</span>
          <select
            tabindex="2"
            class="vl-select"
            v-model="contact.subStatus"
            @change="changeSubStatus(contact.subStatus)"
            :disabled="!canSelectSubStatus"
          >
            <optgroup
              v-for="s in contactStatuses"
              :disabled="!isStatusEnabledById(s.id)"
              :key="s.id"
              :label="s.name"
            >
              <option
                v-for="ss in s.subStatuses"
                :key="ss.id"
                :value="ss.id"
                v-text="ss.name"
                :disabled="!isSubStatusEnabled(ss)"
              />
            </optgroup>
          </select>
        </label>

        <!-- line -->
        <label class="vl-form__label vlw__cti__field--line">
          <span>Lijn</span>
          <select
            tabindex="-1"
            class="vl-select"
            disabled
            v-model="contact.lineId"
          >
            <option disabled="disabled">Lijn</option>
            <option
              v-for="l in lines"
              :key="l.id"
              :value="l.id"
              v-text="l.name"
            ></option>
          </select>
        </label>

        <!-- start theme -->
        <label class="vl-form__label vlw__cti__field--starttheme">
          <span>Startthema</span>
          <select
            tabindex="-1"
            class="vl-select"
            disabled
            v-model="contact.startThemeId"
          >
            <option disabled="disabled">Startthema</option>
            <option
              v-for="t in themes"
              :key="t.id"
              :value="t.id"
              v-text="t.name"
            ></option>
          </select>
        </label>

        <!-- theme -->
        <label class="vl-form__label vlw__cti__field--theme">
          <span>Behandelthema</span>
          <select
            tabindex="3"
            class="vl-select"
            :class="{
              'vl-select--warning': contact.themeId !== contact.startThemeId,
            }"
            v-model="contact.themeId"
            @change="changeTheme(contact.themeId)"
          >
            <option disabled="disabled">Behandelthema</option>
            <option
              v-for="t in themes"
              :key="t.id"
              :value="t.id"
              v-text="t.name"
            ></option>
          </select>
        </label>

        <!-- citizen -->
        <div class="vlw__cti__field--citizen">
          <!-- Selected -->
          <CitizenDetails
            v-if="contact.citizen"
            :citizen="contact.citizen"
            @open="onOpenCitizen"
            @unlink="onUnlinkCitizen"
          />

          <!-- Not selected -->
          <form v-if="!contact.citizen" @submit.prevent="onSearch">
            <!-- first name -->
            <label class="vl-form__label">
              <span>Voornaam</span>

              <VlInputField tabindex="4" v-model.trim="search.firstName" />
            </label>

            <!-- last name -->
            <label class="vl-form__label">
              <span>Naam</span>
              <VlInputField
                v-if="!contact.citizen"
                tabindex="5"
                v-model.trim="search.lastName"
              />
            </label>

            <VlButton
              type="submit"
              icon="search"
              :mod-icon-before="true"
              :mod-narrow="true"
              >Zoek een burger</VlButton
            >

            <div v-if="totalCount" class="vl-u-text--small">
              {{ citizensFoundText }}
            </div>
          </form>
        </div>

        <!-- channel -->
        <label class="vl-form__label vlw__cti__field--channel">
          <span>Kanaal</span>
          <select
            tabindex="-1"
            class="vl-select"
            disabled
            v-model="contact.channel"
          >
            <option disabled="disabled">Kanaal</option>
            <option
              v-for="c in $options.channels"
              :key="c.key"
              :value="c.key"
              v-text="c.name"
            ></option>
          </select>
        </label>

        <!-- recorde question -->
        <label
          class="vl-form__label vlw__cti__field--recordedQuestion"
          v-if="showWhenSpeechRoutingEnabled"
        >
          <span>Ingesproken vraag</span>
          <textarea
            tabindex="7"
            class="vl-textarea vl-textarea--block"
            v-model="contact.recordedQuestion"
            disabled
          ></textarea>
        </label>
        <!-- agent comment -->
        <label
          :class="{
            'vl-form__label vlw__cti__field--comment-speechRoutingEnabled': showWhenSpeechRoutingEnabled,
            'vl-form__label vlw__cti__field--comment-speechRoutingDisabled': !showWhenSpeechRoutingEnabled,
          }"
        >
          <span>Agent comment</span>
          <textarea
            tabindex="8"
            class="vl-textarea vl-textarea--block"
            v-model="contact.comments"
          >
Tweede keer dat deze persoon contact opneemt</textarea
          >
        </label>
      </div>
    </Collapse>
    <!-- Manual contact modal -->
    <portal to="modal" v-if="showManualContact">
      <ManualContact @close="showManualContact = false" />
    </portal>

    <!-- Citizen Modal -->
    <portal to="modal" v-if="showCitizenModal">
      <Citizen
        :search="search"
        @close="showCitizenModal = false"
        @save="selectCitizen"
        @select="selectCitizen"
      ></Citizen>
    </portal>
  </div>
</template>

<script>
import resize from 'vue-resize-directive';

import { values } from 'lodash';
import { mapGetters, mapState, mapActions } from 'vuex';
import { channels, contactSubStatuses } from '@/config';
import CitizenClass from '../modules/Citizen';

import Citizen from './Citizen.vue';
import CitizenDetails from './CitizenDetails.vue';
import ManualContact from './ManualContact.vue';
import bus from '@/utils/bus';

export default {
  name: 'Contact',
  directives: {
    resize,
  },
  components: {
    Citizen,
    CitizenDetails,
    ManualContact,
  },
  channels: values(channels),
  data() {
    return {
      // Citizen modal
      showCitizenModal: false,

      // Manual contact modal,
      showManualContact: false,

      // Width of the component
      width: 0,

      // An citizen instance that provides initial search parameters
      search: new CitizenClass(),
    };
  },
  computed: {
    ...mapState('contact', ['contact', 'isLegacy']),
    ...mapState('citizen', ['totalCount']),
    ...mapState('metadata', ['lines', 'contactStatuses']),
    ...mapGetters('authentication', ['userName']),
    ...mapGetters('contact', [
      'callbacks',
      'hasQuestion',
      'isStatusEnabledById',
      'statusId',
      'isSpeechRouting',
      'showQuickSelectCitizen',
    ]),
    ...mapGetters('metadata', ['availableThemesByLineId', 'getLabelByName']),
    ...mapGetters('emails', ['hasSentEmail']),
    ...mapGetters('cti', ['isLoggedIn', 'isRecall']),

    canSelectSubStatus() {
      return !this.hasQuestion && !this.isRecall;
    },

    // Classes for the component
    classes() {
      // Exit early if stacked
      if (this.width < 768) {
        return [];
      }

      // Array with classes
      const classes = [];

      if (this.width < 1024) {
        classes.push('vlw__cti-contact__fields--col2');
      } else {
        classes.push('vlw__cti-contact__fields--col3');
      }

      // Return the classes array
      return classes;
    },

    // Citizens found text
    citizensFoundText() {
      if (this.totalCount === 1) {
        return '1 burger gevonden';
      }

      return `${this.totalCount} burgers gevonden`;
    },

    // Selected citizen
    citizen() {
      return this.contact.citizen;
    },

    citizenName() {
      return this.citizen
        ? `${this.citizen.firstName} ${this.citizen.lastName}`
        : null;
    },

    label() {
      return `Gegevens contact${
        this.citizenName ? `: ${this.citizenName}` : ''
      }`;
    },

    // Themes in selected line
    themes() {
      return this.availableThemesByLineId(this.contact.lineId);
    },

    showWhenSpeechRoutingEnabled() {
      return this.isSpeechRouting;
    },
  },
  methods: {
    ...mapActions('contact', [
      'changeSubStatus',
      'changeTheme',
      'changeLine',
      'getContact',
      'selectCitizen',
      'unlinkCitizen',
      'deleteContact',
    ]),
    ...mapActions('citizen', ['changeMode']),

    isSubStatusEnabled({ id, manuallySelectable }) {
      return manuallySelectable && id !== contactSubStatuses.WACHTEND_OP_INFO;
    },

    onUnlinkCitizen() {
      // Reset the search IC-1682
      this.search = new CitizenClass();

      // Dispatch action to unlink the citizen
      this.unlinkCitizen();
    },

    // Triggered when element dimensions change
    onResize() {
      if (this.$el && this.$el.getBoundingClientRect) {
        const rect = this.$el.getBoundingClientRect();
        this.width = rect.width || 0;
      }
    },

    // Triggered when user wants to search (submits form)
    onSearch() {
      if (this.showQuickSelectCitizen) {
        this.$store.dispatch('citizen/getQuickSelectCitizens').then(() => {
          this.changeModeToSearch();
        });
      } else {
        this.changeModeToSearch();
      }
    },

    changeModeToSearch() {
      this.changeMode('search');

      // Show the modal
      this.showCitizenModal = true;
    },

    // Triggered when clicked on the citizen's name
    onOpenCitizen() {
      // Edit the selected citizen
      this.$store.dispatch('citizen/edit', this.contact.citizen.id);

      // Show the modal
      this.showCitizenModal = true;
    },

    // Triggered when user clicks delete button
    onDeleteContact() {
      // Do not allow delete if the contact has questions
      if (this.contact.questions.length) {
        const label = this.getLabelByName('delete_contact');

        return bus.$emit('show-modal', {
          message: label.title,
          body: label.body,
          confirm: true,
          confirmText: label.confirm,
        });
      }

      // Show a confirmation modal
      const label = this.getLabelByName('confirm_delete_contact');

      bus.$emit('show-modal', {
        message: label.title,
        body: label.body,
        cancel: true,
        cancelText: label.cancel,
        confirmText: label.confirm,
        confirm: this.deleteContact,
      });
    },

    onBackToOpenContacts() {
      this.getContact(null);

      if (this.isLoggedIn) {
        this.$store.dispatch('cti/closeContact', undefined, { root: true });
      }
    },
  },
};
</script>

<style lang="scss">
// Wrapper
.vlw__cti-contact {
  // Textarea resizing
  .vl-textarea {
    resize: vertical;
  }

  .vlw__collapse__inner-container {
    padding: 2rem;
  }
}

// Selected citizen
.vlw__cti__field--citizen--selected {
  font-weight: normal;
  line-height: 22px;
}

.vlw__cti__field--comment-speechRoutingEnabled {
  min-height: 80px;
}

// Fields stacked
.vlw__cti-contact__fields {
  display: grid;
  grid-gap: 0 20px;

  > * {
    min-width: 0;
    min-height: 0;
  }

  // Labels
  label {
    margin-bottom: 10px;
    display: flex;
    align-items: center;

    > span {
      flex: 0 0 120px;
    }

    > input,
    > select {
      min-width: 0;
      flex: 1 1 auto;
    }
  }
}

// Fields in 2 columns
.vlw__cti-contact__fields--col2 {
  grid-template-columns: auto auto;
  grid-template-rows: auto auto auto;
  grid-template-areas:
    'a b'
    'c d'
    'e f'
    'g h'
    'i j'
    'k l'
    'm n'
    'o p';

  .vlw__cti__field--status {
    grid-area: a;
  }

  .vlw__cti__field--substatus {
    grid-area: c;
  }

  .vlw__cti__field--starttheme {
    grid-area: d;
  }

  .vlw__cti__field--citizen {
    grid-column: h;
    grid-row: h / p;
  }

  .vlw__cti__field--theme {
    grid-area: f;
  }

  .vlw__cti__field--line {
    grid-area: b;
  }

  .vlw__cti__field--channel {
    grid-area: e;
  }

  .vlw__cti__field--recordedQuestion {
    grid-area: g;
  }

  .vlw__cti__field--comment-speechRoutingEnabled {
    grid-area: i;
  }

  .vlw__cti__field--comment-speechRoutingDisabled {
    grid-area: g;
  }
}

// Fields in 3 columns
.vlw__cti-contact__fields--col3 {
  grid-template-columns: auto auto auto;
  grid-template-rows: auto auto auto auto;
  grid-template-areas:
    'a b c'
    'd e f'
    'g h i'
    'j k l'
    'm n o';

  .vlw__cti__field--status {
    grid-area: a;
  }

  .vlw__cti__field--substatus {
    grid-area: d;
  }

  .vlw__cti__field--starttheme {
    grid-area: e;
  }

  .vlw__cti__field--citizen {
    grid-column: c;
    grid-row: c / l;
  }

  .vlw__cti__field--theme {
    grid-area: h;
  }

  .vlw__cti__field--line {
    grid-area: b;
  }

  .vlw__cti__field--channel {
    grid-area: g;
  }

  .vlw__cti__field--recordedQuestion {
    grid-column: j / k;
    grid-row: j;
  }

  .vlw__cti__field--comment-speechRoutingEnabled {
    grid-column: m / n;
    grid-row: m;
  }

  .vlw__cti__field--comment-speechRoutingDisabled {
    grid-column: j / k;
    grid-row: j;
  }
}

// Unlink citizen
.vlw__cti-contact__unlink-citizen {
  margin-left: 10px;
  height: 24px;
}

// Select with a warning border
.vl-select {
  &.vl-select--warning {
    background-color: lighten(orange, 40%);
  }
}
</style>
