import bus from '@/utils/bus';
import { crmAxios } from '@/utils/Axios';
import { UPDATE_LOADING_STATE } from '@/constants/mutation-types';

export default ({ state, commit, getters, dispatch, rootGetters }, payload) => {
  // Exit early if no contact
  if (!state.contact) {
    return Promise.resolve();
  }

  // Prevent multiple requests
  if (state.loading) {
    return Promise.resolve();
  }

  commit(UPDATE_LOADING_STATE, true);

  // Default the payload
  payload = payload || {};

  // Validate
  const validation = state.contact.validate();
  if (!validation.isValid) {
    // If ran in background, don't show a pop-up
    commit(UPDATE_LOADING_STATE, false);

    if (payload.background) {
      console.log('Cant save changes', validation.error.message);
      return Promise.resolve();
    }

    const getLabelByName = rootGetters['metadata/getLabelByName'];

    const label = getLabelByName('contact_missing_fields');

    bus.$emit('show-modal', {
      message: label.title,
      body: validation.error.message,
    });
    return Promise.reject(validation.error);
  }

  // Get the data to post to the CRM api
  const data = state.contact.toApiObject();

  if (getters.channelIsChat) {
    data.sourceQuestion = rootGetters['sidebar/chat/sourceQuestion'];
    data.sourceUrl = rootGetters['sidebar/chat/sourceUrl'];
  }

  if (!getters.isManual) {
    data.interactionRecordingRetentionInMonths =
      rootGetters['cti/interactionRecordingRetentionInMonths'];
    data.recordedQuestion = rootGetters['cti/interactionNlpQuestion'];
  }

  // Build the url
  let url = '/Contact';
  let method = 'post';
  if (state.contact.id) {
    url += `/${state.contact.id}`;
    method = 'put';
  }

  // Call the API
  return crmAxios({
    method,
    url,
    data,
  })
    .then(response => {
      commit('saveContactSuccess', response.data);
      if (method === 'post' && !getters['isManual']) {
        // send contactId to cti
        const interactionId = rootGetters['cti/parentInteractionId'];
        const contactId = response.data.id;

        dispatch(
          'cti/sendContactId',
          { interactionId, contactId },
          { root: true },
        );
      }

      if (method === 'post' && getters['isManual']) {
        dispatch('sync');
      }

      if (method === 'post' && getters.defaultWorkgroupName == null) {
        console.log('De default werkgroep voor dit thema werd niet gevonden.');

        // bus.$emit('show-modal', {
        //   message: 'Foutje',
        //   body: 'De default werkgroep voor dit thema werd niet gevonden.',
        // });
      }
    })
    .catch(error => {
      commit(UPDATE_LOADING_STATE, false);

      commit('saveContactError', error);

      throw error;
    });
};
