import { DUTCH, ENGLISH, FRENCH, GERMAN, OTHER } from '@/constants/languages';

export default [
  {
    key: DUTCH,
    label: 'nl',
    name: 'Nederlands',
    isDefault: true,
  },
  {
    key: ENGLISH,
    label: 'en',
    name: 'Engels',
  },
  {
    key: FRENCH,
    label: 'fr',
    name: 'Frans',
  },
  {
    key: GERMAN,
    label: 'de',
    name: 'Duits',
  },
  {
    key: OTHER,
    label: 'andere',
    name: 'Andere',
  },
];
