export const REQUEST = 'REQUEST';
export const FAILURE = 'FAILURE';
export const SUCCESS = 'SUCCESS';

export const ACCEPT_INTERACTION = 'ACCEPT_INTERACTION';
export const ADD_INTERACTION = 'ADD_INTERACTION';
export const END_INTERACTION = 'END_INTERACTION';
export const HIDE = 'HIDE';
export const PAUSE_INTERACTION = 'PAUSE_INTERACTION';
export const PREPEND_HTML = 'PREPEND_HTML';
export const REMOVE = 'REMOVE';
export const REMOVE_INTERACTION = 'REMOVE_INTERACTION';
export const RESET = 'RESET';
export const RESET_INTERACTION = 'RESET_INTERACTION';
export const SHOW = 'SHOW';
export const UPDATE_CONTACT = 'UPDATE_CONTACT';
export const UPDATE_CONTACT_SUBSTATUS = 'UPDATE_CONTACT_SUBSTATUS';
export const UPDATE_ERROR = 'UPDATE_ERROR';
export const UPDATE_INTERACTION = 'UPDATE_INTERACTION';
export const UPDATE_INTERACTIONS = 'UPDATE_INTERACTIONS';
export const UPDATE_IS_REDIRECTING = 'UPDATE_IS_REDIRECTING';
export const UPDATE_LOADING_STATE = 'UPDATE_LOADING_STATE';
export const UPDATE_OUTBOUND_CALL = 'UPDATE_OUTBOUND_CALL';
export const UPDATE_TECHNICAL_ISSUE = 'UPDATE_TECHNICAL_ISSUE';
export const UPDATE_USER = 'UPDATE_USER';
