import Vue from 'vue';
import Vuex from 'vuex';

import * as modules from './modules';

Vue.use(Vuex);

const getInitialState = () => ({
  isFetchingFromCrm: false,
  isDirty: false,
});

const mutations = {
  isFetchingFromCrm: (state, newVal) => {
    state.isFetchingFromCrm = newVal;
  },
  isDirty: (state, newVal) => {
    state.isDirty = newVal;
  },
};

export default new Vuex.Store({
  modules,
  mutations,
  state: getInitialState(),
});
