import { mapKeys, upperFirst } from 'lodash';

import { PREPEND_HTML } from '@/constants/mutation-types';
import { parseTemplate } from '@/utils';

export default ({ commit, rootGetters, rootState }, { id, templateString }) => {
  const {
    contact: { citizen = {}, ...contact },
  } = rootState.contact;

  const Citizen = {
    FullName: `${citizen.firstName} ${citizen.lastName}`,
    ...mapKeys(citizen, (value, key) => upperFirst(key)),
  };

  const completionInfoKeys = [
    'ChildBirthDate',
    'Country',
    'Gender',
    'Language',
    'PostalCode',
    'Subject',
    'WhoIsCalling',
  ];

  const completionInfo = completionInfoKeys.reduce(
    (acc, key) => ({
      ...acc,
      [key]: contact[`completionInfo_${key}`],
    }),
    {},
  );

  const Contact = {
    ...mapKeys(contact, (value, key) => upperFirst(key)),
    ...completionInfo,
    Comment: contact.comments,
    FirstName: citizen.firstName,
    LastName: citizen.lastName,
    Line: rootGetters['contact/lineName'],
    Workgroup: rootGetters['contact/workgroupName'],
    InteractionId: rootGetters['cti/interactionId'],
    SourceUrl: contact.originatingEmailUrl,
    SourceQuestion: contact.originatingEmailContent,
  };

  const whoAmI = rootGetters['authentication/whoAmI'];

  const User = {
    FirstName: whoAmI.firstName,
    LastName: whoAmI.lastName,
    EmailAddress: whoAmI.emailAddress,
    Alias: whoAmI.alias,
  };

  const templateOptions = {
    Citizen,
    Contact,
    User,
  };

  const replacePlaceholders = parseTemplate(templateOptions);

  const html = replacePlaceholders(templateString);

  return commit(PREPEND_HTML, { id, html });
};
