import bus from '@/utils/bus';

export default ({ state, dispatch, getters, rootGetters }) => {
  const getLabelByName = rootGetters['metadata/getLabelByName'];

  const { cannotBeClosedBecause } = getters;

  if (cannotBeClosedBecause) {
    return bus.$emit('show-modal', {
      message: 'Sluiten niet mogelijk',
      body: cannotBeClosedBecause,
    });
  }

  // check there are questions with escalation points that have no escalationPoint linked
  let nonEscalatedQuestionWithEscalationPointsExists = false;
  state.contact.questions.forEach(q => {
    if (q.escalationPoints.length && !q.escalationPoint) {
      nonEscalatedQuestionWithEscalationPointsExists = true;
    }
  });

  // Check if the contact has a draft email
  const hasDraftEmail = !!(state.contact.emails || []).find(
    email => email.statusCode === 'Draft',
  );

  let label;

  // Build the body message
  if (nonEscalatedQuestionWithEscalationPointsExists) {
    label = getLabelByName('contact_confirm_closing_without_escalation');
  }

  if (hasDraftEmail) {
    label = getLabelByName('contact_confirm_closing_without_sending_email');
  }

  if (nonEscalatedQuestionWithEscalationPointsExists && hasDraftEmail) {
    label = getLabelByName(
      'contact_confirm_closing_without_escalation_and_sending_email',
    );
  }

  // Confirm closing if not escalated
  if (nonEscalatedQuestionWithEscalationPointsExists || hasDraftEmail) {
    // Ask for confirmation
    return bus.$emit('show-modal', {
      message: label.title,
      body: label.body,
      confirmText: label.confirm,
      confirm() {
        dispatch('closeContactCall');
      },
      cancel: true,
      cancelText: label.cancel,
    });
  }

  // Proceed
  return dispatch('closeContactCall');
};
