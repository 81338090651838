import Citizen from '@/modules/Citizen';

export default {
  // Mutation triggered when retrieving citizens
  getCitizens(state) {
    // Update the loading and loadingError state
    state.loading = true;
    state.loadingError = false;
  },

  // Mutation triggered when citizens are retrieved
  getCitizensSuccess(state, response) {
    // Check if the response is one of citizen/search or citizen/{id}
    if (!response.hasOwnProperty('totalCount')) {
      // Update the citizens
      state.totalCount = 1;
      state.citizens = new Array(response).map(result => new Citizen(result));
    } else {
      // Update the citizens
      state.totalCount = response.totalCount || 0;
      state.citizens = (response.results || []).map(
        result => new Citizen(result),
      );
    }

    // Update the loading and loadingError state
    state.loading = false;
    state.loadingError = false;
  },

  getQuickSelectCitizensSuccess(state, response) {
    // Check if the response is one of citizen/search or citizen/{id}
    if (!response.hasOwnProperty('totalCount')) {
      state.quickSelectCitizens = new Array(response).map(
        result => new Citizen(result),
      );
    } else {
      // Update the quick select citizens
      state.quickSelectCitizens = (response.results || []).map(
        result => new Citizen(result),
      );
    }

    // Update the loading and loadingError state
    state.loading = false;
    state.loadingError = false;
  },

  // Mutation triggered when there was an error retrieving citizens
  getCitizensError(state, error) {
    // Parse the error
    if (error.response && error.response.data && error.response.data.message) {
      // Check if a message is available
      state.loadingError = error.response.data.message.toString();
    } else if (error.message) {
      state.loadingError = error.message.toString();
    } else if (error.stack) {
      state.loadingError = error.stack.toString();
    }

    if (!state.loadingError) {
      state.loadingError = 'Failed to retrieve citizens. Please try again.';
    }

    // Update the loading state
    state.loading = false;
  },
};
