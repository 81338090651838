import { SYNC } from '@/constants/action-types';

import { makeAsyncMutationTypes } from '@/utils';
import { crmAxios } from '@/utils/Axios';
import { get } from 'lodash/fp';
import sanitizeEmail from '../utils/sanitize-email';

const { FAILURE, REQUEST, SUCCESS } = makeAsyncMutationTypes(SYNC);

const fetchEmailById = id =>
  crmAxios({
    url: `/Email/${id}`,
  }).then(get('data'));

export default async ({ commit, rootGetters }) => {
  commit(REQUEST);

  console.log(REQUEST);

  try {
    const emails = await Promise.all(
      (rootGetters['contact/emails'] || []).map(({ id }) => fetchEmailById(id)),
    );

    const sanitizedEmails = await Promise.all(
      emails.map(email => sanitizeEmail(email)),
    );

    commit(SUCCESS, sanitizedEmails);
  } catch (e) {
    commit(FAILURE, e);
  }
};
