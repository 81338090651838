<template>
  <div class="p-8">
    <div class="flex items-center mb-4">
      <span class="uppercase font-bold mr-4">Status:</span>

      <span>{{ readableInteractionState }}</span>
    </div>

    <VlAlert v-if="error" :content="error.message" :mod-error="true" />

    <form v-else @submit.prevent="handleOnConclude">
      <div class="flex items-baseline justify-between mb-4">
        <div class="flex">
          <VlInputField
            :disabled="!isManual || hasInitialized || !isLoggedIn"
            v-model="formData.phoneNumber"
            placeholder="Telefoonnnummer"
            required
          />

          <VlButton
            v-if="isManual"
            title="Copy-paste"
            :mod-narrow="true"
            :mod-secondary="true"
            :mod-disabled="hasInitialized || !isLoggedIn"
            :style="{ marginLeft: '-1px' }"
            @click.prevent="handleOnCopyPaste"
          >
            <VlIcon icon="copy-paste" />
          </VlButton>
        </div>

        <div class="flex items-center">
          <VlButton
            type="submit"
            class="divide ml-4"
            title="Burger doorschakelen naar back-office"
            :mod-narrow="true"
            :mod-disabled="!ctiInteractionState || hasCalled"
          >
            <CustomIcon icon="external-forwarding" />
          </VlButton>

          <VlButton
            v-if="isManual"
            title="Bellen"
            class="divide ml-4"
            :mod-narrow="true"
            @click.prevent="start"
            :mod-disabled="!canStart"
          >
            <CustomIcon icon="play" />
          </VlButton>

          <VlButton
            title="Afleggen"
            class="divide ml-4"
            :mod-disabled="!canStop"
            :mod-narrow="true"
            @click.prevent="handleOnEndInteraction"
          >
            <CustomIcon icon="stop" />
          </VlButton>
        </div>
      </div>

      <div class="flex items-center mb-4">
        <span class="uppercase font-bold mr-4">Resultaat:</span>

        <select class="vl-select" v-model="data.resultType">
          <option
            v-for="{ key, name } in $options.resultTypes"
            :key="key"
            :value="key"
            >{{ name }}</option
          >
        </select>
      </div>
    </form>

    <div class="flex justify-end" v-if="isManual">
      <VlLoader v-if="isDeleting" class="mr-4" />

      <a
        :class="['vl-link', { disabled: hasInitialized }]"
        href
        @click.prevent="handleOnDelete"
      >
        <VlIcon icon="trash" />

        <span>Verwijderen</span>
      </a>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';

import {
  CONNECTED,
  DIALING,
  EXTERNALLY_DISCONNECTED,
  INTERNALLY_DISCONNECTED,
  RINGING,
  UNASSIGNED,
} from '@/constants/interaction-states';
import {
  UPDATE_LOADING_STATE,
  UPDATE_OUTBOUND_CALL,
} from '@/constants/mutation-types';
import { getReadableInteractionState } from '@/utils';
import { crmAxios } from '@/utils/Axios';
import { DISCONNECT_SUCCESS } from '@/constants';

const resultTypes = [
  {
    key: 'TransferHandeledCorrectly',
    name: 'Correct verlopen',
  },
  {
    key: 'BoOccupied',
    name: 'BO bezet',
  },
  {
    key: 'BoNoAnswer',
    name: 'BO geen antwoord',
  },
  {
    key: 'NoTransferByBo',
    name: 'Geen overname door BO',
  },
  {
    key: 'WrongNumber',
    name: 'Verkeerd nummer',
  },
];

export default {
  name: 'OutgoingCallBackOffice',

  props: ['id', 'isConnected', 'data'],

  resultTypes,

  data() {
    return {
      error: null,
      formData: {
        phoneNumber: this.data.phoneNumber || this.data.outboundNumber,
      },
      interactionId: this.data.interactionId,
      isDeleting: false,
      isLoading: false,
      isRinging: false,
      isSuccess: false,
    };
  },

  computed: {
    ...mapGetters('cti', [
      'getInteractionById',
      'isLoggedIn',
      'transferInteractionId',
    ]),
    ...mapGetters('contact', ['defaultEscalationPointId']),

    canStart() {
      return this.hasDestination && !this.canStop && !this.hasCalled;
    },

    canStop() {
      return this.isRinging || this.isCalling;
    },

    ctiInteraction() {
      return this.getInteractionById(this.data.interactionId) || {};
    },

    ctiInteractionState() {
      return this.ctiInteraction.interactionState;
    },

    destination() {
      return this.formData.phoneNumber;
    },

    hasDestination() {
      return !!this.destination;
    },

    isCalling() {
      return [CONNECTED, DIALING, RINGING].includes(this.ctiInteractionState);
    },

    isManual() {
      return this.data.phoneNumber == null;
    },

    hasCalled() {
      return (
        this.data.endTransfer != null ||
        [EXTERNALLY_DISCONNECTED, INTERNALLY_DISCONNECTED, UNASSIGNED].includes(
          this.ctiInteractionState,
        )
      );
    },

    hasInitialized() {
      return this.hasCalled || !!this.ctiInteractionState;
    },

    readableInteractionState() {
      if (
        this.ctiInteractionState === INTERNALLY_DISCONNECTED &&
        this.isSuccess
      ) {
        return 'Doorschakeling succesvol';
      }

      return getReadableInteractionState(this.ctiInteractionState);
    },
  },

  mounted() {
    if (!this.hasCalled && !this.isManual) {
      this.start();
    }
  },

  methods: {
    ...mapActions('cti', ['consultTransferInteraction', 'endInteraction']),
    ...mapMutations('cti', {
      updateLoadingState: UPDATE_LOADING_STATE,
    }),

    async handleOnConclude() {
      this.updateLoadingState(true);

      const { questionId, escalationPointId } = this.data;

      const escalateQuestion = async () => {
        try {
          await crmAxios({
            method: 'post',
            url: `/Question/${questionId}/escalate`,
            data: {
              escalationPointId,
              escalationType: 'ForwardCall',
            },
          });
        } catch (error) {
          this.error = error;
        }
      };

      const endInteraction = () =>
        this.consultTransferInteraction({
          phase: 'conclude',
          interactionId: this.transferInteractionId,
          destination: '',
        });

      await Promise.all([endInteraction(), escalateQuestion()]);

      this.isSuccess = true;
      this.updateLoadingState(false);
    },

    async handleOnCopyPaste() {
      this.formData.phoneNumber = await navigator.clipboard.readText();
    },

    async handleOnDelete() {
      this.isDeleting = true;

      await crmAxios({
        method: 'delete',
        url: `/OutboundCall/${this.id}`,
      });

      await this.$store.dispatch('contact/sync', undefined, { root: true });

      this.isDeleting = false;
    },

    handleOnEndInteraction() {
      this.endInteraction(this.interactionId);
    },

    async start() {
      this.isLoading = true;
      this.isRinging = true;

      try {
        if (this.isManual) {
          await this.useDefaultEscalationPoint();
        }

        const response = await this.consultTransferInteraction({
          phase: 'start',
          destination: this.destination,
          interactionId: this.transferInteractionId,
        });

        if (response === DISCONNECT_SUCCESS) {
          this.isLoading = false;
          this.isRinging = false;
          return;
        }

        if (
          !response ||
          !response.data ||
          !response.data.consultInteractionId
        ) {
          throw new Error('Er ging iets verkeerd.');
        }

        const { data } = response;

        this.interactionId = data.consultInteractionId;

        const newData = {
          ...this.data,
          ...data,
          interactionId: data.consultInteractionId,
          outboundNumber: this.destination,
          startTransfer: new Date(),
        };

        // optimistic update
        this.$store.commit(`contact/${UPDATE_OUTBOUND_CALL}`, {
          id: this.id,
          data: newData,
        });

        this.updateCrmRecord(newData);
      } catch (error) {
        this.error = error;
      }

      this.isLoading = false;
      this.isRinging = false;
    },

    updateCrmRecord(data) {
      return crmAxios({
        method: 'put',
        url: `/OutboundCall/${this.id}`,
        data,
      })
        .then(() =>
          this.$store.dispatch('contact/sync', undefined, { root: true }),
        )
        .catch(error => {
          this.error = error;

          this.isDeleting = false;
        });
    },

    async useDefaultEscalationPoint() {
      const {
        data: { articleId },
      } = await crmAxios({
        url: `/EscalationPoint/${this.defaultEscalationPointId}`,
      });

      const [answer] = await this.$store.dispatch(
        'answers/retrieveAnswersFromVLS',
        {
          articleId,
        },
      );

      if (answer == null) {
        throw new Error(`VLS article not found: ${articleId}`);
      }

      await crmAxios({
        method: 'post',
        url: `/Question/${this.data.questionId}/solutions`,
        data: answer.toApiObject(),
      });

      this.$store.dispatch('contact/sync', undefined, { root: true });
    },
  },

  watch: {
    isConnected(isConnected) {
      if (isConnected) {
        this.$emit('start');
      } else {
        this.$emit('stop');
      }
    },

    ctiInteractionState(newVal) {
      const hasEnded = [
        EXTERNALLY_DISCONNECTED,
        INTERNALLY_DISCONNECTED,
        UNASSIGNED,
      ].includes(newVal);

      if (hasEnded) {
        const newData = { ...this.data, endTransfer: new Date() };

        this.updateCrmRecord(newData);
      }
    },

    'data.resultType': function() {
      crmAxios({
        url: `OutboundCall/${this.id}`,
        method: 'put',
        data: this.data,
      });
    },
  },
};
</script>
