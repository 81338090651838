import { HIDE, SHOW } from '@/constants/mutation-types';

const getInitialState = () => ({
  showModal: false,
});

const mutations = {
  [HIDE](state) {
    state.showModal = false;
  },

  [SHOW](state) {
    state.showModal = true;
  },
};

export default {
  mutations,
  namespaced: true,
  state: getInitialState(),
};
