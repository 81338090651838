import actions from './actions';
import getters from './getters';
import modules from './modules';
import mutations from './mutations';

const state = {
  isRedirecting: false,
  user: null,
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  modules,
  mutations,
};
