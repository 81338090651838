import handleNewContact from './__LEGACY__update-contact';
import handleExistingContact from './handle-existing-contact';

const findExistingContactId = ({
  interactionExternalId,
  interactionTransferId,
  interactionWorkgroup,
}) => {
  if (interactionWorkgroup.includes('Chat') && !interactionTransferId) {
    return null;
  }

  return interactionTransferId || interactionExternalId;
};

export default context => async data => {
  const { commit, dispatch } = context;

  const {
    attributes: { Eic_RemoteAddress } = {},
    interactionId,
    interactionRemoteAddress,
    interactionWorkgroup,
  } = data;

  // remove any remaining ACW interactions
  commit('sidebar/RESET', null, { root: true });

  // search citizen if present
  const remoteAddress = interactionRemoteAddress || Eic_RemoteAddress;

  if (remoteAddress) {
    dispatch('citizen/searchByPhone', remoteAddress, { root: true });
  }

  // get contact from CRM
  const existingContactId = findExistingContactId(data);

  if (existingContactId) {
    handleExistingContact(context)(existingContactId, interactionId);
  } else {
    if (interactionWorkgroup.includes('Callback')) {
      return;
    }

    handleNewContact(context)(data);
  }
};
