import _ from 'lodash';

export default {
  isLoggedIn(state) {
    return state.user != null;
  },

  userEmail(state) {
    return _.get(state, 'user.profile.email');
  },

  userProfile(state) {
    return _.get(state, 'user.profile');
  },

  userName(state) {
    return _.get(state, 'user.profile.name');
  },

  userObjectId(state) {
    return _.get(state, 'user.profile.oid');
  },

  whoAmI({ whoAmI }) {
    return whoAmI.data;
  },

  workgroups({ workgroups }) {
    return workgroups.data;
  },
};
