import { mirageApi } from '@/utils';
import { SEARCH } from '@/constants/action-types';
import { makeAsyncMutationTypes } from '@/utils';

const { FAILURE, REQUEST, SUCCESS } = makeAsyncMutationTypes(SEARCH);

export default async ({ commit }, formData) => {
  commit(REQUEST);

  try {
    const {
      data: { personen = [] },
    } = await mirageApi({
      url: '/zoekPersoon',
      method: 'post',
      data: formData,
    });

    return commit(SUCCESS, personen);
  } catch (error) {
    commit(FAILURE, error);
  }
};
