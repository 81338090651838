import { prop } from 'lodash/fp';

import { FETCH } from '@/constants/action-types';
import { makeAsyncMutationTypes } from '@/utils';
import { crmAxios } from '@/utils/Axios';

const { FAILURE, REQUEST, SUCCESS } = makeAsyncMutationTypes(FETCH);

const endpoints = [
  'lines',
  'contactstatuses',
  'questionstatuses',
  'technicalissuetypes',
  'blockedemailaddresses',
  'vlplabels',
  'vlpbannerenabled',
];

const fetchMetadataEndpoint = endpoint =>
  crmAxios({
    url: `/Metadata/${endpoint}`,
  }).then(prop('data'));

export default {
  fetch({ commit }) {
    commit(REQUEST);

    return Promise.all(endpoints.map(fetchMetadataEndpoint))
      .then(
        ([
          lines,
          contactStatuses,
          questionStatuses,
          technicalIssueTypes,
          blockedEmailAddresses,
          vlplabels,
          vlpbannerenabled,
        ]) =>
          commit(SUCCESS, {
            lines,
            contactStatuses,
            questionStatuses,
            technicalIssueTypes,
            blockedEmailAddresses,
            vlplabels,
            vlpbannerenabled,
          }),
      )
      .catch(error => commit(FAILURE, error));
  },
};
