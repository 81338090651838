export default (value) => {
  if (!value) return true;

  const pattern = /^\d{10}$/;
  if (!pattern.test(value.toString())) {
    return false;
  }

  const start = parseInt(value.substr(0, 8));
  const rest = parseInt(value.substr(8, 2), 10);

  // Check control number
  var calculatedControl = 97 - (start % 97);

  return calculatedControl == rest;
};
