import {
  UPDATE_INTERACTIONS,
  UPDATE_LOADING_STATE,
  UPDATE_USER,
} from '@/constants/mutation-types';

import { LOGIN } from '@/constants/action-types';

import { makeAsyncMutationTypes } from '@/utils';

const [login] = [LOGIN].map(makeAsyncMutationTypes);

export default {
  [login.REQUEST](state) {
    state.isLoading = true;
  },

  [login.SUCCESS](state, { statusMessages = [], statusMessagesInfo }) {
    state.isLoading = false;
    state.statusMessages =
      statusMessagesInfo ||
      statusMessages.map(status => ({ status, isSelectable: true }));
  },

  [login.FAILURE](state, error) {
    state.isLoading = false;
    state.error = error;
  },

  [UPDATE_INTERACTIONS](state, interactions) {
    state.interactions = interactions;
  },

  [UPDATE_LOADING_STATE](state, isLoading) {
    state.isLoading = isLoading;
  },

  [UPDATE_USER](state, partialUser) {
    state.user = { ...state.user, ...partialUser };
  },
};
