import io from 'socket.io-client';

import { ctiApi, makeAsyncMutationTypes } from '@/utils';
import { LOGIN } from '@/constants/action-types';

import bus from '@/utils/bus';

import handleCtiError from './handle-cti-error';
import handlePureconnectEvent from './handle-pureconnect-event';

const { FAILURE, REQUEST, SUCCESS } = makeAsyncMutationTypes(LOGIN);

const PASSWORD = 'I2C1234';

const { VUE_APP_CTI_URL } = process.env;

export default (context, { ctiStation, isAuto }) => {
  const { commit, rootGetters } = context;

  const userEmail = rootGetters['authentication/userEmail'];
  const getLabelByName = rootGetters['metadata/getLabelByName'];
  const ctiDisconnectLabel = getLabelByName('cti_connection_lost');

  commit(REQUEST);

  return ctiApi({
    method: 'post',
    url: '/login',
    data: {
      ctiUserName: userEmail,
      ctiPassword: PASSWORD,
      ctiStation,
    },
  })
    .then(({ data }) => {
      commit(SUCCESS, data.data);

      const { pureConnectorToken } = data;

      const socket = io.connect(VUE_APP_CTI_URL, {
        query: { userName: userEmail, pureConnectorToken },
      });

      socket.on('message', message => {
        console.log(message);

        handlePureconnectEvent(context)(message);
      });

      socket.on('disconnect', reason => {
        bus.$emit('show-modal', {
          message: ctiDisconnectLabel.title,
          body: (ctiDisconnectLabel.body || '').replace('${reason}', reason),
          cancel: true,
          cancelText: ctiDisconnectLabel.cancel,
        });
      });
    })
    .catch(error => {
      commit(FAILURE, error);

      if (isAuto && error.response && error.response.status === 400) {
        return;
      }

      handleCtiError(commit)(error);
    });
};
