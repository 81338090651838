export { default as logIn } from './log-in';
export { default as post } from './post';

export const logOut = ({ dispatch }) => dispatch('post', { url: 'logout' });

export const updateStatus = ({ dispatch }, status) =>
  dispatch('post', { url: 'userstatus', data: { status } });

export const acceptInteraction = ({ dispatch }) =>
  dispatch('post', { url: 'pickup' });

export const pauseInteraction = ({ dispatch }, interactionId) =>
  dispatch('post', { url: 'hold', data: { interactionId } });

export const transferInteraction = ({ dispatch }, data) =>
  dispatch('post', {
    url: 'blindtransfer',
    data,
  });

export const endInteraction = ({ dispatch }, interactionId) =>
  dispatch('post', { url: 'disconnect', data: { interactionId } });

export const closeContact = ({ dispatch }, data) =>
  dispatch('post', { url: 'contactclosed', data });

export const call = ({ dispatch }, data) =>
  dispatch('post', { url: 'call', data });

export const consultTransferInteraction = ({ dispatch }, data) =>
  dispatch('post', { url: 'consulttransfer', data });

export const sendContactId = ({ dispatch }, data) =>
  dispatch('post', { url: 'contactid', data });

export const manualContact = ({ dispatch }, data) =>
  dispatch('post', { url: 'manualcontact', data });
