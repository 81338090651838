import { SEARCH } from '@/constants/action-types';
import { RESET } from '@/constants/mutation-types';
import { makeAsyncMutationTypes } from '@/utils';

import { getInitialState } from './index';

const [searchMutations] = [SEARCH].map(makeAsyncMutationTypes);

export default {
  ADD_PERSON(state, person) {
    state.miragePersonsById = {
      ...state.miragePersonsById,
      [person.miragePersoonId]: person,
    };
  },

  [RESET](state) {
    const initialState = getInitialState();

    Object.keys(initialState).forEach(key => {
      state[key] = initialState[key];
    });
  },

  [searchMutations.REQUEST](state) {
    state.isLoading = true;
  },

  [searchMutations.FAILURE](state, error) {
    state.isLoading = false;
    state.error = error;
  },

  [searchMutations.SUCCESS](state, data) {
    state.searchResults = data;
    state.isLoading = false;
    state.error = null;
  },
};
