import { crmAxios } from '@/utils/Axios';

export default ({ state, commit }, q) => {
  // Update the question loading indicator
  q.loading = true;

  // If the question has no id yet, remove it immediately
  if (!q.id) {
    state.contact.deleteQuestion(q);

    // Resolve
    return Promise.resolve();
  }

  // Call the API
  return crmAxios({
    method: 'delete',
    url: `/Question/${q.id}`,
    headers: {
      Accept: 'application/json',
    },
  })
    .then(() => {
      // Delete the question from the contact instance
      state.contact.deleteQuestion(q);

      commit('deleteQuestionSuccess');
    })
    .catch(() => {
      // Update the question loading indicator
      q.loading = false;
    });
};
