import Push from 'push.js';
import { template } from 'lodash';

export { default as ctiApi } from './cti-api';
export { default as evolve } from './evolve';
export { default as findEnforcing } from './find-enforcing';
export { default as generateId } from './generate-id';
export {
  default as getInteractionFromWorkgroup,
} from './get-interaction-from-workgroup';
export {
  default as getInteractionTypeFromCtiInteraction,
} from './get-interaction-type-from-cti-interaction';
export { default as makeAsyncMutationTypes } from './make-async-mutation-types';
export { default as mirageApi } from './mirage-api';
export { default as showModal } from './show-modal';
export { default as validatePhoneNumber } from './validate-phone-number';
export { default as isTeletolk } from './is-teletolk';
export {
  default as getReadableInteractionState,
} from './get-readable-interaction-state';
export { default as stripNonNumerics } from './strip-non-numerics';
export {
  default as getLanguageKeyFromCtiInteraction,
} from './get-language-key-from-cti-interaction';
export { default as handleError } from './handle-error';

const createPushMessage = message =>
  Push.Permission.has() ? Push.create(message) : null;

export const playSound = () => {
  const BEEP_URL = require('../assets/sounds/beep_short.ogg');

  try {
    const audio = new Audio(BEEP_URL);

    audio.addEventListener('canplaythrough', () => {
      audio.play();
    });
  } catch (e) {
    console.log(e);
  }
};

export const alertUser = message =>
  Promise.all([createPushMessage(message), playSound()]);

export const parseTemplate = options => string =>
  template(string, { interpolate: /{{([\s\S]+?)}}/g })(options);
