// Import
import formatDate from 'date-fns/format';
import Question from './Question';
import Citizen from './Citizen';
import { DUTCH } from '@/constants/languages';
import { defaultChannel, themes } from '@/config';

// Create class
class Contact {
  constructor(obj) {
    // Default the obj
    obj = obj || {};

    // temporary hack awaiting big refactor
    Object.keys(obj).forEach(key => {
      if (obj.hasOwnProperty(key)) {
        this[key] = obj[key];
      }
    });

    // Set properties
    this.id = obj.id || null;
    this.contactNumber = obj.contactNumber || null;
    this.interactionId = obj.interactionId || null;
    this.lineId = obj.lineId || null;
    this.workgroupId = obj.workgroupId || null;
    this.startThemeId = obj.startThemeId || '';
    this.themeId = obj.themeId || '';
    this.status = obj.status || null;
    this.subStatus = obj.subStatus || obj.contactSubStatusId || null;
    this.citizen = obj.citizen ? new Citizen(obj.citizen) : undefined; // citizen instance
    this.recordedQuestion = obj.recordedQuestion || '';
    this.comments = obj.comments || '';
    this.modifiedOn = obj.modifiedOn ? new Date(obj.modifiedOn) : new Date();
    this.createdOn = obj.createdOn ? new Date(obj.createdOn) : new Date();
    this.originCode = obj.originCode || 'Cti'; // Herkomst
    this.emails = obj.emails;

    // Incoming Channel
    this.channel = obj.channel || defaultChannel;

    // Incoming Channel
    this.screenSharingSessions = [];

    // Endregistration
    this.completionInfo_ChildBirthdate = obj.completionInfo_ChildBirthdate;
    this.completionInfo_Country = obj.completionInfo_Country;
    this.completionInfo_Gender = obj.completionInfo_Gender;
    this.completionInfo_Language = obj.completionInfo_Language || DUTCH;
    this.completionInfo_PostalCode = obj.completionInfo_PostalCode;
    this.completionInfo_Subject = obj.completionInfo_Subject;
    this.completionInfo_WhoIsCalling = obj.completionInfo_WhoIsCalling;
    this.completionInfo_ScreenSharing = obj.completionInfo_ScreenSharing;
    this.completionInfo_Multiline_1 = obj.completionInfo_Multiline_1;
    this.completionInfo_Multiline_2 = obj.completionInfo_Multiline_2;
    this.completionInfo_TwoOptions_1 = obj.completionInfo_TwoOptions_1;
    this.completionInfo_TwoOptions_2 = obj.completionInfo_TwoOptions_2;
    this.screenSharingSessionId = obj.screenSharingSessionId;

    // Parse questions
    this.questions = (obj.questions || []).map((q, idx) => {
      // Add an internal id
      q.internalId = idx + 1;

      // Create a new question instance
      return new Question(q);
    });
  }

  // Getter for modified
  get modified() {
    return formatDate(this.modifiedOn, 'dd/MM/yyyy HH:mm:ss');
  }

  // Getter for created
  get created() {
    return formatDate(this.createdOn, 'dd/MM/yyyy HH:mm:ss');
  }

  // Helper function to clone an instance of this class
  clone() {
    return new Contact(this);
  }

  // Helper function to validate required props
  validate() {
    let message = [];

    // Loop over required properties
    ['startThemeId', 'themeId', 'subStatus'].forEach(prop => {
      if (!this[prop]) {
        message.push(`${prop} is required.`);
      }
    });

    // Non valid
    if (message.length) {
      message = message.join('<br>');
      return {
        isValid: false,
        error: new Error(message),
      };
    }

    // Valid
    return {
      isValid: true,
    };
  }

  // Getter to check if the instance is a manually created contact
  get isManuallyCreated() {
    return this.originCode === 'ManualVlp';
  }

  // Getter to retrieve an array of all escalation points for external transfer
  get escalationPoints() {
    const escalationPoints = [];
    this.questions.forEach(q => {
      if (q.escalationPoint) {
        escalationPoints.push(q.escalationPoint);
      }
    });
    return escalationPoints;
  }

  // Helper function to add a question
  addQuestion(q) {
    // Collapse all questions
    this.questions.forEach(q => {
      if (!q.isInProgress) {
        q.expanded = false;
      }
    });

    // Max id
    let maxId = 0;
    this.questions.forEach(q => {
      if (q.internalId > maxId) {
        maxId = q.internalId;
      }
    });

    // Default the responseTypeCode to match the channel (see https://vlaamseoverheid.atlassian.net/browse/IC-1283)
    let responseTypeCode = '';
    switch (this.channel) {
      case 'IncomingCall':
        responseTypeCode = 'Call';
        break;
      case 'IncomingChat':
        responseTypeCode = 'Call';
        break;
      case 'IncomingEmail':
        responseTypeCode = 'Mail';
        break;
      case 'OutgoingCall':
        responseTypeCode = 'Call';
        break;
      default:
        responseTypeCode = 'Call';
        break;
    }

    // Create a question instance
    const questionInstance = new Question({
      ...q,
      internalId: maxId + 1, // Get biggest id + 1
      themeId: this.themeId, // Use same theme id
      responseTypeCode, // Use responseTypeCode based on channel in contact
    });

    // Add new question
    this.questions.push(questionInstance);

    // Return the instance of the question
    return questionInstance;
  }

  // Helper function to delete a questions
  deleteQuestion(questionObjOrId) {
    // Find the question
    let idx = -1;
    this.questions.find((question, i) => {
      if (
        question.internalId === (questionObjOrId.internalId || questionObjOrId)
      ) {
        idx = i;
        return true;
      }

      return false;
    });

    // Remove if found
    if (idx > -1) {
      // this.questions[idx].deleted = true;
      this.questions.splice(idx, 1);
    }
  }

  // Helper function to retrieve an object to sync to the API
  toApiObject(data = this) {
    return {
      id: data.id,
      interactionId: data.interactionId,
      workgroupId: data.workgroupId,
      startThemeId: data.startThemeId,
      themeId: data.themeId,
      contactSubStatusId: data.subStatus,
      citizenId: data.citizen ? data.citizen.id : undefined,
      recordedQuestion: data.recordedQuestion,
      comments: data.comments,
      channel: data.channel,
      originCode: data.originCode,
      completionInfo_ChildBirthdate: data.completionInfo_ChildBirthdate || null,
      completionInfo_Country: data.completionInfo_Country,
      completionInfo_Gender: data.completionInfo_Gender,
      completionInfo_Language:
        data.id == null && data.themeId === themes.VITO
          ? null
          : data.completionInfo_Language,
      completionInfo_PostalCode: data.completionInfo_PostalCode,
      completionInfo_ScreenSharing: data.completionInfo_ScreenSharing,
      completionInfo_Multiline_1: data.completionInfo_Multiline_1,
      completionInfo_Multiline_2: data.completionInfo_Multiline_2,
      completionInfo_TwoOptions_1: data.completionInfo_TwoOptions_1,
      completionInfo_TwoOptions_2: data.completionInfo_TwoOptions_2,
      completionInfo_Subject: data.completionInfo_Subject,
      completionInfo_WhoIsCalling: data.completionInfo_WhoIsCalling,
      technicalIssueDetails: data.technicalIssueDetails,
      technicalIssueTypeId: data.technicalIssueTypeId,
      screenSharingSessionId: data.screenSharingSessionId,
    };
  }
}

// Export class
export default Contact;
