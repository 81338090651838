<template>
  <!-- Modal wrapper -->
  <Modal
    @closed="$emit('close')"
    id="modal-escalation"
    class="vlw__cti-escalation"
    :open="true"
    :closable="true"
    title="Vraag escaleren"
  >
    <div class="vlw__cti-escalation__wrapper">
      <div
        class="vlw__cti-escalation__item"
        v-for="(ep, idx) in regularEscalationPoints"
        :key="idx"
      >
        <div class="vlw__cti-escalation__item__inner">
          <div class="vlw__cti-escalation__item__label">
            {{ ep.title }}
          </div>
          <input
            type="checkbox"
            v-if="isScreenSharingAllowed"
            v-model="question.addScreenshotsForEscalationEmail"
          />
          <VlButton
            :mod-disabled="disabled || !ep.emailAllowTransfer"
            @click.prevent="transferEmail(ep)"
            :mod-narrow="true"
            class="vlw__cti-escalation__item__email"
            icon="email"
            :mod-icon-before="true"
            >Doorsturen</VlButton
          >

          <VlButton
            :mod-disabled="
              disabled || !ep.telephoneAllowTransfer || !canTransferByTelephone
            "
            @click.prevent="transferPhone(ep)"
            :mod-narrow="true"
            class="vlw__cti-escalation__item__phone"
            icon="phone-outgoing"
            :mod-icon-before="true"
            >Doorschakelen</VlButton
          >
          <div
            v-if="
              ep.telephoneAllowTransfer &&
                !ep.isEscalationOpen() &&
                canTransferByTelephone
            "
            class="vlw__cti-escalation__item__closed vl-form__error"
          >
            Let op: momenteel gesloten
          </div>
        </div>
      </div>
    </div>
  </Modal>
</template>

<script>
// Import modules
import { mapActions, mapGetters } from 'vuex';
import { ADD_INTERACTION } from '@/constants/mutation-types';
import { OUTGOING_CALL_BACKOFFICE } from '@/constants';

export default {
  props: {
    question: {
      type: Object,
      required: true,
    },

    manuallyEscalateArticelId: String,
    isScreenSharingAllowed: Boolean,
    disabled: Boolean,
  },

  computed: {
    ...mapGetters('cti', ['isConnected', 'isLoggedIn', 'isMail']),
    ...mapGetters('contact', ['outboundCalls']),

    canTransferByTelephone() {
      if (!this.isLoggedIn || !this.isConnected) {
        return false;
      }

      const outboundCalls = this.outboundCalls || [];

      return !(this.isMail && outboundCalls.length === 0);
    },

    regularEscalationPoints() {
      return this.question.escalationPoints.filter(
        ({ id }) => id !== this.manuallyEscalateArticelId,
      );
    },
  },

  methods: {
    ...mapActions('contact', ['escalateQuestion']),
    ...mapActions('sidebar', [ADD_INTERACTION]),

    // Transfer by phone // @todo should only be possible if connected
    transferPhone(ep) {
      this[ADD_INTERACTION]({
        type: OUTGOING_CALL_BACKOFFICE,
        data: {
          phoneNumber: ep.telephoneNumber,
          outboundNumber: ep.telephoneNumber,
          questionId: this.question.id,
          articleId: ep.id,
          resultType: 'TransferHandeledCorrectly', // default
          sourceType: 'ExternalTransfer',
        },
      });

      // Immediately close pop-up
      this.$emit('close');
    },

    // Transfer by email
    transferEmail(ep) {
      // Update the escalationPoint
      this.escalateQuestion({
        questionInstance: this.question,
        escalationPoint: ep,
        escalationType: 'ForwardMail',
      });

      // Immediately close pop-up
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss">
.vlw__cti-escalation__item__inner {
  display: grid;
  grid-template-areas:
    'label addscreenshots email phone'
    'label . . closed';
  padding: 15px 0;
  border-bottom: 1px solid #ccc;
}

.vlw__cti-escalation__item__label {
  grid-area: label;
  width: 275px;
  line-height: 35px;
}

.vlw__cti-escalation__item__addscreenshots {
  grid-area: addscreenshots;
  margin-left: 15px;
}

.vlw__cti-escalation__item__email {
  grid-area: email;
  margin-left: 15px;
}

.vlw__cti-escalation__item__phone {
  grid-area: phone;
  margin-left: 15px;
}

.vlw__cti-escalation__item__closed {
  margin-left: 15px;
  grid-area: closed;
}
</style>
