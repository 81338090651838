<template>
  <div class="vlw__cti-citizen-edit" :class="{ loading: loading }">
    <!-- Loading state -->
    <VlLoader v-if="loading" />

    <!-- Add / edit form -->
    <div class="vlw__cti-citizen-edit__form" v-show="!citizenObj.isQuickSelect">
      <!-- Title -->
      <h2 class="vl-modal-dialog__title">
        Burger {{ mode === 'edit' ? 'bewerken' : 'aanmaken' }}
      </h2>

      <form class="vlw__cti-citizen-edit__form--form" @submit.prevent="onSave">
        <div class="vl-u-display-flex">
          <!-- Voornaam -->
          <div>
            <label class="vl-form__label">
              <span>Voornaam</span>
              <VlInputField
                ref="focusInput"
                v-model.trim="citizenObj.firstName"
                :mod-block="true"
                :mod-error="$v.citizenObj.firstName.$error"
                @blur="$v.citizenObj.firstName.$touch"
              />
            </label>
            <VlFormMessageError v-if="!isFirstNameValid"
              >Dit veld mag niet de waarde "anoniem" of "zwanger" bevatten.
              Gebruik de quick select burger.</VlFormMessageError
            >
            <VlFormMessageError
              v-if="isFirstNameValid && $v.citizenObj.firstName.$error"
              >Het veld 'Voornaam' is een verplicht veld.</VlFormMessageError
            >
          </div>

          <!-- Naam -->
          <div>
            <label class="vl-form__label">
              <span>Naam</span>
              <VlInputField
                v-model.trim="citizenObj.lastName"
                :mod-block="true"
                :mod-error="$v.citizenObj.lastName.$error"
                @blur="$v.citizenObj.lastName.$touch"
              />
            </label>
            <VlFormMessageError v-if="!isLastNameValid">
              Dit veld mag niet de waarde "anoniem" of "zwanger" bevatten.
              Gebruik de quick select burger.
            </VlFormMessageError>
            <VlFormMessageError
              v-if="isLastNameValid && $v.citizenObj.lastName.$error"
              >Het veld 'Naam' is een verplicht veld.</VlFormMessageError
            >
          </div>
        </div>

        <div class="vl-u-display-flex">
          <!-- Emailadres -->
          <div>
            <label class="vl-form__label">
              <span>Emailadres</span>
              <VlInputField
                v-model.trim="citizenObj.emailAddress"
                :mod-block="true"
                :mod-error="$v.citizenObj.emailAddress.$error"
                @blur="handleOnBlurEmail"
                @focus="hasVerifiedEmail = false"
              />
            </label>
            <div v-if="isVerifyingEmail">Aan het verifiëren...</div>

            <VlFormMessageError v-if="hasVerifiedEmail && !emailIsVerified">
              E-mailadres bestaat mogelijks niet. Gelieve nogmaals te
              controleren alvorens op te slaan.
            </VlFormMessageError>

            <VlFormMessageError v-if="$v.citizenObj.emailAddress.$error"
              >Ongeldig emailadres</VlFormMessageError
            >
          </div>

          <!-- Geboortedatum -->
          <div>
            <label class="vl-form__label">
              <span>Geboortedatum</span>
              <Datepicker
                :defaultValue="citizenObj.birthdate"
                v-model="citizenObj.birthdate"
              />
            </label>
          </div>
        </div>

        <div class="vl-u-display-flex">
          <!-- GSM -->
          <div>
            <label class="vl-form__label">
              <span>GSM</span>
              <VlInputField
                v-model.trim="citizenObj.mobilePhone"
                :mod-block="true"
                :mod-error="$v.citizenObj.mobilePhone.$error"
                @blur="$v.citizenObj.mobilePhone.$touch()"
              />
            </label>

            <VlFormMessageError v-if="$v.citizenObj.mobilePhone.$error"
              >Ongeldig of geen gsm-nummer</VlFormMessageError
            >
          </div>

          <!-- Telefoon -->
          <div>
            <label class="vl-form__label">
              <span>Telefoon</span>
              <VlInputField
                v-model.trim="citizenObj.telephone"
                :mod-block="true"
                :mod-error="$v.citizenObj.telephone.$error"
                @blur="$v.citizenObj.telephone.$touch()"
              />
            </label>

            <VlFormMessageError v-if="$v.citizenObj.telephone.$error"
              >Ongeldig of geen vast telefoonnummer</VlFormMessageError
            >
          </div>

          <!-- Organisatie -->
          <div v-if="shouldShowOrganizationField">
            <label class="vl-form__label">
              <span>Organisatie</span>
              <VlInputField
                v-model.trim="citizenObj.organization"
                :mod-block="true"
              />
            </label>
          </div>
        </div>

        <!-- Straat & nummer & bus -->
        <div class="vl-u-display-flex street-number">
          <!-- Straat -->
          <div>
            <label class="vl-form__label">
              <span>Straat</span>
              <VlInputField
                v-model.trim="citizenObj.street"
                :mod-block="true"
              />
            </label>
          </div>

          <!-- Nummer -->
          <div>
            <label class="vl-form__label">
              <span>Nummer</span>
              <VlInputField
                v-model.trim="citizenObj.houseNumber"
                :mod-block="true"
              />
            </label>
          </div>

          <!-- Bus -->
          <div>
            <label class="vl-form__label">
              <span>Bus</span>
              <VlInputField v-model.trim="citizenObj.box" :mod-block="true" />
            </label>
          </div>
        </div>

        <!-- Postcode & gemeente & land -->
        <div class="vl-u-display-flex postalcode-city">
          <!-- Postcode -->
          <div>
            <label class="vl-form__label">
              <span>Postcode</span>
              <VlInputField
                v-model.trim="citizenObj.postalCode"
                :mod-block="true"
              />
            </label>
          </div>

          <!-- Gemeente -->
          <div>
            <label class="vl-form__label">
              <span>Gemeente</span>
              <VlInputField v-model.trim="citizenObj.city" :mod-block="true" />
            </label>
          </div>

          <!-- Land -->
          <div>
            <label class="vl-form__label">
              <span>Land</span>
              <VlInputField
                v-model.trim="citizenObj.country"
                :mod-block="true"
              />
            </label>
          </div>
        </div>

        <!-- Buttons -->
        <div class="vl-action-group vl-action-group--space-between">
          <!-- Submit buttons -->
          <div>
            <!-- Add and link / Save changes -->
            <VlButton type="submit"> {{ submitButtonText }}</VlButton>

            <!-- Add and go back -->
            <VlButton
              type="button"
              v-if="mode === 'add'"
              :mod-secondary="true"
              :mod-narrow="true"
              @click="onAddAndGoBack"
              >Aanmaken</VlButton
            >
          </div>

          <!-- Cancel button -->
          <VlButton
            type="button"
            :mod-secondary="true"
            :mod-narrow="true"
            @click.prevent="changeMode('search')"
            >Annuleren</VlButton
          >
        </div>
      </form>
    </div>
    <p v-show="citizenObj.isQuickSelect">U kan deze burger niet aanpassen.</p>
  </div>
</template>

<script>
import axios from 'axios';
import { mapGetters, mapState, mapActions } from 'vuex';
import { required, email } from 'vuelidate/lib/validators';
import { validatePhoneNumber } from '@/utils';

import CitizenClass from '@/modules/Citizen';
import { lines } from '@/config';

const K_G_Info = {
  RestrictedWord_Zwanger: 'zwanger',
  RestrictedWord_Anoniem: 'anoniem',
};

var restrictedWordsCheck = val => {
  if (!val) return true;
  let lowercaseValue = val.toLowerCase();
  return (
    !lowercaseValue.includes(K_G_Info.RestrictedWord_Zwanger) &&
    !lowercaseValue.includes(K_G_Info.RestrictedWord_Anoniem)
  );
};

export default {
  name: 'CitizenEdit',
  validations: {
    citizenObj: {
      firstName: {
        FirstNameValid: value => restrictedWordsCheck(value),
        required,
      },
      lastName: {
        LastNameValid: value => restrictedWordsCheck(value),
        required,
      },
      emailAddress: { email },
      mobilePhone: {
        validatePhoneNumber: value => validatePhoneNumber(value, 'MOBILE'),
      },
      telephone: {
        validatePhoneNumber: value => validatePhoneNumber(value, 'FIXED_LINE'),
      },
    },
  },

  props: {
    // The citizen instance
    citizen: {
      type: Object,
      required: false,
      default() {
        return new CitizenClass();
      },
    },
  },

  data() {
    return {
      // The internal citizen object to create a citizen
      citizenObj: {},
      isVerifyingEmail: false,
      hasVerifiedEmail: false,
      emailIsVerified: false,
    };
  },

  created() {
    // Load the citizen
    this.get();
  },

  computed: {
    ...mapState('citizen', ['loading', 'mode', 'editCitizenId']),
    ...mapGetters('contact', ['lineId']),

    isFirstNameValid() {
      return restrictedWordsCheck(this.citizenObj.firstName);
    },

    isLastNameValid() {
      return restrictedWordsCheck(this.citizenObj.lastName);
    },

    submitButtonText() {
      if (this.mode === 'add') {
        return 'Aanmaken en koppelen';
      }

      return 'Wijzigingen opslaan';
    },

    isValid() {
      return !this.$v.$invalid;
    },

    shouldShowOrganizationField() {
      return this.lineId === lines.KIND_EN_GEZIN;
    },
  },

  methods: {
    ...mapActions('citizen', ['changeMode', 'search']),

    async handleOnBlurEmail() {
      this.$v.citizenObj.emailAddress.$touch();

      const { emailAddress } = this.citizenObj;

      if (!emailAddress || this.$v.citizenObj.emailAddress.$invalid) {
        return;
      }

      this.isVerifyingEmail = true;
      this.emailIsVerified = false;

      const webServerURL = process.env.VUE_APP_WEB_SERVER_URL || '';

      const baseURL = `${webServerURL}/proxy/usebouncer`;

      try {
        const { data } = await axios({
          baseURL,
          url: '/v1/email/verify',
          params: { email: emailAddress },
        });

        if (data.status === 'deliverable') {
          this.emailIsVerified = true;
        }
      } catch (e) {
        console.error(e);
      }

      this.isVerifyingEmail = false;
      this.hasVerifiedEmail = true;
    },

    // Triggered when opening the component
    get() {
      // Exit early if we have no id (add citizen)
      if (!this.editCitizenId) {
        this.citizenObj = this.citizen
          ? this.citizen.clone()
          : new CitizenClass();
        return;
      }

      // Get the citizen from the API
      this.$store.dispatch('citizen/get', this.editCitizenId).then(obj => {
        // Update the citizen instance
        this.citizenObj = new CitizenClass(obj);
      });
    },

    // Helper to validate the form
    validate() {
      this.$v.$touch();

      // Return valid status
      return this.isValid;
    },

    // Triggered when submitting the form
    async onSave() {
      // Validate and exit early if not valid
      if (!this.validate() || this.isVerifyingEmail) {
        return;
      }

      this.$store.dispatch('citizen/save', this.citizenObj).then(result => {
        if (result == null) {
          return;
        }

        // Update the id of the citizen object if needed (CRM API only returns response when POSTing a new Citizen)
        if (result && result.id) {
          this.citizenObj.id = result.id;
        }

        // Check if we're adding or updating a citizen
        if (this.mode === 'add') {
          // Citizen is added, automatically select the created Citizen
          this.$emit('select', this.citizenObj);
        } else {
          // Citizen is saved, emit a save event so the parent component can access the updated citizen instance
          this.$emit('save', this.citizenObj);
        }

        // Close the popup
        this.$emit('close');
      });
    },

    // Triggered when clicking the button `Add` instead of `Add and link`
    onAddAndGoBack() {
      // Validate and exit early if not valid
      if (!this.validate()) {
        return;
      }

      // Dispatch a save event to the store
      this.$store.dispatch('citizen/save', this.citizenObj).then(result => {
        if (result == null) {
          return;
        }

        // Update the id of the citizen object if needed (CRM API only returns response when POSTing a new Citizen)
        this.citizenObj.id = this.citizenObj.id || result.id;

        // Citizen is saved, emit a save event so the parent component can access the updated citizen instance
        this.$emit('save', this.citizenObj);
      });
    },
  },
};
</script>

<style lang="scss">
.vlw__cti-citizen-edit {
  .vl-loader {
    position: absolute;
    z-index: 1;
    top: 50%;
    left: 50%;
    transform: translateX(-50%);
  }

  &.loading {
    .vlw__cti-citizen-edit__form {
      pointer-events: none;
      opacity: 0.3;
    }
  }
}

.vlw__cti-citizen-edit__form--form {
  > .vl-u-display-flex {
    > div {
      width: 300px;
      margin-bottom: 35px;
      padding-left: 20px;

      &:first-child {
        padding-left: 0;
      }
    }
  }
}
</style>
