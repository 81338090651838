import { crmAxios } from '@/utils/Axios';
import { UPDATE_LOADING_STATE } from '@/constants/mutation-types';

export default (
  { rootState, commit },
  { questionInstance, escalationPoint, escalationType },
) => {
  // Exit early if there's not escalationPoint set
  if (!questionInstance) {
    return Promise.reject(new Error('Please provide a questionInstance'));
  }

  // Exit early if there's not escalationPoint set
  if (!escalationPoint) {
    return Promise.reject(new Error('Please provide an escalationPoint'));
  }

  // Exit early if there's not escalationPoint set
  if (!['ForwardCall', 'ForwardMail'].indexOf(escalationType) === -1) {
    return Promise.reject(
      new Error(
        `Please provide an escalationType of type 'ForwardCall' or 'ForwardMail'.`,
      ),
    );
  }

  commit(UPDATE_LOADING_STATE, true);

  // Update the loading state
  questionInstance.loading = true;

  // Call the API
  return crmAxios({
    method: 'post',
    url: `/Question/${questionInstance.id}/escalate`,
    data: {
      articleId: escalationPoint.id,
      escalationType,
    },
    headers: {
      Accept: 'application/json',
    },
  })
    .then(response => {
      let statusContainingSubStatus;
      // Update the status && substatus of the question if we received another status
      if (response.data && response.data.questionSubStatusId) {
        // Get the status that contains this substatus
        statusContainingSubStatus = rootState.metadata.questionStatuses.find(
          s =>
            s.subStatuses.find(
              ss =>
                ss.id.toString() ===
                response.data.questionSubStatusId.toString(),
            ),
        );
      }

      return crmAxios({
        url: `/EscalationPoint/${escalationPoint.id}`,
      }).then(({ data }) => {
        commit(UPDATE_LOADING_STATE, false);

        questionInstance.escalationPoint = data;

        questionInstance.markEscalated();

        if (statusContainingSubStatus) {
          // Update the status after escalation - this will not trigger a save because of the question status "read-only"
          questionInstance.status = statusContainingSubStatus.id;
          questionInstance.subStatus = response.data.questionSubStatusId;
        }

        // Update the loading state
        questionInstance.loading = false;
      });
    })
    .catch(error => {
      commit(UPDATE_LOADING_STATE, false);

      // Update the loading state
      questionInstance.loading = false;

      console.log('error escalating', error);
    });
};
