import qs from 'qs';
import { crmAxios, vlsAxios } from '@/utils/Axios';
import Answer from '@/modules/Answer';
import bus from '@/utils/bus';

export default {
  // Search for answers
  search({ state, commit, rootGetters }, payload) {
    // Prevent multiple requests
    if (state.loading) {
      return Promise.reject(new Error('Search is still in progress.'));
    }

    // Default the payload
    payload = payload || {};

    // Default the searchValue
    if (typeof payload.search !== 'string') {
      payload.search = '';
    }
    payload.search = payload.search.trim();

    // Check if theme is present
    if (!payload.theme) {
      return Promise.reject(new Error('No theme passed.'));
    }

    // Check if theme code is present
    if (!payload.theme.code) {
      // Build an error
      const getLabelByName = rootGetters['metadata/getLabelByName'];

      const label = getLabelByName('missing_themecode');

      const err = new Error(label.body);

      // Show a modal
      bus.$emit('show-modal', {
        message: label.title,
        body: err.message,
      });

      // Reject with the error
      return Promise.reject(err);
    }

    // Indicate that we're going to request answers
    commit('getAnswers');

    // Search for answers
    let url = '/api/search';
    const params = {
      line_code: payload.theme.code,
      keyword: payload.search,
      start: 0,
      limit: 10,
      // category: 'solution',
      changed: 0,
    };

    const queryString = qs.stringify(params);
    url = queryString ? `${url}?${queryString}` : url;

    // Call the API
    return vlsAxios({
      method: 'get',
      url,
      headers: {
        Accept: 'application/json',
      },
    })
      .then(response => {
        // Get the results array
        let results = (response.data.result || {}).results || [];

        // Map the results array
        results = results.map(r => {
          // Return a new answer instance
          return new Answer(r);
        });

        // Commit a success mutation
        commit('getAnswersSuccess', results);

        // Return the results
        return results;
      })
      .catch(error => commit('getAnswersError', error));
  },

  // Get the popular answers
  getPopular({ commit }, { theme, question }) {
    // eslint-disable-line no-unused-vars
    // Do not proceed if no theme is passed
    if (!theme) {
      return Promise.reject(
        new Error('getPopular: Please pass a theme object'),
      );
    }

    // do not proceed if the theme has no code
    if (!theme.code) {
      return [];
    }

    const rangeInDays = 10;
    const url = `/Solution/mostpopular/${theme.id}/${rangeInDays}`;

    // Call the API
    return crmAxios({
      method: 'get',
      url,
      headers: {
        Accept: 'application/json',
      },
    })
      .then(response => {
        // Map the results array to an array of articleId's
        let results = (response || {}).data || [];
        results = results.map(r => r.articleId);

        // Exit early if empty
        if (!results.length) {
          return [];
        }

        // Request the articles from the VLS
        const params = {
          line_code: theme.code,
          ids: results,
          process_mode: 0,
        };

        // Build the URL
        const queryString = qs.stringify(params);
        let url = '/api/articles';
        url = queryString ? `${url}?${queryString}` : url;

        // Call the API
        return vlsAxios({
          method: 'get',
          url,
          headers: {
            Accept: 'application/json',
          },
        }).then(response => {
          // Get the articles
          const articles = (response.data.result || {}).articles || [];

          // Map the articles to an array of answers and return it
          return articles.map(a => {
            // Return a new answer instance
            return new Answer(a);
          });
        });
      })
      .then(popularAnswers => {
        // Exit early if question was removed before request was finalized
        if (!question) {
          return;
        }

        question.popularAnswers = popularAnswers; // legacy. @todo: remove

        commit('POPULAR_ANSWERS/SUCCESS', {
          popularAnswers,
          themeId: theme.id,
        });
      })
      .catch(e => console.error(e));
  },

  // Get a specific answer from the VLS
  retrieveAnswersFromVLS(context, { articleId, contactIds = [] }) {
    const params = {
      ids: [articleId, ...contactIds],
    };

    const url = `/api/articles?${qs.stringify(params)}`;

    // Call the API
    return vlsAxios({
      method: 'get',
      url,
      headers: {
        Accept: 'application/json',
      },
    }).then(({ data: { result, success } }) => {
      if (!success) {
        throw new Error('VLS - unsuccessful response');
      }

      // Get the results array
      const { articles = [] } = result;

      // Check the answer
      return articles.map(a => new Answer(a));
    });
  },

  // Hydrate a given answer instance with VLS data // @todo remove when data is hydrated from the CRM
  hydrateAnswer(context, answerInstance) {
    // eslint-disable-line no-unused-vars
    let url = '/api/articles';

    const params = {
      ids: answerInstance.id,
    };

    const queryString = qs.stringify(params);
    url = queryString ? `${url}?${queryString}` : url;

    // Call the API
    return vlsAxios({
      method: 'get',
      url,
      headers: {
        Accept: 'application/json',
      },
    }).then(response => {
      // Default the array of received articles
      const articles = (response.data.result || {}).articles || [];

      // Check if found
      if (articles.length) {
        // Get the VLS answer
        const vlsAnswer = articles[0];

        // Update the answer instance with data needed to display in the table
        answerInstance.category = vlsAnswer.category;
        answerInstance.telephoneAllowTransfer =
          vlsAnswer.telephone_allow_transfer;
        answerInstance.telephoneNumber = vlsAnswer.telephone_number;
        answerInstance.emailAllowTransfer = vlsAnswer.email_allow_transfer;
        answerInstance.emailAddress = vlsAnswer.email_address;
      }

      // Return the hydrated instance
      return answerInstance;
    });
  },
};
