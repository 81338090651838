import Cookies from 'js-cookie';
import { propEq } from 'lodash/fp';
import { isPlainObject } from 'lodash';

import {
  UPDATE_CONTACT,
  UPDATE_CONTACT_SUBSTATUS,
  UPDATE_LOADING_STATE,
  UPDATE_OUTBOUND_CALL,
  UPDATE_TECHNICAL_ISSUE,
} from '@/constants/mutation-types';
import { COOKIE_NAME_CONTACT_ID } from '@/constants';
import { ASSIGN } from '@/constants/action-types';
import { makeAsyncMutationTypes } from '@/utils';

const assign = makeAsyncMutationTypes(ASSIGN);

export default {
  [assign.REQUEST](state) {
    state.loading = true;
  },

  [assign.SUCCESS]() {
    // do nothing
  },

  [assign.FAILURE](state, error) {
    this.error = error;
    this.loading = false;
  },

  [UPDATE_LOADING_STATE](state, isLoading) {
    state.loading = isLoading;
  },

  [UPDATE_CONTACT](state, contact) {
    state.contact = contact;
    state.loading = false;
  },

  PARTIALLY_UPDATE_CONTACT(state, partial) {
    if (!isPlainObject(partial)) {
      return;
    }

    Object.keys(partial).forEach(key => {
      state.contact[key] = partial[key];
    });
  },

  [UPDATE_CONTACT_SUBSTATUS](state, subStatus) {
    state.contact.subStatus = subStatus;
  },

  [UPDATE_OUTBOUND_CALL](state, { id, data }) {
    const { outboundCalls = [] } = state.contact || {};

    const index = outboundCalls.findIndex(propEq('id', id));

    if (index === -1) {
      throw new Error(`Cannot find outbound call with id: ${id}`);
    }

    const newOutboundCall = { ...outboundCalls[index], ...data };

    state.contact.outboundCalls[index] = newOutboundCall;
  },

  [UPDATE_TECHNICAL_ISSUE](state, formData) {
    Object.keys(formData).forEach(key => {
      state.contact[key] = formData[key];
    });
  },

  deleteAnswerSuccess() {}, // needed to update state. TODO: REFACTOR

  saveAnswerSuccess() {}, // needed to update state. TODO: REFACTOR

  deleteQuestionSuccess() {}, // needed to update state. TODO: REFACTOR

  saveQuestionSuccess() {}, // needed to update state. TODO: REFACTOR

  saveQuestionError() {}, // needed to update state. TODO: REFACTOR

  saveContactSuccess(state, payload) {
    // Check if a payload was given
    if (payload && payload.id) {
      // Update the current contact instance
      state.contact.id = payload.id;
      state.contact.contactNumber = payload.contactNumber;
      state.contact.createdOn = new Date(payload.createdOn);

      Cookies.set(COOKIE_NAME_CONTACT_ID, payload.id);
    }

    state.loading = false;
  },

  saveContactError(state, error) {
    // Update the error
    state.loadingError = error.message;

    // Update the loading state
    state.loading = false;
  },

  updateIsLegacy(state, payload) {
    state.isLegacy = payload;
  },

  hasSavedEndRegistration(state, value) {
    state.hasSavedEndRegistration = value;
  },
};
