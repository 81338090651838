<template>
  <div class="vlw__secondary-bar">
    <div class="vlw__cti-toolbar">
      <div class="vlw__cti-toolbar__details">
        <h1 v-text="startThemeName"></h1>
        <div
          class="vlw__cti-toolbar__details__info"
          v-if="shouldShowCtiDetails"
        >
          <div class="flex items-center">
            <div class="font-bold">
              {{ parentInteractionWorkgroupName }}
            </div>
          </div>

          <div class="flex items-center">
            <div class="font-bold uppercase">Werkgroep:</div>

            <div class="ml-4">{{ parentInteractionWorkgroup }}</div>
          </div>

          <div class="flex items-center">
            <div class="font-bold uppercase">CTI interactie ID:</div>

            <div class="ml-4">{{ parentInteractionId }}</div>
          </div>

          <div class="flex items-center">
            <div class="font-bold uppercase">CTI kanaal:</div>

            <div class="ml-4">{{ channelName }}</div>
          </div>

          <div class="flex items-center" v-if="parentInteractionState">
            <div class="font-bold uppercase">Status:</div>

            <div class="ml-4">{{ readableInteractionState }}</div>
          </div>
        </div>
      </div>
      <div class="vlw__cti-toolbar__actions">
        <VlLoader
          v-if="isCtiLoading || isLoading"
          class="vlw__cti-toolbar__actions__loader"
        ></VlLoader>

        <VlButton
          title="Opnemen"
          v-if="channelIsChat"
          :mod-disabled="!isAlerting"
          @click.prevent="acceptInteraction"
          class="divide"
        >
          <CustomIcon icon="play" />
        </VlButton>

        <VlButton
          v-if="!!contactId"
          @click="$store.commit('ui/internalTransfer/SHOW')"
          title="Interne transfer"
          :mod-disabled="shouldDisableTransfer"
        >
          <CustomIcon icon="internal-forwarding" />
        </VlButton>

        <VlButton
          v-if="!!contactId && showEndRegistrationButton"
          class="divide"
          @click.prevent="showEndRegistrationModal = true"
          :mod-disabled="!canStartEndRegistration"
        >
          Eindregistratie<VlIcon
            v-if="hasSavedEndRegistration"
            icon="check"
            class="ml-4"
        /></VlButton>

        <VlButton
          v-if="!!contactId"
          class="divide"
          @click.prevent="showFeedbackModal = true"
          :mod-disabled="!canStartEndRegistration"
          >Feedback</VlButton
        >

        <VlButton
          v-if="!!contactId"
          :title="cannotBeClosedBecause || 'Afsluiten'"
          @click.prevent="closeContact"
          :mod-disabled="!!cannotBeClosedBecause"
        >
          <CustomIcon icon="close" />
        </VlButton>
      </div>

      <!-- End registration modal -->
      <portal to="modal" v-if="showEndRegistrationModal">
        <EndRegistration @close="showEndRegistrationModal = false" />
      </portal>

      <portal to="modal" v-if="showInternalTransferModal">
        <InternalTransfer @close="$store.commit('ui/internalTransfer/HIDE')" />
      </portal>

      <portal to="modal" v-if="showFeedbackModal">
        <Feedback @close="showFeedbackModal = false" />
      </portal>
    </div>
  </div>
</template>

<script>
// Import shared components
import { mapState, mapGetters, mapActions } from 'vuex';
import Feedback from '@/components/modals/Feedback';
import EndRegistration from '@/components/modals/EndRegistration';
import InternalTransfer from '@/components/modals/InternalTransfer';

export default {
  name: 'CTIToolBar',

  components: {
    EndRegistration,
    Feedback,
    InternalTransfer,
  },

  data() {
    return {
      showFeedbackModal: false,
      showEndRegistrationModal: false,
    };
  },

  computed: {
    ...mapState('metadata', ['contactStatuses']),
    ...mapGetters('metadata', ['getWorkgroupSimplifiedNameById']),
    ...mapGetters('cti', [
      'parentInteractionId',
      'parentInteractionState',
      'parentInteractionWorkgroup',
      'isUserInAcw',
      'isAlerting',
      'isConnected',
      'isOnHold',
      'isPauseEnabled',
      'readableInteractionState',
    ]),
    ...mapState('cti', { isCtiLoading: 'isLoading ' }),
    ...mapState('contact', ['hasSavedEndRegistration']),
    ...mapGetters('contact', [
      'channelName',
      'channelIsChat',
      'contactId',
      'isLoading',
      'lineId',
      'startThemeName',
      'canStartEndRegistration',
      'cannotBeClosedBecause',
      'workgroupId',
      'requiredEndRegistrationFields',
    ]),
    ...mapState('ui/internalTransfer', {
      showInternalTransferModal: 'showModal',
    }),

    showEndRegistrationButton() {
      return this.requiredEndRegistrationFields.length > 0;
    },

    shouldShowCtiDetails() {
      return this.contactId != null && this.parentInteractionId != null;
    },

    shouldDisableTransfer() {
      return (
        !this.contactId ||
        this.isLoading ||
        !this.workgroupId ||
        this.isUserInAcw
      );
    },

    parentInteractionWorkgroupName() {
      if (this.workgroupId === undefined) return '';
      return this.getWorkgroupSimplifiedNameById(this.workgroupId);
    },
  },

  methods: {
    ...mapActions('contact', ['closeContact']),
  },
};
</script>

<style lang="scss">
.vlw__secondary-bar {
  position: relative;
  z-index: 10001;
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  width: 100%;
  align-items: center;
  min-height: 43px;
  background: #e8ebee;
  box-shadow: 0 1px 3px rgba(12, 13, 14, 0.1),
    0 4px 20px rgba(12, 13, 14, 0.035), 0 1px 1px rgba(12, 13, 14, 0.025);
}

.vlw {
  // Toolbar
  .vlw__cti-toolbar {
    max-width: 1920px;
    width: 100%;
    padding: 12px 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: stretch;
    height: 75px;

    h1 {
      font-size: 20px;
      font-weight: 500;
      margin: 0;
    }
  }

  // Toolbar left
  .vlw__cti-toolbar__details {
    flex: 1 0 auto;
  }

  // Toolbar left info
  .vlw__cti-toolbar__details__info {
    display: flex;
    font-size: 14px;

    > div {
      margin-right: 28px;
      &:last-child {
        margin-right: 0;
      }
    }
  }

  .vlw__cti-toolbar__time {
    svg {
      width: 15px;
    }

    span {
      line-height: 16px;
    }
  }

  // Toolbar right
  .vlw__cti-toolbar__actions {
    flex: 0 0 auto;
    align-self: center;
    text-align: right;
    display: flex;

    .vlw__cti-toolbar__actions__loader {
      align-self: center;
      margin-right: 20px;
      opacity: 0.5;
    }

    > button {
      padding: 0 10px;
      position: relative;
      margin-right: 5px;

      &.divide {
        margin-right: 25px;
      }

      &.regular {
        width: auto;
        padding: 0 10px;
      }

      &:last-child {
        margin: 0;
      }

      svg {
        width: 14px;
        height: 14px;
        display: block;
        margin: 0;
        padding: 0;
        line-height: 14px;
        fill: #fff;
      }
    }
  }
}
</style>
