import * as actions from './actions';
import getters from './getters';
import mutations from './mutations';

const getInitialState = () => ({
  error: null,
  isLoading: false,
  interactions: [],
  statusMessages: [],
  user: {
    isLoggedIn: false,
    name: null,
    status: null,
    dateStatusChanged: null,
    statusDurationInSeconds: 0,
  },
});

export default {
  namespaced: true,
  state: getInitialState(),
  actions,
  getters,
  mutations,
};
