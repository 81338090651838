<template>
  <div class="vlw__cti__open-contacts">
    <div v-if="contacts.length">
      <h2 class="vlw__cti__open-contacts__title vl-u-align-center">
        {{ title }}
      </h2>

      <div class="flex justify-between items-center mb-4">
        <div class="vl-tabs__wrapper">
          <ul class="vl-tabs m-0" role="tablist">
            <li
              class="vl-tab"
              v-for="channel in availableChannels"
              :class="{ 'vl-tab--active': selectedChannel === channel }"
              :key="channel"
            >
              <a
                @click.prevent="selectedChannel = channel"
                class="vl-tab__link"
                href
              >
                {{
                  `${getNiceNameByChannel(
                    channel,
                  )} (${getNumberOfContactsByChannel(channel)})`
                }}
              </a>
            </li>
          </ul>
        </div>
      </div>

      <div class="relative mb-4 inline-block">
        <VlInputField v-model="searchQuery" placeholder="Filter op ID" />

        <VlIcon
          class="absolute pin-r"
          icon="search"
          :style="{ padding: '4px' }"
        />
      </div>

      <VlDataTable>
        <thead>
          <tr>
            <th>Contact ID</th>
            <th>Aangemaakt</th>
            <th />
            <th />
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="{ contactNumber, createdOn, crmUrl, id } in sortedContacts"
            :key="id"
          >
            <td>{{ contactNumber }}</td>
            <td>{{ createdOn | niceDate }}</td>
            <td class="vl-u-align-right">
              <VlLink href="#" @click.prevent="openContact(id)"
                >Open in VLP</VlLink
              >
            </td>
            <td class="vl-u-align-right">
              <div class="vlw__cti__open-contacts__link">
                <VlLink :href="crmUrl" target="_blank">Open in CRM</VlLink>
                <VlIcon icon="external"></VlIcon>
              </div>
            </td>
          </tr>
        </tbody>
      </VlDataTable>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { first, groupBy, sortBy, toLower } from 'lodash';

import {
  INCOMING_CHAT,
  INCOMING_CALL,
  INCOMING_EMAIL,
  OUTGOING_CALL,
  RECALL,
} from '@/constants';

const niceNames = {
  [INCOMING_CHAT]: 'Chats',
  [INCOMING_CALL]: 'Oproepen',
  [INCOMING_EMAIL]: 'E-mails',
  [OUTGOING_CALL]: 'Uitgaande oproepen',
  [RECALL]: 'Terugbelafspraken',
};

export default {
  data() {
    return {
      contacts: [],
      searchQuery: '',
      selectedChannel: null,
    };
  },
  created() {
    this.getOpenContacts().then(data => {
      this.contacts = data.contacts || [];

      this.selectedChannel = first(this.availableChannels);
    });
  },
  computed: {
    ...mapGetters('cti', ['hasInteractions', 'isLoggedIn']),

    availableChannels() {
      return Object.keys(this.contactsByChannel);
    },

    contactsByChannel() {
      return groupBy(this.contacts, 'channel');
    },

    filteredContacts() {
      return this.selectedContacts.filter(({ contactNumber }) =>
        toLower(contactNumber).includes(toLower(this.searchQuery)),
      );
    },

    selectedContacts() {
      return this.contactsByChannel[this.selectedChannel];
    },

    sortedContacts() {
      return sortBy(this.filteredContacts, 'createdOn');
    },

    title() {
      const len = this.contacts.length;

      return len === 1
        ? 'Je hebt 1 openstaand contact'
        : `Je hebt ${len} openstaande contacten`;
    },
  },
  methods: {
    ...mapActions('contact', ['getContact', 'getOpenContacts']),

    getNiceNameByChannel(channel) {
      return niceNames[channel] || 'Onbekend';
    },

    getNumberOfContactsByChannel(channel) {
      const contacts = this.contactsByChannel[channel] || [];

      return contacts.length;
    },

    async openContact(id) {
      if (this.isLoggedIn && !this.hasInteractions) {
        await this.$store.dispatch('cti/manualContact', { contactId: id });
      }

      this.$store.commit('contact/updateIsLegacy', true);

      this.getContact(id);
    },
  },
};
</script>

<style lang="scss">
// Wrapper
.vlw__cti__open-contacts {
  max-width: 800px;

  > div {
    margin-bottom: 40px;
  }
}

// External link wrapper
.vlw__cti__open-contacts__link {
  display: flex;
  justify-content: flex-end;

  // Link
  > a {
    margin-right: 5px;
  }
}
</style>
