<template>
  <div>
    <VlAlert v-if="error" :content="error.message" class="mb-4" />

    <div class="flex items-center justify-between flex-grow p-4">
      <div class="text-3xl uppercase flex items-center">
        Interactie toevoegen
      </div>

      <div class="flex items-center">
        <VlLoader v-if="isLoading" />

        <VlButton
          v-for="{ key, iconName, name } in availableChannelsConfigs"
          :key="key"
          :mod-narrow="true"
          :title="name"
          class="ml-4"
          @click="handleOnAddInteraction(key)"
        >
          <VlIcon :icon="iconName" />
        </VlButton>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { interactions } from '@/config';
import { ADD_INTERACTION } from '@/constants/mutation-types';
import { SCREENSHARING } from '@/constants';
export default {
  name: 'AddInteraction',

  data() {
    return {
      error: null,
    };
  },

  computed: {
    ...mapGetters('contact', ['channel', 'isScreenSharingAllowed']),
    ...mapState('sidebar', ['isLoading']),

    availableChannelsConfigs() {
      const { availableChannels } = interactions[this.channel];

      return availableChannels
        .filter(
          c =>
            c != SCREENSHARING ||
            (c == SCREENSHARING && this.isScreenSharingAllowed),
        )
        .map(key => interactions[key]);
    },
  },

  methods: {
    handleOnAddInteraction(type) {
      return this.$store.dispatch(`sidebar/${ADD_INTERACTION}`, { type });
    },
  },
};
</script>
