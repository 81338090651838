<template>
  <div>
    <label class="vl-form__label">
      <span>{{ label }}</span>

      <slot />
    </label>
  </div>
</template>

<script>
export default {
  name: 'FormField',

  props: ['label'],
};
</script>
