import addQuestion from './add-question';
import assign from './assign';
import closeContact from './close-contact';
import closeContactCall from './close-contact-call';
import deleteAnswer from './delete-answer';
import deleteQuestion from './delete-question';
import escalateQuestion from './escalate-question';
import getContact from './get-contact';
import saveQuestion from './save-question';
import useAnswer from './use-answer';
import save from './save';
import sync from './sync';
import updateTechnicalIssue from './update-technical-issue';
import deleteContact from './delete-contact';
import getOpenContacts from './get-open-contacts';

export default {
  addQuestion,
  assign,
  closeContact,
  closeContactCall,
  deleteQuestion,
  escalateQuestion,
  getContact,
  saveQuestion,
  useAnswer,
  deleteAnswer,
  save,
  sync,
  updateTechnicalIssue,
  deleteContact,
  getOpenContacts,

  fetchEmails({ dispatch, state }) {
    const { emails } = state.contact;

    return Promise.all(
      emails.map(({ id }) => dispatch('emails/fetch', id, { root: true })),
    );
  },

  // Action to change the substatus (makes sure the status is in sync with the substatus)
  changeSubStatus({ state, rootState }, subStatusId) {
    // Get the status that contains this substatus
    const statusContainingSubStatus = rootState.metadata.contactStatuses.find(
      s =>
        s.subStatuses.find(ss => ss.id.toString() === subStatusId.toString()),
    );
    if (statusContainingSubStatus) {
      // Update the status
      state.contact.status = statusContainingSubStatus.id;
    }
  },

  // Action to change the theme
  changeTheme({ state }, themeId) {
    if (!state.contact) {
      return;
    }

    // Update the theme id
    state.contact.themeId = themeId;

    // Set the start theme if not set yet
    if (!state.contact.startThemeId) {
      state.contact.startThemeId = themeId;
    }
  },

  // Action to change the line
  changeLine({ state, rootState }, lineId) {
    if (!state.contact) {
      return;
    }

    // Reset the selected themes
    state.contact.startThemeId = null;
    state.contact.themeId = null;

    // Find the object in the metadata store
    const lineObj = rootState.metadata.lines.find(l => l.id === lineId);
    if (lineObj) {
      state.contact.lineId = lineObj.id;
    }
  },

  // Action to change the workgroup
  changeWorkgroup({ state }, workgroup) {
    // Update the workgroup id in the channel instance
    if (state.contact) {
      state.contact.workgroupId = workgroup ? workgroup.id : null;
    }
  },

  // Action to change the interaction id
  changeInteractionId({ state }, interactionId) {
    if (state.contact) {
      state.contact.interactionId = interactionId;
    }
  },

  // Action to change the nlpQuestion
  changeInteractionNlpQuestion({ state }, interactionNlpQuestion) {
    if (state.contact) {
      state.contact.recordedQuestion = interactionNlpQuestion;
    }
  },

  // Action to select the citizen
  selectCitizen({ state }, citizenInstance) {
    // Update the citizen
    state.contact.citizen = citizenInstance;

    // Loop over all questions and copy over citizen if non selected
    (state.contact.questions || []).forEach(question => {
      // Copy over the citizen if no citizen is set yet
      if (!question.citizen) {
        // Update the citizen of the question
        question.citizen = citizenInstance;
        question.firstName = citizenInstance.firstName;
        question.lastName = citizenInstance.lastName;
      }
    });
  },

  // Action to unlink the citizen
  unlinkCitizen({ state }) {
    // Update the citizen
    state.contact.citizen = null;
  },

  changeChannel({ state }, channel) {
    if (state.contact) {
      state.contact.channel = channel;
    }
  },
};
