<template>
  <Collapse
    v-if="component && !isExternalTicket"
    :initiallyCollapsed="initiallyCollapsed"
    :label="label"
  >
    <template slot="buttons">
      <div class="flex items-center pr-4">
        <div v-if="csatMessage" class="vl-pill mr-4">
          <span class="vl-pill__text uppercase truncate">{{
            csatMessage
          }}</span>
        </div>

        <Timer :seconds="secondsConnected" />
      </div>
    </template>

    <component
      :is="component"
      :data="data"
      :hasStopped="hasStopped"
      :id="id"
      :isConnected="isConnected"
      @close="initiallyCollapsed = true"
      @remove="handleRemove"
      @stop="handleStop"
      @start="handleStart"
    />
  </Collapse>

  <div v-else-if="!isExternalTicket" :class="$style.titleContainer">
    <div class="flex items-center justify-between flex-grow">
      <div class="text-3xl uppercase">{{ label }}</div>

      <Timer :seconds="secondsConnected" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { differenceInSeconds, parseISO } from 'date-fns';
import { interactions } from '@/config';
import { REMOVE_INTERACTION } from '@/constants/mutation-types';
import { ACW, INCOMING_CALL, EXTERNAL_TICKET } from '@/constants';

import Timer from './Timer';

export default {
  name: 'Interaction',

  components: { Timer },

  props: {
    data: {
      required: false,
      type: Object,
      default: () => ({}),
    },

    id: {
      required: true,
      type: String,
    },

    type: {
      required: true,
      type: String,
    },
  },

  data() {
    const { interactionAssignedTimestamp } = this.data;

    const secondsConnected = interactionAssignedTimestamp
      ? differenceInSeconds(new Date(), parseISO(interactionAssignedTimestamp))
      : 0;

    return {
      hasStopped: false,
      initiallyCollapsed: false,
      secondsConnected,
    };
  },

  computed: {
    ...mapGetters('contact', ['channelIsIncomingCall', 'currentWorkgroup']),

    config() {
      return interactions[this.type];
    },

    label() {
      return this.config.name;
    },

    component() {
      return this.config.sidebarComponent;
    },

    isExternalTicket() {
      //Hide by default because an external ticket (chat outside hours) does not need this interaction
      //Untill ticket is only used this purposes and has an e-mail this is fine and no ticket info needs to be shown
      return this.config.key == EXTERNAL_TICKET;
    },

    isConnected() {
      const isConnectedByInteractionId = this.$store.getters[
        'cti/isConnectedByInteractionId'
      ];

      return isConnectedByInteractionId(this.data.interactionId);
    },

    csatMessage() {
      if (this.type !== INCOMING_CALL) {
        return;
      }

      const { hasCustomerSatisfaction } = this.currentWorkgroup;

      if (hasCustomerSatisfaction === 'Yes') {
        return 'Met CSAT';
      }

      if (hasCustomerSatisfaction === 'No') {
        return 'Zonder CSAT';
      }

      return null;
    },
  },

  methods: {
    handleRemove() {
      this.$store.commit(`sidebar/${REMOVE_INTERACTION}`, this.id);
    },

    handleStart() {
      if (this.$options.intervalId) {
        return;
      }

      this.$options.intervalId = window.setInterval(() => {
        this.secondsConnected += 1;
      }, 1000);
    },

    handleStop() {
      window.clearInterval(this.$options.intervalId);

      this.$options.intervalId = null;

      this.hasStopped = true;
    },
  },

  mounted() {
    if (this.type === ACW) {
      this.handleStart();
    }
  },
};
</script>

<style module>
.titleContainer {
  border-bottom: 1px solid #cbd2da;
  padding: 1rem;
}
</style>
