import Vue from 'vue';

export default {
  // Mutation triggered when getting answers
  getAnswers(state) {
    // Update the loading and loadingError state
    state.loading = true;
    state.loadingError = false;
  },

  // Mutation triggered when answers are retrieved
  getAnswersSuccess(state, answers) {
    // Update the answers
    state.answers = answers;

    // Update the loading and loadingError state
    state.loading = false;
    state.loadingError = false;
  },

  // Mutation triggered when answers are retrieved
  getAnswersError(state, error) {
    // Parse the error
    if (error.response && error.response.data && error.response.data.message) {
      // Check if a message is available
      state.loadingError = error.response.data.message.toString();
    } else if (error.message) {
      state.loadingError = error.message.toString();
    } else if (error.stack) {
      state.loadingError = error.stack.toString();
    }

    if (!state.loadingError) {
      state.loadingError = 'Failed to retrieve answers. Please try again.';
    }

    // Update the loading state
    state.loading = false;
  },

  'POPULAR_ANSWERS/SUCCESS'(state, { popularAnswers, themeId }) {
    Vue.set(state.popularAnswersByThemeId, themeId, popularAnswers);
  },
};
