<template>
  <Modal
    @closed="handleOnClose"
    id="modal-end-registration"
    class="vlw__cti-endregistration"
    title="Eindregistratie"
    :open="true"
    :closable="true"
  >
    <!-- Wrapper -->
    <div class="vlw__cti-endregistration-wrapper">
      <EndRegistrationForm
        :contact="contact"
        :defaults="defaults"
        :isLoading="isLoading"
        @submit="handleSubmit"
      />

      <VlAlert v-if="error" :mod-error="true" :content="error.message" />
    </div>
  </Modal>
</template>

<script>
import { mapGetters, mapState } from 'vuex';

import EndRegistrationForm from './EndRegistrationForm';

export default {
  components: { EndRegistrationForm },

  data() {
    return {
      isLoading: false,
      error: null,
    };
  },

  computed: {
    ...mapState('contact', ['contact']),
    ...mapGetters('contact', ['firstQuestionPersonPostalCode']),

    defaults() {
      return {
        completionInfo_ChildBirthdate: null,
        completionInfo_PostalCode:
          (this.contact.citizen && this.contact.citizen.postalCode) ||
          this.firstQuestionPersonPostalCode ||
          '',
      };
    },
  },

  methods: {
    async handleSubmit(formData) {
      this.isLoading = true;

      Object.assign(this.contact, formData);

      try {
        await this.$store.dispatch('contact/save');

        this.handleOnClose();

        this.$store.commit('contact/hasSavedEndRegistration', true);
      } catch (e) {
        this.error = e;
      }

      this.isLoading = false;
    },

    handleOnClose() {
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss">
.vlw__cti-endregistration {
  .vlp__modal__dialog {
    overflow: hidden;
  }
}

// Content wrapper
.vlw__cti-endregistration-wrapper {
  display: flex;
}

// Form
.vlw__cti-endregistration-form {
  width: 100%;

  label > span {
    display: block;
    margin-bottom: 1rem;
  }
}

// Form textarea
.vlw__cti-endregistration-form__extra {
  label > span {
    display: inline;
  }

  textarea {
    min-height: 100px;
    resize: vertical;
  }
}

// Feedback
.vlw__cti-endregistration-feedback {
  flex: 1 0 50%;
  // max-width: 40%;
  padding-left: 40px;

  > div {
    margin-bottom: 20px;
  }

  label > span {
    display: inline-block;
    width: 200px;
  }
}
</style>
