import { filesize } from 'filesize';
import { parseISO, format, formatWithOptions } from 'date-fns/fp';
import { nl } from 'date-fns/locale';
import { handleError } from '@/utils';

const DATE_FORMAT = 'Y-MM-dd';

export const formatDateString = string => format(DATE_FORMAT, new Date(string));

export const secondsToTime = seconds => {
  const substringRange = seconds > 60 * 60 ? [11, 8] : [14, 5];

  return new Date(seconds * 1000).toISOString().substr(...substringRange);
};

const dateFilterByFormat = format => value => {
  if (value == null) {
    return '';
  }

  try {
    const parsedValue = typeof value === 'string' ? parseISO(value) : value;

    return formatWithOptions({ locale: nl }, format)(parsedValue);
  } catch (e) {
    handleError(e);

    return '';
  }
};

export const niceDate = dateFilterByFormat('P');

export const niceDateAndTime = dateFilterByFormat('Pp');

export const fileSize = number => {
  try {
    if (number === undefined) return 0;
    return filesize(number, { locale: 'nl', round: 0 });
  } catch (e) {
    handleError(e);

    return 0;
  }
};
