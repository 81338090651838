<template>
  <div>
    <VlTypography>
      <h1>Relevante wijzigingen</h1>
    </VlTypography>

    <VlLoader v-if="isLoading" />

    <div v-else>
      <div class="flex flex-wrap">
        <div class="flex mr-4 mb-4">
          <label>
            <VlInputField
              placeholder="Filter op text"
              v-model="textSearchValue"
            />
          </label>

          <VlButton
            :mod-narrow="true"
            :mod-disabled="textSearchValue === ''"
            @click="textSearchValue = ''"
            ><VlIcon icon="close"
          /></VlButton>
        </div>

        <div class="flex mr-4 mb-4">
          <label>
            <select class="vl-select" v-model="selectedTheme">
              <option :value="null" :disabled="true">Alle thema's</option>
              <optgroup
                v-for="line in availableLines"
                :key="line.id"
                :label="line.name"
              >
                <option
                  v-for="theme in line.themes"
                  :key="theme.id"
                  :value="theme"
                  :disabled="!isThemeSelectable(theme.code)"
                >
                  {{ theme.name }}
                </option>
              </optgroup>
            </select>
          </label>

          <VlButton
            :mod-narrow="true"
            :mod-disabled="selectedTheme === null"
            @click="selectedTheme = null"
            ><VlIcon icon="close"
          /></VlButton>
        </div>

        <div class="flex mr-4 mb-4">
          <label>
            <select class="vl-select" v-model="selectedType">
              <option :value="null" :disabled="true">Alle types</option>
              <option
                v-for="type in availableTypes"
                :key="type"
                :value="type"
                >{{ type }}</option
              >
            </select>
          </label>

          <VlButton
            :mod-narrow="true"
            :mod-disabled="selectedType === null"
            @click="selectedType = null"
            ><VlIcon icon="close"
          /></VlButton>
        </div>

        <VlButton
          :mod-narrow="true"
          :mod-disabled="filterIsClean"
          @click="
            textSearchValue = '';
            selectedTheme = null;
            selectedType = null;
          "
          >Reset</VlButton
        >
      </div>

      <VlAlert v-if="visibleNewsItems.length === 0" icon="info">
        {{
          filterIsClean
            ? 'Er zijn momenteel geen nieuwe relevante wijzigingen.'
            : 'Er zijn geen wijzigingen die aan de criteria voldoen.'
        }}
      </VlAlert>

      <ul>
        <li v-for="item in visibleNewsItems" :key="item.id">
          <VlAlert :mod-info="true" icon="warning">
            <div class="flex justify-between flex-wrap">
              <a
                class="vl-alert__title"
                :href="`${vlsUrl}/${item.lineCodes[0]}/article/${item.id}`"
                >{{ item.title }}</a
              >

              <div class="vl-pill bg-white ml-4">
                <span class="vl-pill__text">{{ item.type }}</span>
              </div>
            </div>

            <div class="mb-4 text-gray-600 flex">
              <span>{{ item.date | niceDateAndTime }}</span>
            </div>

            <div class="mb-4 vl-alert__message" v-html="item.description" />

            <div class="flex flex-wrap">
              <div v-for="code in item.lineCodes" :key="code">
                <div
                  v-for="name in getThemeNamesByCode(code)"
                  :key="name"
                  class="vl-pill bg-white mr-2 mb-2"
                >
                  <span class="vl-pill__text">{{ name }}</span>
                </div>
              </div>
            </div>
          </VlAlert>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { vlsAxios } from '@/utils/Axios';
import { mapGetters } from 'vuex';
import {
  compact,
  isNil,
  overEvery,
  reverse,
  sortBy,
  toLower,
  uniq,
} from 'lodash';
import { parseISO, differenceInDays } from 'date-fns';
import { vlsUrl } from '@/config';

export default {
  name: 'Newsfeed',

  data() {
    return {
      availableThemes: [],
      error: null,
      isLoading: false,
      newsItems: [],
      selectedTheme: null,
      selectedType: null,
      textSearchValue: '',
    };
  },

  computed: {
    ...mapGetters('metadata', ['availableLines']),

    vlsUrl() {
      return vlsUrl;
    },

    newNewsItems() {
      const MAX_DAYS = 30;

      const byDate = ({ date }) =>
        differenceInDays(new Date(), parseISO(date)) <= MAX_DAYS;

      return this.newsItems.filter(byDate);
    },

    filteredNewsItems() {
      const byText = ({ description, title }) =>
        this.textSearchValue === '' ||
        [description, title].some(value =>
          toLower(value).includes(toLower(this.textSearchValue)),
        );

      const byTheme = ({ lineCodes }) =>
        this.selectedTheme == null ||
        lineCodes.includes(this.selectedTheme.code);

      const byType = ({ type }) =>
        this.selectedType == null || type === this.selectedType;

      return this.newNewsItems.filter(overEvery([byTheme, byType, byText]));
    },

    sortedFilteredNewsItems() {
      return reverse(sortBy(this.filteredNewsItems, 'date'));
    },

    visibleNewsItems() {
      const MAX_NEWS_ITEMS = 50;

      return this.sortedFilteredNewsItems.slice(0, MAX_NEWS_ITEMS);
    },

    availableTypes() {
      return uniq(this.newNewsItems.map(({ type }) => type));
    },

    filterIsClean() {
      return (
        [this.selectedTheme, this.selectedType].every(isNil) &&
        this.textSearchValue === ''
      );
    },
  },

  methods: {
    getThemeNamesByCode(code) {
      const themes = this.availableThemes.filter(theme => theme.code === code);

      if (themes.length === 0) {
        console.error(`Theme not found: ${code}`);
      }

      return themes.map(({ name }) => name);
    },

    isThemeSelectable(code) {
      return this.newNewsItems.reduce(
        (acc, { lineCodes }) => acc || lineCodes.includes(code),
        false,
      );
    },

    async fetchNewsItems(availableLines = []) {
      this.isLoading = true;

      try {
        this.availableThemes = availableLines.reduce(
          (acc, { themes }) => [...acc, ...themes],
          [],
        );

        const uniqueCodes = uniq(
          compact(this.availableThemes.map(({ code }) => code)),
        );

        const { data: newsItems } = await vlsAxios({
          url: `/api/relevant-changes`,
          params: { start: 0, limit: 20, line_codes: uniqueCodes },
        });

        this.newsItems = newsItems;
      } catch (e) {
        console.error(e);

        this.error = e;
      }

      this.isLoading = false;
    },
  },

  created() {
    if (this.availableLines.length > 0) {
      this.fetchNewsItems(this.availableLines);
    } else {
      this.$watch('availableLines', this.fetchNewsItems);
    }
  },
};
</script>

<style></style>
