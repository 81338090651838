<template>
  <Modal
    @closed="$emit('close')"
    id="modal-search-child"
    class="vlw__cti-child-modal is-large"
    :open="true"
    :closable="true"
    title="Zoek een kind"
  >
    <div v-if="!isLoggedIn" class="flex">
      <VlLoader />

      <div class="ml-4">Inloggen...</div>
    </div>

    <div v-else class="flex">
      <div class="pr-8 border-r">
        <form @submit.prevent="handleOnSubmit">
          <FormField label="Voornaam" class="mb-4">
            <VlInputField
              v-model="formData.voornaam"
              :mod-block="true"
              :required="true"
            />
          </FormField>

          <FormField label="Achternaam" class="mb-4">
            <VlInputField
              v-model="formData.naam"
              :mod-block="true"
              :required="true"
            />
          </FormField>

          <FormField label="Geboortedatum" class="mb-8">
            <div>
              <Datepicker v-model="formData.geboorteDatum" :required="true" />
            </div>
          </FormField>

          <VlButton type="submit" :mod-block="true" :mod-loading="isLoading"
            >Zoeken</VlButton
          >
        </form>
      </div>

      <div class="flex-grow pl-8">
        <VlDataTable>
          <thead>
            <th>Voornaam</th>
            <th>Naam</th>
            <th>Geboortedatum</th>
            <th v-if="hasDeceasedChild">Overlijdensdatum</th>
            <th>Postcode</th>
            <th>Discretie vereist?</th>
            <th></th>
          </thead>

          <tbody>
            <tr
              v-for="{
                discretieVereist,
                geboorteDatum,
                miragePersoonId,
                naam,
                overlijdensDatum,
                postcode,
                voornaam,
              } in searchResults"
              :key="miragePersoonId"
            >
              <td>{{ voornaam }}</td>
              <td>{{ naam }}</td>
              <td>{{ geboorteDatum }}</td>
              <td v-if="hasDeceasedChild">{{ overlijdensDatum || 'nvt' }}</td>
              <td>{{ postcode }}</td>
              <td>{{ discretieVereist ? 'JA' : 'NEE' }}</td>
              <td>
                <VlButton
                  @click.prevent="handleOnSelect(miragePersoonId)"
                  :mod-disabled="miragePersoonId == null"
                  :mod-narrow="true"
                  >Selecteer</VlButton
                >
              </td>
            </tr>
          </tbody>
        </VlDataTable>

        <div v-if="searchResults.length === 0 && !error">
          <VlAlert
            v-if="!hasSearched"
            :mod-info="true"
            content="Voer een zoekopdracht uit."
          />

          <VlAlert
            v-else
            :mod-warning="true"
            content="Er werden geen resultaten gevonden."
          />
        </div>

        <VlAlert v-if="error" :mod-error="true" :content="error.message" />
      </div>
    </div>
  </Modal>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';

import { RESET } from '@/constants/mutation-types';
import { authenticationContext } from '@/utils/mirage-api';
import { isLocal } from '@/config';

export default {
  name: 'SearchChild',

  data() {
    return {
      formData: {
        geboorteDatum: null,
        naam: '',
        voornaam: '',
      },
      hasSearched: false,
      isLoggedIn: false,
    };
  },

  computed: {
    ...mapState('mirage', ['error', 'isLoading', 'searchResults']),

    hasDeceasedChild() {
      return this.searchResults.reduce(
        (acc, { overlijdensDatum }) => acc || !!overlijdensDatum,
        false,
      );
    },
  },

  created() {
    if (isLocal) {
      this.isLoggedIn = true;
      return;
    }

    const user = authenticationContext.getCachedUser();

    if (!user) {
      return authenticationContext.login();
    }

    this.isLoggedIn = true;
  },

  methods: {
    ...mapActions('mirage', ['search']),
    ...mapMutations('mirage', [RESET]),

    handleOnSelect(miragePersoonId) {
      this[RESET]();

      this.$emit('select', miragePersoonId);

      this.$emit('close');
    },

    handleOnSubmit() {
      this.search(this.formData).then(() => {
        this.hasSearched = true;
      });
    },
  },
};
</script>

<style lang="scss">
// Modal wrapper
.vlw__cti-child-modal {
  .vl-modal-dialog {
    width: 100%;
    max-width: 70%;
    left: 4rem;
    transform: translate(0, -50%);
  }

  .vl-data-table {
    td {
      vertical-align: middle;
    }

    tr:hover {
      background-color: whitesmoke;
    }
  }
}
</style>
