import actions from './actions';
import getters from './getters';
import mutations from './mutations';
import * as modules from './modules';

export const getInitialState = () => ({
  customInteractions: [],
  error: null,
  isLoading: false,
});

export default {
  namespaced: true,
  state: getInitialState(),
  actions,
  getters,
  modules,
  mutations,
};
