// Require modules
import AuthenticationContext from 'adal-angular/lib/adal';

// Configure authentication
export const config = {
  tenant: `${process.env.VUE_APP_CRM_ADAL_TENANT}`,
  clientId: `${process.env.VUE_APP_CRM_ADAL_CLIENTID}`,
  redirectUri: window.location.href,
  resource: `${process.env.VUE_APP_CRM_ADAL_APPID}`,
  cacheLocation: 'localStorage',
};

// AuthContext instance
export const authenticationContext = new AuthenticationContext(config);

/**
 * @return {Promise.<String>} A promise that resolves to an ADAL token for resource access
 */
export const acquireToken = () =>
  new Promise((resolve, reject) => {
    authenticationContext.acquireToken(config.resource, (error, token) => {
      if (error || !token) {
        return reject(error);
      }
      return resolve(token);
    });
  });

// Export
export default {
  config,
  authenticationContext,
  acquireToken,
};
