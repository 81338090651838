import { INCOMING_CALL } from '@/constants';

export {
  default as readableInteractionState,
} from './readable-interaction-state';
export { default as channels } from './channels';
export { default as interactions } from './interactions';
export { default as languages } from './languages';

const { VUE_APP_ENV, VUE_APP_WEB_SERVER_URL, VUE_APP_VLS_URL } = process.env;

export const isProd = VUE_APP_ENV === 'PROD';
export const isLocal = VUE_APP_ENV === 'LOCAL';
export const isDev = VUE_APP_ENV === 'DEV';

const webServerUrl = VUE_APP_WEB_SERVER_URL || '';
export const vlsApiUrl = `${webServerUrl}/proxy/vls`;
export const vlsUrl = VUE_APP_VLS_URL;

export const defaultChannel = INCOMING_CALL;

export const sidebarMaxWidth = window.innerWidth - 100;
export const sidebarMinWidth = 370;

export const DEBOUNCE_DELAY = 1000;

// Mapping of lines to CRM id's
export const lines = {
  1700: 'd1ed4282-1fbc-e811-a964-000d3a38c0ab',
  DE_LIJN: 'a84f6c8e-1fbc-e811-a964-000d3a38c0ab',
  KIND_EN_GEZIN: 'f40f369b-1fbc-e811-a964-000d3a38c0ab',
  VAPH: '484713a2-1fbc-e811-a964-000d3a38c0ab',
  ZORGKAS_VSB: '10f76ea8-1fbc-e811-a964-000d3a38c0ab',
  AGENTSCHAP_OVERHEIDSPERSONEEL: 'da3bb4b6-1fbc-e811-a964-000d3a38c0ab',
  FIT: 'bd2eb0bc-1fbc-e811-a964-000d3a38c0ab',
  TELETOLK: 'e2d0e4c8-1fbc-e811-a964-000d3a38c0ab',
  HELPDESKIV: 'b8b215cf-1fbc-e811-a964-000d3a38c0ab',
  TAALTELEFOON: '27e724d5-1fbc-e811-a964-000d3a38c0ab',
  VITO: '5e2028fe-fd09-ea11-a811-000d3a2109c9',
};

// Mapping of themes to CRM id's
export const themes = {
  // De Lijn themes
  DE_LIJN: '17163cea-1eb8-e811-a96a-000d3a38c9f2',

  // Kind & Gezin themes
  KIND_EN_GEZIN: '610c36f6-1eb8-e811-a96a-000d3a38c9f2',
  ZOE: '3c233a9a-1951-ec11-8c62-002248811e6e',
  JO: 'b6ec0125-9c90-ec11-b400-000d3a276383',

  // VAPH themes
  VAPH: '552176fc-1eb8-e811-a96a-000d3a38c9f2',

  // Zorgkas themes
  ZORGKAS: 'c65bb803-1fb8-e811-a96a-000d3a38c9f2',

  // VSB themes
  VSB: '9623030a-1fb8-e811-a96a-000d3a38c9f2',

  // 1700 Themes
  VERKEERSBELASTING: '82fb6293-1eb8-e811-a96a-000d3a38c9f2',
  ONROERENDE_VOORHEFFING: '876b679f-1eb8-e811-a96a-000d3a38c9f2',
  ERF_REGISTRATIEBELASTING: '566267a5-1eb8-e811-a96a-000d3a38c9f2',
  STUDIETOELAGEN: '61e66fab-1eb8-e811-a96a-000d3a38c9f2',
  KMO_PORTEFEUILLE: '1684cab7-1eb8-e811-a96a-000d3a38c9f2',
  ZORGBEROEPEN: 'e6b4dac3-1eb8-e811-a96a-000d3a38c9f2',
  ACM_IDM: 'e538b8dd-1eb8-e811-a96a-000d3a38c9f2',
  ANDERE_VRAGEN: 'f5153cea-1eb8-e811-a96a-000d3a38c9f2',
  ERFBELASTING: '7847f301-bd4b-e911-a82b-000d3a2aa154',
  REGISTRATIEBELASTING: '566267a5-1eb8-e811-a96a-000d3a38c9f2',
  WONEN_EN_ENERGIE: 'c6ee0db5-80a9-eb11-b1ac-000d3abfb801',

  VITO: 'e0488116-fe09-ea11-a811-000d3a2109c9',
  FIT: '0705211e-1fb8-e811-a96a-000d3a38c9f2',
  CC_ONDERNEMEN: '890527e7-dcd5-ec11-a7b5-002248852d84',
};

// Mapping of contact statuses to CRM id's
export const contactStatuses = {
  GESLOTEN: '5a45a699-0abc-e811-a964-000d3a38c0ab',
};

export const contactSubStatuses = {
  LOPEND: '6a8d286b-0bbc-e811-a964-000d3a38c0ab',
  NIET_GESTART: '921ae274-0bbc-e811-a964-000d3a38c0ab',
  CLOSED_TBA: '80a53a81-6749-e911-a96c-000d3a29b5de',
  OPEN_TBA: 'cd72eea2-653b-e911-a968-000d3a29be4a',
  WACHTEND_OP_INFO: '68560191-5a34-e911-a967-000d3a29be4a',
};

export const questionStatusIds = {
  REGIO_ACTIE: '3cdb93b6-e090-e811-a95e-000d3a38c9b5',
  GEESCALEERD: '9d4f5cb0-e090-e811-a95e-000d3a38c9b5',
};

export const questionSubStatusIds = {
  ESCALATED_PHONE: '4a791d07-e190-e811-a95e-000d3a38c9b5',
  ESCALATED_EMAIL: '1dafe30e-e190-e811-a95e-000d3a38c9b5',
  REGIO_ACTIE_DIRECT_ANTWOORD: 'f3761f24-e190-e811-a95e-000d3a38c9b5',
};

// Response type codes on question level
export const responseTypeCodes = [
  {
    key: 'Call',
    name: 'Oproep',
    isDefault: true,
  },
  {
    key: 'Mail',
    name: 'E-mail',
  },
];

// Priority codes on question level
export const priorities = [
  {
    key: 'Normal',
    name: 'Normaal',
    isDefault: true,
  },
  {
    key: 'High',
    name: 'Hoog',
  },
];

// Choices in end registration
export const countries = [
  {
    key: 'Belgium',
    name: 'België',
    isDefault: true,
  },
  {
    key: 'Foreign',
    name: 'Buitenland',
  },
  {
    key: 'Anonyous',
    name: 'anoniem',
  },
];

export const genders = [
  {
    key: 'Unknown',
    name: 'Onbekend',
    isDefault: true,
  },
  {
    key: 'Male',
    name: 'Man',
  },
  {
    key: 'Female',
    name: 'Vrouw',
  },
];

export const subjects = [
  {
    key: 'Health',
    name:
      'Gezondheid (arts, apotheker, kinesist, ziekenhuis, ziekenfonds, ...)',
  },
  {
    key: 'Leisure',
    name: 'Vrijetijdsbesteding',
  },
  {
    key: 'Government',
    name:
      'Overheid (contact met overheidsdienst, vraagje over de overheid, ...)',
  },
  {
    key: 'Finance',
    name:
      'Financiën (Bank, boekhouder, verzekeringen, rekeningen betalen, ...)',
  },
  {
    key: 'Care',
    name: 'Verzorging (Kapper, wellness, pedicure, ...)',
  },
  {
    key: 'Mobility',
    name: 'Mobiliteit (Openbaar vervoer, taxiaanvraag, auto-onderhoud, ...)',
  },
  {
    key: 'Utilities',
    name:
      'Nutsvoorzieningen (telecom, post, kabel, internet, water, electriciteit, ...)',
  },
  {
    key: 'Work',
    name:
      'Werk (werk zoeken, contact opnemen met werkgever, afhandelen van opdrachten op het werk, ...)',
  },
  {
    key: 'Personal_Family',
    name:
      'Persoonlijk / gezin (contact met familieleden, school kinderen , vrienden, ...)',
  },
];

export const whoIsCalling = [
  {
    key: 'Pregnant',
    name: 'Zwangere Vrouw',
  },
  {
    key: 'Parents',
    name: 'Ouder',
  },
  {
    key: 'Family',
    name: 'Familie',
  },
  {
    key: 'Education',
    name: 'Onderwijs',
  },
  {
    key: 'DayCare',
    name: 'Kinderopvang',
  },
  {
    key: 'OrganizationOrGovernment',
    name: 'Firma / Organisatie / VZW / OCMW / Overheid',
  },
  {
    key: 'Press',
    name: 'Pers',
  },
  {
    key: 'Other',
    name: 'Andere / Onbekend',
  },
  {
    key: 'Doctor',
    name: 'Dokter',
  },
  {
    key: 'K_GEmployee',
    name: 'Kind & Gezin Medewerker',
  },
  {
    key: 'Youngster',
    name: 'Jongere',
  },
];
