import { crmAxios } from '@/utils/Axios';
import { FETCH } from '@/constants/action-types';
import { makeAsyncMutationTypes } from '@/utils';
import handleError from '../../../../utils/handle-error';
import sanitizeEmail from '../utils/sanitize-email';

const { FAILURE, REQUEST, SUCCESS } = makeAsyncMutationTypes(FETCH);

export default async ({ commit }, id) => {
  commit(REQUEST);

  console.log(REQUEST);

  try {
    const { data } = await crmAxios({
      url: `/Email/${id}`,
    });

    const sanitizedEmail = await sanitizeEmail(data);

    commit(SUCCESS, sanitizedEmail);

    return { data };
  } catch (error) {
    handleError(error);

    commit(FAILURE, error);
  }
};
