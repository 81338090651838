<template>
  <div
    :class="[
      $style.wrapper,
      $style[lowerCaseUser],
      { [$style.firstChild]: isFirstChild },
    ]"
    ref="container"
  >
    <div :class="$style.inner">
      <slot />

      <span v-text="html" />

      <sub v-if="timestamp" :class="$style.time">{{
        timestamp | niceTime
      }}</sub>
    </div>
  </div>
</template>

<script>
import Linkify from 'linkify-it';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { ADD_INTERACTION } from '@/constants/mutation-types';
import { OUTGOING_CALL, OUTGOING_EMAIL } from '@/constants';

const linkify = new Linkify();

linkify.tlds(['brussels', 'vlaanderen'], true);

const phoneSchema = {
  validate(text, pos) {
    const tail = text.slice(pos - 1).split(' ')[0];

    const phoneNumber = parsePhoneNumberFromString(tail, 'BE');

    if (phoneNumber && phoneNumber.isValid()) {
      return tail.length;
    }

    return false;
  },

  normalize(match) {
    const { number } = parsePhoneNumberFromString(match.text, 'BE');

    match.url = `tel:${number}`;
  },
};

linkify.add('0', phoneSchema);
linkify.add('+', phoneSchema);

export default {
  props: {
    isFirstChild: {
      type: Boolean,
      required: false,
    },
    text: {
      type: String,
      required: true,
    },
    timestamp: {
      type: Number,
      required: false,
    },
    user: {
      type: String,
      required: true,
    },
  },

  computed: {
    html() {
      return this.$options.filters.linkify(this.text);
    },

    lowerCaseUser() {
      return this.$options.filters.lowerCase(this.user);
    },
  },

  filters: {
    linkify(text) {
      const matches = linkify.match(text);

      if (matches == null) {
        return text;
      }

      return matches.reduce(
        (acc, { raw, url }) => acc.replace(raw, `<a href="${url}">${raw}</a>`),
        text,
      );
    },

    lowerCase(string) {
      return string.toLowerCase();
    },

    niceTime(timestamp) {
      const date = new Date(timestamp);

      return date.toLocaleTimeString('nl-be', {
        hour: '2-digit',
        minute: '2-digit',
      });
    },
  },

  mounted() {
    const links = this.$refs.container.querySelectorAll('a');

    links.forEach(link => {
      const { href, protocol } = link;

      if (!['tel:', 'mailto:'].includes(protocol)) {
        return;
      }

      link.onclick = e => {
        e.preventDefault();

        const destination = href.replace(protocol, '');

        const payload =
          protocol === 'tel:'
            ? {
                type: OUTGOING_CALL,
                data: { phoneNumber: destination, outboundNumber: destination },
              }
            : {
                type: OUTGOING_EMAIL,
                data: { to: destination },
              };

        this.$store.dispatch(`sidebar/${ADD_INTERACTION}`, payload);
      };
    });
  },
};
</script>

<style module>
.wrapper {
  margin-bottom: 15px !important;
}

.inner {
  border-radius: 8px;
  display: inline-block;
  padding: 10px 15px !important;
  position: relative;
  text-align: left;
}

.inner span {
  color: #333 !important;
}

.firstChild .inner:after {
  content: ' ';
  position: absolute;
  width: 0;
  height: 0;
  top: 0px;
  bottom: auto;
  border: 12px solid;
}

.them .inner {
  background: #f0efef;
}

.them.firstChild .inner:after {
  left: -12px;
  right: auto;
  border-color: #f0efef transparent transparent transparent;
}

.me {
  text-align: right;
}

.me .inner {
  background: #f8e71c;
}

.me.firstChild .inner:after {
  left: auto;
  right: -12px;
  border-color: #f8e71c transparent transparent transparent;
}

.bot {
  text-align: right;
}

.bot .inner {
  color: white !important;
  background: #05c;
}

.bot .inner span {
  color: white !important;
}

.bot .inner .time {
  color: white !important;
}

.bot.firstChild .inner:after {
  left: auto;
  right: -12px;
  border-color: #05c transparent transparent transparent;
}

.time {
  color: grey;
  float: right;
  margin-left: 16px !important;
}
</style>
