import { propEq } from 'lodash/fp';

import * as actions from './actions';
import mutations from './mutations';

export const getInitialState = () => ({
  error: null,
  isLoading: false,
  items: [],
});

const getters = {
  canReply(state, getters, rootState, rootGetters) {
    return rootGetters['contact/isLopendLopend'];
  },

  getEmailById: state => id => {
    return state.items.find(propEq('id', id));
  },

  getEmailsByPurpose: state => purpose => {
    return state.items.filter(propEq('emailPurpose', purpose));
  },

  getInitialEmail(state) {
    return state.items.find(propEq('emailPurpose', 'InitialMail')) || {};
  },

  hasSentEmail({ items }) {
    return items.reduce(
      (acc, { emailPurpose, statusCode }) =>
        acc || (emailPurpose === 'ReplyEmailCitizen' && statusCode === 'Sent'),
      false,
    );
  },
};

export default {
  namespaced: true,
  state: getInitialState(),
  actions,
  getters,
  mutations,
};
