export default ({ dispatch }) => async (contactId, interactionId) => {
  await dispatch('contact/assign', contactId, { root: true });

  await dispatch(
    'cti/sendContactId',
    { interactionId, contactId },
    { root: true },
  );

  await dispatch('contact/getContact', contactId, {
    root: true,
  });
};
