import { toLower } from 'lodash';

import {
  EXTERNAL_TICKET,
  INCOMING_CHAT,
  INCOMING_CALL,
  INCOMING_EMAIL,
  OUTGOING_CALL,
  RECALL,
  TELETOLK,
} from '@/constants';

const mapping = {
  Call: INCOMING_CALL,
  Chat: INCOMING_CHAT,
  Mail: INCOMING_EMAIL,
  Ticket: EXTERNAL_TICKET,
};

const OUTGOING = 'O';

export default ({
  interactionCallDirection,
  interactionId,
  interactionWorkgroup,
}) => {
  if (!interactionWorkgroup && interactionCallDirection === OUTGOING) {
    return OUTGOING_CALL;
  }

  const workgroupHasKey = key =>
    toLower(interactionWorkgroup).includes(toLower(key));

  if (workgroupHasKey(TELETOLK)) {
    return TELETOLK;
  }

  if (workgroupHasKey(RECALL)) {
    return RECALL;
  }

  const interactionType = Object.keys(mapping).reduce(
    (acc, key) => acc || (workgroupHasKey(key) ? mapping[key] : acc),
    null,
  );

  if (!interactionType) {
    console.error(
      `No interaction type found for interaction id: ${interactionId}`,
    );
  }

  return interactionType;
};
