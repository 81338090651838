<template>
  <VlAlert v-if="error" :mod-error="true" :content="error.message" />

  <div class="relative" v-else>
    <div v-if="isTeletolk">
      <div class="p-4">
        <span class="uppercase">Naam burger:</span>
        {{ ' ' }}
        <span class="font-bold">{{
          `${metadata.firstName} ${metadata.lastName}`
        }}</span>
      </div>

      <div class="p-4">
        <span class="uppercase">Te contacteren:</span>
        {{ ' ' }}
        <span class="font-bold">{{ metadata.contactPerson }}</span>
      </div>
    </div>

    <div class="flex items-center justify-end p-4">
      <VlButton
        title="Opleggen"
        class="divide ml-4 flex-none"
        :mod-disabled="isChatClientDisabled"
        :mod-narrow="true"
        @click.prevent="handleOnEndInteraction('AGENT')"
      >
        <CustomIcon icon="stop" />
      </VlButton>
    </div>

    <div class="relative" v-if="token != null">
      <vl-chat-client
        :disabled="isChatClientDisabled"
        :greeting="greeting"
        :ioUrl="ioUrl"
        userType="AGENT"
        :userObjectId="userObjectId"
        @metadata="handleOnMetadata"
        @syncCitizen="handleOnSyncCitizen"
        @terminate="handleOnEndInteraction('CITIZEN')"
        @connected="handleOnConnected"
        ref="chatClient"
      />

      <div :class="$style.templatesButtons">
        <VlButton
          class="mb-4"
          :mod-narrow="true"
          :mod-disabled="isChatClientDisabled"
          @click.prevent="showTemplatesModal = true"
        >
          <VlIcon icon="menu" />
        </VlButton>

        <VlButton
          :mod-narrow="true"
          :mod-disabled="isChatClientDisabled"
          @click.prevent="$refs.chatClient.send()"
        >
          <VlIcon icon="nav-right-double" />
        </VlButton>
      </div>
    </div>

    <portal to="modal" v-if="showTemplatesModal">
      <TemplatesModal
        @select="handleOnSelectTemplate"
        :templates="templates"
        @close="showTemplatesModal = false"
      />
    </portal>
  </div>
</template>

<script>
import { propEq } from 'lodash/fp';
import { mapActions, mapGetters, mapState } from 'vuex';

import VlChatClient from './components/VlChatClient';

import { crmAxios } from '@/utils/Axios';
import { acquireToken } from '@/utils/CrmAuthentication';
import { isTeletolk, parseTemplate } from '@/utils';
import { ADD_INTERACTION } from '@/constants/mutation-types';
import { OUTGOING_CALL } from '@/constants';

import TemplatesModal from '@/components/modals/ChatTemplates';

const { VUE_APP_IO_URL } = process.env;

export default {
  name: 'IncomingChat',

  props: ['data', 'index', 'isConnected'],

  data() {
    return {
      citizen: null,
      error: null,
      greeting: null,
      isLoading: false,
      isTerminated: false,
      metadata: {},
      showTemplatesModal: false,
      templateOptions: {},
      templates: [],
      token: null,
    };
  },

  components: { TemplatesModal, VlChatClient },

  computed: {
    ...mapState('contact', ['contact']),
    ...mapGetters('contact', ['contactId', 'workgroupId']),
    ...mapGetters('authentication', ['whoAmI', 'userObjectId']),
    ...mapGetters('sidebar', ['hasOutboundCallInteractions']),

    ioUrl() {
      return `${VUE_APP_IO_URL}?token=${this.token}&roomId=${this.data.interactionExternalId}`;
    },

    isChatClientDisabled() {
      return !this.isConnected || this.isTerminated;
    },

    isTeletolk() {
      return isTeletolk(this.data.interactionWorkgroup || '');
    },

    transcript() {
      return this.$refs.chatClient.generateTranscript();
    },
  },

  methods: {
    ...mapActions('cti', ['endInteraction']),

    handleOnEndInteraction(reason = 'CONNECTION_LOST') {
      this.isLoading = true;
      this.isTerminated = true;

      Promise.all([
        this.sendTranscriptToCrm(reason),
        this.isConnected
          ? this.endInteraction(this.data.interactionId)
          : undefined,
      ])
        .then(([{ data }]) => {
          this.isLoading = false;

          this.$refs.chatClient.terminate({ transcriptId: data.id });
        })
        .catch(error => {
          this.isLoading = false;

          this.error = error;
        });
    },

    handleOnMetadata(metadata) {
      this.metadata = metadata || {};

      if (this.isTeletolk && !this.hasOutboundCallInteractions) {
        this.$store.dispatch(
          `sidebar/${ADD_INTERACTION}`,
          {
            type: OUTGOING_CALL,
            data: {
              phoneNumber: metadata.phoneNumber,
              contactOrService: metadata.contactPerson,
              sourceType: 'OutgoingCallTeletolk',
              type: 'Initial',
            },
          },
          { root: true },
        );
      }
    },

    handleOnConnected() {
      // Send the contact id
      this.$refs.chatClient.sendMetadata({
        contactId: this.contactId,
      });
    },

    handleOnSyncCitizen(citizen) {
      this.citizen = citizen;
    },

    handleOnSelectTemplate(templateString) {
      const text = this.parseTemplate(templateString);

      this.$refs.chatClient.appendToCurrentMessage(text);

      this.showTemplatesModal = false;
    },

    sendTranscriptToCrm(reason) {
      const transcript = this.$refs.chatClient.generateTranscript();

      const transcriptWithReason = `${transcript}\r\n\r\n${new Date().toLocaleString()}\r\nChat beëindigd. Reden: ${reason}`;

      console.log({ transcriptWithReason });

      const file = new File(
        [transcriptWithReason],
        `chat-transcript-${new Date().toLocaleDateString()}.txt`,
        { type: 'text/plain' },
      );

      const data = new FormData();

      data.append('annotation', file);

      return crmAxios({
        method: 'post',
        url: `/Contact/${this.contactId}/annotations`,
        data,
      });
    },

    parseTemplate(string) {
      return parseTemplate(this.templateOptions)(string);
    },
  },

  async created() {
    this.token = await acquireToken();
  },

  mounted() {
    console.error(`Chat Mount started`);
    this.isLoading = true;

    const { firstname } = this.whoAmI || {};

    crmAxios({
      url: `/ChatTemplate/workgroup/${this.workgroupId}`,
    })
      .then(({ data: templates = [] }) => {
        console.error(`Chat templates loaded`);
        this.templates = templates;
        this.templateOptions = {
          FIRST_NAME: firstname,
        };
        console.error(`Chat preface`);
        const { body: templateString } =
          this.templates.find(propEq('type', 'Preface')) || {};
        console.error(`Chat parsing`);
        this.greeting = this.parseTemplate(templateString);
        console.error(`Chat parsed`);
        this.isLoading = false;

        // start counting
        this.$emit('start');
        console.error(`Chat mounted`);
      })
      .catch(error => {
        console.error(`Chat error`);
        this.isLoading = false;

        this.error = error;
      });
  },

  watch: {
    isConnected(newVal, oldVal) {
      if (oldVal === true && newVal === false) {
        this.$emit('stop');

        if (!this.isTerminated) {
          this.handleOnEndInteraction();
        }
      }
    },
  },
};
</script>

<style module>
.templatesButtons {
  bottom: 1rem;
  position: absolute;
  right: 1rem;
  display: flex;
  flex-direction: column;
}
</style>
