<template>
  <Modal
    @closed="$emit('close')"
    id="modal-chat-templates"
    :open="true"
    :closable="true"
    title="Chat Templates"
  >
    <ul>
      <li
        :class="$style.item"
        v-for="({ body }, index) in templates"
        :key="index"
      >
        <span
          role="button"
          :tabIndex="0"
          @click.prevent="$emit('select', body)"
          >{{ body }}</span
        >
      </li>
    </ul>
  </Modal>
</template>

<script>
export default {
  name: 'ChatTemplates',

  props: ['templates'],
};
</script>

<style lang="scss" module>
.item {
  border-bottom: 1px solid #cbd2da;

  span {
    cursor: pointer;
    display: block;
    padding: 1rem;

    &:hover {
      background: whitesmoke;
    }
  }
}
</style>
