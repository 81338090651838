import { propEq } from 'lodash/fp';

export default {
  // Return the array of found citizens
  citizens(state) {
    return state.citizens || [];
  },

  quickSelectCitizens(state) {
    return state.quickSelectCitizens || [];
  },

  pagerFrom(state) {
    return state.skip + 1;
  },

  pagerTo(state) {
    let to = state.skip + state.perPage;
    if (to > state.totalCount) {
      to = state.totalCount;
    }
    return to;
  },

  // Returns the current range of citizens that is viewed
  currentRange(state, getters) {
    return `${getters.pagerFrom} - ${getters.pagerTo}`;
  },

  // Helper to check if there's a previous page
  hasPreviousPage(state) {
    return state.skip - state.perPage > -1;
  },

  // Helper to check if there's a next page
  hasNextPage(state) {
    return state.skip + state.perPage < state.totalCount;
  },

  getCitizenById: (state, { citizens }) => id => {
    return citizens.find(propEq('id', id));
  },
};
