<template>
  <form @submit.prevent="logIn({ ctiStation })" class="flex items-center ml-8">
    <VlInputField
      v-model="ctiStation"
      placeholder="Station"
      name="ctiLoginStation"
    />

    <VlButton :mod-narrow="true" class="ml-8">Log in</VlButton>
  </form>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  data() {
    return {
      ctiStation: '',
    };
  },

  methods: {
    ...mapActions('cti', ['logIn']),
  },
};
</script>
