<template>
  <div class="vlw__cti-sidebar__content">
    <Interaction
      v-for="{ type, data, id } in interactions"
      :key="id"
      :id="id"
      :data="data"
      :type="type"
    />

    <AddInteraction />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import Interaction from './Interaction';
import AddInteraction from './AddInteraction';

export default {
  name: 'Sidebar',

  components: { AddInteraction, Interaction },

  computed: {
    ...mapGetters('sidebar', ['interactions']),
  },
};
</script>

<style lang="scss">
.vlw__cti-sidebar__content {
  background: whitesmoke;
  height: 100%;

  .vlw__collapse__container {
    border-bottom: 1px solid #cbd2da;
  }

  .vlw__collapse__label {
    padding: 1rem !important;
  }

  .vlw__collapse__inner-container {
    background: white;
  }

  svg {
    width: 14px;
    height: 14px;
    display: block;
    margin: 0;
    padding: 0;
    line-height: 14px;
    fill: #fff;
  }
}
</style>
