import { crmAxios } from '@/utils/Axios';

import { SEND } from '@/constants/action-types';

import { makeAsyncMutationTypes } from '@/utils';

const { FAILURE, REQUEST, SUCCESS } = makeAsyncMutationTypes(SEND);

export default ({ commit, getters }, id) => {
  commit(REQUEST);

  const {
    attachments, // eslint-disable-line no-unused-vars
    emailPurpose, // eslint-disable-line no-unused-vars
    ...outgoingEmail
  } = getters.getEmailById(id);

  return crmAxios({
    method: 'post',
    url: `/Email/${id}/send`,
    data: outgoingEmail,
  })
    .then(() => {
      commit(SUCCESS, id);
    })
    .catch(() => {
      commit(FAILURE);
    });
};
