// Import modules
import Vue from 'vue';

// Create an event bus for triggering events application wide
const bus = new Vue({});

// Vanilla debounce
function debounce(func, wait = 100) {
  let timeout;
  return (...args) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      func.apply(this, args);
    }, wait);
  };
}

// Event listener to receive postmessage messages
function receiveMessage(e) {
  // Check the origin
  if (e.origin === process.env.VUE_APP_VLS_URL) {
    // Emit a postmessage event
    bus.$emit('postmessage', e.data);
  }
}

// Add a window resize listener to which components can listen to
if (window) {
  let windowWidth = window.innerWidth;
  let windowHeight = window.innerHeight;

  // Create a debounced function to be called when the window resizes
  window.addEventListener(
    'resize',
    debounce(() => {
      // Emit an event with current dimensions and old dimensions
      bus.$emit(
        'window-resize',
        {
          width: window.innerWidth,
          height: window.innerHeight,
        },
        {
          width: windowWidth,
          height: windowHeight,
        },
      );

      // Update old dimensions
      windowWidth = window.innerWidth;
      windowHeight = window.innerHeight;
    }, 200),
    false,
  );

  // Listen for postmessages
  window.addEventListener('message', receiveMessage, false);
}

// Export the bus
export default bus;
