<template>
  <iframe
    :src="iframeUrlWithToken"
    class="testiframe"
    title="cobrowse"
    style="width: -webkit-fill-available;height: 500px;"
  ></iframe>
</template>

<script>
import { mapState } from 'vuex';
import { crmAxios } from '@/utils/Axios';
import bus from '@/utils/bus';
import CobrowseAPI from 'cobrowse-agent-sdk';

export default {
  name: 'Cobrowse',

  data() {
    return {
      error: null,
      isLoading: false,
      iframeUrl: '',
      state: '',
    };
  },

  computed: {
    ...mapState('contact', ['contact']),

    iframeUrlWithToken() {
      return this.iframeUrl;
    },
  },

  created() {
    let url = `/Cobrowse/token?themeId=${this.contact.themeId}`;
    this.isLoading = true;

    crmAxios({
      method: 'get',
      url,
      headers: {
        Accept: 'application/json',
      },
    }).then(response => {
      this.isLoading = false;
      if (response.data && response.data.token) {
        this.iframeUrl = `https://eu.cobrowse.io/code?navigation=none&end_action=none&device_controls=none&token=${response.data.token}`;
      }
    });
  },

  async mounted() {
    this.$emit('start');

    const cobrowse = new CobrowseAPI(); // JWT not required
    const ctx = await cobrowse.attachContext(this.$el);

    ctx.on('session.updated', session => {
      bus.$emit('sessionUpdatedCobrowse', session);
    });
  },
};
</script>
