import { CONNECT_ERROR, NO_ROOM_ID } from '../constants';

export const defaultErrorMessage = 'Er ging iets fout. Probeer later nog eens.';
export const errorMessages = {
  [CONNECT_ERROR]:
    'Er is op dit moment geen verbinding. Probeer het later nog eens.',
  [NO_ROOM_ID]: 'No room ID found.',
};
export const firstMessageText =
  'Stel uw vraag, het gesprek wordt daarna gestart.';
