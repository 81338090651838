import { UPDATE_LOADING_STATE } from '@/constants/mutation-types';
import { crmAxios } from '@/utils/Axios';
import Cookies from 'js-cookie';
import { COOKIE_NAME_CONTACT_ID } from '@/constants';

export default ({ state, rootGetters, commit, dispatch }) => {
  // Exit early if no contact
  if (!state.contact) {
    return Promise.reject(new Error('Cannot delete a non-existing contact.'));
  }

  // Only allow to close if:
  // - the contact is manually created
  // AND
  // - the contact has no questions
  if (!state.contact.isManuallyCreated) {
    throw new Error('Can only delete manually created contacts.');
  }

  if (state.contact.questions.length) {
    throw new Error('Can not delete a contact with questions.');
  }

  // Update the loading state
  commit(UPDATE_LOADING_STATE, true);

  // Call the CRM API
  return crmAxios({
    method: 'delete',
    url: `/Contact/${state.contact.id}`,
  })
    .then(() => {
      if (rootGetters['cti/isLoggedIn']) {
        dispatch('cti/closeContact', null, { root: true });
      }

      Cookies.remove(COOKIE_NAME_CONTACT_ID);

      dispatch('getContact', null);

      commit(UPDATE_LOADING_STATE, false);
    })
    .catch(error => {
      commit(UPDATE_LOADING_STATE, false);

      commit('saveContactError', error);
    });
};
