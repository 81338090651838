import { get, identity, last, propEq } from 'lodash/fp';

import { channels, contactStatuses, contactSubStatuses } from '@/config';
import { INCOMING_CALL, INCOMING_CHAT, EXTERNAL_TICKET } from '@/constants';

import cannotBeClosedBecause from './cannot-be-closed-because';

const { LOPEND } = contactSubStatuses;
const { GESLOTEN } = contactStatuses;

export default {
  cannotBeClosedBecause,

  allQuestionsAreValid({ contact }) {
    const questionIsValid = ({ title, answers }) => title && answers.length > 0;

    return contact && contact.questions.every(questionIsValid);
  },

  availableChannels(state, { channel: key }) {
    const channel = channels[key];

    return channel ? channel.availableChannels : [];
  },

  callbacks({ contact }) {
    return contact ? contact.callBacks : [];
  },

  getCallbackByAttempt: (state, { callbacks }) => attempt => {
    return callbacks.find(propEq('attempt', attempt));
  },

  firstCallback(state, { getCallbackByAttempt }) {
    return getCallbackByAttempt('First');
  },

  secondCallback(state, { getCallbackByAttempt }) {
    return getCallbackByAttempt('Second');
  },

  currentCallback(state, { firstCallback, secondCallback }) {
    return secondCallback || firstCallback;
  },

  canTransferInternallyWithinLine(state, { allQuestionsAreValid }) {
    return allQuestionsAreValid;
  },

  canStartEndRegistration({ contact }) {
    if (!contact || !contact.id) {
      return false;
    }

    return true;
  },

  channel({ contact }) {
    return contact ? contact.channel : undefined;
  },

  channelName(state, { channel: key }) {
    const channel = channels[key];

    return channel ? channel.name : undefined;
  },

  channelIsChat(state, { channel }) {
    return channel === INCOMING_CHAT;
  },

  channelIsIncomingCall(state, { channel }) {
    return channel === INCOMING_CALL;
  },

  channelIsTicket(state, { channel }) {
    return channel === EXTERNAL_TICKET;
  },

  createdOn({ contact }) {
    return contact ? contact.createdOn : undefined;
  },

  contactId(state) {
    return state.contact ? state.contact.id : undefined;
  },

  defaultEscalationPointId(
    state,
    { contactId, themeId },
    rootState,
    rootGetters,
  ) {
    if (contactId == null) {
      return;
    }

    const getDefaultEscalationPointIdByThemeId =
      rootGetters['metadata/getDefaultEscalationPointIdByThemeId'];

    return getDefaultEscalationPointIdByThemeId(themeId);
  },

  defaultWorkgroupName: (state, getters, rootState, rootGetters) => {
    const themeId = getters.themeId;

    if (themeId == null) {
      return;
    }

    const getDefaultWorkgroupNameByThemeId =
      rootGetters['metadata/getDefaultWorkgroupNameByThemeId'];

    return getDefaultWorkgroupNameByThemeId(themeId);
  },

  emails({ contact }) {
    return contact ? contact.emails : undefined;
  },

  firstQuestionPersonPostalCode({ contact }, getters, rootState, rootGetters) {
    if (!contact) {
      return null;
    }

    const getPostalCodeFromCitizen = ({
      id,
      postalCode: hasPostalCode,
    } = {}) => {
      if (hasPostalCode) {
        return hasPostalCode;
      }

      const getCitizenById = rootGetters['citizen/getCitizenById'];

      const { postalCode } = getCitizenById(id) || {};

      return postalCode;
    };

    const getPostalCodeFromKindEnGezinPersonId = id => {
      const { miragePersonsById } = rootState.mirage;

      const { postcode } = miragePersonsById[id] || {};

      return postcode;
    };

    return contact.questions.reduce(
      (acc, { citizen, kindEnGezin_PersonId }) =>
        acc ||
        getPostalCodeFromCitizen(citizen) ||
        getPostalCodeFromKindEnGezinPersonId(kindEnGezin_PersonId),
      null,
    );
  },

  hasClosedStatus({ contact }) {
    return contact && contact.status === GESLOTEN;
  },

  hasDraftEmails({ contact }, { contactId }) {
    if (contactId == null) {
      return;
    }

    const { emails = [] } = contact;

    return emails.reduce(
      (acc, { statusCode }) => acc || statusCode === 'Draft',
      false,
    );
  },

  hasActiveScreensharingSessions({ contact }, { contactId }) {
    if (contactId == null) {
      return;
    }

    const { screenSharingSessions = [] } = contact;

    return screenSharingSessions.reduce(
      (acc, { state }) => acc || state === 'active',
      false,
    );
  },

  contactHasCitizenSelected({ contact }) {
    return !!(contact && contact.citizen && contact.citizen.id);
  },

  hasQuestion({ contact }) {
    return contact ? contact.questions.length > 0 : false;
  },

  isLoading(state) {
    return state.loading;
  },

  isLopendLopend(state, { subStatusId }) {
    return subStatusId === LOPEND;
  },

  isManual({ contact: { originCode } = {} } = {}) {
    return originCode === 'ManualVlp';
  },

  isStatusEnabledById: (state, getters, rootState, rootGetters) => id => {
    if (id !== GESLOTEN) {
      return true;
    }

    const hasSentEmail = rootGetters['emails/hasSentEmail'];

    if (hasSentEmail) {
      return false;
    }

    return true;
  },

  lineId(state) {
    return state.contact ? state.contact.lineId : undefined;
  },

  lineName(state, { lineId }, rootState, rootGetters) {
    const getLineById = rootGetters['metadata/getLineById'];

    const line = getLineById(lineId);

    return line ? line.name : null;
  },

  questionById: ({ contact }) => id => {
    return contact.questions.find(propEq('id', id));
  },

  outboundCalls({ contact }) {
    return contact ? contact.outboundCalls : undefined;
  },

  outboundCallByInteractionId: (
    state,
    { outboundCalls = [] },
  ) => interactionId => {
    return outboundCalls.find(propEq('interactionId', interactionId));
  },

  startThemeId(state) {
    return state.contact ? state.contact.startThemeId : undefined;
  },

  startThemeName(state, { startThemeId }, rootState, rootGetters) {
    const getThemeById = rootGetters['metadata/getThemeById'];

    const theme = getThemeById(startThemeId);

    return theme ? theme.name : null;
  },

  subStatusId({ contact }) {
    return contact ? contact.subStatus : undefined;
  },

  statusId(state, { subStatusId }, rootState, rootGetters) {
    if (subStatusId == null) {
      return null;
    }

    const getStatusIdBySubStatusId =
      rootGetters['metadata/getStatusIdBySubStatusId'];

    return getStatusIdBySubStatusId('contactStatuses', subStatusId);
  },

  themeId(state) {
    return state.contact ? state.contact.themeId : undefined;
  },

  workgroupId(state) {
    return state.contact ? state.contact.workgroupId : undefined;
  },

  currentWorkgroup(state, getters, rootState, rootGetters) {
    const parentInteractionWorkgroupName =
      rootGetters['cti/parentInteractionWorkgroup'];

    if (!getters.workgroupId && !parentInteractionWorkgroupName) {
      return;
    }

    const getWorkgroupById = rootGetters['metadata/getWorkgroupById'];
    const getWorkgroupByName = rootGetters['metadata/getWorkgroupByName'];

    return (
      getWorkgroupById(getters.workgroupId) ||
      getWorkgroupByName(parentInteractionWorkgroupName)
    );
  },

  currentLine(state, { lineId }, rootState, rootGetters) {
    const getLineById = rootGetters['metadata/getLineById'];

    return getLineById(lineId);
  },

  requiredEndRegistrationFields(state, { currentLine }) {
    return currentLine ? currentLine.completionInfo_RequiredFields || [] : [];
  },

  requiredEndRegistrationFieldsByApiName(state, getters) {
    const fields = getters.requiredEndRegistrationFields;

    if (fields == null || fields.length === 0) {
      return [];
    }

    return fields.map(field => field.apiName);
  },

  currentTheme(state, { themeId }, rootState, rootGetters) {
    const getThemeById = rootGetters['metadata/getThemeById'];

    return getThemeById(themeId);
  },

  workgroupName(state, getters) {
    if (getters.isManual) {
      return getters.defaultWorkgroupName;
    }

    return getters.currentWorkgroup ? getters.currentWorkgroup.name : null;
  },

  selectedCitizen({ contact }) {
    return contact.citizen;
  },

  selectedCitizenEmail(state, { selectedCitizen }) {
    return selectedCitizen ? (selectedCitizen.emailAddress || '').trim() : null;
  },

  firstQuestion({ contact: { questions = [] } }) {
    return questions[0];
  },

  firstQuestionId(state, { firstQuestion = {} }) {
    return firstQuestion.id;
  },

  lastQuestion({ contact: { questions = [] } }) {
    return last(questions);
  },

  lastQuestionId(state, { lastQuestion = {} }) {
    return lastQuestion.id;
  },

  informationBanners(state, { currentLine, currentTheme, currentWorkgroup }) {
    return [currentLine, currentTheme, currentWorkgroup]
      .map(get('vlpInformationBanner'))
      .filter(identity);
  },

  isSpeechRouting(state, { currentLine }) {
    return currentLine
      ? currentLine.speechRouting != null
        ? currentLine.speechRouting
        : false
      : false;
  },

  isScreenSharingAllowed(state, { currentTheme }) {
    return currentTheme
      ? currentTheme.isScreenSharingAllowed != null
        ? currentTheme.isScreenSharingAllowed
        : false
      : false;
  },

  showQuickSelectCitizen(state, { currentTheme }) {
    return currentTheme
      ? currentTheme.showQuickSelectCitizen != null
        ? currentTheme.showQuickSelectCitizen
        : false
      : false;
  },
};
