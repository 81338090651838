<template>
  <div>
    <div class="flex items-center justify-center" v-if="isLoading">
      <VlLoader />&nbsp;Loading...
    </div>
    <div v-else class="screenshot">
      <img :src="screenshot.imageDataURL" />
      <VlLink class="delete" href="#" @click.prevent="$emit('delete')">
        <VlIcon class="mr-4" icon="trash" />verwijderen
      </VlLink>
    </div>
  </div>
</template>

<script>
// Imports
import { mapGetters } from 'vuex';
import { crmAxios } from '@/utils/Axios';
import bus from '@/utils/bus';

export default {
  props: {
    screenshot: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      error: null,
      isLoading: false,
    };
  },
  computed: {
    ...mapGetters('metadata', ['getLabelByName']),
  },
  methods: {
    async loadImageDataURL() {
      if (this.screenshot.imageDataURL != null) return;
      this.isLoading = true;
      try {
        const { id } = this.screenshot;

        const { data } = await crmAxios({
          url: `/Annotation/${id}`,
          responseType: 'blob',
        });
        var reader = new FileReader();
        reader.readAsDataURL(data);
        reader.onload = event => {
          console.error(event);
          this.screenshot.imageDataURL = reader.result;
        };
      } catch (error) {
        console.error(error);

        this.error = error;

        const label = this.getLabelByName('notpossible_download_attachment');

        bus.$emit('show-modal', {
          body: label.body,
          message: label.title,
        });
      }

      this.isLoading = false;
    },
  },
  async mounted() {
    this.loadImageDataURL();
  },
};
</script>

<style lang="scss">
// Wrapper
.screenshot {
  position: relative;
}

.screenshot img {
  opacity: 0.7;
  width: 100%;
  padding: 5px;
}

.screenshot img:hover {
  opacity: 1;
}

.delete {
  background-color: white;
  display: none;
  position: absolute;
  bottom: 20px;
  right: 10px;
  opacity: 0.5;
}

.screenshot:hover .delete {
  display: block;
}
</style>
