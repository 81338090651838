import bus from '@/utils/bus';
import { toLower } from 'lodash';

export default ({ dispatch, rootState, rootGetters }) => ({
  interactionId,
  interactionWorkgroup,
  interactionNlpQuestion,
}) => {
  dispatch('contact/changeInteractionId', interactionId, {
    root: true,
  });

  if (interactionNlpQuestion) {
    dispatch('contact/changeInteractionNlpQuestion', interactionNlpQuestion, {
      root: true,
    });
  }

  const getLabelByName = rootGetters['metadata/getLabelByName'];

  if (interactionWorkgroup) {
    // Remember if found
    let workgroupFound = false;

    // Loop over all lines
    for (let i = 0; i < (rootState.metadata.lines || []).length; i += 1) {
      // Get the line
      const line = rootState.metadata.lines[i];
      // Check if line has themes
      if (line.themes && line.themes.length) {
        // Loop over themes
        for (let j = 0; j < line.themes.length; j += 1) {
          // Get the theme
          const theme = line.themes[j];

          // Check if the theme has workgroups
          if (theme.workgroups && theme.workgroups.length) {
            for (let k = 0; k < theme.workgroups.length; k += 1) {
              // Get the workgroup
              const workgroup = theme.workgroups[k];

              // Check if workgroup is found
              if (toLower(workgroup.name) === toLower(interactionWorkgroup)) {
                // Remember we found the workgroup
                workgroupFound = true;

                dispatch('contact/changeChannel', workgroup.channel, {
                  root: true,
                });

                // Dispatch events to set the line and theme and workgroup
                dispatch('contact/changeWorkgroup', workgroup, {
                  root: true,
                });
                dispatch('contact/changeLine', line.id, { root: true });
                dispatch('contact/changeTheme', theme.id, {
                  root: true,
                });
              }
            }
          }
        }
      }
    }

    // Check if a workgroup was found
    if (!workgroupFound) {
      // const label = getLabelByName('theme_not_found');
      // Emit an open modal event, add pass message as payload
      console.error(
        `Did not find workgroup in lines metadata for: ${interactionWorkgroup}`,
      );
    }
  } else {
    const label = getLabelByName('cti_no_workgroup');
    // Emit an open modal event, add pass message as payload
    bus.$emit('show-modal', {
      message: label.title,
      body: label.body,
    });
  }
};
