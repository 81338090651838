import { FETCH } from '@/constants/action-types';
import { makeAsyncMutationTypes } from '@/utils';
import { crmAxios } from '@/utils/Axios';

const { FAILURE, REQUEST, SUCCESS } = makeAsyncMutationTypes(FETCH);

const getInitialState = () => ({
  data: {},
  error: null,
  isLoading: false,
});

const actions = {
  fetch({ commit }) {
    commit(REQUEST);

    return crmAxios({
      url: '/User/whoami',
    })
      .then(({ data }) => commit(SUCCESS, data))
      .catch(error => commit(FAILURE, error));
  },
};

const mutations = {
  [FAILURE](state, error) {
    state.error = error;
    state.isLoading = false;
  },

  [REQUEST](state) {
    state.isLoading = true;
  },

  [SUCCESS](state, data) {
    state.data = data;
    state.isLoading = false;
  },
};

export default {
  actions,
  mutations,
  namespaced: true,
  state: getInitialState(),
};
