import CustomAuthenticationContext from '@/utils/custom-adal';
import axios from 'axios';

import { isLocal } from '@/config';

const {
  VUE_APP_MIRAGE_URL,
  VUE_APP_MIRAGE_CLIENT_ID,
  VUE_APP_MIRAGE_RESOURCE_ID,
} = process.env;

const config = {
  instance: 'https://fs.kindengezin.be/',
  clientId: VUE_APP_MIRAGE_CLIENT_ID,
  tenant: 'adfs',
  redirectUri: window.location.href,
  redirect_uri: window.location.href,
  name: 'MIRAGE',
};

export const authenticationContext = new CustomAuthenticationContext(config);

if (authenticationContext.isCallback(window.location.hash)) {
  authenticationContext.handleWindowCallback();
}

const mirageApi = axios.create({
  baseURL: `${VUE_APP_MIRAGE_URL}/mirage-controller/api/ivkglijn`,
});

const acquireToken = authenticationContext =>
  new Promise((resolve, reject) =>
    authenticationContext.acquireToken(
      VUE_APP_MIRAGE_RESOURCE_ID,
      (error, token) => {
        if (error || !token) {
          return reject(error || 'No token received');
        }

        return resolve(token);
      },
    ),
  );

const requestInterceptor = async requestConfig => {
  try {
    const token = await acquireToken(authenticationContext);

    requestConfig.headers.Authorization = `Bearer ${token}`;

    return requestConfig;
  } catch (error) {
    console.log({ error });

    throw new Error(error);
  }
};

mirageApi.interceptors.request.use(requestInterceptor);

const dummyData = {
  personen: [
    {
      miragePersoonId: '8A89800664209223016422772E664FD1',
      naam: 'Bouzarda',
      voornaam: 'Alaa',
      geboorteDatum: '2018-06-20',
      postcode: '2550',
      overlijdensDatum: null,
      discretieVereist: true,
      mirageUrl:
        'https://tstmirage.kindengezin.be/mirage-controller/api/ivkglijn/8A89800664209223016422772E664FD1',
    },
    {
      miragePersoonId: '8A89800664209223016423F20B5E10C9',
      naam: 'Bousrih',
      voornaam: 'Amira',
      geboorteDatum: '2018-06-20',
      postcode: '9600',
      overlijdensDatum: '2018-06-20',
      discretieVereist: false,
      mirageUrl:
        'https://tstmirage.kindengezin.be/mirage-controller/api/ivkglijn/8A89800664209223016423F20B5E10C9',
    },
    {
      miragePersoonId: null,
      naam: 'Berisha',
      voornaam: 'Ahmed',
      geboorteDatum: '2018-06-20',
      postcode: '3550',
      overlijdensDatum: null,
      discretieVereist: false,
      mirageUrl:
        'https://tstmirage.kindengezin.be/mirage-controller/api/ivkglijn/8A8980096425B5B80164278ABAA535FD',
    },
  ],
};

export default options => {
  if (!isLocal) {
    return mirageApi(options);
  }

  const { url, data } = options;

  switch (url) {
    case '/zoekPersoon': {
      return Promise.resolve({ data: dummyData });
    }

    case '/haalPersoon': {
      return Promise.resolve({
        data: dummyData.personen.find(
          ({ miragePersoonId }) => miragePersoonId === data,
        ),
      });
    }
  }
};
