import { ctiApi } from '@/utils';
import { UPDATE_LOADING_STATE } from '@/constants/mutation-types';

import handleCtiError from './handle-cti-error';

export default ({ commit, rootGetters }, { url, data }) => {
  commit(UPDATE_LOADING_STATE, true);

  const workgroup = rootGetters['contact/workgroupName'];

  return ctiApi({
    method: 'post',
    url,
    data: workgroup != null ? { workgroup, ...data } : data,
  }).catch(handleCtiError(commit));
};
