import { FETCH } from '@/constants/action-types';
import { makeAsyncMutationTypes } from '@/utils';

const fetch = makeAsyncMutationTypes(FETCH);

export default {
  [fetch.FAILURE](state, error) {
    state.error = error;
    state.isLoading = false;
  },

  [fetch.REQUEST](state) {
    state.isLoading = true;
  },

  [fetch.SUCCESS](state, data) {
    state.data = data;

    state.isLoading = false;
    state.hasFetched = true;
  },
};
