<template>
  <div :class="$style.wrapper">
    <textarea
      ref="textAreaRef"
      :disabled="disabled"
      :value="value"
      @input="$emit('input', $event.target.value)"
      @keydown.enter.exact.prevent
      @keyup.enter.exact="$emit('send')"
      @keyup.exact="handleKeyUp"
      maxlength="750"
      autocomplete="off"
      placeholder="Typ uw bericht"
      :class="$style.textarea"
    />
  </div>
</template>

<script>
export default {
  name: 'InputField',

  isTypingTimeoutId: null,

  props: ['disabled', 'userType', 'value'],

  computed: {
    shouldShowSendButton() {
      return this.userType === 'CITIZEN';
    },
  },

  methods: {
    handleKeyUp({ keyCode }) {
      if (keyCode === 13) {
        return;
      }

      this.$emit('isTyping', true);

      window.clearTimeout(this.isTypingTimeoutId);

      this.isTypingTimeoutId = window.setTimeout(() => {
        this.$emit('isTyping', false);
      }, 2000);
    },
  },
};
</script>

<style module>
.wrapper {
  position: relative;
}

.textarea {
  border: 1px solid #ccc;
  font-size: 16px;
  height: 100px;
  padding: 1rem;
  padding-right: 6rem;
  resize: vertical;
  width: 100%;
}
</style>
