import { curry } from 'lodash/fp';

// from ramda: https://ramdajs.com/docs/#evolve
/* eslint-disable no-nested-ternary */
const evolve = curry((transformations, object) => {
  const result = object instanceof Array ? [] : {};
  let transformation, key, type;
  for (key in object) {
    transformation = transformations[key];
    type = typeof transformation;
    result[key] =
      type === 'function'
        ? transformation(object[key])
        : transformation && type === 'object'
        ? evolve(transformation, object[key])
        : object[key];
  }
  return result;
});
/* eslint-enable */

export default evolve;
