import { crmAxios } from '@/utils/Axios';

export default ({ commit, state }, payload) => {
  if (!payload.answer) {
    throw new Error('answer not set');
  }

  if (!payload.question) {
    throw new Error('Question not set');
  }

  // Find question in array of questions
  const questionId = payload.question.internalId || payload.question;

  // Use a string comparison, as the id might have been send/retrieved from the VLS using a queryString
  const foundQuestion = state.contact.questions.find(
    (q) => q.internalId.toString() === questionId.toString(),
  );

  if (!foundQuestion) {
    return Promise.reject(new Error(`Question not found (Id: ${questionId})`));
  }

  // Check if the answer is already used
  const answerAlreadyUsed = foundQuestion.answers.find(
    (a) => a.id.toString() === payload.answer.id.toString(),
  );
  if (answerAlreadyUsed) {
    return Promise.resolve();
  }

  // Set the answer's loading state to true
  payload.answer.loading = true;

  // Append the answer to the question's answers
  foundQuestion.addAnswer(payload.answer);

  // Save the answer
  const saveAnswerPromise = crmAxios({
    method: 'post',
    url: `/Question/${foundQuestion.id}/solutions`,
    data: payload.answer.toApiObject(),
    headers: {
      Accept: 'application/json',
    },
  })
    .then((response) => {
      // Update the answer's loading state
      payload.answer.loading = false;

      // Update the answer once it's added to the crm
      if (response.data && typeof response.data.id === 'string') {
        // Update the CRM id
        payload.answer.crmId = response.data.id;

        commit('saveAnswerSuccess'); // needed to update state. TODO: REFACTOR
      } else {
        throw new Error('CRM Error: No solution id returned.');
      }
    })
    .catch(() => {
      // Delete the answer
      foundQuestion.deleteAnswer(payload.answer);
    });

  return saveAnswerPromise;
};
