import { allPass, last, prop, propEq } from 'lodash/fp';

import { readableInteractionState } from '@/config';
import { ALERTING, CONNECTED, HELD } from '@/constants/interaction-states';
import {
  ACW,
  I2C_ACW,
  INCOMING_CHAT,
  INCOMING_EMAIL,
  RECALL,
} from '@/constants';
import {
  getInteractionFromWorkgroup,
  getInteractionTypeFromCtiInteraction,
} from '@/utils';

const isInteractionConnected = ({ interactionState } = {}) =>
  [CONNECTED, HELD].includes(interactionState);

export default {
  recallInteractions({ interactions }) {
    return interactions.filter(
      data => getInteractionTypeFromCtiInteraction(data) === RECALL,
    );
  },

  getInteractionById: ({ interactions }) => id =>
    interactions.find(propEq('interactionId', id)),

  isAlerting(state) {
    return state.interactions.reduce(
      (acc, { interactionState }) => acc || interactionState === ALERTING,
      false,
    );
  },

  isChat(state, { parentInteraction }) {
    return (
      parentInteraction &&
      getInteractionTypeFromCtiInteraction(parentInteraction) === INCOMING_CHAT
    );
  },

  isConnected(state) {
    return state.interactions.reduce(
      (acc, interaction) => acc || isInteractionConnected(interaction),
      false,
    );
  },

  isConnectedByInteractionId: (state, { getInteractionById }) => id => {
    const interaction = getInteractionById(id);

    if (interaction == null) {
      return false;
    }

    return isInteractionConnected(interaction);
  },

  isLoggedIn({ user }) {
    return user.isLoggedIn;
  },

  isUserInAcw({ user }) {
    if (user.isLoggedIn) return user.status === ACW || user.status === I2C_ACW;
    return false;
  },

  isMail(state, { parentInteractionWorkgroup }) {
    if (!parentInteractionWorkgroup) {
      return false;
    }

    const interaction = getInteractionFromWorkgroup(parentInteractionWorkgroup);

    if (interaction == null) {
      return false;
    }

    return interaction.key === INCOMING_EMAIL;
  },

  isRecall(state, { parentInteraction }) {
    return (
      parentInteraction &&
      getInteractionTypeFromCtiInteraction(parentInteraction) === RECALL
    );
  },

  isOnHold(state, { parentInteractionState }) {
    return parentInteractionState === HELD;
  },

  isParentInteractionConnected(state, { parentInteraction }) {
    return isInteractionConnected(parentInteraction);
  },

  isPauseEnabled(state, { isMail }) {
    return !isMail;
  },

  parentInteraction({ interactions }) {
    return interactions[0];
  },

  parentInteractionId(state, { parentInteraction = {} }) {
    return parentInteraction.interactionId;
  },

  parentInteractionState(state, { parentInteraction = {} }) {
    return parentInteraction.interactionState;
  },

  parentInteractionType(state, { parentInteraction }) {
    return parentInteraction
      ? getInteractionTypeFromCtiInteraction(parentInteraction)
      : undefined;
  },

  parentInteractionWorkgroup(state, { parentInteraction = {} }) {
    return parentInteraction.interactionWorkgroup;
  },

  readableInteractionState(state, { parentInteractionState }) {
    return (
      readableInteractionState[parentInteractionState] ||
      `Onbekende status: ${parentInteractionState}`
    );
  },

  transferInteraction(
    { interactions },
    { isChat, isRecall, parentInteraction, recallInteractions },
    rootState,
    rootGetters,
  ) {
    if (isRecall) {
      return last(recallInteractions);
    }

    if (isChat) {
      const outboundCalls = rootGetters['contact/outboundCalls'] || [];

      const outboundCallIds = outboundCalls.map(prop('interactionId'));

      const isOutboundCall = ({ interactionId }) =>
        outboundCallIds.includes(interactionId);

      const isHit = allPass([isOutboundCall, isInteractionConnected]);

      const connectedInteraction = interactions.find(isHit);

      return connectedInteraction || parentInteraction;
    }

    return parentInteraction;
  },

  transferInteractionId(state, { transferInteraction = {} }) {
    return transferInteraction.interactionId;
  },

  hasInteractions({ interactions }) {
    return interactions.length > 0;
  },

  interactionRecordingRetentionInMonths(
    state,
    {
      parentInteraction: { interactionRecordingRetentionInMonths: value } = {},
    },
  ) {
    return value ? value : undefined; // cti sends an empty string if undefined...
  },

  interactionNlpQuestion(
    state,
    { parentInteraction: { interactionNlpQuestion: value } = {} },
  ) {
    return value ? value : undefined; // cti sends an empty string if undefined...
  },
};
