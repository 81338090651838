import { propEq } from 'lodash/fp';

import { lines } from '@/config';

const lineHasWorkgroup = line => workgroupId =>
  line.themes.reduce(
    (acc, { workgroups = [] }) =>
      acc || !!workgroups.find(propEq('id', workgroupId)),
    false,
  );

const getWorkgroupByProp = key => state => value =>
  state.lines.reduce(
    (acc, { themes = [] }) =>
      acc ||
      themes.reduce(
        (acc2, { workgroups = [] }) =>
          acc2 || workgroups.find(propEq(key, value)),
        null,
      ),
    null,
  );

export default {
  getDefaultEscalationPointIdByThemeId: (
    state,
    { getThemeById },
  ) => themeId => {
    if (themeId == null) {
      throw new Error('Expected theme id but none received');
    }

    const theme = getThemeById(themeId);

    if (theme == null) {
      throw new Error(`Theme not found: ${themeId}`);
    }

    return theme.defaultEscalationPointId;
  },

  getDefaultSubStatusIdByType: state => (
    statusType,
    defaultType = 'isDefault',
  ) => {
    const statuses = state[statusType];

    return statuses.reduce((acc, { subStatuses }) => {
      if (acc) {
        return acc;
      }

      const { id } = subStatuses.find(propEq(defaultType, true)) || {};

      return id;
    }, null);
  },

  getThemeById: state => id =>
    (state.lines || []).reduce(
      (acc, { themes = [] }) => acc || themes.find(propEq('id', id)),
      null,
    ),

  getLineById: state => id => (state.lines || []).find(propEq('id', id)),

  getWorkgroupById: getWorkgroupByProp('id'),

  getWorkgroupByName: getWorkgroupByProp('name'),

  lineHasWorkgroup: (state, { getLineById }) => (lineId, workgroupId) => {
    const line = getLineById(lineId);

    return lineHasWorkgroup(line)(workgroupId);
  },

  availableLines: ({ lines = [] }, { userWorkgroups: workgroupIds }) => {
    const isValid = line =>
      workgroupIds.reduce(
        (acc, workgroupId) => acc || lineHasWorkgroup(line)(workgroupId),
        false,
      );

    return lines.filter(isValid);
  },

  hasKindEnGezin(state, { availableLines }) {
    return availableLines.reduce(
      (acc, { id }) => acc || id === lines.KIND_EN_GEZIN,
      false,
    );
  },

  availableThemesByLineId: (
    state,
    { getLineById, userWorkgroups: workgroupIds },
  ) => lineId => {
    const { themes = [] } = getLineById(lineId) || {};

    return themes.filter(({ workgroups }) =>
      workgroups.reduce(
        (acc, { id }) => acc || workgroupIds.includes(id),
        false,
      ),
    );
  },

  getStatusIdBySubStatusId: state => (statusType, subStatusId) => {
    const statuses = state[statusType] || [];

    return statuses.reduce(
      (acc, { id: statusId, subStatuses }) =>
        acc ||
        (subStatuses.find(({ id }) => id === subStatusId) ? statusId : acc),
      null,
    );
  },

  getStatusByTypeAndStatusId: state => (type, id) => {
    const { [type]: statuses } = state;

    return statuses.find(propEq('id', id));
  },

  getStatusByTypeAndSubStatusId: state => (type, id) => {
    const { [type]: statuses } = state;

    const hasSubStatusId = subStatusId => ({ subStatuses }) =>
      subStatuses.reduce((acc, { id }) => acc || id === subStatusId, false);

    return statuses.find(hasSubStatusId(id));
  },

  getSubStatusByTypeAndSubStatusId: state => (type, id) => {
    const { [type]: statuses } = state;

    return statuses.reduce(
      (acc, { subStatuses }) => acc || subStatuses.find(propEq('id', id)),
      null,
    );
  },

  getReadableStatusByTypeAndSubStatusId: (
    state,
    { getStatusByTypeAndSubStatusId, getSubStatusByTypeAndSubStatusId },
  ) => (type, id) => {
    if (!id) {
      return 'nvt';
    }

    const { name: statusName } = getStatusByTypeAndSubStatusId(type, id);
    const { name: subStatusName } = getSubStatusByTypeAndSubStatusId(type, id);

    return `${statusName}/${subStatusName}`;
  },

  getReadableContactStatusBySubStatusId: (
    state,
    { getReadableStatusByTypeAndSubStatusId },
  ) => id => {
    return getReadableStatusByTypeAndSubStatusId('contactStatuses', id);
  },

  getReadableQuestionStatusBySubStatusId: (
    state,
    { getReadableStatusByTypeAndSubStatusId },
  ) => id => {
    return getReadableStatusByTypeAndSubStatusId('questionStatuses', id);
  },

  userWorkgroups: (state, getters, rootState) => {
    return rootState.authentication.workgroups.data;
  },

  getDefaultWorkgroupByThemeId: (state, { getThemeById }) => themeId => {
    if (themeId == null) {
      return null;
    }

    const theme = getThemeById(themeId);

    if (theme == null) {
      throw new Error(`Theme not found: ${themeId}`);
    }

    const { workgroups } = theme;

    return workgroups.find(propEq('isDefault', true));
  },

  getDefaultWorkgroupNameByThemeId: (
    state,
    { getDefaultWorkgroupByThemeId },
  ) => themeId => {
    const workgroup = getDefaultWorkgroupByThemeId(themeId);

    if (workgroup != null) {
      return workgroup.name;
    }
  },

  vlpLabels: ({ vlplabels }) => vlplabels,

  getLabelByName: (state, { vlpLabels }) => name => {
    const fallback = ['body', 'title', 'confirm', 'cancel'].reduce(
      (acc, key) => ({
        ...acc,
        [key]: `Missing string for name ${name}, key ${key}`,
      }),
      {},
    );

    return vlpLabels.find(propEq('name', name)) || fallback;
  },

  isInformationBannerEnabled({ vlpbannerenabled = {} }) {
    return vlpbannerenabled.value === 'True';
  },

  getWorkgroupSimplifiedNameById: (
    state,
    { getWorkgroupById },
  ) => workgroupId => {
    var workgroup = getWorkgroupById(workgroupId);
    return workgroup.simplifiedName;
  },
};
