var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Modal',{staticClass:"vlw__cti-child-modal is-large",attrs:{"id":"modal-search-child","open":true,"closable":true,"title":"Zoek een kind"},on:{"closed":function($event){return _vm.$emit('close')}}},[(!_vm.isLoggedIn)?_c('div',{staticClass:"flex"},[_c('VlLoader'),_c('div',{staticClass:"ml-4"},[_vm._v("Inloggen...")])],1):_c('div',{staticClass:"flex"},[_c('div',{staticClass:"pr-8 border-r"},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.handleOnSubmit($event)}}},[_c('FormField',{staticClass:"mb-4",attrs:{"label":"Voornaam"}},[_c('VlInputField',{attrs:{"mod-block":true,"required":true},model:{value:(_vm.formData.voornaam),callback:function ($$v) {_vm.$set(_vm.formData, "voornaam", $$v)},expression:"formData.voornaam"}})],1),_c('FormField',{staticClass:"mb-4",attrs:{"label":"Achternaam"}},[_c('VlInputField',{attrs:{"mod-block":true,"required":true},model:{value:(_vm.formData.naam),callback:function ($$v) {_vm.$set(_vm.formData, "naam", $$v)},expression:"formData.naam"}})],1),_c('FormField',{staticClass:"mb-8",attrs:{"label":"Geboortedatum"}},[_c('div',[_c('Datepicker',{attrs:{"required":true},model:{value:(_vm.formData.geboorteDatum),callback:function ($$v) {_vm.$set(_vm.formData, "geboorteDatum", $$v)},expression:"formData.geboorteDatum"}})],1)]),_c('VlButton',{attrs:{"type":"submit","mod-block":true,"mod-loading":_vm.isLoading}},[_vm._v("Zoeken")])],1)]),_c('div',{staticClass:"flex-grow pl-8"},[_c('VlDataTable',[_c('thead',[_c('th',[_vm._v("Voornaam")]),_c('th',[_vm._v("Naam")]),_c('th',[_vm._v("Geboortedatum")]),(_vm.hasDeceasedChild)?_c('th',[_vm._v("Overlijdensdatum")]):_vm._e(),_c('th',[_vm._v("Postcode")]),_c('th',[_vm._v("Discretie vereist?")]),_c('th')]),_c('tbody',_vm._l((_vm.searchResults),function(ref){
            var discretieVereist = ref.discretieVereist;
            var geboorteDatum = ref.geboorteDatum;
            var miragePersoonId = ref.miragePersoonId;
            var naam = ref.naam;
            var overlijdensDatum = ref.overlijdensDatum;
            var postcode = ref.postcode;
            var voornaam = ref.voornaam;
return _c('tr',{key:miragePersoonId},[_c('td',[_vm._v(_vm._s(voornaam))]),_c('td',[_vm._v(_vm._s(naam))]),_c('td',[_vm._v(_vm._s(geboorteDatum))]),(_vm.hasDeceasedChild)?_c('td',[_vm._v(_vm._s(overlijdensDatum || 'nvt'))]):_vm._e(),_c('td',[_vm._v(_vm._s(postcode))]),_c('td',[_vm._v(_vm._s(discretieVereist ? 'JA' : 'NEE'))]),_c('td',[_c('VlButton',{attrs:{"mod-disabled":miragePersoonId == null,"mod-narrow":true},on:{"click":function($event){$event.preventDefault();return _vm.handleOnSelect(miragePersoonId)}}},[_vm._v("Selecteer")])],1)])}),0)]),(_vm.searchResults.length === 0 && !_vm.error)?_c('div',[(!_vm.hasSearched)?_c('VlAlert',{attrs:{"mod-info":true,"content":"Voer een zoekopdracht uit."}}):_c('VlAlert',{attrs:{"mod-warning":true,"content":"Er werden geen resultaten gevonden."}})],1):_vm._e(),(_vm.error)?_c('VlAlert',{attrs:{"mod-error":true,"content":_vm.error.message}}):_vm._e()],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }