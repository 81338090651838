import * as actions from './actions';
import mutations from './mutations';

export const getInitialState = () => ({
  error: null,
  isLoading: false,
  miragePersonsById: {},
  searchResults: [],
});

export default {
  actions,
  mutations,
  namespaced: true,
  state: getInitialState(),
};
