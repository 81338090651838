import {
  ADD_INTERACTION,
  UPDATE_LOADING_STATE,
} from '@/constants/mutation-types';

import {
  MANUAL_OUTGOING_CALL_BACKOFFICE,
  OUTGOING_CALL,
  OUTGOING_CALL_BACKOFFICE,
  OUTGOING_EMAIL,
} from '@/constants';
import { generateId } from '@/utils';
import { crmAxios } from '@/utils/Axios';

export default {
  [ADD_INTERACTION]: async (
    { commit, dispatch, rootGetters },
    { type, data = {} },
  ) => {
    commit(UPDATE_LOADING_STATE, { isLoading: true, error: null });

    try {
      switch (type) {
        case OUTGOING_CALL:
        case OUTGOING_CALL_BACKOFFICE:
        case MANUAL_OUTGOING_CALL_BACKOFFICE: {
          const contactId = rootGetters['contact/contactId'];
          const questionId = rootGetters['contact/lastQuestionId']; // default

          const { data: response } = await crmAxios({
            method: 'post',
            url: `/Contact/${contactId}/outboundcalls`,
            data: { type: 'Manual', questionId, ...data }, // default to Manual
          });

          await dispatch('contact/sync', contactId, { root: true });

          commit(UPDATE_LOADING_STATE, { isLoading: false });

          return response;
        }

        case OUTGOING_EMAIL: {
          const response = await dispatch('emails/create', data, {
            root: true,
          });

          commit(UPDATE_LOADING_STATE, { isLoading: false });

          return response;
        }

        default: {
          commit(UPDATE_LOADING_STATE, { isLoading: false });

          return commit(ADD_INTERACTION, { type, data, id: generateId() });
        }
      }
    } catch (error) {
      commit(UPDATE_LOADING_STATE, { isLoading: false, error });
    }
  },
};
