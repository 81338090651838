import Citizen from '@/modules/Citizen';
import actions from './actions';
import getters from './getters';
import mutations from './mutations';

const state = {
  citizens: null,
  quickSelectCitizens: null,
  perPage: 10,
  skip: 0,
  totalCount: 0,
  citizen: null,
  editCitizenId: null,
  searchCitizen: new Citizen(),
  mode: 'search',
  loading: false,
  loadingError: false,
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
