import { crmAxios } from '@/utils/Axios';

export default ({ commit, state }, payload) => {
  // Find question in array of questions
  const foundQuestion = state.contact.questions.find(
    q => q === payload.question,
  );

  // If question is not found, resolve immediately
  if (!foundQuestion) {
    return Promise.resolve();
  }

  // Delete the answer immediately if it has no CRM id yet
  if (!payload.answer.crmId) {
    foundQuestion.deleteAnswer(payload.answer);
    return Promise.resolve();
  }

  // Update the answer loading indicator
  payload.answer.loading = true;

  // Delete the answer using the CRM api
  return crmAxios({
    method: 'delete',
    url: `/Solution/${payload.answer.crmId}`,
    headers: {
      Accept: 'application/json',
    },
  })
    .then(() => {
      // Delete the answer
      foundQuestion.deleteAnswer(payload.answer);

      commit('deleteAnswerSuccess'); // needed to update state. TODO: REFACTOR
    })
    .catch(() => {
      // Update the answer loading indicator
      payload.answer.loading = false;
    });
};
