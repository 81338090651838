<template>
  <div class="p-8">
    <div class="flex items-center mb-4">
      <span class="uppercase font-bold mr-4">Vraag:</span>

      <span>{{ this.question.questionNumber }}</span>
    </div>

    <form class="mb-4" @submit.prevent="handleOnEscalate">
      <VlAlert v-if="error" :content="error.message" :mod-error="true" />

      <div class="flex items-center justify-between">
        <div class="flex flex-grow">
          <VlInputField
            class="flex-grow"
            type="email"
            :disabled="isDisabled"
            v-model="formData.emailAddress"
            placeholder="E-mail"
            required
          />

          <VlButton
            title="Copy-paste"
            :mod-loading="isLoading"
            :mod-narrow="true"
            :mod-secondary="true"
            :mod-disabled="isDisabled"
            :style="{ marginLeft: '-1px' }"
            @click.prevent="handleOnCopyPaste"
          >
            <VlIcon icon="copy-paste" />
          </VlButton>
        </div>

        <div>
          <VlButton
            class="divide ml-4"
            title="Vraag doorsturen naar back-office"
            :mod-narrow="true"
            :mod-disabled="isDisabled"
          >
            <CustomIcon icon="external-forwarding" />
          </VlButton>
        </div>
      </div>
    </form>

    <div class="flex justify-end">
      <a
        :class="['vl-link', { disabled: hasEscalated }]"
        href
        @click.prevent="handleOnDelete"
      >
        <VlIcon icon="trash" />

        <span>Verwijderen</span>
      </a>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import { crmAxios } from '@/utils/Axios';

export default {
  name: 'EmailBackOffice',

  props: ['data', 'id'],

  data() {
    return {
      error: null,
      formData: {
        emailAddress: '',
      },
      isDeleting: false,
      isLoading: false,
    };
  },

  computed: {
    ...mapGetters('contact', ['defaultEscalationPointId', 'questionById']),

    hasEscalated() {
      return !!this.question.escalationPoint;
    },

    isDisabled() {
      return this.defaultEscalationPointId == null || this.hasEscalated;
    },

    question() {
      return this.questionById(this.data.questionId);
    },
  },

  methods: {
    async handleOnCopyPaste() {
      this.formData.emailAddress = await navigator.clipboard.readText();
    },

    handleOnDelete() {
      this.$store.commit('sidebar/REMOVE_INTERACTION', this.id);
    },

    async handleOnEscalate() {
      this.isLoading = true;

      try {
        const {
          data: { articleId },
        } = await crmAxios({
          url: `/EscalationPoint/${this.defaultEscalationPointId}`,
        });

        const [answer] = await this.$store.dispatch(
          'answers/retrieveAnswersFromVLS',
          { articleId },
        );

        if (answer == null) {
          throw new Error(`VLS article not found: ${articleId}`);
        }

        await crmAxios({
          method: 'post',
          url: `/Question/${this.data.questionId}/solutions`,
          data: answer.toApiObject(),
        });

        await crmAxios({
          method: 'post',
          url: `/Question/${this.data.questionId}/escalate`,
          data: {
            emailAddress: this.formData.emailAddress,
            escalationPointId: this.defaultEscalationPointId,
            escalationType: 'ForwardMail',
          },
        });

        await this.$store.dispatch('contact/sync');
      } catch (error) {
        this.error = error;
      }

      this.isLoading = false;
    },
  },

  mounted() {
    this.$emit('start');

    if (this.defaultEscalationPointId == null) {
      this.error = new Error('Default escalation point id not found.');
    }
  },
};
</script>

<style></style>
