import qs from 'qs';
import Citizen from '@/modules/Citizen';
import { crmAxios } from '@/utils/Axios';

export default {
  // Get a citizen by id
  get({ state }, id) {
    // Update the loading state
    state.loading = true;

    // Call the API
    return crmAxios({
      skipErrorInterception: false, // Errors are displayed in the component
      method: 'get',
      url: `/Citizen/${id}`,
      headers: {
        Accept: 'application/json',
      },
    })
      .then(response => {
        // Update the loading state
        state.loading = false;

        // Return the response data
        return response.data;
      })
      .catch(() => {
        // Update the loading state
        state.loading = false;
      });
  },

  // Search citizens
  search({ state, commit }, payload) {
    // Prevent multiple requests
    if (state.loading) {
      return Promise.resolve();
    }

    // Default the payload
    payload = payload || {};

    // Indicate that we're going to search for citizens
    commit('getCitizens');

    // Clone the citizen object
    const searchCitizen = payload.citizen
      ? payload.citizen.clone()
      : new Citizen();

    // Set the URL
    let url = '';

    // Check if we're searching by ID
    if (searchCitizen.onlyIdSet) {
      // Change the URL
      url = `/Citizen/${searchCitizen.id}`;
    } else {
      // Remove the id as we're not querying a specific user
      delete searchCitizen.id;

      // Remove the empty keys in the object
      Object.keys(searchCitizen).forEach(
        key => !searchCitizen[key] && delete searchCitizen[key],
      );

      // Add the top parameter
      searchCitizen.$top = state.perPage;
      searchCitizen.$skip = state.skip;

      // Build the querystring and URL
      const queryString = qs.stringify(searchCitizen);

      // Update the URL
      url = `/Citizen/search?${queryString}`;
    }

    // Call the API
    return crmAxios({
      skipErrorInterception: payload.skipErrorInterception || undefined, // Errors are displayed in the component
      method: 'get',
      url,
      headers: {
        Accept: 'application/json',
      },
    })
      .then(response => {
        response.data.results = (response.data || {}).results || [];
        commit('getCitizensSuccess', response.data);
        return state.citizens;
      })
      .catch(error => commit('getCitizensError', error));
  },

  // Search by phone (used for auto searching based on received phone number)
  // @todo rework if the phone endpoint has a totalCount property
  searchByPhone({ state, commit }, phoneNumber) {
    // Build the querystring and URL
    const url = `/Citizen/search?${qs.stringify({ phone: phoneNumber })}`;

    // Call the API
    return crmAxios({
      skipErrorPopup: true, // Errors aren't displayed
      method: 'get',
      url,
      headers: {
        Accept: 'application/json',
      },
    })
      .then(response => {
        // Parse results
        response.data.results = (response.data || {}).results || [];
        commit('getCitizensSuccess', response.data);
        return state.citizens;
      })
      .catch(() => {});
  },

  getQuickSelectCitizens({ state, commit }) {
    // Build the querystring and URL
    const url = `/Citizen/search/quickselect`;
    // Call the API
    return crmAxios({
      skipErrorPopup: true, // Errors aren't displayed
      method: 'get',
      url,
      headers: {
        Accept: 'application/json',
      },
    })
      .then(response => {
        // Parse results
        response.data.results = (response.data || {}).results || [];
        commit('getQuickSelectCitizensSuccess', response.data);
        return state.quickSelectCitizens;
      })
      .catch(() => {});
  },

  // Update the search citizen object with a given payload
  updateSearchCitizen({ state }, payload) {
    // Update the search citizen with the given payload
    Object.assign(state.searchCitizen, payload);
  },

  // Reset paging
  resetPaging({ state }) {
    state.skip = 0;
  },

  // Previous page
  previousPage({ state, getters }) {
    // Do not proceed if there's no previous page
    if (!getters.hasPreviousPage) {
      return Promise.resolve();
    }

    // Change skip value
    state.skip -= state.perPage;
  },

  // Previous page
  nextPage({ state, getters }) {
    // Do not proceed if there's no next page
    if (!getters.hasNextPage) {
      return Promise.resolve();
    }

    // Change skip value
    state.skip += state.perPage;
  },

  // Add a citizen
  add({ state, dispatch }) {
    // Update the edit citizen id
    state.editCitizenId = null;

    // Change the mode to add
    dispatch('changeMode', 'add');
  },

  // Edit a specific citizen
  edit({ state, dispatch }, id) {
    // Update the edit citizen id
    state.editCitizenId = id;

    // Change the mode to edit
    dispatch('changeMode', 'edit');
  },

  // Change mode
  changeMode({ state }, mode) {
    if (['search', 'edit', 'add'].indexOf(mode) < 0) {
      mode = 'search';
    }

    // Update the state
    state.mode = mode;
  },

  // Save citizen
  save({ state }, citizenInstance) {
    // Change the method based on mode add/edit
    let method = 'post'; // add
    let endpoint = '/Citizen';

    if (state.mode === 'edit') {
      method = 'put';
      endpoint += `/${citizenInstance.id}`;
    }

    // Update the loading and loadingError state
    state.loading = true;
    state.loadingError = false;

    // Call the API
    return crmAxios({
      method,
      url: endpoint,
      headers: {
        Accept: 'application/json',
      },
      data: citizenInstance,
    })
      .then(response => {
        // Update the loading and loadingError state
        state.loading = false;
        state.loadingError = false;

        // Return the response data
        return response.data;
      })
      .catch(err => {
        // Update the loading and loadingError state
        state.loading = false;
        state.loadingError = err.message;
      });
  },

  // Helper to reset the results, search citizen object and more (e.g. used after closing a contact so no info about the previous search is visible)
  reset({ state }) {
    state.citizens = [];
    state.quickSelectCitizens = [];
    state.totalCount = 0;
    state.citizen = null;
    state.searchCitizen = new Citizen();
    state.loading = false;
    state.loadingError = false;
  },
};
