import { parsePhoneNumberFromString } from 'libphonenumber-js/max';

const validatePhoneNumber = (value, type) => {
  if (value === '') {
    return true;
  }

  const phoneNumber = parsePhoneNumberFromString(value, 'BE');

  if (phoneNumber == null) {
    return false;
  }

  return phoneNumber.getType() === type;
};

export default validatePhoneNumber;
