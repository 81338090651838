<template>
  <div v-if="contact.externalTicketNumber" class="p-4">
    <div class="flex items-center mb-4">
      <span class="uppercase font-bold mr-4">Ticket:</span>

      <a :href="contact.externalTicketUrl">
        {{ contact.externalTicketNumber }}
      </a>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'ExternalTicket',

  props: ['data', 'isConnected'],

  computed: {
    ...mapState('contact', ['contact']),
  },

  mounted() {
    this.$emit('start');
  },
};
</script>
