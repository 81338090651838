import { UPDATE_IS_REDIRECTING, UPDATE_USER } from '@/constants/mutation-types';

export default {
  [UPDATE_IS_REDIRECTING](state, isRedirecting) {
    state.isRedirecting = isRedirecting;
  },

  [UPDATE_USER](state, partialUser) {
    state.user = { ...state.user, ...partialUser };
  },
};
