const getInitialState = () => ({
  messages: [],
});

const mutations = {
  ADD_MESSAGE: (state, message) => {
    state.messages.push(message);
  },

  RESET: state => {
    const initialState = getInitialState();

    Object.keys(initialState).forEach(key => {
      state[key] = initialState[key];
    });
  },

  SYNC_MESSAGES: (state, messages) => {
    state.messages = messages;
  },
};

const getters = {
  firstCitizenQuestion: ({ messages }) => {
    return messages.find(({ user }) => user.type === 'CITIZEN');
  },

  sourceQuestion: (state, { firstCitizenQuestion }) => {
    return firstCitizenQuestion ? firstCitizenQuestion.text : '';
  },

  sourceUrl: (state, { firstCitizenQuestion }) => {
    return firstCitizenQuestion ? firstCitizenQuestion.user.referer : '';
  },
};

export default {
  getters,
  mutations,
  namespaced: true,
  state: getInitialState(),
};
