// Import modules
import axios from 'axios';
import bus from '@/utils/bus';
import store from '@/store';
import {
  acquireToken,
  config,
  authenticationContext,
} from './CrmAuthentication';
import { vlsApiUrl } from '@/config';

const CRM_TIMEOUT = 60000;

// Create an instance for connection to the Public CRM api
export const crmPublicAxios = axios.create({
  baseURL: `${process.env.VUE_APP_PUBLIC_CRM_ENDPOINT}`,
  timeout: CRM_TIMEOUT,
});

// Create an instance for connecting to the CRM
export const crmAxios = axios.create({
  baseURL: `${process.env.VUE_APP_CRM_ENDPOINT}`,
  timeout: CRM_TIMEOUT,
  withCredentials: true,
});

// Add a request interceptor for refreshing tokens
crmAxios.interceptors.request.use(async axiosConfig => {
  // queue retrieve and update requests to CRM to avoid race condition
  await new Promise(resolve => {
    const intervalId = window.setInterval(() => {
      if (
        (store.state.isFetchingFromCrm === false &&
          axiosConfig.method !== 'get') ||
        (store.state.isDirty === false && axiosConfig.method === 'get')
      ) {
        //isDirty is set to true when changes are pending (DEBOUNCE_DELAY) and not saved yet
        window.clearInterval(intervalId);

        resolve();
      }
    }, 100);
  });

  store.commit('isFetchingFromCrm', true);

  return acquireToken()
    .then(token => {
      axiosConfig.headers = axiosConfig.headers || {};
      axiosConfig.headers.Authorization = `Bearer ${token}`;
      return Promise.resolve(axiosConfig);
    })
    .catch(err => {
      const errCode = err.split(':')[0];
      switch (errCode) {
        // Need to prompt for user sign in
        case 'AADSTS50058':
          authenticationContext.login();
          break;
        // Token is invalid; grab a new one
        case 'AADSTS65001':
          authenticationContext.acquireTokenRedirect(config.resource);
          break;
        case 'AADSTS16000': // No Access
        default:
          // Other error
          break;
      }
      return Promise.reject(err);
    });
});

const unauthorizedInterceptor = error => {
  store.commit('isFetchingFromCrm', false);

  const getLabelByName = store.getters['metadata/getLabelByName'];

  const label = getLabelByName('dynamics_login');

  if (error.response && error.response.status === 401) {
    // Get extra info
    let body = label.body;
    let extra = error.message;
    if (error.response.data) {
      let { data } = error.response;
      if (typeof data !== 'string') {
        try {
          data = JSON.stringify(data);
        } catch (e) {
          // eslint-disable-line no-empty
        }
      }
      extra += `<br/>${data}`;
    }
    body += `<br/>${extra}`;

    // Emit an open modal event, add pass message as payload
    bus.$emit('show-modal', {
      message: 'Unauthorized',
      body,
    });

    // Login using AD after 5 seconds
    setTimeout(() => {
      authenticationContext.login();
    }, 5000);
  } else {
    // Will be catched in the error interceptor
    throw error;
  }
};

// Create an instance from connecting to the VLS
export const vlsAxios = axios.create({
  baseURL: vlsApiUrl,
  timeout: 10000,
});

// Create an error interceptor
const errorInterceptorVls = error => {
  // Check if we need to skip error intercepting (useful to prevent showing a pop-up by default and to parse errors in the caller)
  if (error.config && error.config.skipErrorInterception) {
    // Immediately reject with the original error
    return Promise.reject(error);
  }

  // Default the response (e.g. useful in case of an OPTIONS call that 401'd)
  error.response = error.response || {};

  const getLabelByName = store.getters['metadata/getLabelByName'];

  // Default the error message and body
  let message = error.message || 'Error';
  const label = getLabelByName('general_error');
  let body = label.body;

  // Parse the error
  if (typeof error.response.message === 'string') {
    body = error.response.message; // eslint-disable-line prefer-destructuring
  }

  if (error.response.data) {
    if (typeof error.response.data.message === 'string') {
      body = error.response.data.message; // eslint-disable-line prefer-destructuring
    }

    if (Array.isArray(error.response.data.messages)) {
      const arrayMessages = [];
      error.response.data.messages.forEach(m => {
        if (typeof m === 'string' && m) {
          arrayMessages.push(m);
        }
      });
      if (arrayMessages.length) {
        body = arrayMessages.join('\n');
      }
    }
  }

  if (error.response.status === 408 || error.code === 'ECONNABORTED') {
    const label = getLabelByName('general_timeout');
    message = label.title;
    body = label.body.replace('${error.config.url}', error.config.url);
  }

  // Create a custom error
  const customError = new Error(body);
  customError.status = error.status || 500;

  // Emit an open modal event, add pass message as payload
  bus.$emit('show-modal', {
    message,
    body,
  });

  // Throw the custom error so it can get catched in the original promise call
  return Promise.reject(customError);
};

// Create an error interceptor for CRM
const errorInterceptorCRM = error => {
  store.commit('isFetchingFromCrm', false);

  const getLabelByName = store.getters['metadata/getLabelByName'];

  // Check if we need to skip error intercepting (useful to prevent showing a pop-up by default and to parse errors in the caller)
  if (error.config && error.config.skipErrorInterception) {
    // Immediately reject with the original error
    return Promise.reject(error);
  }

  // Default the response (e.g. useful in case of an OPTIONS call that 401'd)
  error.response = error.response || {};
  error.response.data = error.response.data || {};

  // Default the error message and body
  let { message } = error;

  let label = getLabelByName('dynamics_general_error');

  let body = label.body;

  // Default the body in case of a 404
  if (error.response.status && error.response.status === 404) {
    label = getLabelByName('dynamics_not_found');

    body = label.body;
  }

  // Default the body in case of a timeout
  if (error.response.status === 408 || error.code === 'ECONNABORTED') {
    label = getLabelByName('dynamics_timeout');

    message = label.title;
    body = label.body.replace('${error.config.url}', error.config.url);
  }

  // Parse HTML responses
  if (
    error.response.headers &&
    (error.response.headers['content-type'] || '').indexOf('text/html') === 0
  ) {
    body = error.response.data; // eslint-disable-line prefer-destructuring
  }

  // Parse message property
  if (typeof error.response.data.message === 'string') {
    body = error.response.data.message; // eslint-disable-line prefer-destructuring
  }

  // Parse messages property
  if (Array.isArray(error.response.data.messages)) {
    const arrayMessages = [];
    error.response.data.messages.forEach(m => {
      if (typeof m === 'string' && m) {
        arrayMessages.push(m);
      }
    });
    if (arrayMessages.length) {
      body = arrayMessages.join('\n');
    }
  }

  // Create a custom error
  const customError = new Error(body);
  customError.status = error.status || 500;

  // Add CRM Error
  message = `CRM Error: ${message}`;

  // Emit an open modal event, add pass message as payload
  bus.$emit('show-modal', {
    message,
    body,
  });

  // Throw the custom error so it can get catched in the original promise call
  return Promise.reject(customError);
};

// Use the error interceptor on both Axios instances
crmAxios.interceptors.response.use(response => {
  store.commit('isFetchingFromCrm', false);

  return response;
}, unauthorizedInterceptor);

crmAxios.interceptors.response.use(response => response, errorInterceptorCRM);

crmPublicAxios.interceptors.response.use(
  response => response,
  errorInterceptorCRM,
);

vlsAxios.interceptors.response.use(response => response, errorInterceptorVls);

export default {
  crmAxios,
  vlsAxios,
};
