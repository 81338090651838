<template>
  <!-- Modal wrapper -->
  <Modal
    @closed="$emit('close')"
    id="modal-citizen"
    class="vlw__cti__citizen-history is-large"
    :open="true"
    :closable="true"
    :title="modalTitle"
  >
    <div>
      <VlDataTable>
        <thead>
          <tr>
            <th>Datum</th>
            <th>Contact ID</th>
            <th>Contactstatus</th>
            <th>Vraag ID</th>
            <th>Vraagstatus</th>
            <th>Kanaal</th>
            <th>Vraag</th>
            <th>Antwoord</th>
            <th>Extra informatie</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="h in historyItems" :key="h.id">
            <td>{{ h.createdOn | niceDate }}</td>
            <td>
              <a :href="getContactCrmUrl(h.id)">{{ h.contactNumber }}</a>
            </td>
            <td>{{ readableContactStatus(h.contactSubStatusId) }}</td>
            <td>
              <a :href="getQuestionCrmUrl(h.questionId)">{{
                h.questionNumber
              }}</a>
            </td>
            <td>{{ readableQuestionStatus(h.questionSubStatusId) }}</td>
            <td>{{ readableChannel(h.channel) }}</td>
            <td>{{ h.questionDescription }}</td>
            <td>{{ h.answer }}</td>
            <td>{{ h.extraInformation }}</td>
          </tr>
        </tbody>
      </VlDataTable>
    </div>
  </Modal>
</template>

<script>
import { mapGetters } from 'vuex';

import { channels } from '@/config';

export default {
  props: ['citizen', 'historyItems'],

  data() {
    return {
      channels,
    };
  },

  computed: {
    ...mapGetters('metadata', [
      'getReadableContactStatusBySubStatusId',
      'getReadableQuestionStatusBySubStatusId',
    ]),

    // Title of the modal
    modalTitle() {
      return `Historiek van ${this.citizen.firstName} ${this.citizen.lastName}`;
    },
  },

  methods: {
    readableChannel(key) {
      const { name } = channels[key];

      return name;
    },

    readableContactStatus(subStatusId) {
      return this.getReadableContactStatusBySubStatusId(subStatusId);
    },

    readableQuestionStatus(subStatusId) {
      return this.getReadableQuestionStatusBySubStatusId(subStatusId);
    },

    getContactCrmUrl(id) {
      const { VUE_APP_CRM_URL, VUE_APP_CRM_APPID } = process.env;

      return `${VUE_APP_CRM_URL}/main.aspx?appid=${VUE_APP_CRM_APPID}&pagetype=entityrecord&etn=inf_conversation&id=${id}`;
    },

    getQuestionCrmUrl(id) {
      const { VUE_APP_CRM_URL, VUE_APP_CRM_APPID } = process.env;

      return `${VUE_APP_CRM_URL}/main.aspx?appid=${VUE_APP_CRM_APPID}&pagetype=entityrecord&etn=inf_question&id=${id}`;
    },
  },
};
</script>

<style lang="scss">
// Modal
.vlw__cti__citizen-history {
  .vl-modal-dialog {
    width: 100%;
    max-width: 90vw;
  }

  .vl-data-table {
    td {
      vertical-align: middle;
    }

    tr:hover {
      background-color: whitesmoke;
    }
  }
}
</style>
