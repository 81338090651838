<template>
  <Timer :seconds="seconds" />
</template>

<script>
import { mapState } from 'vuex';
import { differenceInSeconds } from 'date-fns';

import Timer from '@/components/sidebar/Timer';

export default {
  components: { Timer },

  intervalId: null,

  data() {
    return {
      seconds: 0,
    };
  },

  computed: {
    ...mapState('cti', ['user']),

    dateStatusChanged() {
      return this.user.dateStatusChanged;
    },

    statusDurationInSeconds() {
      return this.user.statusDurationInSeconds;
    },
  },

  methods: {
    clearInterval() {
      window.clearInterval(this.$options.intervalId);
    },
  },

  mounted() {
    this.seconds = this.statusDurationInSeconds;
  },

  beforeDestroy() {
    this.clearInterval();
  },

  watch: {
    dateStatusChanged: {
      handler: function(value) {
        this.clearInterval();

        this.$options.intervalId = window.setInterval(() => {
          this.seconds = differenceInSeconds(new Date(), value);
        }, 1000);
      },
      immediate: true,
    },
  },
};
</script>

<style></style>
