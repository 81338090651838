<template>
  <div :class="['flex flex-grow', { [$style.isExpanding]: isExpanding }]">
    <div
      id="home-container"
      class="flex flex-grow overflow-auto overscroll-none"
    >
      <slot />
    </div>

    <div class="relative">
      <div ref="expander" :class="$style.expander">
        <div
          :class="[$style.innerExpander, { [$style.isExpanding]: isExpanding }]"
        />
      </div>
    </div>

    <div :class="$style.sidebar" :style="{ flexBasis: `${sidebarWidth}px` }">
      <slot name="sidebar" />
    </div>
  </div>
</template>

<script>
import Cookies from 'js-cookie';
import { clamp } from 'lodash';

import { COOKIE_NAME_SIDEBAR_WIDTH } from '@/constants';
import { sidebarMaxWidth, sidebarMinWidth } from '@/config';

const DEFAULT_SIDEBAR_WIDTH = 450;

const sidebarWidth =
  parseInt(Cookies.get(COOKIE_NAME_SIDEBAR_WIDTH)) || DEFAULT_SIDEBAR_WIDTH;

export default {
  name: 'PageContainer',

  data() {
    return {
      isExpanding: false,
      sidebarWidth,
    };
  },

  mounted() {
    const { expander } = this.$refs;

    expander.onmousedown = () => {
      this.isExpanding = true;

      window.onmousemove = e => {
        this.sidebarWidth = clamp(
          window.innerWidth - e.clientX,
          sidebarMinWidth,
          sidebarMaxWidth,
        );
      };
    };

    window.onmouseup = () => {
      if (this.isExpanding) {
        Cookies.set(COOKIE_NAME_SIDEBAR_WIDTH, this.sidebarWidth);

        this.isExpanding = false;

        window.onmousemove = null;
      }
    };
  },
};
</script>

<style lang="scss" module>
.isExpanding {
  pointer-events: none;
}

.expander {
  cursor: ew-resize;
  display: flex;
  height: 100%;
  justify-content: center;
  left: -10px;
  position: absolute;
  width: 20px;

  .innerExpander {
    background: #cbd2da;
    height: 100%;
    transition: background 200ms ease-in-out;
    width: 1px;

    &.isExpanding {
      background: #05c;
    }
  }

  &:hover {
    .innerExpander {
      background: #05c;
    }
  }
}

.sidebar {
  flex-grow: 0;
  flex-shrink: 0;
  overflow: auto;
}
</style>
