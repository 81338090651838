import Vue from 'vue';
import PortalVue from 'portal-vue';
import Validations from 'vuelidate';
import VueAppInsights from 'vue-application-insights';

import store from '@/store';
import App from '@/components/App.vue';
import { VlUtil, VlCore } from '@govflanders/vl-ui-vue-components';
import * as filters from '@/filters';

import '@/components/shared';
import './styles/index.scss';

const { VUE_APP_APPINSIGHTS_INSTRUMENTATIONKEY } = process.env;

Vue.use(VlUtil);
Vue.use(VlCore);
Vue.use(PortalVue);
Vue.use(Validations);
Vue.use(VueAppInsights, {
  baseName: 'VLP',
  id: VUE_APP_APPINSIGHTS_INSTRUMENTATIONKEY,
});

Vue.config.productionTip = false;

Object.keys(filters).forEach((key) => {
  Vue.filter(key, filters[key]);
});

// Create app
new Vue({
  store,
  render: (h) => h(App),
}).$mount('#app');
