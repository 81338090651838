<template>
  <div class="flex">
    <VlLink class="mr-4" href @click.prevent="handleOnDownload">{{
      attachment.fileName
    }}</VlLink>

    <span>{{ attachment.fileSize | fileSize }}</span>

    <VlLink
      v-if="allowDelete"
      class="mr-4"
      href
      @click.prevent="handleOnDelete"
    >
      <VlIcon icon="bin" />
    </VlLink>

    <VlLoader v-if="isLoading" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { saveAs } from 'file-saver';
import { crmAxios } from '@/utils/Axios';
import bus from '@/utils/bus';

export default {
  name: 'Attachment',

  props: ['allowDelete', 'attachment'],

  data() {
    return {
      error: null,
      isLoading: false,
    };
  },

  computed: {
    ...mapGetters('metadata', ['getLabelByName']),
  },

  methods: {
    async handleOnDelete() {
      this.isLoading = true;

      try {
        const { id } = this.attachment;

        await crmAxios({
          method: 'delete',
          url: `/Attachment/${id}`,
        });

        await this.$store.dispatch('emails/sync');
      } catch (error) {
        console.error(error);

        this.error = error;
      }

      this.isLoading = false;
    },

    async handleOnDownload() {
      this.isLoading = true;

      try {
        const { fileName, id } = this.attachment;

        const { data, headers } = await crmAxios({
          url: `/Attachment/${id}`,
          responseType: 'blob',
        });

        await saveAs(
          new File([data], fileName, { type: headers['content-type'] }),
        );
      } catch (error) {
        console.error(error);

        this.error = error;

        const label = this.getLabelByName('notpossible_download_attachment');

        bus.$emit('show-modal', {
          body: label.body,
          message: label.title,
        });
      }

      this.isLoading = false;
    },
  },
};
</script>
