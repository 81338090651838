<template>
  <message
    text="Stel uw vraag, het gesprek wordt daarna gestart."
    user="THEM"
    :isFirstChild="true"
  />
</template>

<script>
import Message from './Message';

export default {
  components: { Message },
};
</script>
