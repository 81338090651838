export default (
  { state, dispatch, getters, rootGetters, rootState },
  questionDefaults,
) => {
  // Add the question
  const question = state.contact.addQuestion(questionDefaults);

  // Default the status / substatus of the question
  rootState.metadata.questionStatuses.forEach(qs => {
    qs.subStatuses.forEach(ss => {
      if (ss.isDefault === true) {
        question.status = qs.id;
        question.subStatus = ss.id;
      }
    });
  });

  if (getters.channelIsChat) {
    const sourceQuestion = rootGetters['sidebar/chat/sourceQuestion'];
    question.title = sourceQuestion;
  }

  // Set the loading state of the question to true
  question.loading = true;

  // Immediately add the question to the CRM and return the promise
  return dispatch('saveQuestion', question)
    .then(() => {
      // Update the loading state
      question.loading = false;

      // Return the question
      return question;
    })
    .catch(() => {
      // Delete the question
      state.contact.deleteQuestion(question);
    });
};
