<template>
  <div class="vlw__cti-citizen-search" :class="{ loading: loading }">
    <!-- Loading if no answers -->
    <VlLoader v-if="loading" />

    <!-- Search form -->
    <div class="vlw__cti-citizen-search__form">
      <h2 class="vl-modal-dialog__title">Zoek een burger</h2>

      <form @submit.prevent="onSearch">
        <!-- Voornaam -->
        <div>
          <label class="vl-form__label">
            <span>Voornaam</span>
            <VlInputField
              ref="focusInput"
              v-model="search.firstName"
              :mod-block="true"
            />
          </label>
        </div>

        <!-- Naam -->
        <div>
          <label class="vl-form__label">
            <span>Naam</span>
            <VlInputField v-model="search.lastName" :mod-block="true" />
          </label>
        </div>

        <!-- Geboortedatum -->
        <div>
          <label class="vl-form__label">
            <span>Geboortedatum</span>
            <Datepicker v-model="search.birthdate" />
          </label>
        </div>

        <!-- Emailadres -->
        <div>
          <label class="vl-form__label">
            <span>Emailadres</span>
            <VlInputField
              v-model.trim="search.emailAddress"
              :mod-block="true"
              :mod-error="$v.search.emailAddress.$error"
              @blur="$v.search.emailAddress.$touch"
            />
          </label>
          <VlFormMessageError v-if="$v.search.emailAddress.$error"
            >Ongeldig emailadres</VlFormMessageError
          >
        </div>

        <!-- Telefoon -->
        <div>
          <label class="vl-form__label">
            <span>Telefoon</span>
            <VlInputField v-model="search.telephone" :mod-block="true" />
          </label>
        </div>

        <!-- GSM -->
        <div>
          <label class="vl-form__label">
            <span>GSM</span>
            <VlInputField v-model="search.mobilePhone" :mod-block="true" />
          </label>
        </div>

        <!-- Straat & nummer -->
        <div class="street-number">
          <!-- Straat -->
          <div>
            <label class="vl-form__label">
              <span>Straat</span>
              <VlInputField v-model="search.street" :mod-block="true" />
            </label>
          </div>

          <!-- Nummer -->
          <div>
            <label class="vl-form__label">
              <span>Nummer</span>
              <VlInputField v-model="search.houseNumber" :mod-block="true" />
            </label>
          </div>
        </div>

        <!-- Postcode & gemeente -->
        <div class="postalcode-city">
          <!-- Postcode -->
          <div>
            <label class="vl-form__label">
              <span>Postcode</span>
              <VlInputField v-model="search.postalCode" :mod-block="true" />
            </label>
          </div>

          <!-- Gemeente -->
          <div>
            <label class="vl-form__label">
              <span>Gemeente</span>
              <VlInputField v-model="search.city" :mod-block="true" />
            </label>
          </div>
        </div>

        <!-- Submit -->
        <VlButton type="submit" :mod-block="true">Zoeken</VlButton>
      </form>
    </div>

    <!-- Results -->
    <div class="vlw__cti-citizen-search__results">
      <!-- Error -->
      <VlAlert v-if="loadingError" :content="loadingError" :mod-error="true" />

      <!-- No results -->
      <div v-if="queried && !loading && !loadingError && !citizens.length">
        <h3>Geen resultaten gevonden</h3>
      </div>

      <!-- Results heading -->
      <div
        v-if="!loadingError"
        class="vlw__cti-citizen-search__results__heading"
      >
        <h3 v-if="citizens.length" v-text="amountOfResults"></h3>

        <!-- Pager -->
        <Pager
          v-if="citizens.length"
          :from="pagerFrom"
          :to="pagerTo"
          :total="totalCount"
          @previous="onPreviousPage"
          @next="onNextPage"
        ></Pager>
      </div>

      <!-- Table -->
      <VlDataTable v-if="citizens.length">
        <thead>
          <tr>
            <th>Voornaam</th>
            <th>Naam</th>
            <th>Emailadres</th>
            <th>Postcode</th>
            <th>Telefoon / GSM</th>
            <th class="vlw__cti-citizen-search__actions"></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="c in citizens" :key="c.id">
            <td>{{ c.firstName }}</td>
            <td>{{ c.lastName }}</td>
            <td>{{ c.emailAddress }}</td>
            <td>{{ c.postalCode }}</td>
            <td>
              {{ c.telephone }}
              <br v-if="c.telephone && c.mobilePhone" />
              {{ c.mobilePhone }}
            </td>
            <td class="vlw__cti-citizen-search__results__actions">
              <VlButton
                :mod-narrow="true"
                :mod-secondary="true"
                @click.prevent="$emit('edit', c)"
                >Bewerk</VlButton
              >
              <VlButton :mod-narrow="true" @click.prevent="select(c)"
                >Kies contact</VlButton
              >
            </td>
          </tr>
        </tbody>
      </VlDataTable>

      <!-- Add new citizen -->
      <VlAlert title="Nieuwe burger aanmaken?" content>
        <template slot="actions">
          <VlButton @click.prevent="addCitizen">Burger aanmaken</VlButton>
        </template>
      </VlAlert>

      <!-- Add quick select citizen -->
      <VlAlert
        v-if="showQuickSelectCitizen"
        title="Bestaande burger snel selecteren?"
        content
      >
        <VlButton
          class="vlw__cti-citizen-search__results__quickselectbutton"
          v-for="c in quickSelectCitizens"
          :key="c.id"
          :mod-narrow="true"
          @click.prevent="select(c)"
          >{{ c.lastName }}</VlButton
        >
      </VlAlert>
    </div>
  </div>
</template>

<script>
// Import modules
import { mapGetters, mapState, mapActions } from 'vuex';
import { email } from 'vuelidate/lib/validators';
import CitizenClass from '../modules/Citizen';
import Pager from './Pager.vue';

export default {
  name: 'CitizenSearch',
  components: {
    Pager,
  },
  validations: {
    search: {
      emailAddress: { email },
    },
  },
  props: {
    // An citizen instance that provides initial search parameters
    search: {
      type: Object,
      required: false,
      default() {
        return new CitizenClass();
      },
    },
  },
  data() {
    return {
      queried: false,
    };
  },
  computed: {
    ...mapState('citizen', ['loading', 'loadingError', 'totalCount']),
    ...mapGetters('citizen', [
      'citizens',
      'pagerFrom',
      'pagerTo',
      'quickSelectCitizens',
    ]),
    ...mapGetters('contact', ['showQuickSelectCitizen']),
    amountOfResults() {
      if (this.totalCount === 1) {
        return '1 resultaat';
      }
      return `${this.totalCount} resultaten`;
    },

    isValid() {
      return !this.$v.$invalid;
    },
  },
  methods: {
    // Navigation methods
    ...mapActions('citizen', [
      'previousPage',
      'nextPage',
      'changeMode',
      'resetPaging',
    ]),

    // Add a citizen
    addCitizen() {
      // Emit an `add` event and pass the search parameters to prefill the form
      this.$emit('add', this.search);
    },

    // Select a citizen
    select(citizenInstance) {
      // Emit a select event
      this.$emit('select', citizenInstance);

      // Emit a close event
      this.$emit('close');
    },

    // Triggered when going back to previous page
    onPreviousPage() {
      // Go to the previous page
      this.previousPage();

      // Trigger a search
      this.onSearch();
    },

    // Triggered when going back to next page
    onNextPage() {
      // Go to the next page
      this.nextPage();

      // Trigger a search
      this.onSearch();
    },

    // Triggered when form is submitted
    onFormSubmit() {
      // Reset the paging
      this.resetPaging();

      // Trigger the search
      this.onSearch();
    },

    // Trigger search
    onSearch() {
      this.$v.$touch();

      // Validate and exit early if not valid
      if (!this.isValid) {
        return;
      }

      // Update queried so we can display the "create citizen" alert
      this.queried = true;

      // Pass a "new" property so paging gets reset
      const payload = {
        skipErrorInterception: true, // Prevent network error popups since errors are displayed in component
        citizen: this.search, // The citizen to search for
      };

      // Dispatch the search action
      this.$store.dispatch('citizen/search', payload);
    },
  },
  mounted() {
    // Trigger search immediately when mounted
    if (
      !this.queried &&
      (this.search.firstName || this.search.lastName || this.search.birthdate)
    ) {
      // Reset the paging
      this.resetPaging();

      // Trigger the search
      this.onSearch();
    }
  },
};
</script>

<style lang="scss">
// Wrapper
.vlw__cti-citizen-search {
  position: relative;
  display: flex;

  h2 {
    margin-bottom: 10px;
  }

  h3 {
    margin-bottom: 15px;
  }

  .vl-loader {
    position: absolute;
    z-index: 1;
    top: 50px;
    left: 50%;
    transform: translateX(-50%);
  }

  &.loading {
    .vlw__cti-citizen-search__form,
    .vlw__cti-citizen-search__results {
      pointer-events: none;
      opacity: 0.3;
    }
  }
}

// Form
.vlw__cti-citizen-search__form {
  padding-right: 50px;
  border-right: 1px solid #ccc;
  flex: 0 0 300px;

  > form > div {
    margin-bottom: 5px;
  }

  .street-number {
    display: flex;

    > div {
      &:first-child {
        flex: 1 0 70%;
        margin-right: 10px;
      }
    }
  }

  .postalcode-city {
    display: flex;

    > div {
      &:first-child {
        flex: 1 0 20%;
        margin-right: 10px;
      }
    }
  }
}

// Results
.vlw__cti-citizen-search__results {
  padding-left: 40px;
  width: 100%;
}

// Result actions
td.vlw__cti-citizen-search__results__actions {
  min-width: 210px;
  text-align: right;

  > button {
    margin-right: 5px;

    &:last-child {
      margin: 0;
    }
  }
}

.vlw__cti-citizen-search__results__quickselectbutton {
  margin-right: 10px;
}

// Results heading
.vlw__cti-citizen-search__results__heading {
  display: flex;

  > h3 {
    flex: 1 0 auto;
  }
}
</style>
