import { identity, prop, propEq, sortBy } from 'lodash/fp';
import { parseISO } from 'date-fns';

import { getInteractionTypeFromCtiInteraction } from '@/utils';
import {
  EIC_GENERIC_OBJECT,
  EXTERNAL_TICKET,
  INCOMING_CHAT,
  INCOMING_CALL,
  INCOMING_EMAIL,
  MANUAL_OUTGOING_CALL_BACKOFFICE,
  OUTGOING_CALL,
  OUTGOING_CALL_BACKOFFICE,
  OUTGOING_EMAIL,
  RECALL,
  TELETOLK,
} from '@/constants';

const sanitizeCtiInteraction = data => ({
  type: getInteractionTypeFromCtiInteraction(data),
  data,
  id: data.interactionId,
  date: parseISO(
    data.interactionAssignedTimestamp || data.interactionStateTimestamp,
  ),
});

const sanitizeEmail = data => ({
  type:
    data.directionCode === true || data.emailPurpose === 'ReplyEmailCitizen'
      ? OUTGOING_EMAIL
      : INCOMING_EMAIL,
  data,
  id: data.id,
  date: parseISO(data.createdOn),
});

const sanitizeOutboundCall = data => ({
  type: OUTGOING_CALL,
  data,
  id: data.id,
  date: parseISO(data.createdOn),
});

const sanitizeOutboundBOCall = data => ({
  type: OUTGOING_CALL_BACKOFFICE,
  data,
  id: data.id,
  date: parseISO(data.createdOn),
});

const sanitizeManualOutboundBOCall = data => ({
  type: MANUAL_OUTGOING_CALL_BACKOFFICE,
  data,
  id: data.id,
  date: parseISO(data.createdOn),
});

export default {
  ctiInteractions(state, getters, rootState) {
    const { interactions } = rootState.cti;

    return interactions.map(sanitizeCtiInteraction);
  },

  chatInteractions(state, { ctiInteractions }) {
    return ctiInteractions.filter(
      ({ data: { interactionObjectType }, type }) =>
        type === INCOMING_CHAT && interactionObjectType === EIC_GENERIC_OBJECT,
    );
  },

  emailInteractions(state, getters, rootState) {
    const emails = rootState.emails.items;

    return emails.map(sanitizeEmail);
  },

  inboundCallInteractions(state, { ctiInteractions }, rootState, rootGetters) {
    const outboundCallByInteractionId =
      rootGetters['contact/outboundCallByInteractionId'];

    return ctiInteractions.filter(
      ({ data: { interactionCallDirection, interactionId }, type }, index) =>
        type === INCOMING_CALL &&
        (interactionCallDirection === 'I' ||
          // an incoming call interaction resulting from a transfer has call direction O (don't ask)
          (index === 0 &&
            interactionCallDirection === 'O' &&
            outboundCallByInteractionId(interactionId) == null)),
    );
  },

  manualOutboundCallBOInteractions(state, getters, rootState, rootGetters) {
    const outboundCalls = rootGetters['contact/outboundCalls'] || [];

    return outboundCalls
      .filter(
        ({ phoneNumber, sourceType }) =>
          sourceType === 'ExternalTransfer' && phoneNumber == null,
      )
      .map(sanitizeManualOutboundBOCall);
  },

  outboundCallInteractions(state, getters, rootState, rootGetters) {
    const outboundCalls = rootGetters['contact/outboundCalls'] || [];

    return outboundCalls
      .filter(({ sourceType }) => sourceType !== 'ExternalTransfer')
      .map(sanitizeOutboundCall);
  },

  outboundCallBOInteractions(state, getters, rootState, rootGetters) {
    const outboundCalls = rootGetters['contact/outboundCalls'] || [];

    return outboundCalls
      .filter(
        ({ phoneNumber, sourceType }) =>
          sourceType === 'ExternalTransfer' && phoneNumber != null,
      )
      .map(sanitizeOutboundBOCall);
  },

  recallInteractions(state, { ctiInteractions }) {
    return ctiInteractions.filter(
      ({ data: { interactionCallDirection }, type }, index) =>
        // the parent cti interaction is the actual callback interaction
        // the second is the outgoing call interaction associated with it
        index === 1 && type === RECALL && interactionCallDirection === 'O',
    );
  },

  teletolkInteractions(state, { ctiInteractions }) {
    return ctiInteractions.filter(
      ({ data: { interactionObjectType }, type }) =>
        type === TELETOLK && interactionObjectType === EIC_GENERIC_OBJECT,
    );
  },

  externalTicketInteractions(state, { ctiInteractions }) {
    return ctiInteractions.filter(
      ({ data: { interactionObjectType }, type }) =>
        type === EXTERNAL_TICKET &&
        interactionObjectType === EIC_GENERIC_OBJECT,
    );
  },

  interactions(
    { customInteractions },
    {
      chatInteractions,
      emailInteractions,
      externalTicketInteractions,
      inboundCallInteractions,
      manualOutboundCallBOInteractions,
      outboundCallInteractions,
      outboundCallBOInteractions,
      recallInteractions,
      teletolkInteractions,
    },
  ) {
    const interactions = [
      ...chatInteractions,
      ...customInteractions,
      ...emailInteractions,
      ...externalTicketInteractions,
      ...inboundCallInteractions,
      ...manualOutboundCallBOInteractions,
      ...outboundCallInteractions,
      ...outboundCallBOInteractions,
      ...recallInteractions,
      ...teletolkInteractions,
    ].filter(identity);

    const sortByDate = sortBy(prop('date'));

    return sortByDate(interactions);
  },

  getInteractionById: (state, { interactions }) => id => {
    return interactions.find(propEq('id', id));
  },

  hasOutboundCallInteractions: (state, { outboundCallInteractions }) =>
    outboundCallInteractions.length > 0,
};
