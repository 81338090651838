export const descriptions = [
  {
    key: 'PastNuNiet',
    label: 'Past nu niet',
    defaultStatus: 'TerugBellen',
  },
  {
    key: 'NietBereikt',
    label: 'Niet bereikt',
    defaultStatus: 'TerugBellen',
  },
  {
    key: 'AntwoordGegeven',
    label: 'Antwoord gegeven',
    defaultStatus: 'NietMeerTerugBellen',
  },
  {
    key: 'HeeftAlAntwoord',
    label: 'Heeft al antwoord',
    defaultStatus: 'NietMeerTerugBellen',
  },
  {
    key: 'Andere',
    label: 'Andere',
  },
];

export const statuses = [
  {
    key: 'NietMeerTerugBellen',
    label: 'Niet meer terugbellen',
  },
  {
    key: 'TerugBellen',
    label: 'Terugbellen',
  },
];
