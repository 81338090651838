export const ASSIGN = 'ASSIGN';
export const CREATE = 'CREATE';
export const DELETE = 'DELETE';
export const FETCH = 'FETCH';
export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const SEND = 'SEND';
export const SYNC = 'SYNC';
export const SEARCH = 'SEARCH';

export const ADD_INTERACTION = 'ADD_INTERACTION';
