import Contact from '@/modules/Contact';
import { crmAxios } from '@/utils/Axios';
import {
  UPDATE_CONTACT,
  // UPDATE_LOADING_STATE,
} from '@/constants/mutation-types';

export default (
  { commit, getters, state },
  contactId = getters['contactId'],
) => {
  // commit(UPDATE_LOADING_STATE, true);

  return crmAxios({
    url: `/Contact/${contactId}`,
  })
    .then(({ data }) => {
      commit(UPDATE_CONTACT, new Contact({ ...state.contact, ...data }));
    })
    .catch(error => commit('saveContactError', error));
};
