import bus from '@/utils/bus';
import Cookies from 'js-cookie';

import Contact from '@/modules/Contact';
import { crmAxios } from '@/utils/Axios';
import {
  RESET,
  UPDATE_CONTACT,
  UPDATE_LOADING_STATE,
} from '@/constants/mutation-types';
import { COOKIE_NAME_CONTACT_ID } from '@/constants';

export default async (
  { commit, dispatch, rootState, getters },
  contactId = getters['contactId'] || Cookies.get(COOKIE_NAME_CONTACT_ID),
) => {
  commit(UPDATE_LOADING_STATE, true);

  // Create an initialization object for creating a new contact
  const obj = {
    originCode: 'Cti',
  };

  // Reset the citizen store
  dispatch('citizen/reset', null, { root: true });

  // Set default statuses in the object
  rootState.metadata.contactStatuses.forEach(cs => {
    cs.subStatuses.forEach(ss => {
      if (ss.isDefault === true) {
        obj.status = cs.id;
        obj.subStatus = ss.id;
      }
    });
  });

  // reset contact
  if (contactId == null) {
    commit(`sidebar/${RESET}`, null, { root: true });
    commit(`sidebar/chat/${RESET}`, null, { root: true });
    commit(`emails/${RESET}`, null, { root: true });
    commit(`mirage/${RESET}`, null, { root: true });

    commit(UPDATE_CONTACT, new Contact(obj));

    Cookies.remove(COOKIE_NAME_CONTACT_ID);

    commit(UPDATE_LOADING_STATE, false);
    commit('hasSavedEndRegistration', false);

    return;
  }

  // Call the API
  return crmAxios({
    method: 'get',
    url: `/Contact/${contactId}`,
    headers: {
      Accept: 'application/json',
    },
  })
    .then(({ data }) => {
      Cookies.set(COOKIE_NAME_CONTACT_ID, data.id);

      const newContact = new Contact(data);

      // Set the status based on the received substatus
      if (newContact.subStatus) {
        rootState.metadata.contactStatuses.forEach(cs => {
          cs.subStatuses.forEach(ss => {
            if (ss.id.toString() === newContact.subStatus.toString()) {
              newContact.status = cs.id;
            }
          });
        });
      }

      // Set the lineId based on the received themeId
      rootState.metadata.lines.forEach(line => {
        const foundTheme = line.themes.find(t => t.id === newContact.themeId);
        if (foundTheme) {
          newContact.lineId = line.id;
        }
      });

      // Loop over questions and set the status based on the received substatus
      newContact.questions.forEach(question => {
        // Check if the status has a substatus
        if (question.subStatus) {
          // Find the subStatus in the array of received question statuses to find it's parent status
          rootState.metadata.questionStatuses.forEach(qs => {
            qs.subStatuses.forEach(ss => {
              if (ss.id.toString() === question.subStatus.toString()) {
                question.status = qs.id;
              }
            });
          });
        }
      });

      // If a citizen is assigned, dispatch an event to the citizen store to update the search parameters to match to selected citizen
      if (newContact.citizen) {
        dispatch(
          'citizen/updateSearchCitizen',
          {
            firstName: newContact.citizen.firstName,
            lastName: newContact.citizen.lastName,
          },
          { root: true },
        );
      }

      commit(UPDATE_CONTACT, newContact);

      dispatch('emails/sync', null, { root: true }).then(() => {
        commit(UPDATE_LOADING_STATE, false);
      });
    })
    .catch(err => {
      // Show a modal indicating an error retrieving the contact
      bus.$emit('show-modal', {
        message: 'CRM Error: Ophalen contact mislukt',
        body: `Het contact met ID ${contactId} kon niet opgehaald worden. (${err.message})`,
        confirmText: 'Probeer opnieuw',
        confirm() {
          // Reload the page
          window.location.reload(true);
        },
      });

      // Create a new contact from the initialization object
      commit(UPDATE_CONTACT, new Contact(obj));
      commit(UPDATE_LOADING_STATE, false);

      // Re-throw the error
      throw err;
    });
};
