<template>
  <div>
    <label class="vl-form__label">
      <span>Voornaam</span>
      <VlInputField :disabled="true" :value="citizen.firstName" />
    </label>

    <label class="vl-form__label">
      <span>Naam</span>
      <VlInputField :disabled="true" :value="citizen.lastName" />
    </label>

    <label v-if="citizen.emailAddress" class="vl-form__label">
      <span>E-mail</span>
      <VlInputField :disabled="true" :value="citizen.emailAddress" />
    </label>

    <label v-if="citizen.telephone" class="vl-form__label">
      <span>Telefoonnummer</span>
      <VlInputField :disabled="true" :value="citizen.telephone" />
    </label>

    <label v-if="citizen.mobilePhone" class="vl-form__label">
      <span>GSM</span>
      <VlInputField :disabled="true" :value="citizen.mobilePhone" />
    </label>

    <slot />

    <div class="flex justify-end mb-4">
      <VlLink
        :class="[
          'ml-4 flex',
          { disabled: disabled || historyItems.length === 0 },
        ]"
        href="#"
        title="Historiek weergeven"
        @click.prevent="showCitizenHistoryModal = true"
      >
        <span class="mr-2">{{ historyItems.length }}</span>

        <VlIcon icon="clock" />
      </VlLink>

      <VlLink
        :class="['ml-4', { disabled }]"
        href="#"
        title="Burger bewerken"
        @click.prevent="$emit('open', citizen)"
      >
        <VlIcon icon="edit" />
      </VlLink>

      <VlLink
        :class="['ml-4', { disabled }]"
        href="#"
        title="Burger ontkoppelen"
        @click.prevent="$emit('unlink', citizen)"
      >
        <VlIcon icon="close" />
      </VlLink>
    </div>

    <portal to="modal">
      <CitizenHistoryModal
        v-if="showCitizenHistoryModal"
        @close="showCitizenHistoryModal = false"
        :citizen="citizen"
        :historyItems="historyItems"
      />
    </portal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import CitizenClass from '@/modules/Citizen';
import { crmAxios } from '@/utils/Axios';

import CitizenHistoryModal from './modals/CitizenHistoryModal';

export default {
  props: {
    citizen: {
      type: CitizenClass,
      required: true,
    },

    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  components: { CitizenHistoryModal },

  data() {
    return {
      error: null,
      isLoading: false,
      historyItems: [],
      showCitizenHistoryModal: false,
    };
  },

  computed: {
    ...mapGetters('contact', ['themeId']),
  },

  created() {
    this.isLoading = true;

    crmAxios({
      url: `/Citizen/${this.citizen.id}/history/${this.themeId}`,
    })
      .then(({ data }) => {
        this.historyItems = data;

        this.isLoading = false;
      })
      .catch(error => {
        this.error = error;

        this.isLoading = false;
      });
  },
};
</script>
