import { crmAxios } from '@/utils/Axios';

import { DELETE } from '@/constants/action-types';

import { makeAsyncMutationTypes } from '@/utils';

const { FAILURE, REQUEST, SUCCESS } = makeAsyncMutationTypes(DELETE);

export default ({ commit, dispatch }, id) => {
  commit(REQUEST);

  return crmAxios({
    method: 'delete',
    url: `/Email/${id}`,
  })
    .then(async () => {
      commit(SUCCESS);

      await dispatch('contact/sync', undefined, { root: true });

      return dispatch('sync');
    })
    .catch(error => {
      commit(FAILURE, error);
    });
};
