<template>
  <div
    :class="[
      'vlw__collapse__container',
      $style.container,
      { [$style.isCollapsed]: isCollapsed },
    ]"
  >
    <div class="flex justify-between">
      <a
        :class="['vlw__collapse__label flex items-center', $style.label]"
        role="button"
        :tabIndex="0"
        @click="isCollapsed = !isCollapsed"
      >
        <VlIcon class="mr-4" :class="$style.chevron" icon="arrow-down-fat" />

        <slot name="label">
          <div class="text-3xl uppercase font-bold">{{ label }}</div>
        </slot>
      </a>

      <slot name="buttons" />
    </div>

    <div class="vlw__collapse__inner-container" v-if="!isCollapsed">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'Collapse',

  props: {
    initiallyCollapsed: {
      default: true,
    },
    label: {},
  },

  data() {
    return {
      isCollapsed: this.initiallyCollapsed,
    };
  },

  watch: {
    initiallyCollapsed(value) {
      this.isCollapsed = value;
    },

    isCollapsed(isCollapsed) {
      this.$emit('toggle', isCollapsed);
    },
  },
};
</script>

<style lang="scss" module>
.chevron {
  transition: transform 200ms;
}

.container {
  .label {
    cursor: pointer;
    padding: 1rem 0;

    &:focus {
      outline: none;
    }
  }

  &.isCollapsed {
    .chevron {
      transform: rotate(-90deg);
    }
  }
}
</style>
