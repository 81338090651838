import {
  EXTERNAL_TICKET,
  INCOMING_CALL,
  INCOMING_CHAT,
  INCOMING_EMAIL,
  OUTGOING_CALL,
  OUTGOING_EMAIL,
  SCREENSHARING,
} from '@/constants';

import ExternalTicket from '@/components/sidebar/ExternalTicket';
import IncomingChat from '@/components/sidebar/chat/IncomingChat';
import IncomingEmail from '@/components/sidebar/email/IncomingEmail';
import IncomingCall from '@/components/sidebar/IncomingCall';
import OutgoingCall from '@/components/sidebar/OutgoingCall';

export default {
  [EXTERNAL_TICKET]: {
    key: EXTERNAL_TICKET,
    ctiKey: 'Ticket',
    name: 'Inkomend Ticket',
    sidebarComponent: ExternalTicket,
    availableChannels: [OUTGOING_EMAIL, OUTGOING_CALL],
  },

  [INCOMING_CALL]: {
    key: INCOMING_CALL,
    ctiKey: 'Call',
    name: 'Inkomende oproep',
    sidebarComponent: IncomingCall,
    availableChannels: [OUTGOING_EMAIL, OUTGOING_CALL, SCREENSHARING],
    iconName: 'phone-incoming',
  },

  [INCOMING_CHAT]: {
    key: INCOMING_CHAT,
    ctiKey: 'Chat',
    name: 'Inkomende chat',
    sidebarComponent: IncomingChat,
    availableChannels: [OUTGOING_EMAIL, OUTGOING_CALL, SCREENSHARING],
    iconName: 'chat',
  },

  [INCOMING_EMAIL]: {
    key: INCOMING_EMAIL,
    ctiKey: 'Mail',
    name: 'Inkomende e-mail',
    sidebarComponent: IncomingEmail,
    availableChannels: [OUTGOING_CALL, OUTGOING_EMAIL],
    iconName: 'email',
  },

  [OUTGOING_CALL]: {
    key: OUTGOING_CALL,
    // ctiKey: 'Recall',
    name: 'Uitgaande oproep',
    sidebarComponent: OutgoingCall,
    availableChannels: [OUTGOING_CALL, OUTGOING_EMAIL, SCREENSHARING],
    iconName: 'phone-outgoing',
  },
};
