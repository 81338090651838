<template>
  <div class="p-8">
    <VlAlert
      v-if="!canEdit"
      :mod-info="true"
      content="E-mail is verstuurd."
      icon="check"
    />

    <form @submit.prevent="submit" :class="{ 'opacity-75': !canEdit }">
      <div class="flex justify-between mb-4">
        <VlButton
          type="submit"
          :mod-narrow="true"
          :modDisabled="!canSend"
          :mod-loading="isSending"
          >Verzenden</VlButton
        >

        <div>
          <VlLink href @click.prevent="showCc = !showCc">CC</VlLink>
          <span>{{ ' | ' }}</span>
          <VlLink href @click.prevent="showBcc = !showBcc">BCC</VlLink>
        </div>
      </div>

      <FormField label="naar">
        <VlInputField
          ref="to"
          v-model.trim="formData.to"
          type="email"
          :disabled="!canEdit"
          :mod-error="$v.formData.to.$error"
          @blur="$v.formData.to.$touch"
        />
      </FormField>

      <VlFormMessageError class="mb-4" v-if="$v.formData.to.$error"
        >Ongeldig e-mailadres.</VlFormMessageError
      >

      <FormField v-if="showCc" label="CC">
        <VlInputField
          ref="cc"
          v-model.trim="formData.cc"
          type="email"
          :disabled="!canEdit"
          :mod-error="$v.formData.cc.$error"
          @blur="$v.formData.cc.$touch"
        />
      </FormField>

      <VlFormMessageError class="mb-4" v-if="$v.formData.cc.$error"
        >Ongeldig e-mailadres.</VlFormMessageError
      >

      <FormField v-if="showBcc" label="BCC">
        <VlInputField
          ref="bcc"
          v-model.trim="formData.bcc"
          type="email"
          :disabled="!canEdit"
          :mod-error="$v.formData.bcc.$error"
          @blur="$v.formData.bcc.$touch"
        />
      </FormField>

      <VlFormMessageError class="mb-4" v-if="$v.formData.bcc.$error"
        >Ongeldig e-mailadres.</VlFormMessageError
      >

      <FormField label="onderwerp">
        <VlInputField
          v-model="formData.subject"
          :disabled="!canEdit"
          :mod-error="$v.formData.subject.$error"
          @blur="$v.formData.subject.$touch"
        />
      </FormField>

      <VlFormMessageError class="mb-4" v-if="$v.formData.subject.$error"
        >Verplicht veld.</VlFormMessageError
      >

      <div class="mb-4 bg-white">
        <VlTypography v-if="canEdit">
          <ckeditor
            :editor="$options.editor"
            v-model="formData.body"
            :config="$options.editorConfig"
          ></ckeditor>
        </VlTypography>

        <div v-else :class="$style.bodyContainer" v-html="formData.body" />
      </div>

      <div class="mb-4 flex justify-between" v-if="canEdit">
        <VlLink href="#" @click.prevent="addAttachment">
          <VlIcon icon="paperclip" class="mr-4" />Bijlage toevoegen
        </VlLink>

        <VlLink href="#" @click.prevent="chooseTemplate">Kies template</VlLink>
      </div>

      <Attachment
        class="p-4"
        v-for="attachment in formData.attachments"
        :allowDelete="true"
        :attachment="attachment"
        :key="attachment.id"
      />

      <div class="mb-4 flex justify-between" v-if="canEdit">
        <div class="flex items-center">
          <VlButton
            type="submit"
            :mod-narrow="true"
            :modDisabled="!canSend"
            :mod-loading="isSending"
            >Verzenden
          </VlButton>
        </div>

        <VlLink href="#" class="flex items-center" @click.prevent="deleteEmail">
          <VlLoader v-if="isDeleting" class="mr-4" />

          <VlIcon icon="bin" class="mr-4" />E-mail verwijderen
        </VlLink>
      </div>
    </form>

    <TemplatesModal
      v-if="showTemplatesModal"
      @close="showTemplatesModal = false"
      :emailId="data.id"
    />
  </div>
</template>

<script>
import CKEditor from '@ckeditor/ckeditor5-vue2';
import {
  ClassicEditor,
  AutoLink,
  Autosave,
  Bold,
  Essentials,
  FontColor,
  FontFamily,
  FontSize,
  GeneralHtmlSupport,
  Heading,
  Italic,
  Link,
  List,
  Paragraph,
  PasteFromOffice,
  SelectAll,
  Table,
  TableToolbar,
  Undo,
  Alignment,
} from 'ckeditor5';
import { debounce, noop } from 'lodash';
import { propEq } from 'lodash/fp';
import { email, required } from 'vuelidate/lib/validators';

import { DEBOUNCE_DELAY } from '@/config';
import { crmAxios } from '@/utils/Axios';

import Attachment from './Attachment';
import FormField from './FormField';
import TemplatesModal from './TemplatesModal';
import coreTranslations from 'ckeditor5/translations/nl.js';

import 'ckeditor5/ckeditor5.css';

const uploadAttachment = (id) => (file) => {
  const data = new FormData();

  data.append('file', file);

  return crmAxios({
    method: 'post',
    url: `/Email/${id}/attachments`,
    data,
  });
};

export default {
  name: 'OutgoingEmail',

  fileInput: null,

  unwatchFormData: noop,

  editorConfig: {
    toolbar: {
      items: [
        '|',
        'heading',
        '|',
        'fontSize',
        'fontFamily',
        '|',
        'alignment:left',
        'alignment:center',
        'alignment:right',
        'alignment:justify',
        '|',
        'bold',
        'italic',
        'bulletedList',
        'numberedList',
        '|',
        'link',
        'insertTable',
        '|',
        'undo',
        'redo',
      ],
      shouldNotGroupWhenFull: true,
    },
    plugins: [
      Alignment,
      AutoLink,
      Autosave,
      Bold,
      Essentials,
      FontColor,
      FontFamily,
      FontSize,
      GeneralHtmlSupport,
      Heading,
      Italic,
      Link,
      List,
      Paragraph,
      PasteFromOffice,
      SelectAll,
      Table,
      TableToolbar,
      Undo,
    ],
    fontFamily: {
      options: [, 'Arial'],
    },
    fontSize: {
      options: [8, 10, 12, 'default', 14, 18, 20, 22],
      supportAllValues: true,
    },
    heading: {
      options: [
        {
          model: 'paragraph',
          title: 'Paragraph',
          class: 'ck-heading_paragraph',
        },
        {
          model: 'heading1',
          view: 'h1',
          title: 'Heading 1',
          class: 'ck-heading_heading1',
        },
        {
          model: 'heading2',
          view: 'h2',
          title: 'Heading 2',
          class: 'ck-heading_heading2',
        },
        {
          model: 'heading3',
          view: 'h3',
          title: 'Heading 3',
          class: 'ck-heading_heading3',
        },
        {
          model: 'heading4',
          view: 'h4',
          title: 'Heading 4',
          class: 'ck-heading_heading4',
        },
        {
          model: 'heading5',
          view: 'h5',
          title: 'Heading 5',
          class: 'ck-heading_heading5',
        },
        {
          model: 'heading6',
          view: 'h6',
          title: 'Heading 6',
          class: 'ck-heading_heading6',
        },
      ],
    },
    htmlSupport: {
      allow: [
        {
          name: /^.*$/,
          styles: true,
          attributes: true,
          classes: true,
        },
      ],
    },
    link: {
      addTargetToExternalLinks: true,
      defaultProtocol: 'https://',
      decorators: {
        toggleDownloadable: {
          mode: 'manual',
          label: 'Downloadable',
          attributes: {
            download: 'file',
          },
        },
      },
    },
    table: {
      contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells'],
    },
    translations: [coreTranslations],
  },
  editor: ClassicEditor,

  components: {
    Attachment,
    FormField,
    TemplatesModal,
    ckeditor: CKEditor.component,
  },

  validations: {
    formData: {
      bcc: { email },
      body: { required },
      cc: { email },
      subject: { required },
      to: { email, required },
    },
  },

  props: ['data', 'hasStopped'],

  data() {
    return {
      error: null,
      isDeleting: false,
      isSending: false,
      showCc: false,
      showBcc: false,
      showTemplatesModal: false,
    };
  },

  computed: {
    formData() {
      const getEmailById = this.$store.getters['emails/getEmailById'];

      return getEmailById(this.data.id) || {};
    },

    canEdit() {
      return this.data.statusCode === 'Draft';
    },

    canSend() {
      return !this.$v.$invalid;
    },
  },

  methods: {
    addAttachment() {
      this.$options.fileInput.click();
    },

    chooseTemplate() {
      this.showTemplatesModal = true;
    },

    deleteEmail() {
      this.isDeleting = true;

      this.$store
        .dispatch('emails/delete', this.data.id)
        .then(() => {
          this.isDeleting = false;
          // this.$emit('remove');
        })
        .catch((error) => {
          this.isDeleting = false;
          this.error = error;
        });
    },

    handleFiles() {
      const { files } = this.$options.fileInput;
      const { attachments, id } = this.formData;

      if (!attachments || !id) {
        return;
      }

      const newFiles = Array.from(files).filter(
        ({ name }) => !attachments.find(propEq('name', name)),
      );

      // optimistic update
      this.formData.attachments = [
        ...attachments,
        ...newFiles.map(({ name }) => ({ fileName: name })),
      ];

      Promise.all(newFiles.map(uploadAttachment(id))).then(() => {
        this.$store.dispatch('emails/sync');
      });
    },

    submit() {
      this.isSending = true;

      this.$store
        .dispatch('emails/send', this.data.id)
        .then(() => {
          this.$store.dispatch('emails/sync');
          this.$store.dispatch('contact/sync');
          this.isSending = false;
          this.$options.unwatchFormData();
          this.$emit('stop');
          this.$emit('close');
        })
        .catch((error) => {
          this.isSending = false;
          this.error = error;
        });
    },

    sync(newFormData) {
      // Exit early if editing is disabled
      if (!this.canEdit) {
        return;
      }

      // Pick needed props
      let { id, subject, to, cc, bcc, body, statusCode } = newFormData;

      // Do not sync invalid props
      to = this.$v.formData.to.$invalid ? '' : to;
      cc = this.$v.formData.cc.$invalid ? '' : cc;
      bcc = this.$v.formData.bcc.$invalid ? '' : bcc;

      // Put changes to CRM api
      return crmAxios({
        method: 'put',
        url: `/Email/${id}`,
        data: { subject, to, cc, bcc, body, statusCode },
      });
    },
  },

  mounted() {
    if (this.hasStopped || !this.canEdit) {
      return;
    }

    // start counting
    this.$emit('start');

    // add sync listener
    this.$options.unwatchFormData = this.$watch(
      'formData',
      debounce(this.sync, DEBOUNCE_DELAY),
      { deep: true },
    );

    // add file input listener
    this.$options.fileInput = document.createElement('input');

    const { fileInput } = this.$options;

    fileInput.type = 'file';
    fileInput.setAttribute('multiple', true);

    fileInput.addEventListener('change', this.handleFiles);
  },

  beforeDestroy() {
    const { fileInput } = this.$options;

    if (fileInput) {
      fileInput.removeEventListener('change', this.handleFiles);
    }
  },
};
</script>

<style>
.editor-container_classic-editor .editor-container__editor {
  min-width: 795px;
  max-width: 795px;
}

.ck-editor__editable {
  min-height: 200px;
  font-family: Arial, sans-serif;
  font-size: 13px;
}

.ql-container {
  font-family: Arial, sans-serif;
}

.ql-container p {
  font-size: 16px;
}

.vl-typography h2 {
  font-size: 26px;
  font-weight: 500;
}

.vl-typography h3 {
  font-size: 22px;
  font-weight: 500;
}

.vl-typography h4 {
  font-size: 18px;
  font-weight: 500;
}

.ck-widget {
  display: none;
}
</style>

<style module>
.bodyContainer {
  background: #f4f5f7;
  border: 1px solid #cbd2da;
  margin: 2rem 0;
  padding: 1rem;
}

.bodyContainer pre {
  white-space: pre-line;
}
</style>
