<template>
  <!-- Modal wrapper -->
  <Modal
    @closed="$emit('close')"
    id="modal-internal-transfer"
    :open="true"
    :closable="true"
    title="Intern doorschakelen"
  >
    <div class="flex items-center justify-center" v-if="isLoading">
      <VlLoader />&nbsp;Loading...
    </div>

    <div v-else>
      <div class="flex justify-between items-center mb-4">
        <div class="vl-tabs__wrapper">
          <ul class="vl-tabs m-0" role="tablist">
            <li
              class="vl-tab"
              v-for="{ key, label } in availableLanguages"
              :class="{ 'vl-tab--active': selectedLanguage === key }"
              :key="key"
            >
              <a
                @click.prevent="selectedLanguage = key"
                class="vl-tab__link"
                href
                >{{ label.toUpperCase() }}</a
              >
            </li>
          </ul>
        </div>
      </div>

      <div class="relative mb-4">
        <VlInputField v-model="searchQuery" class="w-full" />

        <VlIcon
          class="absolute pin-r"
          icon="search"
          :style="{ padding: '4px' }"
        />
      </div>

      <div class="flex">
        <div>
          <div class="uppercase font-bold text-3xl mb-4">Binnen de lijn</div>

          <VlAlert
            v-if="!canTransferInternallyWithinLine"
            :mod-warning="true"
            content="Niet alle vragen hebben een titel en een oplossing."
          />

          <ul v-if="internalWorkgroups.length > 0">
            <li
              :class="$style.item"
              v-for="workgroup in internalWorkgroups"
              :key="workgroup.id"
            >
              <div class="flex items-center">
                <div class="mr-8 flex-grow">
                  <div>{{ workgroup.simplifiedName || workgroup.name }}</div>

                  <div class="text-gray-600">{{ workgroup.name }}</div>
                </div>

                <VlButton
                  @click.prevent="makeTransfer(workgroup)"
                  :mod-narrow="true"
                  :mod-disabled="!canTransferInternallyWithinLine"
                  >Transfer</VlButton
                >
              </div>

              <p
                v-if="!isAgentAvailable(workgroup) && !isMail(workgroup)"
                :mod-error="true"
                :style="{ color: 'red' }"
                class="text-sm"
              >
                <VlIcon icon="warning" class="mr-2" />
                <span>Niemand beschikbaar</span>
              </p>

              <p
                v-if="isLineClosed(workgroup)"
                :mod-error="true"
                :style="{ color: 'red' }"
                class="text-sm"
              >
                <VlIcon icon="warning" class="mr-2" />
                <span>Lijn gesloten</span>
              </p>
            </li>
          </ul>

          <VlAlert
            v-else
            :mod-warning="true"
            content="Er zijn geen werkgroepen beschikbaar"
          />
        </div>

        <div
          class="ml-4 mr-4"
          style="width: 1px; flex-shrink: 0; background: #cbd2da;"
        ></div>

        <div>
          <div class="uppercase font-bold text-3xl mb-4">Buiten de lijn</div>

          <ul v-if="externalWorkgroups.length > 0">
            <li
              :class="$style.item"
              v-for="workgroup in externalWorkgroups"
              :key="workgroup.id"
            >
              <div class="flex items-center">
                <div class="mr-8 flex-grow">
                  <div>{{ workgroup.simplifiedName || workgroup.name }}</div>

                  <div class="text-gray-600">{{ workgroup.name }}</div>
                </div>

                <VlButton
                  @click.prevent="makeTransfer(workgroup)"
                  :mod-narrow="true"
                  >Transfer</VlButton
                >
              </div>

              <p
                v-if="!isAgentAvailable(workgroup) && !isMail(workgroup)"
                :mod-error="true"
                :style="{ color: 'red' }"
                class="text-sm"
              >
                <VlIcon icon="warning" class="mr-2" />
                <span>Niemand beschikbaar</span>
              </p>
              <p
                v-if="isLineClosed(workgroup)"
                :mod-error="true"
                :style="{ color: 'red' }"
                class="text-sm"
              >
                <VlIcon icon="warning" class="mr-2" />
                <span>Lijn gesloten</span>
              </p>
            </li>
          </ul>

          <VlAlert
            v-else
            :mod-warning="true"
            content="Er zijn geen werkgroepen beschikbaar"
          />
        </div>
      </div>
    </div>
  </Modal>
</template>

<script>
import { allPass, groupBy, prop, propEq, toLower } from 'lodash/fp';
import { mapGetters } from 'vuex';

import { DUTCH } from '@/constants/languages';
import { languages } from '@/config';
import { crmAxios } from '@/utils/Axios';
import { ctiApi } from '@/utils';
import { updateCurrentCallback } from '@/helpers';

export default {
  data() {
    return {
      availableWorkgroups: [],
      ctiWorkgroupsInfo: [],
      error: null,
      isLoading: false,
      searchQuery: '',
      selectedLanguage: DUTCH,
    };
  },

  computed: {
    ...mapGetters('contact', [
      'canTransferInternallyWithinLine',
      'contactId',
      'lineId',
      'workgroupId',
    ]),
    ...mapGetters('metadata', ['lineHasWorkgroup', 'getWorkgroupById']),
    ...mapGetters('cti', ['isRecall', 'transferInteractionId']),

    availableLanguages() {
      const languageExists = ({ key }) =>
        this.availableWorkgroups.reduce(
          (acc, { language }) => acc || language === key,
          false,
        );

      return languages.filter(languageExists);
    },

    availableWorkgroupsBySelectedLanguage() {
      const workgroupHasSelectedLanguage = propEq(
        'language',
        this.selectedLanguage,
      );

      const workgroupHasSearchQuery = ({ name }) =>
        toLower(name).includes(toLower(this.searchQuery));

      const isHit = allPass([
        workgroupHasSelectedLanguage,
        workgroupHasSearchQuery,
      ]);

      return this.availableWorkgroups.filter(isHit);
    },

    availableWorkgroupsByIsInternal() {
      const workgroupIsInternal = ({ id }) =>
        this.lineHasWorkgroup(this.lineId, id);

      return groupBy(
        workgroupIsInternal,
        this.availableWorkgroupsBySelectedLanguage,
      );
    },

    externalWorkgroups() {
      return this.availableWorkgroupsByIsInternal['false'] || [];
    },

    internalWorkgroups() {
      return this.availableWorkgroupsByIsInternal['true'] || [];
    },
  },

  methods: {
    getCtiObject(workgroup) {
      const ctiInfo = (this.ctiWorkgroupsInfo || []).find(
        propEq('workgroup', workgroup.name),
      );

      if (ctiInfo == null) {
        console.error(
          `Workgoup ${workgroup.name} not found in CTI response /workgroups`,
        );
      }
      return ctiInfo;
    },

    isAgentAvailable(workgroup) {
      const ctiInfo = this.getCtiObject(workgroup);
      return ctiInfo && ctiInfo.loggedOn > 0;
    },

    isMail(workgroup) {
      return workgroup.name.toLowerCase().includes('mail');
    },

    isLineClosed(workgroup) {
      const ctiInfo = this.getCtiObject(workgroup);
      return ctiInfo && ctiInfo.closed === true;
    },

    makeTransfer(workgroup) {
      this.isLoading = true;

      if (this.isRecall) {
        updateCurrentCallback({
          description: 'Andere',
          status: 'NietMeerTerugBellen',
        });
      }

      return crmAxios({
        method: 'post',
        url: `Contact/${this.contactId}/transfer`,
        data: { workgroupId: workgroup.id },
      })
        .then(({ data }) => {
          const { contactId: transferId } = data;

          const isInternal = this.contactId === transferId;

          return this.$store.dispatch('cti/transferInteraction', {
            destination: workgroup.name,
            interactionId: this.transferInteractionId,
            isInternal,
            transferId,
          });
        })
        .catch(error => {
          this.isLoading = false;

          this.error = error;
        });
    },
  },

  async mounted() {
    this.isLoading = true;

    try {
      const { data: availableWorkgroups = [] } = await crmAxios({
        url: `/Metadata/transfer/${this.workgroupId}`,
      });

      const { data: ctiWorkgroupsInfo = [] } = await ctiApi({
        method: 'post',
        url: '/workgroupinfo',
        data: { workgroups: availableWorkgroups.map(prop('name')) },
      });

      this.ctiWorkgroupsInfo = ctiWorkgroupsInfo;

      this.availableWorkgroups = availableWorkgroups.map(({ id, ...rest }) => ({
        ...this.getWorkgroupById(id),
        ...rest,
      }));

      const firstAvailableLanguage = this.availableLanguages[0];
      this.selectedLanguage = firstAvailableLanguage
        ? firstAvailableLanguage.key
        : DUTCH;

      this.isLoading = false;
    } catch (error) {
      this.error = error;

      this.isLoading = false;
    }
  },
};
</script>

<style lang="scss" module>
.item {
  padding: 1rem;

  button {
    visibility: hidden;
  }

  &:hover {
    background: whitesmoke;

    button {
      visibility: visible;
    }
  }
}
</style>
