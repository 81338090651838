import { propEq } from 'lodash/fp';

import { CREATE, DELETE, FETCH, SEND, SYNC } from '@/constants/action-types';
import { PREPEND_HTML, RESET } from '@/constants/mutation-types';

import { makeAsyncMutationTypes } from '@/utils';
import { getInitialState } from './index';

const [
  createMutations,
  deleteMutations,
  fetchMutations,
  sendMutations,
  syncMutations,
] = [CREATE, DELETE, FETCH, SEND, SYNC].map(makeAsyncMutationTypes);

export default {
  [PREPEND_HTML](state, { id, html }) {
    const index = state.items.findIndex(propEq('id', id));

    const email = state.items[index];

    email.body = `${html}${email.body}`;

    state.items[index] = email;
  },

  [createMutations.REQUEST](state) {
    state.isLoading = true;
  },

  [createMutations.FAILURE](state, error) {
    state.isLoading = false;
    state.error = error;
  },

  [createMutations.SUCCESS]() {
    // do nothing
  },

  [deleteMutations.REQUEST]() {
    // do nothing
  },

  [deleteMutations.FAILURE](state, error) {
    state.isLoading = false;
    state.error = error;
  },

  [deleteMutations.SUCCESS]() {
    // do nothing
  },

  [fetchMutations.FAILURE](state, error) {
    state.isLoading = false;
    state.error = error;
  },

  [fetchMutations.REQUEST](state) {
    state.isLoading = true;
  },

  [fetchMutations.SUCCESS](state, data) {
    state.isLoading = false;
    state.items.push(data);
  },

  [RESET](state) {
    const initialState = getInitialState();

    Object.keys(initialState).forEach(key => {
      state[key] = initialState[key];
    });
  },

  [sendMutations.REQUEST](state) {
    state.isLoading = true;
  },

  [sendMutations.FAILURE](state, error) {
    state.isLoading = false;
    state.error = error;
  },

  [sendMutations.SUCCESS](state) {
    state.isLoading = false;
  },

  [syncMutations.REQUEST](state) {
    state.isLoading = true;
  },

  [syncMutations.FAILURE](state, error) {
    state.isLoading = false;
    state.error = error;
  },

  [syncMutations.SUCCESS](state, results) {
    state.isLoading = false;
    state.items = results;
  },
};
