import actions from './actions';
import getters from './getters';
import mutations from './mutations';

const state = {
  contactStatuses: [],
  error: false,
  hasFetched: false,
  isLoading: false,
  lines: [],
  questionStatuses: [],
  blockedEmailAddresses: [],
  vlplabels: [],
  vlpbannerenabled: {},
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
