<template>
  <Modal
    id="vlw__cti__manual-contact"
    class="vlw__cti__manual-contact"
    @closed="$emit('close')"
    :open="true"
    :closable="true"
  >
    <!-- Title -->
    <h1>Manueel contact aanmaken</h1>

    <!-- Loading state -->
    <VlLoader v-if="loading" />

    <!-- Wrapper -->
    <div>
      <!-- Lijn -->
      <div>
        <label class="vl-form__label">
          <span>Lijn</span>
          <select class="vl-select" v-model="lineId">
            <option disabled="disabled">Lijn</option>
            <option
              v-for="l in availableLines"
              :key="l.id"
              :value="l.id"
              v-text="l.name"
            ></option>
          </select>
        </label>
      </div>

      <!-- Behandelthema -->
      <div>
        <label class="vl-form__label">
          <span>Behandelthema</span>
          <select class="vl-select" v-model="themeId">
            <option disabled="disabled">Behandelthema</option>
            <option
              v-for="t in themes"
              :key="t.id"
              :value="t.id"
              v-text="t.name"
            ></option>
          </select>
        </label>
      </div>

      <!-- Kanaal -->
      <div>
        <label class="vl-form__label">
          <span>Kanaal</span>
          <select class="vl-select" v-model="channel">
            <option disabled="disabled">Kanaal</option>
            <option
              v-for="c in $options.channels"
              :key="c.key"
              :value="c.key"
              v-text="c.name"
            ></option>
          </select>
        </label>
      </div>

      <!-- Button -->
      <VlButton @click.prevent="create" :mod-disabled="!canCreate"
        >Aanmaken</VlButton
      >
    </div>
  </Modal>
</template>

<script>
// Imports
import { values } from 'lodash';
import { mapState, mapActions, mapGetters } from 'vuex';
import { channels } from '@/config';

export default {
  channels: values(channels),
  data() {
    return {
      // Local variables before creation
      lineId: null,
      themeId: null,
      channel: null,
    };
  },
  computed: {
    ...mapState('contact', ['contact', 'loading']),
    ...mapState('metadata', ['contactStatuses']),
    ...mapGetters('metadata', [
      'availableLines',
      'availableThemesByLineId',
      'getDefaultWorkgroupByThemeId',
    ]),
    ...mapGetters('cti', ['isLoggedIn']),

    // Themes in selected line
    themes() {
      return this.availableThemesByLineId(this.lineId);
    },

    canCreate() {
      return [this.lineId, this.themeId, this.channel].every(
        val => val != null,
      );
    },
  },
  methods: {
    ...mapActions('contact', ['save', 'closeContact']),

    // Method called when create is clicked
    async create() {
      const workgroup = this.getDefaultWorkgroupByThemeId(this.themeId) || {};

      if (this.isLoggedIn) {
        await this.$store.dispatch('cti/manualContact', {
          workgroup: workgroup.name,
        });
      }

      // If contact exists, save it first
      let saveExistingContactPromise = Promise.resolve();
      if (this.contact.id) {
        saveExistingContactPromise = this.save().then(this.closeContact);
      }

      saveExistingContactPromise.then(() => {
        // Update variables
        this.contact.lineId = this.lineId;
        this.contact.themeId = this.themeId;
        this.contact.startThemeId = this.themeId;
        this.contact.channel = this.channel;
        this.contact.originCode = 'ManualVlp';
        this.contact.workgroupId = workgroup.id;

        // Set default subStatus based on isDefaultManual (https://vlaamseoverheid.atlassian.net/browse/IC-1410)
        this.contactStatuses.forEach(cs => {
          cs.subStatuses.forEach(ss => {
            if (ss.isDefaultManual === true) {
              this.contact.status = cs.id;
              this.contact.subStatus = ss.id;
            }
          });
        });

        // Save the contact
        this.save();

        // Close the modal
        this.$emit('close');
      });
    },
  },
};
</script>

<style lang="scss">
// Modal wrapper
.vlw__cti__manual-contact {
  position: relative;

  // Loader
  .vl-loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .vl-modal-dialog {
    /*width: 100%;*/
    /*min-width: 1120px;*/
  }

  label {
    margin-bottom: 0.5em;
    display: block;

    > span {
      display: inline-block;
      min-width: 150px;
    }
  }
}
</style>
