import { crmAxios } from '@/utils/Axios';
import handleError from '@/utils/handle-error';

const blobToBase64 = blob => {
  return new Promise((resolve, reject) => {
    try {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.readAsDataURL(blob);
    } catch (e) {
      reject(e);
    }
  });
};

const isImage = filename => filename.match(/.(jpg|jpeg|png|gif)$/i) != null;

const sanitizeEmail = async email => {
  const base64strings = await Promise.all(
    email.attachments
      .filter(({ fileName }) => isImage(fileName))
      .map(async ({ id }) => {
        try {
          const { data: blob } = await crmAxios({
            url: `/Attachment/${id}`,
            responseType: 'blob',
          });

          const base64 = await blobToBase64(blob);

          return base64;
        } catch (e) {
          handleError(e);

          return null;
        }
      }),
  );

  const newBody = email.attachments.reduce(
    (acc, { attachmentContentId }, index) => {
      const base64 = base64strings[index];

      return base64 ? acc.replace(`cid:${attachmentContentId}`, base64) : acc;
    },
    email.body || '',
  );

  return { ...email, body: newBody };
};

export default sanitizeEmail;
