import { propEq } from 'lodash/fp';

import {
  ADD_INTERACTION,
  REMOVE_INTERACTION,
  RESET,
  UPDATE_INTERACTION,
  UPDATE_LOADING_STATE,
} from '@/constants/mutation-types';

import { getInitialState } from './index';

export default {
  [ADD_INTERACTION](state, interaction) {
    state.customInteractions.push(interaction);
  },

  [REMOVE_INTERACTION](state, id) {
    const index = state.customInteractions.findIndex(propEq('id', id));

    state.customInteractions.splice(index, 1);
  },

  [RESET](state) {
    const initialState = getInitialState();

    Object.keys(initialState).forEach(key => {
      state[key] = initialState[key];
    });
  },

  [UPDATE_INTERACTION](state, { index, data }) {
    state.customInteractions[index] = {
      ...state.customInteractions[index],
      ...data,
    };
  },

  [UPDATE_LOADING_STATE](state, { isLoading, error }) {
    state.isLoading = isLoading;
    state.error = error;
  },
};
