<template>
  <!-- Wrapper -->
  <div :class="{ darkmode: isDarkMode }">
    <div class="vlw">
      <!-- Custom icons -->
      <Icons />

      <!-- App -->
      <div class="vlw__cti" v-if="isLoggedIn && hasFetched">
        <MainHeader class="vlw__cti__main-header" />

        <!-- Content below header -->
        <PageContainer
          class="vlw__cti__content"
          v-if="contact && contact.id"
          :class="{ 'contact-created': crmContactCreated }"
        >
          <Home />

          <template slot="sidebar">
            <Sidebar />
          </template>
        </PageContainer>

        <!-- No contact -->
        <div v-else class="flex overflow-hidden">
          <div class="w-50 p-16 overflow-auto">
            <Newsfeed />
          </div>
          <div class="vlw__cti-toolbar__no-contact p-16 overflow-auto w-50">
            <div class="vlw__cti-toolbar__no-contact__actions">
              <!-- Stay available -->
              <div v-if="!loading" class="vl-u-align-center">
                <h1>
                  {{ user.isLoggedIn ? user.status : 'Blijf beschikbaar' }}
                </h1>
                <div>
                  <h2 v-if="!user.isLoggedIn">
                    Op dit ogenblik is er geen contact beschikbaar
                  </h2>

                  <!-- Timer -->
                  <div
                    v-if="user.isLoggedIn"
                    class="vlw__cti-toolbar__no-contact__timer"
                  >
                    <StatusTimer />
                  </div>
                </div>

                <VlButton @click.prevent="showManualContact = true"
                  >Manueel contact aanmaken</VlButton
                >
              </div>

              <!-- Creating new contact -->
              <div v-else class="vl-u-align-center">
                <h1>Nieuw contact</h1>
                <h2>Een nieuw contact wordt voorbereid</h2>
                <VlLoader />
              </div>
            </div>

            <!-- Display open contacts -->
            <MyOpenContacts
              class="vlw__cti-toolbar__no-contact__open-contacts"
            />

            <!-- Manual contact -->
            <portal to="modal" v-if="showManualContact">
              <ManualContact @close="showManualContact = false" />
            </portal>
          </div>
        </div>
      </div>

      <!-- Loading metadata -->
      <Modal
        id="crm-loading-modal"
        v-if="isLoggedIn && !hasFetched && !errorModalShown"
        :closable="false"
        :open="true"
      >
        <h1>CRM: Loading</h1>
        <div>
          Loading CRM metadata
          <VlLoader tag-name="span" />
        </div>
      </Modal>

      <!-- Login -->
      <Login v-if="!isLoggedIn" />

      <!-- Component modals -->
      <portal-target name="modal" />

      <!-- Error modal (always on top) -->
      <Modal
        id="error-modal"
        class="vl-modal-dialog__error"
        v-if="errorModalShown"
        :title="errorMessage || 'Error'"
        :closable="errorClosable"
        :open="true"
        @closed="errorModalShown = false"
      >
        <div v-html="errorBody"></div>
        <!--modal-footer-->
        <template slot="modal-footer">
          <div class="vl-modal-dialog__error__footer">
            <VlButton
              v-if="errorCancel"
              :mod-secondary="true"
              :mod-narrow="true"
              v-text="errorCancelText"
              @click.prevent="onCancelModal"
            />

            <VlButton
              v-if="errorConfirm"
              v-text="errorConfirmText"
              @click.prevent="onConfirmModal"
              class="vl-modal-dialog__error__footer__confirm"
            />
          </div>
        </template>
      </Modal>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import { debounce } from 'lodash';
import bus from '@/utils/bus';
import { DEBOUNCE_DELAY, isLocal } from '@/config';
import MainHeader from '@/components/header/Header';
import Icons from '@/components/Icons.vue';
import Sidebar from '@/components/sidebar/Sidebar.vue';
import Home from '@/components/Home.vue';
import ManualContact from '@/components/ManualContact.vue';
import Login from '@/components/Login.vue';
import MyOpenContacts from '@/components/MyOpenContacts.vue';
import StatusTimer from './StatusTimer';
import Newsfeed from './Newsfeed';
import { authenticationContext as mirageAuthenticationContext } from '@/utils/mirage-api';

import PageContainer from './PageContainer';

export default {
  components: {
    Icons,
    MainHeader,
    Sidebar,
    Home,
    ManualContact,
    Login,
    PageContainer,
    MyOpenContacts,
    StatusTimer,
    Newsfeed,
  },

  data() {
    return {
      errorClosable: true,
      errorMessage: '',
      errorBody: '',
      errorModalShown: false,
      errorComponent: null,
      errorConfirm: false,
      errorConfirmText: 'ok',
      errorCancel: false,
      errorCancelText: 'cancel',
      showManualContact: false,
    };
  },

  computed: {
    ...mapState('contact', ['contact', 'loading']),
    ...mapState('metadata', ['hasFetched']),
    ...mapState('cti', ['user']),
    // todo: rename isLoggedIn to isLoggedIntoCrm
    ...mapGetters('authentication', ['isLoggedIn']),
    ...mapGetters('metadata', ['hasKindEnGezin', 'getLabelByName']),
    ...mapState('ui', ['isDarkMode']),

    // Helper to check if the contact is already created in the CRM
    crmContactCreated() {
      return !!this.contact.id;
    },
  },

  mounted() {
    // Initialize authentication
    this.$store.dispatch('authentication/initialize');

    // Subscribe to postmessage events
    bus.$on('postmessage', data => {
      // Get the data from the VLS
      this.$store
        .dispatch('answers/retrieveAnswersFromVLS', data)
        .then(answers => {
          answers.forEach(answer => {
            this.$store.dispatch('contact/useAnswer', {
              question: data.questionId,
              answer,
            });
          });
        });
    });

    bus.$on('sessionUpdatedCobrowse', data => {
      let existingSession = false;
      let session = data;
      let sessionState = session.state;
      let sessionId = session.id;

      this.contact.screenSharingSessions.forEach(s => {
        if (s != undefined && sessionState != undefined && s.id === sessionId) {
          existingSession = true;
          s.state = sessionState;
          if (sessionState == 'ended') {
            const index = this.contact.screenSharingSessions.indexOf(s);
            this.contact.screenSharingSessions.splice(index, 1);
          }
        }
      });

      if (!existingSession) {
        this.contact.screenSharingSessions.push({
          id: sessionId,
          state: sessionState,
        });
      }

      if (
        data !== null &&
        session !== null &&
        session !== undefined &&
        session.state === 'active'
      ) {
        if (this.contact.screenSharingSessionId === session.id) return;
        this.contact.screenSharingSessionId = session.id;
        this.contact.completionInfo_ScreenSharing = 'Yes';
      }
    });
    // Subscribe to errors
    bus.$on('show-modal', data => {
      this.errorClosable =
        typeof data.closable === 'boolean' ? data.closable : true;
      this.errorMessage = data.message;
      this.errorBody = data.body;
      this.errorConfirm = data.confirm || false;
      this.errorConfirmText = data.confirmText || 'ok';
      this.errorCancel = data.cancel || false;
      this.errorCancelText = data.cancelText || 'cancel';
      this.errorModalShown = true;
      this.errorComponent = null;
    });
  },

  methods: {
    ...mapActions('contact', ['getContact']),
    ...mapActions('metadata', {
      fetchMetadata: 'fetch',
    }),
    ...mapActions('authentication/workgroups', {
      fetchWorkgroups: 'fetch',
    }),
    ...mapActions('authentication/whoAmI', {
      fetchWhoAmI: 'fetch',
    }),

    // Contact change
    onContactChange() {
      // Dispatch an action to save the contact in the background
      this.$store.dispatch('contact/save', { background: true });
    },

    // Cancel method on modal
    onCancelModal() {
      // Run cancel method
      if (typeof this.errorCancel === 'function') {
        this.errorCancel();
      }

      // Close modal
      this.errorModalShown = false;
    },

    // Confirm method on modal
    onConfirmModal() {
      // Run confirmation method
      if (typeof this.errorConfirm === 'function') {
        this.errorConfirm();
      }

      // Close modal
      this.errorModalShown = false;
    },
  },

  watch: {
    async isLoggedIn(isLoggedIn) {
      if (!isLoggedIn) {
        return;
      }

      try {
        await Promise.all([
          this.fetchMetadata(),
          this.fetchWorkgroups(),
          this.fetchWhoAmI(),
        ]);

        const hasKindEnGezin = this.$store.getters['metadata/hasKindEnGezin'];

        if (hasKindEnGezin && !isLocal) {
          const user = mirageAuthenticationContext.getCachedUser();

          const label = this.getLabelByName('login_mirage');

          if (!user) {
            bus.$emit('show-modal', {
              message: label.title,
              body: label.body,
              cancel: true,
              cancelText: label.cancel,
              confirmText: label.confirm,
              confirm: () => {
                mirageAuthenticationContext.login();
              },
            });
          }
        }

        // Create the contact (either new or from a cookie)
        await this.getContact();

        // Setup a debounced watcher
        const debouncedContactChange = debounce(
          this.onContactChange,
          DEBOUNCE_DELAY,
        );

        const debouncedWatchedContactProps = ['comments'];

        debouncedWatchedContactProps.forEach(prop => {
          this.$watch(`contact.${prop}`, debouncedContactChange);
        });

        // Setup the watcher for props that can change (no deep watcher as questions have a different endpoint)
        const watchedContactProps = [
          'themeId',
          'subStatus',
          'citizen',
          'channel',
          'completionInfo_Language',
          'screenSharingSessionId',
          'recordedQuestion',
        ];

        watchedContactProps.forEach(prop => {
          this.$watch(`contact.${prop}`, this.onContactChange);
        });
      } catch (e) {
        console.error(e);
      }
    },
  },
};
</script>

<style lang="scss">
// No contact: timer
.vlw__cti-toolbar__no-contact__timer {
  display: inline-flex;
  margin-bottom: 1em;
}

// No contact:  actions
.vlw__cti-toolbar__no-contact__actions {
  margin-bottom: 40px;

  h1,
  h2 {
    font-weight: 400;
  }

  h1 {
    font-size: 30px;
  }

  h2 {
    font-size: 18px;
    margin-bottom: 20px;
  }
}

// No contact: open contacts
.vlw__cti-toolbar__no-contact__open-contacts {
  margin: 0 auto;

  h2 {
    font-size: 20px;
  }
}

// app
.vlw__cti {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

// Content
.vlw__cti__content {
  display: flex;
  flex-grow: 1;
  overflow-y: auto;

  &.contact-created {
    opacity: 1;
  }
}

// Heading defaults
h1,
h2,
h3 {
  display: block;
  font-weight: 500;
}

h1 {
  font-size: 22px;
  margin-bottom: 10px;
}

h2 {
  font-size: 22px;
}

h3 {
  font-size: 18px;
}

.vl-modal-dialog__error__footer {
  padding-top: 20px;
  display: flex;
  justify-content: space-between;
}

.vl-modal-dialog__error__footer__confirm {
  align-self: flex-end;
}
</style>
