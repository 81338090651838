<template>
  <div class="screenshots">
    <div
      class="screenshots-info"
      ref="target"
      @paste="onPaste"
      style="cursor:pointer"
    >
      Klik hier en druk Control-V om een screenshot te plakken.
    </div>
    <br />
    <Screenshot
      v-for="s in screenshots"
      :ref="'question-' + s.internalId"
      :screenshot="s"
      :key="s.internalId"
      @delete="onDeleteScreenshot(s)"
    />
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { crmAxios } from '@/utils/Axios';
import Screenshot from './Screenshot.vue';

export default {
  components: {
    Screenshot,
  },
  data() {
    return {
      imageDataURL: null,
    };
  },
  props: {
    question: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapState('contact', ['contact']),
    screenshots() {
      return this.question.annotations;
    },
  },
  methods: {
    ...mapActions('contact', ['addQuestion', 'deleteQuestion']),
    ...mapActions('question', ['addAnnotation']),

    onPaste(event) {
      const clipboardData = event.clipboardData || window.clipboardData;
      const items = clipboardData.items;
      for (let i = 0; i < items.length; i++) {
        if (items[i].type.indexOf('image') !== -1) {
          const imageFile = items[i].getAsFile();
          this.processImage(imageFile);
        }
      }
    },
    processImage(imageFile) {
      const reader = new FileReader();
      reader.onload = event => {
        this.question.addAnnotation(imageFile, event.target.result);
      };

      reader.readAsDataURL(imageFile);
    },

    // On delete question
    async onDeleteScreenshot(s) {
      s.loading = true;

      try {
        const { crmId } = s;

        await crmAxios({
          method: 'delete',
          url: `/Annotation/${crmId}`,
        });

        const index = this.screenshots.indexOf(s);
        this.screenshots.splice(index, 1);

        //await this.$store.dispatch('contact/sync');
      } catch (error) {
        this.error = error;
      }

      s.loading = false;
    },
  },
};
</script>
<style lang="scss">
// Wrapper
.screenshots {
  border: 1px;
  border-style: dashed;
  border-color: grey;
}
.screenshots-info {
  text-align: center;
}
</style>
