import { crmAxios } from '@/utils/Axios';
import { UPDATE_LOADING_STATE } from '@/constants/mutation-types';

export default async ({ state, dispatch, commit, rootGetters }) => {
  commit(UPDATE_LOADING_STATE, true);

  try {
    await crmAxios({
      method: 'post',
      url: `/Contact/${state.contact.id}/close`,
    });

    const shouldCloseCtiContact = rootGetters['cti/isLoggedIn'];

    await (shouldCloseCtiContact
      ? dispatch('cti/closeContact', null, { root: true })
      : null);

    dispatch('getContact', null);

    commit(UPDATE_LOADING_STATE, false);
  } catch (error) {
    commit('saveContactError', error);
  }

  commit(UPDATE_LOADING_STATE, false);
};
