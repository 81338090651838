<template>
  <div :class="[$style.container, { [$style.disabled]: !isAllGood }]">
    <div
      ref="scrollContainer"
      :class="[$style.innerContainer, { [$style.fixedHeight]: isAgent }]"
    >
      <div class="vl-u-align-center" v-if="isLoading">
        <vl-loader />
      </div>

      <div :class="$style.messages">
        <first-message v-if="isCitizen" />

        <message
          v-for="(message, index) in parsedMessages"
          :key="index"
          v-bind="message"
          :isFirstChild="messageIsFirstChild(message, index)"
        />

        <message v-if="isTyping" text user="THEM">
          <vl-loader />
        </message>

        <message v-if="isSending" text user="ME">
          <vl-loader />
        </message>
      </div>

      <vl-alert v-if="notification" :modWarning="true" icon="warning">
        {{ notification }}
      </vl-alert>

      <vl-alert v-if="errorMessage" :modError="true" icon="warning">
        {{ errorMessage }}
      </vl-alert>
    </div>

    <input-field
      v-model="currentMessage"
      @send="send"
      @isTyping="handleIsTyping"
      :disabled="!isAllGood"
      :userType="userType"
      ref="input"
    />
  </div>
</template>

<script>
import io from 'socket.io-client';
import { identity } from 'lodash';

import FirstMessage from './FirstMessage';
import InputField from './InputField';
import Message from './Message';

import { defaultErrorMessage } from '../config';
import {
  AGENT,
  BOT,
  CITIZEN,
  IS_TYPING,
  ME,
  TERMINATE,
  METADATA,
  THEM,
} from '../constants';
import { setUpEventHandlers } from '../lib';

export default {
  name: 'VlChatClient',

  socket: null,

  components: { FirstMessage, InputField, Message },

  props: {
    disabled: {
      default: false,
      type: Boolean,
      required: false,
    },

    greeting: {
      type: String,
      required: false,
    },

    ioUrl: {
      type: String,
      required: false,
      default: '/',
    },

    initialRoomId: {
      type: String,
      required: false,
    },

    userType: {
      type: String,
      required: false,
      default: CITIZEN,
    },

    userObjectId: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      currentMessage: '',
      errorMessage: null,
      hasAgentTerminated: false,
      hasSynced: false,
      isConnected: false,
      isLoading: true,
      isSending: false,
      isTerminated: false,
      isTyping: false,
      messages: [],
      placeInQueue: null,
      roomId: this.initialRoomId,
      transcriptId: null,
      users: [],
    };
  },

  computed: {
    citizen() {
      return this.users.find(({ type }) => type === CITIZEN);
    },

    isAgent() {
      return this.userType === AGENT;
    },

    isAllGood() {
      return this.isConnected && this.errorMessage == null && !this.disabled;
    },

    isCitizen() {
      return this.userType === CITIZEN;
    },

    notification() {
      if (this.isTerminated) {
        return this.hasAgentTerminated
          ? 'De chat is beëindigd.'
          : 'De burger heeft de chat beëindigd.';
      }

      if (this.users.length < 2) {
        return 'De verbinding werd verbroken. Wacht max 1 minuut, en sluit dan zonodig de chat.';
      }

      return null;
    },

    otherUserType() {
      return this.userType === AGENT ? CITIZEN : AGENT;
    },

    parsedMessages() {
      return this.messages.map(({ user, ...message }) => ({
        ...message,
        user: user.type === this.userType ? ME : user.type === BOT ? BOT : THEM,
      }));
    },

    placeNotification() {
      if (!this.placeInQueue) {
        return;
      }

      return this.placeInQueue === 1
        ? 'Er is 1 wachtende voor u.'
        : `Er zijn ${this.placeInQueue} wachtenden voor u.`;
    },
  },

  methods: {
    generateTranscript() {
      const toNiceDate = timestamp => new Date(timestamp).toLocaleString();

      const stringifyMessage = ({ text, timestamp, user }) =>
        `${toNiceDate(timestamp)}${
          this.userType === AGENT && user.type === CITIZEN
            ? `, ${user.referer}`
            : ''
        }\r\n${user.type}: ${text}`;

      const transcript = this.messages.reduce(
        (acc, message) => `${acc}${stringifyMessage(message)}\r\n\r\n`,
        '',
      );

      return this.isAgent
        ? transcript
        : `Transcript ID: ${this.transcriptId}\r\n\r\n${transcript}`;
    },

    handleIsTyping(isTyping) {
      this.$options.socket.emit(IS_TYPING, isTyping);
    },

    handleOnError(errorMessage) {
      this.isLoading = false;

      this.errorMessage = errorMessage || defaultErrorMessage;

      this.$emit('error', errorMessage);
    },

    messageIsFirstChild(message, index) {
      const prevMessage = this.parsedMessages[index - 1];

      return prevMessage == null || prevMessage.user !== message.user;
    },

    scrollToBottom() {
      this.$nextTick(() => {
        if (this.$refs.scrollContainer) {
          this.$refs.scrollContainer.scrollTop = this.$refs.scrollContainer.scrollHeight;
        }
      });
    },

    sendMetadata(metadata) {
      if (!metadata) {
        return;
      }

      this.$options.socket.emit(METADATA, metadata);
    },

    send(text = this.currentMessage) {
      if (!text) {
        return;
      }

      this.isSending = true;

      this.$options.socket.send(text, message => {
        this.messages.push(message);

        this.isSending = false;
      });

      this.handleIsTyping(false);

      this.currentMessage = '';
    },

    setCurrentMessage(text) {
      this.currentMessage = text;
    },

    appendToCurrentMessage(text) {
      this.currentMessage = [this.currentMessage, text]
        .filter(identity)
        .join(' ');
    },

    terminate({ transcriptId }) {
      this.transcriptId = transcriptId;

      this.isTerminated = true;

      this.hasAgentTerminated = true;

      this.$options.socket.emit(TERMINATE, { transcriptId });
    },

    userHasMessaged(userType = this.userType, userId = this.userObjectId) {
      return this.messages.reduce(
        (acc, { user }) =>
          acc || (user.type === userType && user.agentId === userId),
        false,
      );
    },
  },

  watch: {
    // todo: refactor
    // we listen to both hasSynced and greeting, due to a race condition between fetching the chat templates, and syncing the room
    hasSynced(newVal, oldVal) {
      if (
        newVal === true &&
        oldVal === false &&
        this.isAgent &&
        !this.userHasMessaged()
      ) {
        this.send(this.greeting);
      }
    },

    greeting(newVal, oldVal) {
      if (
        newVal != null &&
        oldVal == null &&
        this.isAgent &&
        (this.hasSynced && !this.userHasMessaged())
      ) {
        this.send(newVal);
      }
    },

    messages() {
      this.$store.commit('sidebar/chat/SYNC_MESSAGES', this.messages);

      this.scrollToBottom();
    },

    isConnected(value) {
      if (value) {
        this.$emit('connected');
      } else {
        this.$emit('disconnected');
      }
    },
  },

  mounted() {
    this.$options.socket = io(this.ioUrl);

    setUpEventHandlers.bind(this)();
  },

  beforeDestroy() {
    this.$options.socket.close();
  },
};
</script>

<style module>
.container {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
}

.container.disabled {
  opacity: 0.5;
}

.innerContainer {
  flex-grow: 1;
  overflow-y: auto;
}

.innerContainer.fixedHeight {
  max-height: 400px;
}

.messages {
  flex-grow: 1;
  padding: 16px !important;
}

textarea {
  display: block;
}
</style>
