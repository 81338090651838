<template>
  <Modal
    @closed="$emit('close')"
    :open="true"
    :closable="true"
    id="templates-modal"
    title="Selecteer een template"
    class="vl-email-templates is-large"
  >
    <VlAlert
      v-if="error"
      :mod-error="true"
      content="Error: Het template kon niet worden geladen."
    />

    <!-- Left / Right -->
    <div class="flex justify-between">
      <!-- Left -->
      <div class="vl-email-templates__left">
        <!-- Theme selection -->
        <div v-if="questionThemes.length > 1" class="mb-4">
          <h2 class="vl-title vl-title--h2">Thema</h2>
          <select
            class="vl-select"
            v-model="selectedThemeId"
            @change="changeTheme"
          >
            <option disabled="disabled">Thema</option>
            <option
              v-for="t in questionThemes"
              :key="t.id"
              :value="t.id"
              v-text="t.name"
            ></option>
          </select>
        </div>

        <!-- Results wrapper -->
        <div v-if="templates.length">
          <!-- Language selection -->
          <div class="flex justify-between items-center mb-4">
            <div class="vl-tabs__wrapper">
              <ul class="vl-tabs m-0" role="tablist">
                <li
                  class="vl-tab"
                  v-for="{ key, label } in filteredLanguages"
                  :class="{ 'vl-tab--active': selectedLanguage === key }"
                  :key="key"
                >
                  <a
                    @click.prevent="selectedLanguage = key"
                    class="vl-tab__link"
                    href
                    >{{ label }}</a
                  >
                </li>
              </ul>
            </div>
          </div>

          <!-- Search -->
          <div class="relative mb-4">
            <VlInputField v-model="searchQuery" class="w-full" />

            <VlIcon
              class="absolute pin-r"
              icon="search"
              :style="{ padding: '4px' }"
            />
          </div>

          <!-- Results -->
          <ul class="vl-email-templates__left__results">
            <li
              v-for="{ name, id } in filteredTemplates"
              :key="id"
              class="border-b"
            >
              <div class="flex items-center justify-between">
                <VlLink
                  href
                  @click.prevent="previewTemplateId = id"
                  class="p-4 block flex-grow"
                  >{{ name }}</VlLink
                >

                <div class="ml-4">
                  <VlButton
                    @click.prevent="selectTemplate(id)"
                    :mod-narrow="true"
                    :mod-disabled="isSelectingTemplateId != null"
                    :mod-loading="id === isSelectingTemplateId"
                    >Selecteer</VlButton
                  >
                </div>
              </div>
            </li>
          </ul>
        </div>

        <!-- No results message -->
        <div v-if="!isLoading && !templates.length">Geen resultaten</div>
      </div>

      <!-- Right -->
      <div class="vl-email-templates__right">
        <!-- Preview -->

        <div class="absolute pin" v-if="isLoading">
          <VlLoader />
        </div>

        <div v-else v-html="previewHtml" />
      </div>
    </div>
  </Modal>
</template>

<script>
import { allPass, get, propEq, toLower } from 'lodash/fp';
import { mapState, mapGetters } from 'vuex';
import { DUTCH } from '@/constants/languages';
import { languages } from '@/config';

import { crmAxios } from '@/utils/Axios';

const fetchTemplateHtml = id =>
  crmAxios({
    url: `/EmailTemplate/${id}`,
  });

export default {
  props: ['emailId'],

  data() {
    return {
      error: null,
      isLoading: false,
      isSelectingTemplateId: null,
      previewHtml: null,
      previewTemplateId: null,
      searchQuery: '',
      selectedLanguage: DUTCH,
      templates: [],
      selectedThemeId: null,
    };
  },

  created() {
    // Update the selected theme id to the first one found
    this.selectedThemeId = this.questionThemes.length
      ? this.questionThemes[0].id
      : null;

    // Load the templates
    this.loadTemplates();
  },

  computed: {
    ...mapState('contact', ['contact']),
    ...mapGetters('contact', ['themeId']),
    ...mapGetters('metadata', ['availableThemesByLineId', 'getThemeById']),

    // Themes in selected line
    themes() {
      return this.availableThemesByLineId(this.contact.lineId);
    },

    // Themes, filtered by the selected themes on question level
    questionThemes() {
      // Get an array of theme id's of all questions in the contact
      const questionThemeIds = this.contact.questions.map(
        question => question.themeId,
      );

      // Filter all themes from the line to contain only these from the questions in the contact
      return this.themes.filter(theme => {
        return questionThemeIds.indexOf(theme.id) > -1;
      });
    },

    filteredTemplates() {
      const isInSelectedLanguage = propEq('language', this.selectedLanguage);

      const containsSearchQuery = ({ name }) =>
        toLower(name).includes(toLower(this.searchQuery));

      const isHit = allPass([containsSearchQuery, isInSelectedLanguage]);

      return this.templates.filter(isHit);
    },

    filteredLanguages() {
      const hasTemplates = ({ key }) =>
        !!this.templates.find(({ language }) => language === key);

      return languages.filter(hasTemplates);
    },
  },

  methods: {
    async selectTemplate(id) {
      this.isSelectingTemplateId = id;

      try {
        const templateString =
          id === this.previewTemplateId
            ? this.previewHtml
            : await fetchTemplateHtml(id).then(get('data.body'));

        this.$store.dispatch(`emails/prepend`, {
          id: this.emailId,
          templateString,
        });

        this.$emit('close');
      } catch (error) {
        console.error(error);

        this.error = error;

        this.isSelectingTemplateId = null;
      }
    },

    loadTemplates() {
      // Update the loading state
      this.isLoading = true;

      // Use the theme from the select box and fall back to the theme of the contact
      const themeId = this.selectedThemeId || this.themeId;

      // Call the API
      crmAxios({
        url: `/EmailTemplate/theme/${themeId}`,
      })
        .then(({ data }) => {
          this.templates = data;

          const { id } = this.templates[0];

          this.previewTemplateId = id;

          this.isLoading = false;
        })
        .catch(error => {
          this.error = error;

          // Update the loading state
          this.isLoading = false;
        });
    },

    // Triggered when the theme selector changes
    changeTheme() {
      // Hide the current preview
      this.previewTemplateId = null;

      // Load the templates for the selected theme
      this.loadTemplates();
    },
  },

  watch: {
    previewTemplateId(id) {
      // Exit early if no id is set
      if (!id) {
        this.previewHtml = '';
        return;
      }

      fetchTemplateHtml(id).then(({ data }) => {
        this.previewHtml = data.body;
      });
    },
  },
};
</script>

<style>
.vl-email-templates .vl-modal-dialog {
  min-width: 960px;
  width: fit-content !important;
}

.vl-email-templates__left {
  flex: 1 0 380px;
  margin-right: 20px;
}

.vl-email-templates__right {
  position: relative;
  flex: 1 0 500px;
}

.vl-email-templates__left__results {
  max-height: 80vh;
  overflow-y: scroll;
}
</style>
