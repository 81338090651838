import { errorMessages } from '../config';

import {
  CONNECT,
  CONNECT_ERROR,
  DISCONNECT,
  ERROR,
  IS_TYPING,
  MESSAGE,
  METADATA,
  PLACE_IN_QUEUE,
  SERVER_ERROR,
  SYNC_ROOM,
  TERMINATE,
} from '../constants';

export default function() {
  const { socket } = this.$options;

  socket.on(CONNECT, () => {
    this.isConnected = true;
    this.errorMessage = null;
  });

  socket.on(CONNECT_ERROR, () => {
    this.handleOnError(errorMessages[CONNECT_ERROR]);
  });

  socket.on(DISCONNECT, () => {
    this.isConnected = false;
  });

  socket.on(ERROR, errorMessage => {
    this.handleOnError(errorMessage);
  });

  socket.on(IS_TYPING, isTyping => {
    this.isTyping = isTyping;
  });

  socket.on(MESSAGE, message => {
    this.messages.push(message);
  });

  socket.on(METADATA, metadata => {
    this.$emit('metadata', metadata);
  });

  socket.on(PLACE_IN_QUEUE, placeInQueue => {
    this.placeInQueue = placeInQueue;
  });

  socket.on(SERVER_ERROR, errorMessage => {
    this.handleOnError(errorMessage);
  });

  socket.on(SYNC_ROOM, ({ messages, users }) => {
    this.isLoading = false;

    this.messages = messages;
    this.users = users;

    if (this.isAgent) {
      this.$emit('syncCitizen', this.citizen);
    }

    this.hasSynced = true;
  });

  socket.on(TERMINATE, () => {
    this.$emit('terminate');

    this.isTerminated = true;

    socket.close();
  });
}
