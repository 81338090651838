// Validation for rijksregisternummer, based on https://bitbucket.org/vlaamseoverheid/vl-build/src/dev/package/vl-ui-form-validation/src/vue/vl-form-validation.js
export default (value) => {
  if (!value) return true;

  const pattern = /^((([0-9]{2})\.){2})([0-9]{2})[-][0-9]{3}\.[0-9]{2}$/gi;
  if (!pattern.test(value.toString())) {
    return false;
  }

  value = value.replace(/\D/g, '');
  const controlPart = parseInt(value.substr(9, 2), 10);

  let numberPart = value.substr(0, 9);

  // Check control number
  let calculatedControl = 97 - (numberPart % 97);

  if (calculatedControl == controlPart) {
    return true;
  }

  // Check not yet ok. We check for a possible 1900/2000 situation;
  numberPart = '2' + numberPart;
  calculatedControl = 97 - (numberPart % 97);

  return calculatedControl == controlPart;
};
