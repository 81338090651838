import internalTransfer from './internal-transfer';

const getInitialState = () => ({
  isDarkMode: false,
});

const mutations = {
  UPDATE_DARK_MODE(state, newVal) {
    state.isDarkMode = newVal;
  },
};

export default {
  namespaced: true,
  state: getInitialState(),
  mutations,
  modules: { internalTransfer },
};
