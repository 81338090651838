<template>
  <div class="p-4">
    <VlAlert
      v-if="aggregatedError"
      :content="aggregatedError.message"
      :mod-error="true"
    />

    <div v-else>
      <div class="flex items-center mb-4">
        <span class="uppercase font-bold"
          >Poging {{ isSecondAttempt ? '2' : '1' }}</span
        >
      </div>

      <div class="flex items-center mb-4">
        <span class="uppercase font-bold mr-4">Status:</span>

        <span>{{ readableInteractionState }}</span>
      </div>

      <div class="flex items-baseline justify-between mb-4">
        <VlInputField
          :disabled="true"
          :value="currentCallback.telephoneNumber"
        />

        <div class="flex items-center">
          <VlButton
            :title="isOnHold ? 'Burger terugnemen' : 'Wachtmuziek geven'"
            :mod-narrow="true"
            @click.prevent="handleOnToggle"
            :mod-disabled="isDisabled"
          >
            <CustomIcon :icon="isOnHold ? 'play' : 'pause'" />
          </VlButton>

          <VlButton
            title="Opleggen"
            class="divide ml-4"
            :mod-disabled="!hasInitialized || hasCalled"
            :mod-narrow="true"
            @click.prevent="handleOnEndInteraction"
          >
            <CustomIcon icon="stop" />
          </VlButton>
        </div>
      </div>

      <div class="flex items-center mb-4">
        <span class="uppercase font-bold mr-4"
          >Nummer waarmee de burger belde:</span
        >

        <span>{{ currentCallback.originatingTelephoneNumber }}</span>
      </div>

      <div class="flex items-center mb-4">
        <span class="uppercase font-bold mr-4">Aangemaakt op:</span>

        <span>{{ createdOn | niceDateAndTime }}</span>
      </div>

      <div class="mb-4">
        <div>Omschrijving eerste poging</div>

        <div class="flex">
          <select
            class="vl-select"
            :disabled="isSecondAttempt || isLoadingCallback"
            :value="firstCallback.description"
            @change="handleOnChangeDescription"
          >
            <option :value="null" :disabled="true">Selecteer een optie</option>

            <option
              v-for="{ key, label } in $options.descriptions"
              :key="key"
              :value="key"
              >{{ label }}</option
            >
          </select>

          <VlLoader class="ml-4" v-if="isLoadingCallback" />
        </div>
      </div>

      <div v-if="!isSecondAttempt" class="mb-4">
        <div>Tweede afspraak nodig?</div>

        <div class="flex">
          <select
            class="vl-select"
            :disabled="firstCallback.description !== 'Andere'"
            :value="firstCallback.status"
            @change="handleOnChangeStatus"
          >
            <option
              v-for="{ key, label } in $options.statuses"
              :key="key"
              :value="key"
              >{{ label }}</option
            >
          </select>

          <VlLoader class="ml-4" v-if="isLoadingCallback" />
        </div>
      </div>

      <div v-else class="mb-4">
        <div>Omschrijving tweede poging</div>

        <div class="flex">
          <select
            class="vl-select"
            :value="secondCallback.description"
            :disabled="isLoadingCallback"
            @change="handleOnChangeDescription"
          >
            <option :value="null" :disabled="true">Selecteer een optie</option>

            <option
              v-for="{ key, label } in $options.descriptions"
              :key="key"
              :value="key"
              >{{ label }}</option
            >
          </select>

          <VlLoader class="ml-4" v-if="isLoadingCallback" />
        </div>
      </div>

      <div>
        <div>Templates:</div>

        <div class="border p-4">
          <VlLoader v-if="isLoadingTemplates" />

          <div
            v-else-if="filteredTemplates.length > 0"
            class="mb-4"
            v-for="{ content, id, name } in filteredTemplates"
            :key="id"
          >
            <div class="font-bold">{{ name }}</div>

            <div>{{ content }}</div>
          </div>

          <VlAlert
            v-else
            :mod-warning="true"
            content="Geen templates beschikbaar"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { propEq } from 'lodash/fp';
import { mapActions, mapGetters } from 'vuex';

import { crmAxios } from '@/utils/Axios';
import {
  getLanguageKeyFromCtiInteraction,
  getReadableInteractionState,
} from '@/utils';
import { descriptions, statuses } from '@/config/recall';
import { updateCurrentCallback } from '@/helpers';

import {
  DIALING,
  EXTERNALLY_DISCONNECTED,
  HELD,
  INTERNALLY_DISCONNECTED,
  RINGING,
} from '@/constants/interaction-states';

export default {
  name: 'Recall',

  descriptions,

  statuses,

  props: ['data', 'isConnected'],

  data() {
    return {
      error: null,
      isLoadingCallback: false,
      isLoadingTemplates: false,
      templates: [],
    };
  },

  computed: {
    ...mapGetters('cti', ['isLoggedIn', 'parentInteractionId']),
    ...mapGetters('contact', ['callbacks', 'createdOn', 'themeId']),

    aggregatedError() {
      return this.error || this.computedError;
    },

    computedError() {
      if (!this.firstCallback) {
        return new Error('ERROR: No callbacks found on contact.');
      }

      if (!this.ctiInteractionLanguage) {
        return new Error(
          `ERROR: No language found in interaction workgroup: ${this.data.interactionWorkgroup}`,
        );
      }

      return null;
    },

    ctiInteractionLanguage() {
      return getLanguageKeyFromCtiInteraction(this.data);
    },

    ctiInteractionState() {
      return this.data.interactionState;
    },

    currentCallback() {
      return this.secondCallback || this.firstCallback;
    },

    firstCallback() {
      return this.getCallbackByAttempt('First');
    },

    secondCallback() {
      return this.getCallbackByAttempt('Second');
    },

    isCalling() {
      return [DIALING, RINGING].includes(this.ctiInteractionState);
    },

    isDisabled() {
      return this.isCalling || this.hasCalled || !this.isLoggedIn;
    },

    isOnHold() {
      return this.ctiInteractionState === HELD;
    },

    isSecondAttempt() {
      return this.secondCallback != null;
    },

    hasCalled() {
      return [EXTERNALLY_DISCONNECTED, INTERNALLY_DISCONNECTED].includes(
        this.ctiInteractionState,
      );
    },

    hasInitialized() {
      return this.hasCalled || !!this.ctiInteractionState;
    },

    readableInteractionState() {
      return this.data.interactionCallDirection === 'O'
        ? getReadableInteractionState(this.ctiInteractionState)
        : 'Gelieve even te wachten.';
    },

    filteredTemplates() {
      return this.templates.filter(
        ({ attempt, language }) =>
          language === this.ctiInteractionLanguage &&
          attempt === this.currentCallback.attempt,
      );
    },
  },

  async created() {
    if (this.firstCallback) {
      this.descriptionFirstAttempt = this.firstCallback.description;
    }

    this.fetchTemplates();
  },

  methods: {
    ...mapActions('cti', [
      'acceptInteraction',
      'endInteraction',
      'pauseInteraction',
    ]),

    getCallbackByAttempt(attempt) {
      return (this.callbacks || []).find(propEq('attempt', attempt));
    },

    async fetchTemplates() {
      this.isLoadingTemplates = true;
      try {
        const { data: templates } = await crmAxios({
          url: `/Template/callback/theme/${this.themeId}?language=${this.ctiInteractionLanguage}`,
        });
        this.templates = templates;
      } catch (error) {
        console.error(error);
        this.error = error;
      }
      this.isLoadingTemplates = false;
    },

    async handleOnChange(data) {
      this.isLoadingCallback = true;

      const sanitizedData = this.isSecondAttempt
        ? { ...data, status: 'NietMeerTerugBellen' }
        : data;

      try {
        await updateCurrentCallback(sanitizedData);

        await this.$store.dispatch('contact/sync');
      } catch (error) {
        this.error = error;
      }

      this.isLoadingCallback = false;
    },

    handleOnChangeDescription({ target: { value: description } }) {
      const { defaultStatus: status = this.status } = descriptions.find(
        propEq('key', description),
      );

      const { telephoneNumber } = this.currentCallback;

      this.handleOnChange({
        description,
        status,
        telephoneNumber,
      });
    },

    handleOnChangeStatus({ target: { value: status } }) {
      const { telephoneNumber } = this.currentCallback;

      this.handleOnChange({
        description: this.currentCallback.description,
        status,
        telephoneNumber,
      });
    },

    handleOnEndInteraction() {
      this.endInteraction(this.data.interactionId);
    },

    handleOnToggle() {
      const { interactionId } = this.data;

      this.isConnected
        ? this.pauseInteraction(interactionId)
        : this.acceptInteraction(interactionId);
    },
  },

  watch: {
    isConnected(newVal) {
      if (newVal) {
        this.$emit('start');
      } else {
        this.$emit('stop');
      }
    },
  },
};
</script>
