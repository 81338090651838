<template>
  <div class="primary-header">
    <div class="primary-header__brand">
      <span class="primary-header__brand__umbrella">
        <div aria-hidden="true" class="primary-header__brand__umbrella__logo">
          <FlandersLogo class="primary-header__brand__umbrella__logo__icon" />

          <p v-if="title" class="primary-header__brand__umbrella__logo__label">
            {{ title }}
          </p>
        </div>
      </span>

      <!-- Left slot -->
      <slot name="left" />
    </div>

    <!-- Right slot -->
    <div class="flex items-center">
      <slot name="right" />
    </div>
  </div>
</template>

<script>
// Import modules
import FlandersLogo from './FlandersLogo';

// Export component
export default {
  components: {
    FlandersLogo,
  },
  props: {
    title: {
      type: String,
      required: false,
      default: 'Vlaanderen',
    },
  },
};
</script>

<style lang="scss">
// Bar
.primary-header {
  padding-right: 2rem;
  position: relative;
  z-index: 10003;
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  width: 100%;
  height: 43px;
  border-bottom: 1px solid #ccd1d9;
}

// Brand
.primary-header__brand {
  display: flex;
  flex: 1;
  align-items: center;
  height: 100%;
}

// Umbrella
.primary-header__brand__umbrella {
  display: flex;
  height: 100%;
  text-decoration: none;
}

// Logo
.primary-header__brand__umbrella__logo {
  position: relative;
  display: flex;
  align-items: center;
  padding-left: 2px;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 28px;
    height: 100%;
    background: #fbe000;
    transition: background 0.1s ease-in-out;
  }

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 28px;
    width: 0;
    height: 0;
    border-top: 43px solid transparent;
    border-bottom: 0 solid transparent;
    border-left: 13.5px solid #fbe000;
    transition: border-color 0.1s ease-in-out;
  }
}

// Icon
.primary-header__brand__umbrella__logo__icon {
  width: 31px;
  height: 31px;
  margin-right: 17px;
  fill: #333332;
  position: relative;
  z-index: 2;
  text-decoration: none;
  font-weight: 500;
  color: inherit;
}

// Label
.primary-header__brand__umbrella__logo__label {
  font-size: 18px;
  line-height: 1;
  margin-right: 20px;
  position: relative;
  z-index: 2;
  text-decoration: none;
  font-weight: 500;
  color: inherit;
}
</style>
