<template>
  <form class="vlw__cti-endregistration-form" @submit.prevent="handleSubmit">
    <!-- Postal code -->
    <FormField
      v-if="showPostalCode"
      :label="labelByApiName('completionInfo_PostalCode') || 'Postcode'"
      class="mb-8"
    >
      <VlInputField
        type="number"
        oninput="this.value=this.value.slice(0,this.maxLength)"
        maxlength="4"
        v-model="completionInfo_PostalCode"
        :required="isPostalCodeRequired"
      />
    </FormField>

    <!-- Country -->
    <FormField
      v-if="showCountry"
      :label="labelByApiName('completionInfo_Country') || 'Land'"
      class="mb-8"
    >
      <select class="vl-select" v-model="completionInfo_Country" required>
        <option disabled="disabled">Land</option>
        <option
          v-for="c in $options.countries"
          :key="c.key"
          :value="c.key"
          v-text="c.name"
        ></option>
      </select>
    </FormField>

    <!-- Language -->
    <FormField
      v-if="showLanguage"
      :label="labelByApiName('completionInfo_Language') || 'Taal'"
      class="mb-8"
    >
      <select class="vl-select" v-model="completionInfo_Language" required>
        <option disabled="disabled">Taal</option>
        <option
          v-for="l in $options.languages"
          :key="l.key"
          :value="l.key"
          v-text="l.name"
        ></option>
      </select>
    </FormField>

    <!-- Gender -->
    <FormField
      v-if="showGender"
      :label="labelByApiName('completionInfo_Gender') || 'Geslacht'"
      class="mb-8"
    >
      <select class="vl-select" v-model="completionInfo_Gender" required>
        <option disabled="disabled">Geslacht</option>
        <option
          v-for="g in $options.genders"
          :key="g.key"
          :value="g.key"
          v-text="g.name"
        ></option>
      </select>
    </FormField>

    <!-- Subject -->
    <FormField
      v-if="showSubject"
      :label="labelByApiName('completionInfo_Subject') || 'Onderwerp'"
      class="mb-8"
    >
      <div
        v-for="{ key, name } in $options.availableSubjects"
        :key="key"
        class="mb-4"
      >
        <input
          type="checkbox"
          :id="key"
          :value="key"
          v-model="completionInfo_Subject"
        />
        <label class="ml-4" :for="key">{{ name }}</label>
      </div>
    </FormField>

    <!-- Who is calling -->
    <FormField
      v-if="showWhoIsCalling"
      :label="labelByApiName('completionInfo_WhoIsCalling') || 'Wie Belt'"
      class="mb-8"
    >
      <select class="vl-select" v-model="completionInfo_WhoIsCalling" required>
        <option disabled="disabled">Wie belt</option>
        <option
          v-for="w in $options.whoIsCallingOptions"
          :key="w.key"
          :value="w.key"
          v-text="w.name"
        ></option>
      </select>
    </FormField>

    <!-- Child birth date contact.isChildBirthDateRequired -->
    <FormField
      v-if="isChildBirthDateRequired"
      :label="labelBirthdate"
      class="mb-8"
    >
      <Datepicker
        :defaultValue="completionInfo_ChildBirthdate"
        v-model="completionInfo_ChildBirthdate"
        required
      />
    </FormField>

    <FormField
      v-if="showScreenSharing"
      :label="
        labelByApiName('completionInfo_ScreenSharing') ||
          'Is er schermdelen gebruikt?'
      "
      class="mb-8"
    >
      <label class="flex">
        <input
          type="radio"
          value="Yes"
          v-model="completionInfo_ScreenSharing"
          name="completionInfo_ScreenSharing"
          :required="isScreenSharingRequired"
        />
        <span class="ml-4">Ja</span>
      </label>

      <label class="flex">
        <input
          type="radio"
          value="No"
          v-model="completionInfo_ScreenSharing"
          name="completionInfo_ScreenSharing"
          :required="isScreenSharingRequired"
        />
        <span class="ml-4">Nee</span>
      </label>
    </FormField>

    <FormField
      v-if="showTwoOptions1"
      :label="
        labelByApiName('completionInfo_TwoOptions_1') || 'Ja of Nee veld 1?'
      "
      class="mb-8"
    >
      <label class="flex">
        <input
          type="radio"
          value="Yes"
          v-model="completionInfo_TwoOptions_1"
          name="completionInfo_TwoOptions_1"
          :required="isTwoOptions_1_Required"
        />
        <span class="ml-4">Ja</span>
      </label>

      <label class="flex">
        <input
          type="radio"
          value="No"
          v-model="completionInfo_TwoOptions_1"
          name="completionInfo_TwoOptions_1"
          :required="isTwoOptions_1_Required"
        />
        <span class="ml-4">Nee</span>
      </label>
    </FormField>

    <FormField
      v-if="showTwoOptions2"
      :label="
        labelByApiName('completionInfo_TwoOptions_2') || 'Ja of Nee veld 2?'
      "
      class="mb-8"
    >
      <label class="flex">
        <input
          type="radio"
          value="Yes"
          v-model="completionInfo_TwoOptions_2"
          name="completionInfo_TwoOptions_2"
          :required="isTwoOptions_2_Required"
        />
        <span class="ml-4">Ja</span>
      </label>

      <label class="flex">
        <input
          type="radio"
          value="No"
          v-model="completionInfo_TwoOptions_2"
          name="completionInfo_TwoOptions_2"
          :required="isTwoOptions_2_Required"
        />
        <span class="ml-4">Nee</span>
      </label>
    </FormField>

    <FormField
      v-if="showMultiline1"
      :label="labelByApiName('completionInfo_Multiline_1') || 'Tekstveld 1'"
      class="mb-8"
    >
      <VlInputField
        type="text"
        maxlength="100"
        v-model="completionInfo_Multiline_1"
        :required="isMultiline_1_Required"
      />
    </FormField>

    <FormField
      v-if="showMultiline2"
      :label="labelByApiName('completionInfo_Multiline_2') || 'Tekstveld 2'"
      class="mb-8"
    >
      <VlInputField
        type="text"
        maxlength="100"
        v-model="completionInfo_Multiline_2"
        :required="isMultiline_2_Required"
      />
    </FormField>

    <!-- Close button -->
    <div class="flex items-center">
      <VlButton type="submit" :mod-disabled="isLoading">Bewaren</VlButton>

      <VlLoader class="ml-4" v-if="isLoading" />
    </div>
  </form>
</template>

<script>
import { mapGetters } from 'vuex';
import { propEq } from 'lodash/fp';

import {
  countries,
  languages,
  genders,
  subjects as availableSubjects,
  whoIsCalling as whoIsCallingOptions,
} from '@/config';

export default {
  countries,
  languages,
  genders,
  availableSubjects,
  whoIsCallingOptions,

  props: ['contact', 'defaults', 'isLoading'],

  data() {
    return this.getDefaultValues();
  },

  computed: {
    ...mapGetters('contact', [
      'requiredEndRegistrationFields',
      'requiredEndRegistrationFieldsByApiName',
    ]),

    isPostalCodeRequired() {
      return this.getIsFieldRequired('completionInfo_PostalCode');
    },

    isCountryRequired() {
      return this.getIsFieldRequired('completionInfo_Country');
    },

    isLanguageRequired() {
      return this.getIsFieldRequired('completionInfo_Language');
    },

    isGenderRequired() {
      return this.getIsFieldRequired('completionInfo_Gender');
    },

    isSubjectRequired() {
      return this.getIsFieldRequired('completionInfo_Subject');
    },

    isWhoIsCallingRequired() {
      return this.getIsFieldRequired('completionInfo_WhoIsCalling');
    },

    isChildBirthDateRequired() {
      return this.completionInfo_WhoIsCalling === 'Pregnant';
    },

    isScreenSharingRequired() {
      return this.getIsFieldRequired('completionInfo_ScreenSharing');
    },

    isMultiline_1_Required() {
      return this.getIsFieldRequired('completionInfo_Multiline_1');
    },

    isMultiline_2_Required() {
      return this.getIsFieldRequired('completionInfo_Multiline_2');
    },

    isTwoOptions_1_Required() {
      return this.getIsFieldRequired('completionInfo_TwoOptions_1');
    },

    isTwoOptions_2_Required() {
      return this.getIsFieldRequired('completionInfo_TwoOptions_2');
    },

    showPostalCode() {
      return this.requiredEndRegistrationFieldsByApiName.includes(
        'completionInfo_PostalCode',
      );
    },

    showCountry() {
      return this.requiredEndRegistrationFieldsByApiName.includes(
        'completionInfo_Country',
      );
    },

    showLanguage() {
      return this.requiredEndRegistrationFieldsByApiName.includes(
        'completionInfo_Language',
      );
    },

    showGender() {
      return this.requiredEndRegistrationFieldsByApiName.includes(
        'completionInfo_Gender',
      );
    },

    showSubject() {
      return this.requiredEndRegistrationFieldsByApiName.includes(
        'completionInfo_Subject',
      );
    },

    showWhoIsCalling() {
      return this.requiredEndRegistrationFieldsByApiName.includes(
        'completionInfo_WhoIsCalling',
      );
    },

    showScreenSharing() {
      return this.requiredEndRegistrationFieldsByApiName.includes(
        'completionInfo_ScreenSharing',
      );
    },

    showMultiline1() {
      return this.requiredEndRegistrationFieldsByApiName.includes(
        'completionInfo_Multiline_1',
      );
    },

    showMultiline2() {
      return this.requiredEndRegistrationFieldsByApiName.includes(
        'completionInfo_Multiline_2',
      );
    },

    showTwoOptions1() {
      return this.requiredEndRegistrationFieldsByApiName.includes(
        'completionInfo_TwoOptions_1',
      );
    },

    showTwoOptions2() {
      return this.requiredEndRegistrationFieldsByApiName.includes(
        'completionInfo_TwoOptions_2',
      );
    },
  },

  methods: {
    labelByApiName(apiName) {
      const fieldObj = this.requiredEndRegistrationFields.find(
        propEq('apiName', apiName),
      );

      return fieldObj ? fieldObj.name : undefined;
    },

    getIsFieldRequired(apiName) {
      const fieldObj = this.requiredEndRegistrationFields.find(
        propEq('apiName', apiName),
      );
      return fieldObj ? fieldObj.required : false;
    },

    getDefaultValues() {
      const keys = [
        'completionInfo_PostalCode',
        'completionInfo_Country',
        'completionInfo_Language',
        'completionInfo_Gender',
        'completionInfo_Subject',
        'completionInfo_WhoIsCalling',
        'completionInfo_ChildBirthdate',
        'completionInfo_ScreenSharing',
        'completionInfo_Multiline_1',
        'completionInfo_Multiline_2',
        'completionInfo_TwoOptions_1',
        'completionInfo_TwoOptions_2',
      ];

      return keys.reduce(
        (acc, key) => ({
          ...acc,
          [key]: this.contact[key] || this.defaults[key],
        }),
        {},
      );
    },

    handleSubmit() {
      this.$emit('submit', this.$data);
    },
  },
};
</script>
