import bus from '@/utils/bus';
import { toLower } from 'lodash';
import { values } from 'lodash/fp';
import { interactions } from '@/config';
import { TELETOLK } from '@/constants';
import store from '@/store';

export default (workgroup = '') => {
  if (!workgroup) {
    console.error('Interaction has no workgroup.');
    return null;
  }

  const workgroupHasKey = key => toLower(workgroup).includes(toLower(key));

  // hacky. refactor. or ask Joop to stick to a naming convention.
  const interaction = workgroupHasKey(TELETOLK)
    ? interactions[TELETOLK]
    : values(interactions).find(({ ctiKey }) => workgroupHasKey(ctiKey));

  if (interaction == null) {
    const getLabelByName = store.getters['metadata/getLabelByName'];

    const label = getLabelByName('workgroup_not_found');

    const body = label.body.replace('${workgroup}', workgroup);

    bus.$emit('show-modal', {
      message: label.title,
      body,
    });

    throw new Error(body);
  }

  return interaction;
};
