// Create class
import formatDate from 'date-fns/format';
import differenceInDays from 'date-fns/differenceInDays';

class Annotation {
  constructor(obj) {
    // Default the obj
    obj = obj || {};

    // CRM ID
    this.crmId = obj.crmId || null;

    // UI loading state
    this.loading = false;

    // Set properties
    this.id = obj.id || null;

    // Default the fileName
    this.fileName = obj.fileName || '';

    this.fileSize = obj.fileSize || '';

    this.mimeType = obj.mimeType || '';

    this.imageDataURL = obj.imageDataURL;

    // Parse updated date
    this.updatedOn =
      typeof obj.updated === 'string' ? new Date(obj.updated) : null;

    // Parse created date
    this.createdOn =
      typeof obj.created === 'string' ? new Date(obj.created) : null;
  }

  // Helper function to clone an instance of this class
  clone() {
    return new Annotation(this);
  }

  // Getter for updated
  get updated() {
    // Exit early if updated date is unknown
    if (!this.updatedOn) {
      return '';
    }

    // Return a formatted date
    return formatDate(this.updatedOn, 'dd/MM/yyyy HH:mm:ss');
  }

  // Getter for created
  get created() {
    // Exit early if updated date is unknown
    if (!this.createdOn) {
      return '';
    }

    // Return a formatted date
    return formatDate(this.createdOn, 'dd/MM/yyyy HH:mm:ss');
  }

  // Getter for last updated in days
  get lastUpdatedInDays() {
    // Exit early if updated date is unknown
    if (!this.updatedOn) {
      return false;
    }

    return differenceInDays(new Date(), this.updatedOn);
  }

  // Getter for last created in days
  get lastCreatedInDays() {
    // Exit early if updated date is unknown
    if (!this.createdOn) {
      return false;
    }

    return differenceInDays(new Date(), this.createdOn);
  }

  // Helper function to retrieve an object to sync to the API
  toApiObject() {
    return {
      id: this.crmId,
      fileName: this.fileName || '',
    };
  }
}

// Export class
export default Annotation;
