<template>
  <div :class="$style.container">
    <span class="font-bold uppercase mr-4">{{ label }}:</span>

    <slot />
  </div>
</template>

<script>
export default {
  name: 'FormField',

  props: ['label'],
};
</script>

<style module>
.container {
  align-items: center;
  display: flex;
  flex-grow: 1;
  margin-bottom: 1rem;
}

.container input {
  flex-grow: 1;
  margin-left: 1rem;
}
</style>
