import channels from './channels';

import {
  ACW,
  INCOMING_CHAT,
  MANUAL_EMAIL_BACKOFFICE,
  MANUAL_OUTGOING_CALL_BACKOFFICE,
  OUTGOING_CALL,
  OUTGOING_CALL_BACKOFFICE,
  OUTGOING_EMAIL,
  RECALL,
  TELETOLK,
  SCREENSHARING,
} from '@/constants';

import EmailBackOffice from '@/components/sidebar/EmailBackOffice';
import IncomingChat from '@/components/sidebar/chat/IncomingChat';
import OutgoingCallBackOffice from '@/components/sidebar/OutgoingCallBackOffice';
import OutgoingEmail from '@/components/sidebar/email/OutgoingEmail';
import Recall from '@/components/sidebar/Recall';
import Cobrowse from '@/components/sidebar/Cobrowse';

export default {
  ...channels,

  [ACW]: {
    key: ACW,
    name: 'Naverwerkingstijd',
  },

  [MANUAL_EMAIL_BACKOFFICE]: {
    key: MANUAL_EMAIL_BACKOFFICE,
    name: 'Manueel doorsturen',
    sidebarComponent: EmailBackOffice,
  },

  [MANUAL_OUTGOING_CALL_BACKOFFICE]: {
    key: MANUAL_OUTGOING_CALL_BACKOFFICE,
    name: 'Manueel doorschakelen',
    sidebarComponent: OutgoingCallBackOffice,
    iconName: 'phone-outgoing',
  },

  [OUTGOING_CALL_BACKOFFICE]: {
    key: OUTGOING_CALL_BACKOFFICE,
    name: 'Doorschakelen',
    sidebarComponent: OutgoingCallBackOffice,
    iconName: 'phone-outgoing',
  },

  [OUTGOING_EMAIL]: {
    key: OUTGOING_EMAIL,
    name: 'Uitgaande e-mail',
    sidebarComponent: OutgoingEmail,
    iconName: 'email',
  },

  [TELETOLK]: {
    channel: INCOMING_CHAT,
    key: TELETOLK,
    name: 'Teletolk',
    sidebarComponent: IncomingChat,
  },

  [RECALL]: {
    channel: OUTGOING_CALL,
    key: RECALL,
    name: 'Terugbelafspraak',
    sidebarComponent: Recall,
  },

  [SCREENSHARING]: {
    key: SCREENSHARING,
    name: 'Screensharing',
    sidebarComponent: Cobrowse,
    iconName: 'computer-screen',
  },
};
