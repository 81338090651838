<template>
  <div class="vl-pager">
    <ul class="vl-pager__list">
      <li class="vl-pager__element">
        <span class="vl-u-visually-hidden">Resultaat</span>
        <strong>{{ from }} - {{ to }}</strong> van {{ total }}
      </li>
      <li class="vl-pager__element">
        <a
          @click.prevent="$emit('previous')"
          href="#"
          class="vl-pager__element__cta"
        >
          <VlIcon icon="arrow-left-fat" />vorige
        </a>
      </li>
      <li class="vl-pager__element">
        <a
          @click.prevent="$emit('next')"
          href="#"
          class="vl-pager__element__cta"
          >volgende
          <VlIcon icon="arrow-right-fat" />
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'Pager',
  props: {
    from: {
      type: Number,
      required: true,
      default: 0,
    },
    to: {
      type: Number,
      required: true,
      default: 0,
    },
    total: {
      type: Number,
      required: false,
      default: 0,
    },
  },
};
</script>

<style lang="scss">
.vl-pager {
}

.vl-pager__element a {
  color: #999;

  .vl-icon {
    font-size: 12px;
    display: block;
    margin: 6px 3px 0 3px;
  }
}
</style>
