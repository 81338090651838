<template>
  <!-- Modal wrapper -->
  <Modal
    @closed="$emit('close')"
    id="modal-feedback"
    class="vlw__feedback"
    title="Feedback"
    :open="true"
    :closable="true"
  >
    <form @submit.prevent="handleOnSubmit">
      <div class="mb-8">
        <div class="flex mb-4">
          <div class="font-bold mr-4">Issue:</div>

          <select
            class="vl-select"
            v-model="formData.technicalIssueTypeId"
            required
          >
            <option disabled :value="null">Selecteer een optie</option>
            <option
              v-for="{ id, name } in technicalIssueTypes"
              :key="id"
              :value="id"
              >{{ name }}</option
            >
          </select>
        </div>

        <div class="flex mb-4">
          <div class="font-bold mr-4">Details:</div>

          <textarea
            class="vl-textarea w-full"
            v-model="formData.technicalIssueDetails"
          />
        </div>
      </div>

      <div class="flex justify-end mb-4">
        <VlButton :mod-narrow="true" :mod-loading="isLoading" type="submit"
          >Verzenden</VlButton
        >
      </div>

      <VlAlert v-if="error" :mod-error="true" :content="error.message" />
    </form>
  </Modal>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'Feedback',

  data() {
    return {
      error: null,
      formData: {
        technicalIssueDetails: '',
        technicalIssueTypeId: null,
      },
      isLoading: false,
    };
  },

  computed: {
    ...mapState('metadata', ['technicalIssueTypes']),
  },

  methods: {
    handleOnSubmit() {
      this.isLoading = true;

      this.$store
        .dispatch('contact/updateTechnicalIssue', this.formData)
        .then(() => {
          this.isLoading = false;

          this.$emit('close');
        })
        .catch(error => {
          this.isLoading = false;

          this.error = error;
        });
    },
  },
};
</script>

<style lang="scss">
.vlw__feedback {
  .vl-modal-dialog {
    width: auto;
  }
}
</style>
