// Create class
class Citizen {
  constructor(obj) {
    // Default the obj
    obj = obj || {};

    // Set properties
    this.id = obj.id || null;

    this.firstName = obj.firstName || '';
    this.lastName = obj.lastName || '';

    this.street = obj.street || '';
    this.houseNumber = obj.houseNumber || '';
    this.box = obj.box || '';
    this.postalCode = obj.postalCode || '';
    this.city = obj.city || '';
    this.country = obj.country || '';

    this.emailAddress = obj.emailAddress || '';
    this.telephone = obj.telephone || '';
    this.mobilePhone = obj.mobilePhone || '';

    this.birthdate = obj.birthdate || null;
    this.organization = obj.organization || '';
    this.isQuickSelect = obj.isQuickSelect || '';

    if (obj.miragePersoonId) {
      Object.keys(obj).forEach(key => {
        this[key] = obj.keys;
      });
    }
  }

  // Helper to check if only the id is set (used to use another CRM API endpoint in case we're searching for a specific user using it's ID)
  get onlyIdSet() {
    const propNames = Object.keys(this).filter(propName => propName !== 'id');
    const isPropSet = !!propNames.find(propName => !!this[propName]);
    return !!(this.id && !isPropSet);
  }

  // Helper function to clone an instance of this class
  clone() {
    return new Citizen(this);
  }
}

// Export class
export default Citizen;
