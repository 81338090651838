import bus from '@/utils/bus';
import { get } from 'lodash';
import { UPDATE_LOADING_STATE } from '@/constants/mutation-types';
import { DISCONNECT_SUCCESS } from '@/constants';

export default commit => error => {
  commit(UPDATE_LOADING_STATE, false);

  const body = get(error, 'response.data.message') || error.message;

  // the cti returns an error on a successful disconnect call (?!)
  // this is the only way to detect it
  // @todo: refactor once the cti has been updated
  if (body === 'The telephony command was canceled.') {
    return DISCONNECT_SUCCESS;
  }

  // https://github.com/axios/axios#handling-errors

  if (error.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    console.log(error.response.data);
    console.log(error.response.status);
    console.log(error.response.headers);
  } else if (error.request) {
    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
    // http.ClientRequest in node.js
    console.log(error.request);
  } else {
    // Something happened in setting up the request that triggered an Error
    console.log('Error', error.message);
  }

  console.log(error.config);

  // Emit an open modal event, add pass message as payload
  bus.$emit('show-modal', {
    message: 'CTI Error',
    body,
  });
};
