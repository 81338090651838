import {
  ALERTING,
  CONNECTED,
  HELD,
  MESSAGING,
  OFFERING,
  PARKED,
  DIALING,
  INTERNALLY_DISCONNECTED,
  EXTERNALLY_DISCONNECTED,
  RINGING,
} from '@/constants/interaction-states';

export default {
  [ALERTING]: 'Telefoon rinkelt', // todo: rewrite. (n/a in case of chat of mail)
  [CONNECTED]: 'Verbonden',
  [HELD]: 'Wachtmuziek',
  [MESSAGING]: 'Bericht Voicemail',
  [OFFERING]: 'Aangeboden',
  [PARKED]: 'Geparkeerd',
  [DIALING]: 'Telefoon maakt verbinding',
  [RINGING]: 'Telefoon gaat over',
  [INTERNALLY_DISCONNECTED]: 'Agent afgelegd',
  [EXTERNALLY_DISCONNECTED]: 'Burger afgelegd',
};
