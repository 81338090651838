import axios from 'axios';

const { VUE_APP_CTI_URL } = process.env;

const api = axios.create({
  baseURL: `${VUE_APP_CTI_URL}/cti`,
  withCredentials: true,
});

// cti always returns a 200 satus code. manually check for errors and throw.
api.interceptors.response.use(response => {
  const { data } = response;

  if (data && data.errorId) {
    throw new Error(data.message || data.errorId);
  }

  return response;
});

export default api;
