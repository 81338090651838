// https://help.genesys.com/cic/mergedprojects/wh_tr/desktop/pdfs/attributes_tr.pdf
// page 61

export const ALERTING = 'A';
export const CONNECTED = 'C';
export const HELD = 'H';
export const MESSAGING = 'M';
export const OFFERING = 'O';
export const PARKED = 'P';
export const RINGING = 'R';
export const SUSPENDED = 'X';
export const DIALING = 'S';
export const INTERNALLY_DISCONNECTED = 'I';
export const EXTERNALLY_DISCONNECTED = 'E';
export const UNASSIGNED = 'U'; // custom state
