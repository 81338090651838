import { overEvery, overSome, identity, negate } from 'lodash/fp';

const hasNoQuestionsAndIsNotClosed = (
  state,
  { hasClosedStatus, hasQuestion },
) => !hasQuestion && !hasClosedStatus;

const isRecall = (state, getters, rootState, rootGetters) =>
  rootGetters['cti/isRecall'];

const allCallbacksHaveStatuses = (state, { callbacks = [] }) =>
  callbacks.every(({ status, description }) =>
    [status, description].every(value => value != null),
  );

const validations = [
  {
    predicate: (state, { isLoading }) => !isLoading,
    name: 'contact_update_pending',
  },
  {
    predicate: (state, { contactId }) => identity(contactId),
    name: 'contact_not_active',
  },
  {
    predicate: (state, { channelIsChat }, rootState, rootGetters) =>
      !(channelIsChat && rootGetters['cti/isParentInteractionConnected']),
    name: 'contact_close_chat',
  },
  {
    predicate: overSome([
      overEvery([allCallbacksHaveStatuses, isRecall]),
      negate(hasNoQuestionsAndIsNotClosed),
    ]),
    name: 'contact_closing_not_possible_when_status_is_not_closed',
  },
  {
    predicate: (state, { allQuestionsAreValid }) => allQuestionsAreValid,
    name: 'contact_closing_not_possible_when_questions_are_not_complete',
  },
  {
    predicate: (
      { hasSavedEndRegistration },
      { hasQuestion, requiredEndRegistrationFields },
    ) =>
      hasSavedEndRegistration ||
      requiredEndRegistrationFields.length === 0 ||
      !hasQuestion,
    name: 'contact_closing_not_possible_when_missing_final_registration',
  },
  {
    predicate: (state, { hasDraftEmails }) => !hasDraftEmails,
    name: 'contact_closing_not_possible_when_emails_in_draft',
  },
  {
    predicate: (state, { hasActiveScreensharingSessions }) =>
      !hasActiveScreensharingSessions,
    name: 'contact_closing_not_possible_when_screensharingsession_active',
  },
];

export default (state, getters, rootState, rootGetters) => {
  const getLabelByName = rootGetters['metadata/getLabelByName'];

  return validations.reduce((acc, { predicate, name }) => {
    if (acc || predicate(state, getters, rootState, rootGetters)) {
      return acc;
    }

    const label = getLabelByName(name);

    return label.body;
  }, null);
};
