import { ASSIGN } from '@/constants/action-types';

import { crmAxios } from '@/utils/Axios';

import { makeAsyncMutationTypes } from '@/utils';

const { FAILURE, REQUEST, SUCCESS } = makeAsyncMutationTypes(ASSIGN);

export default ({ commit, getters }, contactId = getters.contactId) => {
  commit(REQUEST);

  return crmAxios({
    method: 'post',
    url: `/Contact/${contactId}/assign`,
  })
    .then(() => {
      commit(SUCCESS);
    })
    .catch(error => {
      commit(FAILURE, error);
    });
};
