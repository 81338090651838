import { FETCH } from '@/constants/action-types';
import { makeAsyncMutationTypes } from '@/utils';
import { crmAxios } from '@/utils/Axios';

const { FAILURE, REQUEST, SUCCESS } = makeAsyncMutationTypes(FETCH);

export default {
  fetch({ commit }) {
    commit(REQUEST);

    return crmAxios({
      url: `/User/workgroups`,
    })
      .then(({ data }) => commit(SUCCESS, data))
      .catch(error => commit(FAILURE, error));
  },
};
