// Create class
import formatDate from 'date-fns/format';
import differenceInDays from 'date-fns/differenceInDays';
import _ from 'lodash';

class Answer {
  constructor(obj) {
    // Default the obj
    obj = obj || {};

    // CRM ID
    this.crmId = obj.crmId || null;

    // UI loading state
    this.loading = false;

    // Set properties
    this.id = obj.id || null;
    this.category = obj.subType || obj.category || null;

    // Escalation properties
    this.isEscalated = obj.isEscalated || false;

    // Allowed to transfer by phone
    let telephoneAllowTransfer = null;

    // From CRM
    if (typeof obj.isPhoneEscalationAllowed === 'boolean') {
      telephoneAllowTransfer = obj.isPhoneEscalationAllowed;
    }

    // From VLS
    if (typeof obj.telephone_allow_transfer === 'boolean') {
      telephoneAllowTransfer = obj.telephone_allow_transfer;
    }

    this.telephoneAllowTransfer = telephoneAllowTransfer;

    // Allowed to transfer by email
    let emailAllowTransfer = null;

    // From CRM
    if (typeof obj.isEmailEscalationAllowed === 'boolean') {
      emailAllowTransfer = obj.isEmailEscalationAllowed;
    }

    // From VLS
    if (typeof obj.email_allow_transfer === 'boolean') {
      emailAllowTransfer = obj.email_allow_transfer;
    }

    this.emailAllowTransfer = emailAllowTransfer;

    // Telephone number and email address
    this.telephoneNumber = obj.phone || obj.telephone_number || null; // CRM - VLS - null
    this.emailAddress = obj.emailAddress || obj.email_address || null; // CRM - VLS - null

    // Default the title
    this.title = obj.title || '';

    // Remove [HIGHLIGHT]...[/HIGHLIGHT] tags
    this.title = this.title.replace(/\[HIGHLIGHT\]/g, '');
    this.title = this.title.replace(/\[\/HIGHLIGHT\]/g, '');

    // Parse updated date
    this.updatedOn =
      typeof obj.updated === 'string' ? new Date(obj.updated) : null;

    // Parse created date
    this.createdOn =
      typeof obj.created === 'string' ? new Date(obj.created) : null;

    this.office_hours = obj.office_hours;
  }

  // Helper function to clone an instance of this class
  clone() {
    return new Answer(this);
  }

  // Getter for updated
  get updated() {
    // Exit early if updated date is unknown
    if (!this.updatedOn) {
      return '';
    }

    // Return a formatted date
    return formatDate(this.updatedOn, 'dd/MM/yyyy HH:mm:ss');
  }

  // Getter for created
  get created() {
    // Exit early if updated date is unknown
    if (!this.createdOn) {
      return '';
    }

    // Return a formatted date
    return formatDate(this.createdOn, 'dd/MM/yyyy HH:mm:ss');
  }

  // Getter for last updated in days
  get lastUpdatedInDays() {
    // Exit early if updated date is unknown
    if (!this.updatedOn) {
      return false;
    }

    return differenceInDays(new Date(), this.updatedOn);
  }

  // Getter for last created in days
  get lastCreatedInDays() {
    // Exit early if updated date is unknown
    if (!this.createdOn) {
      return false;
    }

    return differenceInDays(new Date(), this.createdOn);
  }

  // Getter for email transfer information
  get emailTransferInfo() {
    // Transfer is allowed, show the phone number
    if (this.emailAllowTransfer) {
      return this.emailAddress;
    }

    // Transfer is not allowed, show 'No'
    if (this.emailAllowTransfer === false) {
      return 'Nee';
    }

    // Fall back to an empty string (e.g. we received the anwer from the CRM, but is hasn't been enriched with VLS data)
    return '';
  }

  // Getter for phone transfer information
  get telephoneTransferInfo() {
    // Transfer is allowed, show the phone number
    if (this.telephoneAllowTransfer) {
      return this.telephoneNumber;
    }

    // Transfer is not allowed, show 'No'
    if (this.telephoneAllowTransfer === false) {
      return 'Nee';
    }

    // Fall back to an empty string (e.g. we received the anwer from the CRM, but is hasn't been enriched with VLS data)
    return '';
  }

  addLeadingZeros(number) {
    return `0${number}`.slice(-2);
  }

  isEscalationOpen() {
    if (this.office_hours === undefined || this.office_hours === null) {
      return true;
    }

    let date = new Date();
    let daysPeriods = this.office_hours.days;
    let openStatus = false;
    let compareDay = date.getDay();

    const compareTime = parseInt(
      `${date.getHours()}:${this.addLeadingZeros(date.getMinutes())}`.replace(
        ':',
        '',
      ),
      10,
    );

    // The data we are comparing with considers Monday to be the first
    // day of the week, while JavaScript considers that Sunday. Convert the
    // retrieved day.
    compareDay = compareDay === 0 ? 6 : compareDay - 1;

    const matchedDay = _.find(daysPeriods, { day: compareDay });
    if (matchedDay) {
      matchedDay.periods.some(period => {
        const periodTimeStart = parseInt(
          `${period.start.hours}:${this.addLeadingZeros(
            period.start.minutes,
          )}`.replace(':', ''),
          10,
        );
        const periodTimeEnd = parseInt(
          `${period.end.hours}:${this.addLeadingZeros(
            period.end.minutes,
          )}`.replace(':', ''),
          10,
        );
        if (compareTime >= periodTimeStart && compareTime <= periodTimeEnd) {
          openStatus = true;
        }
        return openStatus;
      });
    }

    return openStatus;
  }

  // Helper function to retrieve an object to sync to the API
  toApiObject() {
    return {
      id: this.crmId,
      title: this.title || '',
      subType: this.category || '',
      articleId: this.id || '',
    };
  }
}

// Export class
export default Answer;
