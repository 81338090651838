import { FETCH } from '@/constants/action-types';
import { makeAsyncMutationTypes } from '@/utils';
import { handleError } from '@/utils';

const fetch = makeAsyncMutationTypes(FETCH);

export default {
  [fetch.FAILURE](state, error) {
    state.error = error;
    state.isLoading = false;
  },

  [fetch.REQUEST](state) {
    state.isLoading = true;
  },

  [fetch.SUCCESS](state, data) {
    Object.keys(data).forEach(key => {
      if (key === 'vlplabels') {
        try {
          const response = data[key];

          if (response == null) {
            throw new Error(`No response from vlplabels endpoint`);
          }

          const { value } = response;

          state[key] = JSON.parse(value);
        } catch (e) {
          handleError(e);
        }
      } else {
        state[key] = data[key];
      }
    });

    state.isLoading = false;
    state.hasFetched = true;
  },
};
