// todo: refactor

import { crmAxios } from '@/utils/Axios';

export default ({ state, commit }, questionInstance) => {
  // Exit early if no contact
  if (!state.contact) {
    return Promise.resolve();
  }

  // Update the loading state
  questionInstance.loading = true;

  // Get the data to post to the CRM api
  const data = questionInstance.toApiObject();

  // Build the url
  let url = `/Contact/${state.contact.id}/questions`;
  let method = 'post';
  if (questionInstance.id) {
    url = `/Question/${questionInstance.id}`;
    method = 'put';
  }

  // Call the API
  return crmAxios({
    method,
    url,
    data,
    headers: {
      Accept: 'application/json',
    },
  })
    .then(response => {
      // Validate response
      if (!response.data) {
        throw new Error(
          'CRM Error: No response received when saving question.',
        );
      }

      // Update the question instance
      if (response.data.id) {
        questionInstance.id = response.data.id;
      }

      if (response.data.questionNumber) {
        questionInstance.questionNumber = response.data.questionNumber;
      }

      if (response.data.createdOn) {
        questionInstance.createdOn = new Date(response.data.createdOn);
      }

      // Update the loading state
      questionInstance.loading = false;

      // Commit a success mutation
      commit('saveQuestionSuccess', response.data);

      // Return the question instance
      return questionInstance;
    })
    .catch(error => {
      // Update the loading state
      questionInstance.loading = false;

      commit('saveQuestionError', error);

      throw error;
    });
};
