import actions from './actions';
import getters from './getters';
import mutations from './mutations';

const state = {
  contact: undefined,
  isLegacy: false,
  isLoggedIn: false,
  isConnected: false,
  loading: false,
  loadingError: false,
  hasSavedEndRegistration: false,
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
