<template>
  <div class="p-4">
    <div class="flex items-center mb-4">
      <span class="uppercase font-bold mr-4">Status:</span>

      <span>{{ readableInteractionState }}</span>
    </div>

    <VlAlert v-if="error" :content="error.message" :mod-error="true" />

    <form v-else class="mb-4">
      <div class="flex items-baseline justify-between">
        <div v-if="!hasInitialized">
          <VlInputField
            v-model="outboundNumber"
            placeholder="Telefoonnummer"
            :disabled="isDisabled"
            required
          />
        </div>

        <VlInputField
          v-else
          :disabled="true"
          :value="data.interactionRemoteAddress || data.outboundNumber"
        />

        <div class="flex items-center">
          <VlButton
            :mod-narrow="true"
            @click.prevent="
              hasInitialized ? handleOnToggle() : handleOnSubmit()
            "
            :mod-disabled="isDisabled || !outboundNumber"
            :title="title"
          >
            <CustomIcon :icon="iconName" />
          </VlButton>

          <VlButton
            title="Opleggen"
            class="divide ml-4"
            :mod-disabled="!hasInitialized || hasCalled"
            :mod-narrow="true"
            @click.prevent="handleOnEndInteraction"
          >
            <CustomIcon icon="stop" />
          </VlButton>
        </div>
      </div>
    </form>

    <div class="flex justify-end">
      <VlLoader v-if="isDeleting" class="mr-4" />

      <a
        :class="['vl-link', { disabled: hasInitialized }]"
        href
        @click.prevent="handleOnDelete"
      >
        <VlIcon icon="trash" />

        <span>Verwijderen</span>
      </a>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import {
  DIALING,
  EXTERNALLY_DISCONNECTED,
  HELD,
  INTERNALLY_DISCONNECTED,
  RINGING,
} from '@/constants/interaction-states';
import { UPDATE_OUTBOUND_CALL } from '@/constants/mutation-types';
import { getReadableInteractionState } from '@/utils';
import { crmAxios } from '@/utils/Axios';

export default {
  name: 'OutgoingCall',

  props: ['id', 'isConnected', 'data'],

  data() {
    return {
      error: null,
      isDeleting: false,
      isLoading: false,
      outboundNumber: this.data.phoneNumber,
    };
  },

  computed: {
    ...mapGetters('cti', ['getInteractionById', 'isLoggedIn']),
    ...mapGetters('contact', ['workgroupName']),

    ctiInteraction() {
      return this.getInteractionById(this.data.interactionId) || {};
    },

    ctiInteractionState() {
      return this.ctiInteraction.interactionState;
    },

    iconName() {
      return this.isConnected && !this.isOnHold ? 'pause' : 'play';
    },

    isCalling() {
      return [DIALING, RINGING].includes(this.ctiInteractionState);
    },

    isDisabled() {
      return this.isCalling || this.hasCalled || !this.isLoggedIn;
    },

    isOnHold() {
      return this.ctiInteractionState === HELD;
    },

    hasCalled() {
      return (
        this.data.endTransfer != null ||
        [EXTERNALLY_DISCONNECTED, INTERNALLY_DISCONNECTED].includes(
          this.ctiInteractionState,
        )
      );
    },

    hasInitialized() {
      return this.hasCalled || !!this.ctiInteractionState;
    },

    readableInteractionState() {
      return getReadableInteractionState(this.ctiInteractionState);
    },

    title() {
      if (!this.hasInitialized) {
        return 'Bellen';
      }

      if (!this.isConnected) {
        return '';
      }

      if (this.isOnHold) {
        return 'Burger terugnemen';
      }

      return 'Wachtmuziek geven';
    },
  },

  methods: {
    ...mapActions('cti', [
      'acceptInteraction',
      'endInteraction',
      'pauseInteraction',
    ]),

    handleOnDelete() {
      this.isDeleting = true;

      return crmAxios({
        method: 'delete',
        url: `/OutboundCall/${this.id}`,
      })
        .then(() =>
          this.$store.dispatch('contact/sync', undefined, { root: true }),
        )
        .catch(error => {
          this.error = error;

          this.isDeleting = false;
        });
    },

    handleOnEndInteraction() {
      this.endInteraction(this.data.interactionId);
    },

    handleOnToggle() {
      const { interactionId } = this.data;

      this.isConnected
        ? this.pauseInteraction(interactionId)
        : this.acceptInteraction(interactionId);
    },

    handleOnSubmit() {
      this.isLoading = true;

      this.$store
        .dispatch('cti/call', {
          target: this.outboundNumber,
          workgroup: this.workgroupName,
        })
        .then(response => {
          if (!response) {
            throw new Error('Did not receive response from CTI');
          }

          const { data } = response;

          if (!data.interactionId) {
            throw new Error('Did not receive interaction ID from CTI');
          }

          const newData = {
            ...this.data,
            ...data,
            outboundNumber: this.outboundNumber,
            startTransfer: new Date(),
          };

          // optimistic update
          this.$store.commit(`contact/${UPDATE_OUTBOUND_CALL}`, {
            id: this.id,
            data: newData,
          });

          return this.updateCrmRecord(newData);
        })
        .catch(error => {
          this.isLoading = false;

          this.error = error;
        });
    },

    updateCrmRecord(data) {
      return crmAxios({
        method: 'put',
        url: `/OutboundCall/${this.id}`,
        data,
      })
        .then(() =>
          this.$store.dispatch('contact/sync', undefined, { root: true }),
        )
        .catch(error => {
          this.error = error;

          this.isDeleting = false;
        });
    },
  },

  watch: {
    isConnected(isConnected) {
      if (isConnected) {
        this.$emit('start');
      } else {
        this.$emit('stop');
      }
    },

    ctiInteractionState(newVal) {
      const hasEnded = [
        EXTERNALLY_DISCONNECTED,
        INTERNALLY_DISCONNECTED,
      ].includes(newVal);

      if (hasEnded) {
        const newData = { ...this.data, endTransfer: new Date() };

        this.updateCrmRecord(newData);
      }
    },
  },
};
</script>
