import { crmAxios } from '@/utils/Axios';

import { CREATE } from '@/constants/action-types';

import { makeAsyncMutationTypes } from '@/utils';

const { FAILURE, REQUEST, SUCCESS } = makeAsyncMutationTypes(CREATE);

export default ({ commit, dispatch, rootGetters }, payload = {}) => {
  commit(REQUEST);

  const to = rootGetters['contact/selectedCitizenEmail'];

  const data = {
    bcc: '',
    body: '',
    cc: '',
    subject: '',
    to,
    ...payload,
  };

  const contactId = rootGetters['contact/contactId'];

  return crmAxios({
    method: 'post',
    url: `/Contact/${contactId}/emails`,
    data,
  })
    .then(({ data: { id } }) => {
      commit(SUCCESS, id);

      dispatch('contact/sync', contactId, { root: true });

      return dispatch('fetch', id);
    })
    .catch(error => {
      commit(FAILURE, error);
    });
};
