import store from '@/store';

import { crmAxios } from '@/utils/Axios';

export default data => {
  const currentCallback = store.getters['contact/currentCallback'];

  if (currentCallback == null) {
    console.warn('Expecting callback but none found');
    return;
  }

  const parentInteractionId = store.getters['cti/parentInteractionId'];

  return crmAxios({
    method: 'put',
    url: `/Callback/${currentCallback.id}`,
    data: {
      ...currentCallback,
      ...data,
      interactionId: parentInteractionId,
    },
  });
};
