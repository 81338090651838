import { last, toLower } from 'lodash';

import { languages } from '@/config';

export default ({ interactionWorkgroup }) => {
  const parts = interactionWorkgroup.split('_');

  const lastPart = last(parts);

  const { key } =
    languages.find(({ label }) => toLower(label) === toLower(lastPart)) || {};

  return key;
};
