<template>
  <!-- Wrapper -->
  <component :is="tagName" :class="classes" @keyup.esc="onEscape">
    <!-- Backdrop -->
    <div class="vlp__modal__backdrop" @click.prevent="onBackdropClick"></div>

    <!-- Modal -->
    <dialog
      ref="dialog"
      class="vlp__modal__dialog"
      role="dialog"
      aria-modal="true"
      open="true"
    >
      <!-- Drag handle -->
      <div
        v-if="draggable"
        ref="drag"
        class="vlp__modal__drag"
        @mousedown="startDrag"
        @mouseup="stopDrag"
      ></div>

      <!-- Close button -->
      <button
        type="button"
        class="vl-modal-dialog__close"
        v-if="closable"
        @click="onCloseButtonClick"
      >
        <vl-icon icon="cross" class="vlp__modal__close" />
        <span v-vl-visually-hidden>Venster sluiten</span>
      </button>

      <!-- Title -->
      <h2 v-if="title" class="vlp__modal__title">{{ title }}</h2>

      <!-- Content -->
      <div class="vlp__modal__content">
        <slot />
      </div>

      <!-- Footer -->
      <slot name="modal-footer" />
    </dialog>
  </component>
</template>

<script>
import { VlIcon } from '@govflanders/vl-ui-vue-components';

export default {
  components: {
    VlIcon,
  },
  props: {
    tagName: {
      type: String,
      default: 'div',
      required: false,
    },

    title: {
      type: String,
      required: false,
    },

    draggable: {
      type: Boolean,
      required: false,
      default: true,
    },

    closable: {
      type: Boolean,
      required: false,
      default: true,
    },
  },

  data() {
    return {
      // Indicator if dragging
      isDragging: false,

      // Initial position when starting dragging
      dragStartX: 0,
      dragStartY: 0,
    };
  },

  computed: {
    // Classes on wrapper
    classes() {
      return {
        vlp__modal: true,
        'vlp__modal--dragging': this.isDragging,
      };
    },
  },

  methods: {
    // Triggered when entering escape key
    onEscape() {
      // Exit early if not closable
      if (!this.closable) {
        return;
      }

      // Default, emit a `closed` event
      this.$emit('closed');
    },

    beforeDestroy() {
      this.removeDragListener();
    },

    // Triggered when clicking on the backdrop
    onBackdropClick() {
      // Exit early if not closable
      if (!this.closable) {
        return;
      }

      // Default, emit a `closed` event
      this.$emit('closed');
    },

    // Triggered when clicking the close button
    onCloseButtonClick() {
      // Emit a `closed` event
      this.$emit('closed');
    },

    // Helper to setup the drag listener
    addDragListener() {
      window.addEventListener('mousemove', this.onDrag);
      window.addEventListener('mouseup', this.removeDragListener);
    },

    // Helper to remove the drag listener
    removeDragListener() {
      // Unmark dragging
      this.isDragging = false;

      window.removeEventListener('mousemove', this.onDrag);
      window.removeEventListener('mouseup', this.removeDragListener);
    },

    // Triggered when start dragging
    startDrag(evt) {
      // Get the start position relative to the drag handle
      const drag = this.$refs.drag.getBoundingClientRect();
      this.dragStartX = evt.clientX - drag.left;
      this.dragStartY = evt.clientY - drag.top;

      // Mark dragging
      this.isDragging = true;

      // Add drag listener
      this.addDragListener();
    },

    // Triggered when stop dragging
    stopDrag() {
      // Remove drag listener
      this.removeDragListener();
    },

    // Triggered when dragging
    onDrag(evt) {
      // Exit early if not dragging
      if (!this.isDragging) {
        return;
      }

      // Reference to the dialog and dragging element
      const dialog = this.$refs.dialog;

      // Get the new top left position
      let newLeft = evt.clientX - this.dragStartX;
      let newTop = evt.clientY - this.dragStartY;

      // Get the dimensions of the dialog
      const { width, height } = dialog.getBoundingClientRect();

      // Prevent negative overflow X
      if (newLeft < 5) {
        newLeft = 5;
      }

      // Prevent positive overflow X
      if (newLeft + width > window.innerWidth - 5) {
        newLeft = window.innerWidth - width - 5;
      }

      // Prevent negative overflow Y
      if (newTop < 5) {
        newTop = 5;
      }

      // Prevent positive overflow Y
      if (newTop + height > window.innerHeight - 5) {
        newTop = window.innerHeight - height - 5;
      }

      // Update the position, taking into account the dimensions of the dialog
      dialog.style.transform = '';
      dialog.style.left = `${newLeft + width / 2}px`;
      dialog.style.top = `${newTop + height / 2}px`;
    },
  },
};
</script>

<style lang="scss">
// Wrapper
.vlp__modal {
  // No overflow to prevent horizontal document scroll on dragging
  overflow: hidden;

  &.is-large .vlp__modal__dialog {
    width: 100%;
    max-width: 70%;
  }
}

// Wrapper, when dragging
.vlp__modal--dragging {
  // No selection
  * {
    user-select: none;
    pointer-events: none;
  }

  // No drag cursor on handle + allow pointer events (drag)
  .vlp__modal__drag {
    cursor: default;
    pointer-events: auto;
  }
}

// Backdrop
.vlp__modal__backdrop {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 10008;
  background: rgba(255, 255, 255, 0.3);
}

// Dialog
.vlp__modal__dialog {
  display: block;
  max-width: 95vw;
  max-height: 95vh;

  margin: 0;
  padding: 3rem;

  background-color: #fff;
  border: 0;
  box-shadow: 0 0 2.1rem 0 rgba(0, 0, 0, 0.3);
  overflow: auto;
  z-index: 10010;

  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

// Drag handle
.vlp__modal__drag {
  cursor: move;
  height: 30px;
  position: absolute;
  top: 0;
  left: 0;
  right: 40px;
}

// Title
.vlp__modal__title {
  font-weight: 500;
  font-size: 2rem;
  margin-bottom: 1.5rem;
}
</style>
