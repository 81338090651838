<template>
  <div class="p-4">
    <div class="flex items-center mb-4">
      <span class="uppercase font-bold mr-4">Status:</span>

      <span>{{ readableInteractionState }}</span>
    </div>

    <div class="flex items-center justify-between">
      <div>
        <div class="font-bold">{{ data.interactionRemoteAddress }}</div>

        <div class="text-grey flex items-center">
          <CustomIcon icon="time" />

          <span class="ml-4">
            IVR:
            {{ data.interactionIvrDurationInSeconds | secondsToTime }}
          </span>
        </div>
      </div>

      <div class="flex items-center">
        <VlButton
          :title="isOnHold ? 'Burger terugnemen' : 'Wachtmuziek geven'"
          :mod-narrow="true"
          @click.prevent="handleOnToggle"
          :mod-disabled="isDisabled"
        >
          <CustomIcon :icon="isOnHold ? 'play' : 'pause'" />
        </VlButton>

        <VlButton
          title="Afleggen"
          class="divide ml-4"
          :mod-disabled="!isConnected || isDisabled"
          :mod-narrow="true"
          @click.prevent="handleOnEndInteraction"
        >
          <CustomIcon icon="stop" />
        </VlButton>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import {
  EXTERNALLY_DISCONNECTED,
  HELD,
  INTERNALLY_DISCONNECTED,
  UNASSIGNED,
} from '@/constants/interaction-states';
import { getReadableInteractionState } from '@/utils';

export default {
  name: 'IncomingCall',

  intervalId: null,

  props: ['data', 'isConnected'],

  computed: {
    ...mapGetters('cti', ['getInteractionById', 'isConnectedByInteractionId']),
    ...mapGetters('sidebar', ['outboundCallBOInteractions']),

    ctiInteraction() {
      return this.getInteractionById(this.data.interactionId) || {};
    },

    ctiInteractionState() {
      return this.ctiInteraction.interactionState;
    },

    hasCalled() {
      return (
        this.data.endTransfer != null ||
        [EXTERNALLY_DISCONNECTED, INTERNALLY_DISCONNECTED, UNASSIGNED].includes(
          this.ctiInteractionState,
        )
      );
    },

    isOnHold() {
      return this.ctiInteractionState === HELD;
    },

    isDisabled() {
      const isWaitingForBackOffice = this.outboundCallBOInteractions.reduce(
        (acc, { data: { interactionId } }) =>
          acc || this.isConnectedByInteractionId(interactionId),
        false,
      );

      return this.hasCalled || isWaitingForBackOffice;
    },

    readableInteractionState() {
      return getReadableInteractionState(this.ctiInteractionState);
    },
  },

  methods: {
    ...mapActions('cti', [
      'acceptInteraction',
      'endInteraction',
      'pauseInteraction',
    ]),

    handleOnEndInteraction() {
      this.endInteraction(this.data.interactionId);
    },

    handleOnToggle() {
      const { interactionId } = this.data;

      this.isConnected
        ? this.pauseInteraction(interactionId)
        : this.acceptInteraction(interactionId);
    },
  },

  mounted() {
    this.$emit('start');
  },

  watch: {
    isConnected(isConnected) {
      if (!isConnected) {
        this.$emit('stop');
      }
    },
  },
};
</script>
