<template>
  <div class="p-8">
    <div class="flex items-center mb-4">
      <div class="font-bold uppercase mr-4">van:</div>
      <div>{{ data.from }}</div>
    </div>

    <div class="flex items-center mb-4" v-if="data.cc">
      <div class="font-bold uppercase mr-4">cc:</div>
      <div>{{ data.cc }}</div>
    </div>

    <div class="flex items-center mb-4" v-if="data.bcc">
      <div class="font-bold uppercase mr-4">cc:</div>
      <div>{{ data.bcc }}</div>
    </div>

    <div class="flex items-center mb-4">
      <div class="font-bold uppercase mr-4">naar:</div>
      <div>{{ data.to }}</div>
    </div>

    <div class="flex items-center mb-4">
      <div class="font-bold uppercase mr-4">onderwerp:</div>
      <div>{{ data.subject }}</div>
    </div>

    <div :class="['clearfix', $style.bodyContainer]">
      <div v-html="data.body" />
    </div>

    <Attachment
      class="ml-4"
      v-for="attachment in data.attachments || []"
      :key="attachment.id"
      :attachment="attachment"
    />

    <div class="mt-4 flex items-center">
      <VlButton
        @click="replyToEmail"
        :mod-disabled="!canReply"
        :mod-loading="isLoading"
        >Antwoorden</VlButton
      >
    </div>

    <vl-alert v-if="error" :modError="true" icon="warning" class="mt-4">{{
      error.message
    }}</vl-alert>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import { saveAs } from 'file-saver';
import { nl } from 'date-fns/locale';
import { format, parseISO } from 'date-fns';
import { prop } from 'lodash/fp';

import { crmAxios } from '@/utils/Axios';
import { OUTGOING_EMAIL } from '@/constants';
import { ADD_INTERACTION } from '@/constants/mutation-types';
import { handleError } from '@/utils';

import Attachment from './Attachment';

export default {
  name: 'IncomingEmail',

  components: { Attachment },

  data() {
    return {
      error: null,
      isLoading: false,
    };
  },

  computed: {
    ...mapGetters('emails', {
      data: 'getInitialEmail',
      canReply: 'canReply',
    }),
    ...mapGetters('contact', ['selectedCitizenEmail']),
    ...mapState('metadata', ['blockedEmailAddresses']),
  },

  methods: {
    ...mapActions('sidebar', [ADD_INTERACTION]),

    openAttachment({ id, fileName }) {
      crmAxios({
        url: `/Attachment/${id}`,
        responseType: 'blob',
      }).then(({ data, headers }) => {
        try {
          saveAs(new File([data], fileName, { type: headers['content-type'] }));
        } catch (e) {
          handleError(e);
        }
      });
    },

    replyToEmail() {
      this.isLoading = true;

      const getNiceDate = (string) => {
        const date = parseISO(string);

        return format(date, 'PPPPpppp', {
          locale: nl,
        });
      };

      const getBody = ({ body: initialMailBody, createdOn, from }) => {
        const parser = new DOMParser();
        const htmlDoc = parser.parseFromString(initialMailBody, 'text/html');
        const bodyAsString = htmlDoc.body.innerHTML;

        const prefix =
          createdOn && from
            ? `<p></p><p>Op ${getNiceDate(createdOn)} schreef ${from}:</p>`
            : '';

        return `<p></p>${prefix}<p>${bodyAsString}</p>`;
      };

      const to = this.blockedEmailAddresses
        .map(prop('name'))
        .includes(this.data.from)
        ? this.selectedCitizenEmail
        : this.data.from;

      const data = {
        to,
        body: getBody(this.data),
        subject: `Antwoord op ${this.data.subject}`,
      };

      this[ADD_INTERACTION]({ type: OUTGOING_EMAIL, data })
        .then(() => {
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
          this.error = error;
        });
    },
  },

  mounted() {
    this.$emit('start');
  },
};
</script>

<style module>
.bodyContainer {
  background: #f4f5f7;
  border: 1px solid #cbd2da;
  margin: 2rem 0;
  padding: 1rem;
}

.bodyContainer pre {
  white-space: pre-line;
}
</style>
