// native socket.io events
export const CONNECT = 'connect';
export const CONNECT_ERROR = 'connect_error';
export const CONNECT_TIMEOUT = 'connect_timeout';
export const ERROR = 'error';
export const DISCONNECT = 'disconnect';
export const DISCONNECTING = 'disconnecting';
export const MESSAGE = 'message';
export const PING = 'ping';
export const PONG = 'pong';
export const RECONNECT = 'reconnect';
export const RECONNECT_ATTEMPT = 'reconnect_attempt';
export const RECONNECTING = 'reconnecting';
export const RECONNECT_ERROR = 'reconnect_error';
export const RECONNECT_FAILED = 'reconnect_failed';

// custom events
export const DISCONNECTED = 'DISCONNECTED';
export const INIT_ROOM = 'INIT_ROOM';
export const IS_TYPING = 'IS_TYPING';
export const JOIN_ROOM = 'JOIN_ROOM';
export const JOINED_ROOM = 'JOINED_ROOM';
export const LEAVE_ROOM = 'LEAVE_ROOM';
export const LEFT_ROOM = 'LEFT_ROOM';
export const METADATA = 'METADATA';
export const NO_ROOM_ID = 'NO_ROOM_ID';
export const PLACE_IN_QUEUE = 'PLACE_IN_QUEUE';
export const SYNC_ROOM = 'SYNC_ROOM';
export const TERMINATE = 'TERMINATE';

// error events
export const AUTHENTICATION_ERROR = 'AUTHENTICATION_ERROR';
export const SERVER_ERROR = 'SERVER_ERROR';

// other constants
export const AGENT = 'AGENT';
export const CITIZEN = 'CITIZEN';
export const BOT = 'BOT';
export const COOKIE_NAME_ROOM_ID = 'vl-chat-roomId';
export const ME = 'ME';
export const THEM = 'THEM';
