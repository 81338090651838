<template>
  <div class="vlw__header">
    <PrimaryHeader :style="appEnvStyle">
      <!-- Left -->
      <template v-slot:left>
        <div v-if="appVersion" v-html="appVersion" />
      </template>

      <!-- Right -->
      <template v-slot:right>
        <label class="mr-8">
          <span class="mr-2">Dark Mode</span>
          <input type="checkbox" v-model="isDarkMode" />
        </label>

        <VlLink :href="linkToCRM" target="_blank">CRM</VlLink>

        <div class="flex items-center ml-8">
          <div class="vlw__header__user-image"></div>
          <div v-if="userName" class="vlw__header__user-name">
            {{ userName }}
          </div>
        </div>

        <div v-if="user.isLoggedIn" class="ml-8 flex justify-center">
          <select
            class="vl-select"
            v-model="user.status"
            @change="onChangeStatus"
          >
            <option
              v-for="{ status, isSelectable } in statusMessages"
              :key="status"
              :value="status"
              :disabled="!isSelectable"
              >{{ status }}</option
            >
            <option value="Log out">Log out</option>
          </select>

          <!-- Timer -->
          <StatusTimer class="ml-4" />
        </div>

        <CtiLogin v-else />

        <VlButton
          class="ml-8"
          @click.prevent="handleLogOut"
          title="Volledig uitloggen"
          :mod-narrow="true"
        >
          <VlIcon icon="logout" />
        </VlButton>
      </template>
    </PrimaryHeader>

    <CTIToolBar />
  </div>
</template>

<script>
import { identity } from 'lodash';
import { mapState, mapActions, mapGetters } from 'vuex';
import CTIToolBar from '@/components/header/CTIToolbar';
import PrimaryHeader from '@/components/header/PrimaryHeader';
import bus from '@/utils/bus';
import CtiLogin from './CtiLogin';
import StatusTimer from '@/components/StatusTimer';

const {
  VUE_APP_CRM_URL,
  VUE_APP_ENV,
  VUE_APP_ENV_COLOR,
  VUE_APP_VERSION,
} = process.env;

const LOG_OUT = 'Log out';

export default {
  components: {
    CTIToolBar,
    CtiLogin,
    PrimaryHeader,
    StatusTimer,
  },
  computed: {
    ...mapGetters('cti', ['isConnected', 'isAlerting']),
    ...mapGetters('authentication', ['isLoggedIn', 'userName']),
    ...mapState('cti', ['statusMessages', 'user']),
    ...mapGetters('metadata', ['getLabelByName']),

    isDarkMode: {
      get() {
        return this.$store.state.ui.isDarkMode;
      },

      set(value) {
        this.$store.commit('ui/UPDATE_DARK_MODE', value);
      },
    },

    appVersion() {
      return `v${[VUE_APP_VERSION, VUE_APP_ENV]
        .filter(identity)
        .join(' &mdash; ')}`;
    },

    appEnvStyle() {
      return VUE_APP_ENV_COLOR ? `background: ${VUE_APP_ENV_COLOR};` : null;
    },

    linkToCRM() {
      return VUE_APP_CRM_URL;
    },
  },
  methods: {
    ...mapActions('cti', {
      updateStatus: 'updateStatus',
      ctiLogOut: 'logOut',
    }),
    ...mapActions('authentication', {
      crmLogOut: 'logOut',
    }),

    // Triggered when user confirms logout
    confirmLogOut() {
      Promise.all([this.ctiLogOut(), this.crmLogOut()]);
    },

    // Triggered when clicking the logout button
    handleLogOut() {
      // Prevent logout if currently connected or alerting
      if (this.isConnected || this.isAlerting) {
        // Exit early
        const label = this.getLabelByName('connected_logout');

        return bus.$emit('show-modal', {
          message: label.title,
          body: label.body,
          confirmText: label.confirm,
          confirm: true,
        });
      }

      // Ask user to confirm logout
      const label = this.getLabelByName('confirm_logout');

      bus.$emit('show-modal', {
        message: label.title,
        body: label.body,
        confirmText: label.confirm,
        confirm: this.confirmLogOut,
        cancel: true,
        cancelText: label.cancel,
      });
    },

    // Status change
    onChangeStatus({ currentTarget: { value: id } }) {
      // Check if logout was chosen
      if (id === LOG_OUT) {
        this.ctiLogOut();
        return;
      }

      // Dispatch action to the CTI store
      this.updateStatus(id);

      // Close the popover
      if (this.$refs.statusPopover) {
        this.$refs.statusPopover.expanded = false;
      }
    },
  },
};
</script>

<style lang="scss">
.vlw__header__user-image {
  background: #d8d8d8;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  margin-right: 5px;
}

.vlw__header__user-name {
  line-height: 24px;
  text-transform: uppercase;
  font-weight: 500;
}
</style>
