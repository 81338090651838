<template>
  <!-- Form wrapper -->
  <form
    :action="uri"
    method="post"
    target="_blank"
    @submit.prevent="handleOnSubmit"
  >
    <VlAlert
      v-if="validation"
      :mod-error="true"
      :content="validation.message"
    />
    <VlAlert v-if="error" :mod-error="true" :content="error.message" />

    <div>
      <!-- Link -->
      <VlLink
        v-if="isLink"
        target="_blank"
        :href="linkUrl"
        icon="external"
        :mod-icon-after="true"
        class="vl-button vl-button--narrow vl-button--secondary"
        >{{ name }}</VlLink
      >

      <!-- Button -->
      <VlButton
        v-if="!isLink"
        icon="external"
        :mod-narrow="true"
        :mod-secondary="true"
        :mod-icon-after="true"
        :mod-loading="isLoading"
        >{{ name }}</VlButton
      >

      <!-- Hidden form inputs to submit -->
      <input
        v-for="hi in hiddenInputs"
        :key="hi.name"
        type="hidden"
        :name="hi.name"
        :value="hi.value"
      />
    </div>
  </form>
</template>

<script>
// Import modules
import { mapState } from 'vuex';
import { entries, toLower } from 'lodash';
import { crmAxios } from '@/utils/Axios';
import { stripNonNumerics } from '@/utils';

const [ECAD, KINDENGEZIN002, STUDIETOELAGEN, ZOFA, ZORBA, POFI_VKB, POFI_OV] = [
  'ECAD',
  'KINDENGEZIN002',
  'STUDIETOELAGEN',
  'ZOFA',
  'ZORBA',
  'POFI_VKB',
  'POFI_OV',
];

// Export component
export default {
  props: {
    question: {
      type: Object,
      required: true,
    },

    uri: {
      type: String,
      required: false,
    },

    technicalName: {
      type: String,
      required: true,
    },

    name: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      error: null,
      validation: null,
      isLoading: false,

      // Array of objects with `name` and `value` to use for rendering hidden inputs in the form
      hiddenInputs: [],
    };
  },

  computed: {
    ...mapState('mirage', ['miragePersonsById']),

    // Computed to get the national registry number (numbers only)
    nationalNumberOnlyNumbers() {
      return stripNonNumerics(this.question.nationalRegistryNumber || '');
    },

    // Computed to check if the button is a simple link
    isLink() {
      // Always true if technical name is unknown
      if (
        ![
          ECAD,
          KINDENGEZIN002,
          STUDIETOELAGEN,
          ZOFA,
          ZORBA,
          POFI_OV,
          POFI_VKB,
        ].includes(this.technicalName)
      ) {
        return true;
      }

      // Exception for ECAD if no RRN is given
      if (this.technicalName === ECAD && !this.nationalNumberOnlyNumbers) {
        return true;
      }

      // Default, only for K&G
      return [KINDENGEZIN002].includes(this.technicalName);
    },

    // Computed to return the link url
    linkUrl() {
      if (this.technicalName !== KINDENGEZIN002) {
        return this.uri;
      }

      const { mirageUrl } =
        this.miragePersonsById[this.question.kindEnGezin_PersonId] || {};

      return mirageUrl || this.uri;
    },
  },

  methods: {
    // Triggered when form is submitted
    async handleOnSubmit() {
      // Clear previous errors
      this.error = null;
      this.validation = null;

      // Empty hiddenInputs array
      this.hiddenInputs = [];
      if ([POFI_OV, POFI_VKB].includes(this.technicalName)) {
        if (
          (this.nationalNumberOnlyNumbers === '' ||
            this.nationalNumberOnlyNumbers === null) &&
          (this.question.kboNumber === '' || this.question.kboNumber === null)
        ) {
          this.validation = {
            message: 'RRN of KBO is verplicht om POFI te openen',
          };
          return;
        }
        if (
          this.nationalNumberOnlyNumbers !== '' &&
          this.question.kboNumber !== ''
        ) {
          this.validation = {
            message:
              'Gelieve enkel RRN of KBO op te geven. Beide is niet mogelijk.',
          };
          return;
        }
        if (
          this.question.articleNumber === '' ||
          this.question.articleNumber === null
        ) {
          this.validation = {
            message: 'Kohierartikelnummber is verplicht om POFI te openen',
          };
          return;
        }

        this.isLoading = true;

        var body = {
          nationalNumber: this.nationalNumberOnlyNumbers,
          companyNumber: this.question.kboNumber,
          articleNumber: this.question.articleNumber,
          taxFamily: this.technicalName === POFI_OV ? 'OV' : 'VKB',
        };

        try {
          const response = await crmAxios({
            url: `/ExternalApplication/pofi`,
            method: 'post',
            data: body,
          });

          window.open(response.data, '_blank');
        } catch (error) {
          // Remember error
          if (!error) {
            this.error = error.message || error.toString();
          }
        }
      } else {
        this.isLoading = true;

        // Retrieve parameters for opening external application
        try {
          const {
            data: { body },
          } =
            this.technicalName === STUDIETOELAGEN
              ? { data: { body: {} } }
              : await crmAxios({
                  url: `/ExternalApplication/${toLower(
                    this.technicalName,
                  )}?nationalNumber=${this.nationalNumberOnlyNumbers}`,
                  method: 'post',
                });

          // Update the hiddenInputs array
          entries(body).forEach(([name, value]) =>
            this.hiddenInputs.push({ name, value }),
          );

          // Wait until the next DOM update cycle has finished (hidden inputs are rendered)
          await this.$nextTick();

          // Submit the form (root DOM element of this Vue instance)
          this.$el.submit();
        } catch (error) {
          // Remember error
          if (!error) {
            this.error = error.message || error.toString();
          }
        }
      }

      // Stop loading
      this.isLoading = false;
    },
  },
};
</script>
