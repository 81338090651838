<template>
  <div>
    <VlLoader v-if="isLoading" />

    <VlAlert v-else-if="error" :mod-error="true" :content="error.message" />

    <div v-else>
      <VlAlert v-if="warning" :mod-warning="true" :content="warning" />

      <label class="vl-form__label">
        <span>Voornaam</span>
        <VlInputField :disabled="true" :value="miragePerson.voornaam" />
      </label>

      <label class="vl-form__label">
        <span>Naam</span>
        <VlInputField :disabled="true" :value="miragePerson.naam" />
      </label>

      <label class="vl-form__label">
        <span>Geboortedatum</span>
        <VlInputField
          :disabled="true"
          :value="miragePerson.geboorteDatum | niceDate"
        />
      </label>

      <label v-if="miragePerson.overlijdensDatum" class="vl-form__label">
        <span>Overlijdensdatum</span>
        <VlInputField
          :disabled="true"
          :value="miragePerson.overlijdensDatum | niceDate"
        />
      </label>

      <label class="vl-form__label">
        <span>Postcode</span>
        <VlInputField :disabled="true" :value="miragePerson.postcode" />
      </label>

      <slot />

      <div class="flex justify-end mb-4">
        <VlLink
          :class="['ml-4', { disabled }]"
          href="#"
          title="Kind ontkoppelen"
          @click.prevent="$emit('reset')"
        >
          <VlIcon icon="close" />
        </VlLink>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { mirageApi } from '@/utils';

export default {
  props: {
    miragePersoonId: {
      type: String,
      required: true,
    },

    disabled: {
      type: Boolean,
    },
  },

  data() {
    return {
      error: null,
      isLoading: false,
      miragePerson: {},
    };
  },

  computed: {
    ...mapState('mirage', ['miragePersonsById']),

    warning() {
      const { discretieVereist, overlijdensDatum } = this.miragePerson;

      if (discretieVereist) {
        return 'Let op: Discretie vereist';
      }

      if (overlijdensDatum) {
        const { niceDate } = this.$options.filters;

        return `Let op: Kindje overleden op ${niceDate(overlijdensDatum)}`;
      }
    },
  },

  methods: {
    fetch(miragePersoonId) {
      const storedPerson = this.miragePersonsById[miragePersoonId];
      if (storedPerson) {
        this.miragePerson = storedPerson;
        return;
      }

      this.isLoading = true;

      mirageApi({
        method: 'post',
        url: '/haalPersoon',
        data: miragePersoonId,
        headers: {
          'Content-Type': 'text/plain',
        },
      })
        .then(({ data }) => {
          this.miragePerson = data;

          this.isLoading = false;

          this.$store.commit('mirage/ADD_PERSON', data);
        })
        .catch(error => {
          this.error = error;

          this.isLoading = false;
        });
    },
  },

  mounted() {
    this.$watch('miragePersoonId', this.fetch, { immediate: true });
  },
};
</script>
