<template>
  <!-- Modal wrapper -->
  <Modal
    @closed="$emit('close')"
    id="modal-citizen"
    class="vlw__cti-citizen-modal is-large"
    :open="true"
    :closable="true"
  >
    <!-- Search -->
    <CitizenSearch
      v-show="mode === 'search'"
      :search="internalSearch"
      @add="onCitizenAdd"
      @edit="onCitizenEdit"
      @close="$emit('close')"
      @select="onCitizenSelect"
    />

    <!-- Add / edit -->
    <CitizenEdit
      v-if="mode === 'edit' || mode === 'add'"
      :citizen="editCitizen"
      @save="onCitizenSave"
      @select="onCitizenSelect"
      @close="$emit('close')"
    />
  </Modal>
</template>

<script>
// Imports
import { mapState, mapActions } from 'vuex';
import CitizenSearch from './CitizenSearch.vue';
import CitizenEdit from './CitizenEdit.vue';
import CitizenClass from '../modules/Citizen';

export default {
  components: {
    CitizenSearch,
    CitizenEdit,
  },
  props: {
    // A citizen instance that provides initial search parameters
    search: {
      type: Object,
      required: false,
      default() {
        return new CitizenClass();
      },
    },
  },
  data() {
    return {
      // Copy of the search property, used in this component
      internalSearch: this.search ? this.search.clone() : new CitizenClass(),

      // Citizen to edit
      editCitizen: null,
    };
  },
  methods: {
    ...mapActions('citizen', ['changeMode', 'edit', 'add']),

    // Triggered when selecting a citizen
    onCitizenSelect(citizenInstance) {
      this.$emit('select', citizenInstance);
    },

    // Triggered when the user wants to add a citizen, prefilled with the search parameters
    onCitizenAdd() {
      // Update the editCitizen property
      this.editCitizen = this.internalSearch.clone();

      // Change to add mode
      this.add();
    },

    // Triggered when the user wants to edit a citizen from the search results
    onCitizenEdit(citizenInstance) {
      // Update the editCitizen property
      this.editCitizen = citizenInstance.clone();

      // Dispatch an action to edit the citizen
      this.edit(citizenInstance.id);
    },

    // Method triggered when the user adds/edits the citizen instance
    onCitizenSave(citizenInstance) {
      this.$emit('save', citizenInstance);

      // Go back to the search
      this.changeMode('search');

      // Dispatch the search action with the ID of the created/updated user
      this.$store.dispatch('citizen/search', {
        citizen: new CitizenClass({ id: citizenInstance.id }),
        new: true,
      });
    },
  },
  computed: {
    ...mapState('citizen', ['mode']),
  },
};
</script>
