<template>
  <div>
    <VlLoader v-if="isLoading" />

    <VlAlert v-if="error" :content="error.message" :mod-error="error" />

    <div class="flex">
      <ExternalApplicationButton
        class="mr-4"
        v-for="config in configs"
        :key="config.technicalName"
        :question="question"
        v-bind="config"
      />
    </div>
  </div>
</template>

<script>
import { crmAxios } from '@/utils/Axios';

import ExternalApplicationButton from './ExternalApplicationButton';

export default {
  props: {
    question: {
      type: Object,
      required: true,
    },
  },

  components: { ExternalApplicationButton },

  data() {
    return {
      error: null,
      configs: [],
      isLoading: false,
    };
  },

  mounted() {
    this.$watch('question.themeId', this.fetch, { immediate: true });
  },

  methods: {
    async fetch(themeId = this.question.themeId) {
      this.configs = [];
      this.isLoading = true;

      try {
        const { data: configs } = await crmAxios({
          url: `/ExternalApplication/${themeId}`,
        });

        this.configs = configs;
      } catch (error) {
        console.error({ error });

        this.error = error;
      }

      this.isLoading = false;
    },
  },
};
</script>
