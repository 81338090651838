<template>
  <div class="flex">
    <VlButton
      v-if="!question.isThemeOndernemen"
      class="mr-4"
      :mod-disabled="disabled || !hasCitizen"
      :mod-narrow="true"
      @click.prevent="handleOnManuallyEscalateByEmail"
      icon="email"
      :mod-icon-before="true"
      >Manueel doorsturen</VlButton
    >

    <VlButton
      class="mr-4"
      :mod-disabled="disabled || !isConnected"
      :mod-loading="isEscalatingByPhone"
      :mod-narrow="true"
      @click.prevent="handleOnManuallyEscalateByPhone"
      icon="phone-outgoing"
      :mod-icon-before="true"
      >Manueel doorschakelen</VlButton
    >
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import {
  MANUAL_EMAIL_BACKOFFICE,
  MANUAL_OUTGOING_CALL_BACKOFFICE,
} from '@/constants';

export default {
  props: ['disabled', 'question'],

  data() {
    return {
      isEscalatingByPhone: false,
    };
  },

  computed: {
    ...mapGetters('cti', ['isConnected']),
    ...mapGetters('contact', ['defaultEscalationPointId']),

    hasCitizen() {
      return this.question.citizen.id;
    },
  },

  methods: {
    handleOnManuallyEscalateByEmail() {
      this.$store.dispatch('sidebar/ADD_INTERACTION', {
        type: MANUAL_EMAIL_BACKOFFICE,
        data: {
          questionId: this.question.id,
        },
      });
    },

    async handleOnManuallyEscalateByPhone() {
      this.$store.dispatch('sidebar/ADD_INTERACTION', {
        type: MANUAL_OUTGOING_CALL_BACKOFFICE,
        data: {
          phoneNumber: null,
          outboundNumber: null,
          questionId: this.question.id,
          escalationPointId: this.defaultEscalationPointId,
          resultType: 'TransferHandeledCorrectly', // default
          sourceType: 'ExternalTransfer',
        },
      });
    },
  },
};
</script>

<style></style>
